import React from 'react'
import propTypes from "prop-types";
import { Alert, DatePicker, Form, Input, Modal, Button, Tooltip, theme, Popconfirm, Row, Col, Typography } from "antd";
import { dateToString } from '../../frameworks/Util';
import dayjs from 'dayjs';
import TSTable from "../common/TSTable";
import { DATE_FORMAT } from '../../constants/string';
import Header from '../common/Header';
import { POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../constants/urls';
import { 
  CheckCircleOutlined,
  CheckCircleFilled, 
  CloseCircleFilled, 
  MinusCircleOutlined } from "@ant-design/icons";
import * as _ from 'lodash';


export default function DropPackageModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const tableRef = React.useRef(null);
  const [dropRemains, setDropRemains] = React.useState(null);
  const [usingExtra, setUsingExtra] = React.useState(false);

  const { Text } = Typography;
  const {
    token: { colorSuccess, colorError },
  } = theme.useToken();

  // Handle dropping
  const handleCreateDrop = async () => {
    if (!props.package) {
      setErrorMessages("Package not found!");
      return;
    }

    let data = await form.validateFields();
    setErrorMessages(null);
    setLoading(true);
    data = {
      ...data,
      start_date: data.date[0].format(DATE_FORMAT),
      end_date: data.date[1].format(DATE_FORMAT),
      member_package: props.package.id
    }
    delete data["date"];
    try {
      await POST(URL_PACKAGE.PACKAGE_DROP, data);
      form.resetFields();
      setErrorMessages(null);
      tableRef.current.fetch()
      setDropRemains(dropRemains-1)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelDrop = async (row) => {
    setErrorMessages(null);
    setLoading(true);
    try {
      await POST(`${URL_PACKAGE.PACKAGE_DROP}${row.id}/cancel/`);
      setErrorMessages(null);
      tableRef.current.fetch()
      setDropRemains(dropRemains+1)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const renderStatus = (is_canceled, row) => {
    if (is_canceled) {
      return (
        <Tooltip title={"Cancelled"} trigger="hover" placement="right">
          <CloseCircleFilled style={{ color: colorError }} />
        </Tooltip>
      )
    }

    if (dayjs().isAfter(dayjs(row.end_date))) {
      // Past actived
      return (
        <Tooltip title={"Active"} trigger="hover" placement="right">
          <CheckCircleFilled style={{ color: colorSuccess }} />
        </Tooltip>
      )
    }
    else {
      return (
        <Popconfirm
          title={"Do you want to cancel this drop ?"}
          placement={"topRight"}
          okText={"Cancel Drop"}
          okButtonProps={{
            type: "primary",
            style: { background: colorError },
            icon: <MinusCircleOutlined />
          }}
          cancelText={"Close"}
          onConfirm={() => handleCancelDrop(row)}>
          <Button
            type={"primary"}
            style={{ marginLeft: 4, marginRight: 4, background: colorSuccess }}
            icon={<CheckCircleOutlined />}
          />
        </Popconfirm>
      )
    }
    
  }

  const columns = [
    {
      title: 'From',
      dataIndex: 'start_date',
      align: 'center',
      width: '15%',
      render: (value) => (<label>{value ? dateToString(value) : ""}</label>)
    },
    {
      title: 'To',
      dataIndex: 'end_date',
      align: 'center',
      width: '15%',
      render: (value) => (<label>{value ? dateToString(value) : ""}</label>)
    },
    {
      title: 'Day(s)',
      dataIndex: 'days',
      align: 'center',
      width: '10%',
    },
    {
      title: 'Note',
      dataIndex: 'note',
      align: 'center',
      width: '30%',
    },
    {
      title: 'By',
      dataIndex: 'created_by_full_name',
      align: 'center',
      width: '20%',
    },
    {
      title: 'Status',
      dataIndex: 'is_canceled',
      align: 'center',
      width: '10%',
      render: (value, row) => (<label>{renderStatus(value, row)}</label>)
    }
  ]

  // Disable date into DatePicker
  const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, 'day').endOf('day');
  };

  React.useEffect(() => {
    if (!props.open) {
      form.resetFields();
      setErrorMessages(null);
    }
    else {
      if(tableRef) {
        tableRef.current.fetch();
      }
    }
  }, [props.open]);

  React.useEffect(() => {
    if(props.package){
      setDropRemains(props.package.drop_remain)
      setUsingExtra(props.package.using_extra)
    }else{

    }
  },[props.package])

  return (
    <Modal
      open={props.open}
      width={900}
      okText={"Close"}
      okButtonProps={{loading: loading}}
      onCancel={props.onClose}
      footer={[
          <Button type='primary' key="ok" onClick={props.onClose} loading={loading}>
            Close
          </Button>
      ]}
      >
      <Header title={`Drop Package : ${props.package ? props.package.package_name + " (" + props.package.variant_type + ")" : "-"}`} />
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: 16, textAlign: "left"}}
        />
      }
      <Row gutter={12} style={{ marginTop: '1rem' }} >
        <Col>
          <Text type='secondary'>
            Total Drops: { _.get(props, 'package.max_drop_count', 0) - _.get(props, 'package.drop_remain', 0) }/{ _.get(props, 'package.max_drop_count', 0) }
          </Text>
        </Col>
        <Col>
          <Text type='secondary'>
            Total Drop Days: { _.get(props, 'package.drop_days', 0) }/{ _.get(props, 'package.max_drop_days', 0) } days
          </Text>
        </Col>
      </Row>
      {(!usingExtra && dropRemains && dropRemains > 0 &&
        <Form
          form={form}
          layout="inline"
          onFinish={handleCreateDrop}
          style={{ marginTop: 16 }} >
            <Form.Item
              name={"date"}
              label={"Drop"}
              rules={[{ required: true }]}>
              <RangePicker
                style={{ width: "100%" }}
                format={DATE_FORMAT}
                disabledDate={disabledDate} />
            </Form.Item> 
            <Form.Item name={"note"}  label={"Note"}>
              <Input style={{ width: "280px" }}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" style={{ float: 'right' }} htmlType="submit" loading={loading}>
                New Drop
              </Button>
            </Form.Item>
        </Form>
      )}

      <TSTable
        style={{marginTop: 16}}
        ref={tableRef}
        initialFetch={false}
        columns={props.hideCheckOut
          ? columns.filter(col => col.dataIndex !== 'check_out')
          : columns}
        url={props.package ? `${URL_PACKAGE.PACKAGE_DROP}?member_package=${props.package.id}` : ""}
        rowKey={"id"}
        size={"small"}/>
    </Modal>
  )
}

DropPackageModal.defaultProps = {
  package: null,
  open: false,
  onClose: () => {},
}

DropPackageModal.propTypes = {
  package: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
}