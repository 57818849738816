import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { LiffProvider } from 'react-liff';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as Sentry from '@sentry/browser';


function getLiffId() {
  // if (window.location.host === 'alpha.al8m.com') 
  // return '1660818480-gkBnrX52'; // Test Server
  
  return '1661146805-4O0jOYNy';  // Default path
  //return '1660818480-45n3gxVG';  // localhost
};


// Sentry.init({
//   dsn: "https://44510cefd972401db2e7f87ca87fe4e9@sentry.tailor-solutions.com/59",
// });


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <LiffProvider liffId={getLiffId()} mock={true}>
      <App />
    </LiffProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
