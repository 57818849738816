import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Modal,  Select, theme } from "antd";
import Header from '../../common/Header';
import { GET, POST } from '../../../frameworks/HttpClient';
import { URL_MEMBER } from '../../../constants/urls';


export default function UpdateUserGroupModal (props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [list, setList] = React.useState([]);

	const {
		token: { colorWarning },
	} = theme.useToken();

	// Update sale
	const updateUser = async () => {
		try {
			let data = await form.validateFields();
			data = {
				...data, 
				user_type: props.userType,
				member: props.member
			}
			await POST(URL_MEMBER.USER_UPDATE_LOG, data);
			props.onUpdated()
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Load sale list
	const fetchData = async () => {
		setErrorMessages(null);
		try {
			const response = await GET(`${props.fetchUrl}?page_size=9999`);
			setList(
				response.data.results.map(item => {
					return {
						...item,
						value: item.id,
						label: item.full_name
					}
				})
			)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			fetchData();
			if (props.data) {
				form.setFieldsValue({new_user: props.data})
			}
		}
	}, [props.open])

  return (
    <Modal
    okText={"Update"}
		okButtonProps={{ style: {background: colorWarning}}}
    open={props.open}
    onOk={updateUser}
    onCancel={props.onClose}>
			<Header title={props.title}></Header>
			
			<Form 
				form={form}
				style={{marginTop: 16}}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}>
				
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}

				<Form.Item 
					name={"new_user"}
					label={props.label}
					rules={[{ required: true }]}>
					<Select options={list} />
				</Form.Item>
			</Form>
    </Modal>
  )
}
UpdateUserGroupModal.defaultProps = {
  open: false,
	title: "",
	label: "Full Name",
	userType: "",
	fetchUrl: "",
	member: null,
	data: null,
	onUpdated: (data) => {},
	onClose: () => {},
}

UpdateUserGroupModal.propTypes = {
  open: propTypes.bool,
	title: propTypes.string,
	label: propTypes.string,
	userType: propTypes.string,
	fetchUrl: propTypes.string,
	member: propTypes.string,
	data: propTypes.string,
  onUpdated: propTypes.func,
	onClose: propTypes.func,
}