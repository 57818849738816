import React from 'react';
import propTypes from "prop-types";
import Header from '../components/common/Header'
import { Alert, Button, Col, Row, Space, Tabs, theme } from 'antd'
import ClassListView from '../components/pt/ClassListView'
import ClassWeekView from '../components/pt/ClassWeekView'
import ClassMonthView from '../components/pt/ClassMonthView'
import PTCourseModal from '../components/pt/PTCourseModal'
import PTClassModal from '../components/pt/PTClassModal'
import StudioRentModal from '../components/pt/StudioRentModal';
import { URL_TRAINER, URL_USERS } from '../constants/urls'
import { GET } from '../frameworks/HttpClient'
import StudioCourseRentModal from '../components/pt/StudioCourseRentModal';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';
import ClassListViewMobile from '../components/pt/ClassListViewMobile';

let activeKey = "list"
export default function PTClassView(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [trigger, setTrigger] = React.useState(false);
  // Handle Modal
  const [openStudioCourseModal, setOpenStudioCourseModal] = React.useState(false);
  const [openStudioModal, setOpenStudioModal] = React.useState(false);
  const [openCourseModal, setOpenCourseModal] = React.useState(false);
  const [openClassModal, setOpenClassModal] = React.useState(false);
  // Handle Options
  const [studioOptions, setStudioOptions] = React.useState([]);
  const [trainerOptions, setTrainerOptions] = React.useState([]);
  const [rentTarget, setRentTarget] = React.useState(null);
  const listRef = React.useRef(null);
  const weekRef = React.useRef(null);
  const monthRef = React.useRef(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('sm');

  const {
    token: {colorInfo, colorSuccess, colorPrimary, colorWarning}
  } = theme.useToken();

  const buttonRender = () => (
    <Row>
      <Space wrap>
        {props.isManager && (
          <Col>
            <Button
              style={{ borderColor: colorSuccess, color: colorSuccess }}
              type={"default"}
              onClick={() => setOpenCourseModal(true)}>Course (Continuous)</Button>
          </Col>
        )}
        {props.isManager && (
          <Col>
            <Button
              style={{ borderColor: colorInfo, color: colorInfo }}
              type={"default"}
              onClick={() => setOpenClassModal(true)}>Class (1 Time)</Button>
          </Col>
        )}
        {props.canBooking && (
          <Col>
            <Button
              style={{ borderColor: colorWarning, color: colorWarning }}
              type={"default"}
              onClick={() => setOpenStudioCourseModal(true)}>Booking (continuous)</Button>
          </Col>
        )}
        {(props.canBooking || props.partner) && (
          <Col>
            <Button
              style={{ borderColor: colorPrimary, color: colorPrimary }}
              type={"default"}
              onClick={() => setOpenStudioModal(true)}>Booking</Button>
          </Col>
        )}
      </Space> 
    </Row>
  );

  const refresh = () => {
    // Update event list
    if (listRef && listRef.current) {
      listRef.current.fetchData();
    }
    // Update event week
    if (weekRef && weekRef.current) {
      weekRef.current.fetchData();
    }
    // Update event month
    if (monthRef && monthRef.current) {
      monthRef.current.fetchData();
    }
  }

  const items = [
    {
      key: "list",
      label: "List",
      children: isOnMobile 
        ? <ClassListViewMobile ref={listRef} partner={props.partner} onRentDetail={(id) => setRentTarget(id)}/> 
        : <ClassListView ref={listRef} partner={props.partner} onRentDetail={(id) => setRentTarget(id)}/>
    },
    {
      key: "week",
      label: "Week",
      children:(
        <ClassWeekView 
          ref={weekRef}
          studioOptions={studioOptions}
          trainerOptions={trainerOptions}
          partner={props.partner}
          onRentDetail={(id) => setRentTarget(id)}/>)
    },
    {
      key: "month",
      label: "Month",
      children:(
        <ClassMonthView 
          ref={monthRef}
          studioOptions={studioOptions}
          trainerOptions={trainerOptions}
          partner={props.partner}
          onRentDetail={(id) => setRentTarget(id)}/>)
    }
  ];

  const fetchStudio = async () => {
    try {
      const response = await GET(URL_TRAINER.STUDIO, {page_size: 999});
      let results = response.data.results
      if (props.partner) {
        // Filter allow booking for partner
        results = results.filter((studio) => studio.allow_booking)
      }
      setStudioOptions(results.map(item => ({ ...item, value: item.id, label: item.name })));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  };

  const fetchTrainer = async () => {
    try {
      const response = await GET(`${URL_TRAINER.TRAINERS}`, { page_size: 999 })
      const partners_response = await GET(`${URL_USERS.PARTNERS}`)

      setTrainerOptions([
        ...response.data.results.map(item => ({ ...item, value: item.id, label: item.full_name })),
        ...partners_response.data.results.map(item => ({...item, value:item.id, label:item.full_name}))
      ]);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  };

  React.useEffect(() => {
    setErrorMessages(null);
    fetchStudio();
    fetchTrainer();
  }, [])
  
  return (
    <div>
      <Header title={"PT Class"} withoutUnderline={true}/>
      {errorMessages && (
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: "12px", textAlign: "left"}}/>
      )}

      {isOnMobile && buttonRender()}
      <Tabs 
        items={items}
        style={{marginTop: 16}}
        activeKey={activeKey}
        onChange={(key) => {
          activeKey = key
          setTrigger(!trigger);
        }}
        tabBarExtraContent={{ 
          right: !isOnMobile && buttonRender()
        }}/>

        {/* For pt course */}
        <PTCourseModal
          open={openCourseModal}
          studioOptions={studioOptions}
          trainerOptions={trainerOptions}
          onClose={() => setOpenCourseModal(false)}
          onCreated={() => {
            setOpenCourseModal(false);
            refresh()
          }}/>

        {/* For pt class */}
        <PTClassModal
          open={openClassModal}
          studioOptions={studioOptions}
          trainerOptions={trainerOptions}
          onClose={() => setOpenClassModal(false)}
          onCreated={() => {
            setOpenClassModal(false);
            refresh()
          }}/>

        {/* For booking (course) */}
        <StudioCourseRentModal 
          open={openStudioCourseModal}
          studioOptions={studioOptions}
          onClose={() => setOpenStudioCourseModal(false)}
          onCreated={() => {
            setOpenStudioCourseModal(false);
            refresh();
          }}/>

        {/* For rent */}
        <StudioRentModal
          open={(openStudioModal || rentTarget) ? true : false}
          id={rentTarget}
          studioOptions={studioOptions}
          partner={rentTarget ? null : props.partner}
          readOnly={(props.partner && rentTarget) ? true : false}
          onClose={() => {
            setOpenStudioModal(false)
            setRentTarget(null);
          }}
          onUpdated={() => {
            setOpenStudioModal(false);
            setRentTarget(null);
            refresh();
          }}/>
    </div>
  )
}

PTClassView.defaultProps = {
  isManager: false,
  canBooking: false,
  partner: null,
}

PTClassView.propTypes = {
  isManager: propTypes.bool,
  canBooking: propTypes.bool,
  partner: propTypes.object,
}