import moment from "moment";
import dayjs from 'dayjs';
import { DATE_FORMAT, HUMAN_DATE_FORMAT } from "../constants/string";

// /** Convert Date-Time */
export const dateTimeToString = (date) => {
  if (!date) {
    return "";
  }
  return moment(date).format("YYYY-MM-DD HH:mm");
}

export const dateTimeToHumanString = (date) => {
	if (!date) {
		return "";
	}
	return moment(date).format("DD/MM/YYYY HH:mm");
}

export const dateToString = (date) => {
  if (!date) {
    return "";
  }
  return moment(date).format("YYYY-MM-DD");
}

export const dateToHumanString = (date) => {
	if (!date) {
		return "";
	}
	return moment(date).format("DD/MM/YYYY");
}

export const timeToString = (date) => {
  if (!date) {
    return "";
  }
  return moment(date).format("HH:mm");
}

export const toHumanDate = (dateStr) => { // Use dayjs
	return dayjs(dateStr, DATE_FORMAT).format(HUMAN_DATE_FORMAT)
}

// export const dateToString = (date) => {
//   if (!date) {
//     return "";
//   }

//   return moment(date).format("DD/MM/YYYY");
// }

// export const monthToString = (date) => {
// 	if (!date) {
// 		return "";
// 	}

// 	return moment(date, 'MM-YYYY').format("MMM YYYY")
// }

export const formatComma = (value, enable_negative=false) => {
  if (!value) {
    return 0;
  }
  const isNegative = value.toString().startsWith('-')
  const split_decimal = (+parseFloat(value.toString().replace(',', '').replace('-', ''))).toFixed(2).split('.');
  const number = split_decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (+split_decimal[1] ? "." + split_decimal[1] : "");
  return `${isNegative && enable_negative ? '-' : ''}${number}`
}

export const ellipsisString = (value, threshold_length=15) => {
  if (!value) {
    return '';
  }
  
  return `${value.toString().substring(0, threshold_length)}${value.toString().length > threshold_length ? '...' : ''}`
}

export const convertNullToEmpty = (obj) => {
	return Object.keys(obj).reduce((o, current) => {
		if(obj[current] != null && obj[current] != undefined && obj[current] != '') {
			return { ...o, [current]: obj[current] };
		}
		return { ...o, [current]: '' };
	}, {})
}

export const imageToGrayScale = (file, max_width) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (readerEvent) {
			let image = new Image();
			image.onload = function (imageEvent) {

				// Resize the image
				let canvas = document.createElement('canvas'),
					width = image.width,
					height = image.height;

				if (width > max_width) {
					height = height * (max_width / width);
					width = max_width;
				}

				canvas.width = width;
				canvas.height = height;
				canvas.getContext('2d').drawImage(image, 0, 0, width, height);
				let imgPixels = canvas.getContext('2d').getImageData(0, 0, width, height);
				for(let y = 0; y < imgPixels.height; y++) {
					for(let x = 0; x < imgPixels.width; x++) {
						let i = (y * 4) * imgPixels.width + x * 4;
						let avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
						imgPixels.data[i] = avg; 
						imgPixels.data[i + 1] = avg; 
						imgPixels.data[i + 2] = avg;
					}
				}
				canvas.getContext('2d').putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);

				const dataUrl = canvas.toDataURL('image/png');
				resolve(dataUrl);
			}
			image.src = readerEvent.target.result;
		}
		reader.readAsDataURL(file);
	});
}

export const currencyFormat = (value) => {
	if (!value)
		return "0.00";

	const currency = parseFloat(value).toFixed(2);
	return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const shortCurrencyFormat = (value, enable_negative = false) => {
	if (!value) {
		return 0;
	}

	const isNegative = value.toString().startsWith('-')
	const numberValue = (+parseFloat(value.toString().replace(',', '').replace('-', '')));
	if (numberValue >= 1000000000) {
		return `${isNegative && enable_negative ? '-' : ''}${(numberValue / 1000000000).toFixed(2)}B`
	} else if (numberValue >= 1000000) {
		return `${isNegative && enable_negative ? '-' : ''}${(numberValue / 1000000).toFixed(2)}M`
	} else if (numberValue >= 1000) {
		return `${isNegative && enable_negative ? '-' : ''}${(numberValue / 1000).toFixed(2)}K`
	}
	return `${isNegative && enable_negative ? '-' : ''}${formatComma(value)}`
}

// ปัดเศษทศนิยม decimals ตำแหน่ง
export const toFixed = (num, decimals = 2) => {
	if (parseFloat(num) == NaN)
		return 0

	num *= Math.pow(10, decimals);
	num = (Math.round(num, decimals) + (((num - Math.round(num, decimals)) >= 0.4) ? 1 : 0)) / Math.pow(10, decimals);
	return num.toFixed(decimals);
}

/**
 * Format number in bytes into KB, MB, GB...
 * @param {*} bytes
 * @param {*} decimals
 */
export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatFileName = (link) => {
	if (!link) {
		return ""
	}

	const file_name = link.split('/').pop()
	return <a target="_blank" rel="noopener noreferrer" href={link}>{ellipsisString(file_name, 35)}</a>
}

// Map function for object (like map for array)
//https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export const objectMap = (obj, fn) => 
Object.fromEntries(
	Object.entries(obj).map(
		([k, v], i) => [k, fn(v, k, i)]
	)
)

// Remove key,value with null on value
export const removeEmpty = obj => 
	Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

// Replace dot with duble underscore sale.name to sale__name
export const replaceKey = obj => 
  Object.keys(obj).reduce( (acc, curr) => {
		curr.includes('.') ? acc[curr.replaceAll('.', '__')] = obj[curr] : acc[curr] = obj[curr]
		return acc
	}, {})


export const isNumeric = str => {
	if (typeof str != "string") return false // we only process strings!  
	return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const closeTab = () => {
	window.opener = null;
	window.open("", "_self");
	window.close();
}
