import React from "react"
import propTypes from "prop-types";
import { Alert, Col, Descriptions, Divider, Input, Modal, Row, theme } from "antd";
import Header from "../common/Header";
import { URL_SALE, URL_VOUCHER } from "../../constants/urls";
import { GET, POST } from "../../frameworks/HttpClient";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { formatComma } from "../../frameworks/Util";

export default function CouponModal(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [couponDetail, setCouponDetail] = React.useState(null);
	const [searchText, setSearchText] = React.useState("");

	const {
		token: {colorSuccess, colorSecondary}
	} = theme.useToken();

	// Add coupon to cart
	const addToCart = async () => {
		if (!props.memberID || !couponDetail) {
			setErrorMessages("Coupon not found!");
			return;
		}
		const couponCode = couponDetail.code;
		let myData = {
			member: props.memberID,
			item: `${URL_VOUCHER.COUPON_CODE}${couponCode}/`
		}

		setErrorMessages(null);
		try {
			await POST(URL_SALE.CART_ITEM, myData);
			props.onAdded();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const searchCoupon = async (e) => {
		// Do somethings
		const code = searchText;
		setErrorMessages(null);
		try {
			const response = await GET(`${URL_VOUCHER.COUPON_CODE}${code}/`);
			setCouponDetail(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
			setCouponDetail(null);
		}
	}

	const handleChange = (e) => setSearchText(e.target.value);

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			setCouponDetail(null);
			setSearchText("");
		}
	}, [props.open])

	return (
		<Modal
			width={"760px"}
      open={props.open}
      onOk={addToCart}
			okText={(couponDetail && couponDetail.price) ? `${formatComma(couponDetail.price)} Baht` : "Add To Cart"}
			okButtonProps={{
				style: {
					background: (couponDetail) ? colorSuccess : colorSecondary,
					fontWeight: "bold"
				},
				disabled: couponDetail ? false : true,
				icon: <ShoppingCartOutlined style={{ fontSize: 18 }}/>,
			}}
      onCancel={props.onClose}>
			<Header title={"Coupon"} />

			{errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{
						marginBottom: "12px", 
						marginTop: "12px", 
						textAlign: "left"}}/>
			)}
			<Row justify={"end"} gutter={24} style={{marginTop: 24, padding: 0}}>
				<Col span={24}>
					<Input
						autoComplete="off"
						className={"custom-addon"}
						addonBefore={"Coupon For Sale:"}
						value={searchText}
						onChange={handleChange}
						onPressEnter={searchCoupon}/>
				</Col>
			</Row>

			{couponDetail && (
				<div>
					<Divider>{"Coupon Detail"}</Divider>
					<Descriptions 
						title={`[ ${couponDetail ? couponDetail.code : "-"} ] - ${couponDetail ? couponDetail.name : "Unknown"}`} 
						column={1}>
						<Descriptions.Item 
							label={"Detail"} 
							style={{fontWeight: "bold"}}>
								{couponDetail ? couponDetail.detail : "-"}
						</Descriptions.Item>
						<Descriptions.Item 
							label={"Expired"} 
							style={{fontWeight: "bold"}}>
								{couponDetail ? couponDetail.expired : "-"}
						</Descriptions.Item>
					</Descriptions>
				</div>
			)}
		</Modal>
	)
}

CouponModal.defaultProps = {
  open: false,
	memberID: null,
	onAdded: () => {},
  onClose: () => {},
}

CouponModal.propTypes = {
  open: propTypes.bool,
	memberID: propTypes.string,
	onAdded: propTypes.func,
  onClose: propTypes.func,
}