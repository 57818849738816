import React from "react";
import propTypes from "prop-types";
import { Calendar, theme } from "antd";
import dayjs from 'dayjs';

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const TSCalendar = React.forwardRef((props, ref) => {

	const {
    token: { colorPrimary, colorWarningBg }
  } = theme.useToken();

	const [touchStart, setTouchStart] = React.useState(null)
	const [touchEnd, setTouchEnd] = React.useState(null)

	// the required distance between touchStart and touchEnd to be detected as a swipe
	const minSwipeDistance = 50

	const onTouchStart = (e) => {
		setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
		setTouchStart(e.targetTouches[0].clientX)
	}

	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return
		const distance = touchStart - touchEnd
		const isLeftSwipe = distance > minSwipeDistance
		const isRightSwipe = distance < -minSwipeDistance

		if(isLeftSwipe) {
			props.onSwipeLeft()
		}
		else if(isRightSwipe) {
			props.onSwipeRight()
		}
		// if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
	}

	// Mocking data
  const getListData = (value) => {
    let listData = [];
		const targetValue = value.format('YYYY-MM-DD');
		for (let item of props.data) {
			if (item.date === targetValue) {
				listData.push(item);
			}
		}
    return listData;
  };

	const dateCellRender = (value) => {
		let isBg = false;
		if (props.activeStart && props.activeEnd) {
			isBg = value.startOf('day').isBetween(props.activeStart, props.activeEnd, null, '[]')
		}
    const listData = getListData(value);
    return (
			<div 
				style={{ height: "96%", background: isBg ? colorWarningBg : null }}
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
				onTouchEnd={onTouchEnd}
				onClick={() => props.onDateSelected(value, listData)}>
        {listData.map((item, index) => (
					<div 
						key={index}
						style={{
							fontSize: 8, 
							background: item.color ? item.color : colorPrimary, 
							marginTop: 2, 
							padding: 2, 
							borderRadius: 2, 
							color: "#fff",
							lineHeight: "1.3em",
							maxHeight: "3em",
							width: "100%",
							overflow: "hidden"}}>
						{item.label}
					</div>
        ))}
			</div>
    );
  };

	return (
		<Calendar 
			style={{...props.style}}
			mode={"month"}
			dateCellRender={dateCellRender}
			headerRender={props.headerRender}
			onChange={props.onChange}/>
	)
});

TSCalendar.defaultProps = {
	style: {}, // Calendar style
	data: [], // Data list [{date: "", label: "", color: ""}]
	headerRender: null, // Custom header
	onChange: () => {}, // Event change date
  onDateSelected: (date, events) => {}, // Event date selected
	onSwipeLeft: () => {},
	onSwipeRight: () => {},

	// For display background
	activeStart: null,
	activeEnd: null,
}

TSCalendar.propTypes = {
  style: propTypes.object,
	data: propTypes.array,
	headerRender: propTypes.any,
	onChange: propTypes.func,
  onDateSelected: propTypes.func,
	onSwipeLeft: propTypes.func,
	onSwipeRight: propTypes.func,

	// For display background
	activeStart: propTypes.string,
	activeEnd: propTypes.string,
}

export default TSCalendar;