import React from 'react';
import propTypes from "prop-types";
import dayjs from 'dayjs';
import { Alert, Button, Col, DatePicker, Row, Select, theme } from 'antd'
import Header from '../components/common/Header'
import AppointmentModal from '../components/pt/AppointmentModal';
import EventPTSessionModal from '../components/pt/EventPTSessionModal';
import "./../styles.css";
import { PlusCircleOutlined } from '@ant-design/icons';
import TSCalendar from '../components/common/TSCalendar';
import { URL_TRAINER } from '../constants/urls';
import { GET } from '../frameworks/HttpClient';
import { DATE_FORMAT, MONTH_FORMAT, USER_GROUP } from '../constants/string';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import PTSessionActionModal from '../components/pt/PTSessionActionModal';
import useUserData from '../hooks/useUserData';


export default function PTCalendarView(props) {
	const { user } = useUserData();
  const isPTGroup = user.groups.find(group =>(group == USER_GROUP.PT || group == USER_GROUP.PT_MANAGER)) ? true: false;

	const navigate = useNavigate();
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Handle Appointment modal
	const [openAppointModal, setOpenAppointModal] = React.useState(false);
	// Options
	const [month, setMonth] = React.useState(dayjs());
	const [trainer, setTrainer] = React.useState(null);
	const [trainerOptions, setTrainerOptions] = React.useState([]);
	// Event List
	const [events, setEvents] = React.useState([]);
	const [eventsDetail, setEventsDetail] = React.useState(null);
	// Change status
	const [checkTarget, setCheckTarget] = React.useState(null);
  	const [uncheckTarget, setUncheckTarget] = React.useState(null);
	const {
		token: { colorSuccess, colorInfoTextHover, colorPrimary }
	} = theme.useToken();

	const handleMonthChange = (date) => setMonth(date);

	const handlePTChange = (value, selected) => setTrainer(selected);

	// Fetch trainer
	const fetchTrainer = async () => {
		try {
			const response = await GET(`${URL_TRAINER.TRAINERS}?page_size=9999`);
			let temp = response.data.results.map(item => ({ ...item, value: item.id, label: item.full_name }))
			temp.splice(0, 0, {id: null, value: null, label: "-"});
			setTrainerOptions(temp);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  };

	// Fetch event
	const fetchEvent = async () => {
		// Prepare Data
		let params = {
			month: month ? month.format(MONTH_FORMAT) : null,
			trainer: trainer ? trainer.id : null,
			page_size: 999
		}

		setErrorMessages(null);
		try {
			const response = await GET(URL_TRAINER.TRAINER_CALENDAR, params);
			setEvents(response.data.map(item => {
				const eventDate = new Date(`${item.date} , ${item.end_time}`);
				
				const nowDate = dayjs();
				const label = item && item.type === "pt_class" ? 
					<>
						{item.class_type_name} ({item.start_time.substr(0, 5)} - {item.end_time.substr(0, 5)})<br/>
						{item.studio} - {item.trainer_name} 
					</>
					:
					`${item.event_name ? item.event_name : ""} (${item.start_time.substr(0, 5)} - ${item.end_time.substr(0, 5)}) 
					 ${item.member_full_name ? item.member_full_name : ""}`

			
				return {
					...item,
					label: label,
					
					color:
					(item && item.is_checked === true) ? colorSuccess : 
					(item.type ==="pt_session" && item.is_checked === false) ? "#E87F1C" :
					(item.type ==="pt_session" && !item.is_checked ) ? colorInfoTextHover : 
					(item.type ==="pt_class" && item.is_checked === false && nowDate <= eventDate) ? colorInfoTextHover : "#AFAFAF"
				 }				
					
			}
			));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Filter event with date
	const filterEvents = (date, list) => {
		if (list.length > 0) {
			setEventsDetail({
				date: date.format("DD MMMM YYYY"),
				events: list
			})
		}
	}

	// Calendar Header
	const CustomCalendarHeader = ({ value, type, onChange, onTypeChange }) => {
		return (
				<Row 
					gutter={[16, 16]}
					style={{marginTop: 16, marginBottom: 16}}>
					<Col xl={6} sm={9} xs={24}>
						<label>{"Month: "}</label>
						<DatePicker 
							style={{width: "70%"}}
							allowClear={false}
							picker={"month"}
							value={month}
							inputReadOnly={true}
							onChange={onChange} />
					</Col>
					<Col xl={6} sm={9} xs={24}>
						{(props.isManager || props.isReception || props.isSale) && (
							<div>
								<label>{"PT: "}</label>
								<Select 
									style={{width: "76%"}}
									options={trainerOptions}
									onChange={handlePTChange}
									value={trainer}/>
							</div>
						)}
					</Col>
					{ isPTGroup && (
						<Col xl={12} sm={6} xs={24} style={{textAlign: "right"}}>
							<Button
								type={"primary"}
								style={{ background: colorSuccess }}
								onClick={() => setOpenAppointModal(true)}
								icon={<PlusCircleOutlined />}> 
								Appointment
							</Button>
						</Col>
					)}
				</Row>
		)
	}

	React.useEffect(() => {
		if (month) 
			fetchEvent();
		
	}, [month, trainer])

	React.useEffect(() => {
		fetchTrainer();

		return setErrorMessages(null)
	}, []);

	return (
		<div>
			<Header title={"PT Calendar"} />

			{errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginTop: "12px", textAlign: "left"}}/>
			)}
			
				<TSCalendar
					data={events}
					headerRender={CustomCalendarHeader}
					onChange={handleMonthChange}
					onDateSelected={filterEvents}/>

			<AppointmentModal
				open={openAppointModal}
				onClose={() => setOpenAppointModal(false)}
				onCreated={() => {
					setOpenAppointModal(false);
					fetchEvent();
				}}/>

			{/* Event Detail */}
			<EventPTSessionModal
				open={eventsDetail ? true : false}
				onClose={() => setEventsDetail(null)}
				data={eventsDetail}
				gotoDetail={(id) => {
					setEventsDetail(null);
					navigate(`${PATH.CLASS_DETAIL}/${id}/`);
				}}
				onUpdated={() => {
					setEventsDetail(null);
					fetchEvent();
				}}
				onEventApprove={(id) => {
					setEventsDetail(null);
					setCheckTarget(id);
				}}
				onEventReject={(id) => {
					setEventsDetail(null);
					setUncheckTarget(id);
				}}/>
			
			{/* Approve Appointment */}
      <PTSessionActionModal
        title={"Approve Appointment!"}
        open={checkTarget ? true : false}
        requireNote={false}
				requireSignature={true}
        url={`${URL_TRAINER.SESSION_APPOINTMENT}${checkTarget ? checkTarget : ""}/check/`}
        data={{ is_checked: true }}
        onClose={() => setCheckTarget(null)}
        onSuccess={() => {
          setCheckTarget(null);
          fetchEvent();
        }}/>
      
      {/* Reject Appointment */}
      <PTSessionActionModal
        title={"Reject Appointment!"}
        open={uncheckTarget ? true : false}
        requireNote={true}
        url={`${URL_TRAINER.SESSION_APPOINTMENT}${uncheckTarget ? uncheckTarget : ""}/check/`}
        data={{ is_checked: false }}
        onClose={() => setUncheckTarget(null)}
        onSuccess={() => {
          setUncheckTarget(null);
          fetchEvent();
        }}/>
		</div>
	)
}

PTCalendarView.defaultProps = {
	isManager: false,
}

PTCalendarView.propTypes = {
	isManager: propTypes.bool,
}