import React from 'react';
import { MEMBER_PACKAGE_REF_TYPE } from '../../constants/string';
import { Button, Popover, Tag } from 'antd';
import * as _ from 'lodash';
import { RetweetOutlined, SendOutlined, ShoppingCartOutlined, StarOutlined } from '@ant-design/icons';


const MEMBER_PACKAGE_REF_TYPE_MAPPER = {
  [MEMBER_PACKAGE_REF_TYPE.PURCHASE]: { text: 'Purchase', color: 'blue', icon: <ShoppingCartOutlined /> },
  [MEMBER_PACKAGE_REF_TYPE.POINT_PURCHASE]: { text: 'Reward Point Purchase', color: 'geekblue', icon: <StarOutlined /> },
  [MEMBER_PACKAGE_REF_TYPE.TRANSFER]: { text: 'Package Transfer', color: 'cyan', icon: <SendOutlined /> },
  [MEMBER_PACKAGE_REF_TYPE.EXCHANGE]: { text: 'Purchase Exchange', color: 'gold', icon: <RetweetOutlined /> },
}

export default function PackageRefTag(props) {
  const { data } = props;

  const refType = _.get(MEMBER_PACKAGE_REF_TYPE_MAPPER, _.get(data, 'ref_type', null), null) 

  return refType ? (
    <Popover style={{ margin: 0 }} content={_.get(refType, 'text', null)} trigger='hover'>
      <Tag style={{ margin: 0 }} color={_.get(refType, 'color')}>{_.get(refType, 'icon', null)}</Tag>
    </Popover>
  ) : null
}