import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Card, Col, Form, Image, Input, Modal, Row, theme, Collapse } from "antd";
import Header from '../common/Header';
import { ShoppingCartOutlined } from '@ant-design/icons';
import logo from "../../assets/ahc_logo.png"
import "../../styles.css"
import { URL_PACKAGE, URL_SALE } from '../../constants/urls';
import { GET, POST } from '../../frameworks/HttpClient';
import { formatComma } from '../../frameworks/Util';

const VARIANT_TO_TEXT = {
	GYM: "Gym Only",
	POOL: "Pool Only",
	ALL: "Gym + Pool"
}

export default function MembershipPackageModal(props) {
	const { Panel } = Collapse;
	const [form] = Form.useForm();
	const [packages, setMainPackages] = React.useState([]);
	const [catePackages, setCatePackages] = React.useState([]);
	const [errorMessages, setErrorMessages] = React.useState(null);

  const {
    token: { colorSuccess, colorTextSecondary, colorTextQuaternary },
  } = theme.useToken();

  const addToCart = async (data) => {
		if (!props.memberID || !data) {
			setErrorMessages("Data not found!");
			return;
		}
		let myData = {
			member: props.memberID,
			item: `${URL_PACKAGE.PACKAGE_VARIANT}${data.id}/`
		}
		
		setErrorMessages(null);
		try {
			await POST(URL_SALE.CART_ITEM, myData);
			props.onAdded();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const fetchData = async () => {
		let data = await form.validateFields();
		setErrorMessages(null);
		try {
			const response = await GET(`${URL_PACKAGE.PACKAGES}?page_size=100`, data);
			setMainPackages(response.data.results);
			const cate = await GET(`${URL_PACKAGE.PACKAGE_CATEGORY}?type=package`)
			setCatePackages(cate.data.results);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			fetchData();
		} else {
			setErrorMessages(null);
		}
			
	}, [props.open])

  	return (
    	<Modal
			width={"760px"}
      		open={props.open}
      		onOk={props.onClose}
      		onCancel={props.onClose}
			footer={[
				<Button type={"primary"} onClick={props.onClose}>{"Close"}</Button>
			]}>
      <Header title={"Membership Package"}></Header>

			{errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{
						marginBottom: "12px", 
						marginTop: "12px", 
						textAlign: "left"}}/>
			)}

      	<Row 
				justify={'end'} 
				style={{marginTop: 16}}>
        <Col md={12} sm={24} xs={24}>
				<Form form={form} layout={"horizontal"}>
					<Form.Item name={"search"} label={"Search"}>
						<Input.Search
							autoComplete="off"
							placeholder={'Search ...'}
							onSearch={fetchData}
						/>
					</Form.Item>
				</Form>
				</Col>
      </Row>
	<Form>
	  <Collapse 
	  	className='custom-collapse-header'
		style={{
			background: "#AF3033",
			border: "none",
			colorTextHeading : "#fffff"}}>
	  		{catePackages.map((cate) => (
	  		<Panel header={`${cate.name}`} key={`${cate.id}`}>
				{packages && packages.filter(f => f.categories.includes(`${cate.id}`)).map(item => (<Card >
							<Row>
								<Col md={6} sm={24} xs={24} style={{textAlign: "center"}}>
									<Image style={{height: 86, width: "auto"}} src={item.image ? item.image : logo}/>
								</Col>
								<Col md={12} sm={24} xs={24}>
									<p 
										style={{fontWeight: "bold", marginTop: 0}}>
											{item.name}
									</p>
									<div 
										style={{
											lineHeight: "1.5em",
											whiteSpace: "pre-wrap",
											color: colorTextSecondary}}>
										{(item.detail) ? item.detail : ""}
									</div>
								</Col>
								<Col md={6} sm={24} xs={24} style={{textAlign: "right"}}>
									{item.variants.map(variant => (
										<div key={variant.id}>
											<Button
												type={"primary"}
												icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
												style={{
													width: "100%",
													background: colorSuccess,
													fontWeight: "bold", 
													margin: 0 }}
												onClick={() => addToCart(variant)}>
													{`${variant.price ? formatComma(variant.price) : "0"} Bath`}
											</Button>
											<p 
												style={{color: colorTextQuaternary, marginTop: 0,}}>
													{`*${VARIANT_TO_TEXT[variant.type]}`}
											</p>
										</div>
									))}
								</Col>
							</Row>
						</Card> ))}
			  </Panel>))}

			  <Panel header={"Other"} key={"Other"}>
			  {packages && packages.map(item => item.categories.length === 0 && 
				(<Card >
							<Row>
								<Col md={6} sm={24} xs={24} style={{textAlign: "center"}}>
									<Image style={{height: 86, width: "auto"}} src={item.image ? item.image : logo}/>
								</Col>
								<Col md={12} sm={24} xs={24}>
									<p 
										style={{fontWeight: "bold", marginTop: 0}}>
											{item.name}
									</p>
									<div 
										style={{
											lineHeight: "1.5em",
											whiteSpace: "pre-wrap",
											color: colorTextSecondary}}>
										{(item.detail) ? item.detail : ""}
									</div>
								</Col>
								<Col md={6} sm={24} xs={24} style={{textAlign: "right"}}>
									{item.variants.map(variant => (
										<div key={variant.id}>
											<Button
												type={"primary"}
												icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
												style={{
													width: "100%",
													background: colorSuccess,
													fontWeight: "bold", 
													margin: 0 }}
												onClick={() => addToCart(variant)}>
													{`${variant.price ? formatComma(variant.price) : "0"} Bath`}
											</Button>
											<p 
												style={{color: colorTextQuaternary, marginTop: 0,}}>
													{`*${VARIANT_TO_TEXT[variant.type]}`}
											</p>
										</div>
									))}
								</Col>
							</Row>
						</Card> 
						))}
			  </Panel>
		</Collapse>
	</Form>
    		</Modal >
  )
}
MembershipPackageModal.defaultProps = {
  open: false,
	memberID: null,
	onAdded: () => {},
  onClose: () => {},
}

MembershipPackageModal.propTypes = {
  open: propTypes.bool,
	memberID: propTypes.string,
	onAdded: propTypes.func,
  onClose: propTypes.func,
}
