import { Select, Spin, message } from "antd";
import React from "react";
import propTypes from 'prop-types';
import { GET } from "../../frameworks/HttpClient";
import { URL_TRAINER } from "../../constants/urls";
import * as _ from 'lodash';


const defaultOptions = [
  { value: '', label: '-' }
]

const PTSelect = React.forwardRef((props, ref) => {
  const { 
    addDefault,
    onChange,
    params,
    ...otherProps
  } = props;

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState(addDefault ? [...defaultOptions] : []);
  
  React.useImperativeHandle(ref, () => ({
    fetch: () => fetchData()
  }))

  React.useEffect(() => {
    fetchData();
  }, [params])

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await GET(URL_TRAINER.TRAINERS, params);
      let results = _.get(response, 'data.results', []).map(
        data => ({ value: data.id, label: _.get(data, 'full_name', '') }))
      let newOptions = addDefault ? [...defaultOptions, ...results] : results;
      setOptions(newOptions);
    } catch (error) {
      messageApi.open({ type: 'warning', content: 'Unable to load PT list, please try again later.' })
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Select options={options} onChange={onChange} {...otherProps}/>
      {contextHolder}
    </Spin>
  )
})

export default PTSelect;

PTSelect.defaultProps = {
  addDefault: false,
  onChange: () => null,
  params: {},
};

PTSelect.propTypes = {
  addDefault: propTypes.bool,
  onChange: propTypes.func,
  params: propTypes.object,
};
