import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Card, Image, Layout, Modal, Row } from "antd";
import PDPAModalTHView from './PDPAModalTHView';
import { useNavigate } from "react-router-dom";
import PDPAModalENView from './PDPAModalENView';
import flagEng from './../../assets/eng.png';
import flagThai from "./../../assets/thai.png";
import Header from '../common/Header';
import SignaturePad from 'react-signature-canvas';
import { PATH } from '../../routes/CustomRoutes';

export default function PDPAModal(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
  	const [local, setLocal] = React.useState("EN");
	const [width, setWidth] = React.useState(0);
	const padRef = React.useRef(null);
	const signatureRef = React.useRef(null);
	const navigate = useNavigate();

	const handleClear = () => {
		if (signatureRef && signatureRef.current) {
			signatureRef.current.clear();
		}
	}

	const handleOK = async () => {
		let data = {}
		let signatureError = "Signature is empty!"
		if (signatureRef && signatureRef.current) {
			const ref = signatureRef.current;
			if (!ref.isEmpty()) {
				data["signature"] = ref.getTrimmedCanvas().toDataURL("image/png");
				signatureError = null;
			}
		} 

		if (signatureError) {
			setErrorMessages(signatureError);
			return;
		}
		setErrorMessages(null);
		try {
			props.signature(data);
			props.onUpdated();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  }

	React.useEffect(() => {
		// Initial width for signature pad
		if (props.open && padRef && (width === 0)) {
			setWidth(padRef.current.offsetWidth);
		}

		// Clear signature after close modal
		if (!props.open) {
			handleClear();
			setErrorMessages(null);
		}

	}, [props.open, padRef])

  return (
    <Modal
	  closable= {false}
	  maskClosable ={false}
      okText='Accept'
	  keyboard={false}
      width={'80%'}
      open={props.open}
      onOk={handleOK}
      onCancel={props.onClose}
			footer={[
				<Button key={"1"} onClick={() => navigate(PATH.RECEPTION)}>{"Cancel"}</Button>,
				<Button key={"2"} type={"primary"} ghost onClick={handleClear}>{"Clear"}</Button>,
				<Button key={"3"} type={"primary"} onClick={handleOK}>{"Confirm"}</Button>
			]}
			>
      <Header title={"Consent Form"}></Header>
      <Row align='end' style={{margin: 8}}>
        <Image
					style={{borderRadius: 8, height: "24px", width: "auto"}}
          src={flagEng}
          preview={false}
          onClick={() => setLocal("EN")} />
        <Image
					style={{marginLeft: 8, borderRadius: 8, height: "24px", width: "auto"}}
          src={flagThai}
          preview={false}
          onClick={() => setLocal("TH")} />
      </Row>
      <div >
        <Card>
          <Layout>
            {(local === "TH") && (
              <PDPAModalTHView />
            )}

            {(local === "EN") && (
              <PDPAModalENView />
            )}
          </Layout>
        </Card>
        <br />

        <p style={{fontWeight: 'bold', marginTop:0}} >{"Signature"}</p>
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
				<div className="section-wrapper" ref={padRef}>
					<SignaturePad 
						penColor={"blue"} 
						ref={signatureRef} 
						canvasProps={{width: (width ? (width - 32) : 0), height: 120}}/>
				</div>
      </div>
    </Modal>
  )

}

PDPAModal.defaultProps = {
  open: false,
	member: null,
	signature: () => {},
	onUpdated: () => {},
  onClose: () => {}
}

PDPAModal.propTypes = {
  open: propTypes.bool,
	member: propTypes.string,
	signature: propTypes.func,
	onUpdated: propTypes.func,
  onClose: propTypes.func,
}