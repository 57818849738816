import React from "react";
import propTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGrid from "@fullcalendar/timegrid"
import { Alert, Col, Row, Select, theme } from "antd";
import { dateToString } from "../../frameworks/Util";
import { GET } from "../../frameworks/HttpClient";
import { URL_TRAINER } from "../../constants/urls";
import { PATH } from "../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";

const ClassWeekView = React.forwardRef((props, ref) => {
	const navigate = useNavigate();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [trainer, setTrainer] = React.useState(null);
	const [studio, setStudio] = React.useState(null);
	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);
  const [studioOptions, setStudioOptions] = React.useState([]);
  const [trainerOptions, setTrainerOptions] = React.useState([]);
	const [events, setEvents] = React.useState([]);
	const calendarRef = React.useRef();

  const {
    token: { colorPrimary, colorTextTertiary }
  } = theme.useToken();

	React.useImperativeHandle(ref, () => ({
		fetchData: () => fetchEvent()
	}));

  const handleStudioChange = (value, selected) => setStudio(selected);

  const handlePTChange = (value, selected) => setTrainer(selected);

	const handleDateChange = (data) => {
		if (data) {
			setStartDate(dateToString(data.start));
      setEndDate(dateToString(data.end));
		}
	}

	const handleClick = (info) => {
		const targetId = (info && info.event && info.event._def && info.event._def) ? info.event._def.publicId : null;
		const data = targetId ? events.find(el => el.id = targetId) : null
		const isClass = (data && data.class_type) ? true : false;
		if (targetId) {
			if (isClass && props.partner == null) {
				// Goto Class Detail
				navigate(`${PATH.CLASS_DETAIL}/${targetId}/`)
			} else {
				props.onRentDetail(targetId)
			}
		}
		
	}

	const fetchEvent = async () => {
		// Prepare Data
		let params = {
			from_date: startDate ? startDate : null,
			to_date: endDate ? endDate : null,
			studio: studio ? studio.id : null,
			trainer: trainer ? trainer.id : null,
			page_size: 999
		}

		try {
			const response = await GET(URL_TRAINER.TRAINER_CLASS, params);
			setEvents(response.data.results.map(item => {
				const title = `${item.class_type ? item.class_type_name : item.note ? item.note : ""}` + 
					`${params.trainer ? "" : " (" + item.trainer_name + ")"}`
				return {
					...item,
					start: `${item.date}T${item.start_time}`,
					end: `${item.date}T${item.end_time}`,
					title: title,
					color: item.class_type ? colorPrimary : colorTextTertiary
				}
			}));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.studioOptions && props.studioOptions.length > 0) {
			setStudioOptions([
				{ id: null, value: null, label: "ALL" },
				...props.studioOptions
			]);
      setStudio(props.studioOptions[0]);
		}
	}, [props.studioOptions]);

	React.useEffect(() => {
		if (props.trainerOptions && props.trainerOptions.length > 0) {
      const allOption = { id: null, value: null, label: "ALL" }
      if (props.partner) {
        const partnerOption = {
          id: props.partner.id, 
          value: props.partner.id, 
          label: props.partner.full_name,
          ...props.partner
        }
        setTrainerOptions([allOption, partnerOption])
      } else {
        setTrainerOptions([ allOption, ...props.trainerOptions ])
      }
		}
	}, [props.trainerOptions]);

	React.useEffect(() => {
		if (startDate && endDate && studio) {
			fetchEvent();
		}
	}, [startDate, endDate, studio, trainer]);

  return (
    <div>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: 16 }}>
        <Col xl={6} sm={9} xs={24}>
          <label>{"Studio: "}</label>
          <Select
            style={{ width: "70%" }}
            options={studioOptions}
            onChange={handleStudioChange}
            value={studio} />
        </Col>
        <Col xl={6} sm={9} xs={24}>
          <label>{props.partner ? "Partner: " : "PT: "}</label>
          <Select
          style={{ width: "76%" }}
          options={trainerOptions}
          onChange={handlePTChange}
          value={trainer} />
        </Col>
      </Row>

			{errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{textAlign: "left", marginBottom: "8px"}}/>
			)}

      <FullCalendar
				ref={calendarRef}
        editable={false}
        themeSystem={colorPrimary}
        contentHeight={"62vh"}
        headerToolbar={{
          start: "title",
          center: "",
          end: "prev next",
        }}
        plugins={[timeGrid, interactionPlugin,]}
        initialView={"timeGridWeek"}
        allDaySlot={false}
				events={events}
				datesSet={handleDateChange}
        slotDuration={"00:30:00"}
        slotMinTime={"07:00:00"}
        slotMaxTime={"23:00:00"}
				eventClick={handleClick} />
    </div>
  )
});

ClassWeekView.defaultProps = {
  studioOptions: [],
  trainerOptions: [],
  partner: null,
  onRentDetail: (id) => {}
}

ClassWeekView.propTypes = {
  studioOptions: propTypes.array,
  trainerOptions: propTypes.array,
  partner: propTypes.object,
  onRentDetail: propTypes.func,
}

export default React.memo(ClassWeekView);