import React, { useState } from 'react'
import { Footer, } from 'antd/es/layout/layout'
import { Button, Image, Layout, Row, } from 'antd';
import HeaderScreen from '../components/common/HeaderScreen';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import flagEng from "../assets/eng.png";
import flagThai from "../assets/thai.png";
import "../styles.css"
import ConsentTHView from '../components/member/ConsentTHView';
import ConsentENView from '../components/member/ConsentENView';

export default function ConsentScreen() {
	const navigate = useNavigate();
	const [local, setLocal] = useState('EN');

	return (
		<Layout style={{ minHeight: "100vh"}}>
			<HeaderScreen title={"ALPHA HEALTH CLUB PHUKET"} />
			<div style={{margin: 24}}>
				<Row align='end' style={{marginBottom: 16}}>
					<Image
						src={flagEng}
						preview={false}
						style={{ borderRadius: 8, height: "24px", width: "auto"}}
						onClick={() => setLocal("EN")} />
					<Image
						src={flagThai}
						preview={false}
						style={{ marginLeft: 8, borderRadius: 8, height: "24px", width: "auto"}}
						onClick={() => setLocal("TH")} />
				</Row>
				{(local === "TH") && (
					<ConsentTHView />
				)}

				{(local === "EN") && (
					<ConsentENView />
				)}
			</div>

			<Footer style={{ background: "#fff", textAlign: "center" }}>
				<Button type={"primary"} shape={"round"} onClick={() => navigate(PATH.REGISTER,{ replace: true })}>{"Accept"}</Button>
			</Footer>
		</Layout>
	)
}


