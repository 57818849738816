import React from "react";
import propTypes from "prop-types";
import {  Col, DatePicker, Row } from "antd";
import TSTable from "../common/TSTable";
import dayjs from 'dayjs';
import { URL_TRAINER } from "../../constants/urls";
import { DATE_FORMAT } from "../../constants/string";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../routes/CustomRoutes";

const ClassListView = React.forwardRef((props, ref) => {
	const navigate = useNavigate();
	const [date, setDate] = React.useState(dayjs());
	const classRef = React.useRef(null);

	React.useImperativeHandle(ref, () => ({
		fetchData: () => {
			if (classRef && classRef.current) {
				classRef.current.fetch();
			}
		}
	}));

	const columns = [
		{
			title: 'Date',
    	dataIndex: 'date',
			width: 110,
			sorter: true,
		},
		{
			title: 'Time',
			dataIndex: 'start_time',
			width: 110,
			sorter: true,
			render: (value, original) => (
				<p style={{margin: 0}}>
					{`${value.substring(0, 5)}-${original.end_time.substring(0, 5)}`}
				</p>
			)
		},
		{
			title: 'Class',
    	dataIndex: 'class_type_name',
			render: (value, original) => (
				<>
				{value ? value : original.note }
				</>
			),
			sorter: true,
			// searcher: true,
		},
		{
			title: 'Studio',
			dataIndex: 'studio_name',
			searcher: true,
		},
		{
			title: 'Trainer',
			dataIndex: 'trainer_name',
			searcher: true,
		},
		{
			title: 'Checked',
			dataIndex: 'checked_count',
			width: 80,
			render: (value, original) => (<p style={{textAlign: "center", margin: 0}}>{original.class_type ? value : "-"}</p>)
		},
				{
			title: 'Attend',
			dataIndex: 'join',
			width: 80,
			render: (value, original) => (<p style={{textAlign: "center", margin: 0}}>{original.class_type ? value : "-"}</p>)
		},
		{
			title: 'Booking',
    	dataIndex: 'booked_count',
			width: 80,
			render: (value, original) => (
				<p style={{textAlign: "center", margin: 0}}>
					{original.members_limit ? (`${value}/${original.members_limit}`): "-" }
				</p>
			)
		},
	]
	const handleDateChange = (date) => {
	if(date)
	setDate(date)
	}
	;
	const handleClick = (data) => {
		const isClass = (data && data.class_type) ? true : false;
		if (isClass && props.partner == null) {
			navigate(`${PATH.CLASS_DETAIL}/${data.id}/`)
		} else {
			props.onRentDetail(data.id);
		}
	}

	React.useEffect(() => {
		if (classRef && classRef.current) {
			classRef.current.fetch();
		}
	}, [date])

  return (
    <div>
      <Row 
        gutter={[16, 16]}
        style={{marginBottom: 16}}>
        <Col xl={6} sm={9} xs={24}>
          <label>{"Date: "}</label>
          <DatePicker 
            style={{width: "76%"}}
            onChange={handleDateChange} 
            inputReadOnly={true}
            allowClear={false}
            value={date}/>
        </Col>
      </Row>

      <TSTable 
        ref={classRef}
        columns={columns}
        url={`${URL_TRAINER.TRAINER_CLASS}?date=${date ? date.format(DATE_FORMAT) : null}`}
        rowKey={"id"}
        size={"small"}
        onRow={(record, rowIndex) => ({
          onClick: _ => handleClick(record)
        })}
      />
    </div>
  )
});

ClassListView.defaultProps = {
	partner: null,
  onRentDetail: (id) => {},
}

ClassListView.propTypes = {
	partner: propTypes.object,
  onRentDetail: propTypes.func,
}

export default React.memo(ClassListView);