import React from 'react';
import propTypes from "prop-types";
import Header from '../common/Header';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/string';
import { Alert, Button, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, theme, TimePicker } from 'antd';
import { GET, PATCH, POST } from '../../frameworks/HttpClient';
import { URL_TRAINER, URL_USERS } from '../../constants/urls';
import dayjs from 'dayjs';

export default function StudioCourseRentModal(props) {
  const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [studioOptions, setStudioOptions] = React.useState([]);
  const [partnerOptions, setPartnerOptions] = React.useState([]);
  const {
    token: { colorSuccess, colorWarning, colorPrimary },
  } = theme.useToken();

  // Handle date picker
  const disabledDate = (current) => current && current <= dayjs().startOf('day');

  /** Manage partner options */
  const fetchPartners = async (partner) => {
    if (partner) {
      setPartnerOptions([{ ...partner, value: partner.id, label: partner.full_name }])
      form.setFieldValue("trainer", partner.id)
      return;
    }

    // Fetch partner options
    try {
			const response = await GET(`${URL_USERS.PARTNERS}`, { page_size: 999 })
			setPartnerOptions(response.data.results.map(item => ({ ...item, value: item.id, label: item.full_name })));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  }

  const handleCreate = async () => {
    let response = null;
    
    try {
      // Validate data
      let data = await form.validateFields();
      setErrorMessages(null);
      setLoading(true);
      // Prepare data
      data["start_date"] = data.date[0].format(DATE_FORMAT);
      data["end_date"] = data.date[1].format(DATE_FORMAT);
      data["start_time"] = data.time[0].format(TIME_FORMAT);
      data["end_time"] = data.time[1].format(TIME_FORMAT);
      delete data["time"];
      // Create course
      response = await POST(URL_TRAINER.CLASS_RENTAL_COURSE, data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setLoading(false) }

    if (response)
      props.onCreated();
  }

  // Filter studio for booking
  React.useEffect(() => {
    if (props.studioOptions) {
      const result = props.studioOptions.filter((studio) => studio.allow_booking);
      setStudioOptions(result)
    }
  }, [props.studioOptions])

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
      fetchPartners(props.partner);
    } else {
      setErrorMessages(null);
    }
  }, [props.open]);

  return (
    <Modal
      okText={"Create"}
      okButtonProps={{ 
        style: {background: colorSuccess},
        loading: loading,
      }}
      open={props.open}
      onOk={handleCreate}
      onCancel={props.onClose}>
      
      <Header title={"Booking (Continuous)"} />
      {errorMessages && (
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{
            marginBottom: "12px",
            marginTop: "12px",
            textAlign: "left"
          }} />
      )}
      
      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
        <Form.Item 
          name={"studio"} 
          label={"Studio"} 
          rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            options={studioOptions}
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) => 
            //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} 
              />
        </Form.Item>

        <Form.Item 
          name={"trainer"} 
          label={"Partner"} 
          rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            options={partnerOptions}
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) => 
            //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} 
              />
        </Form.Item>

        <Form.Item 
          name={"weekdays"} 
          label={"Day"} 
          rules={[{ required: true }]}>
          <Checkbox.Group>
            <Row>
              <Col span={6}>
                <Checkbox value={0}>{"Mon"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={1}>{"Tue"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={2}>{"Wed"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={3}>{"Thu"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={4}>{"Fri"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={5}>{"Sat"}</Checkbox>
              </Col> 
              <Col span={6}>
                <Checkbox value={6}>{"Sun"}</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item 
          name={"time"} 
          label={"Time"} 
          rules={[{ required: true }]}>
          <TimePicker.RangePicker 
            style={{width: '100%'}} 
            format={TIME_FORMAT}
            inputReadOnly={true}
            minuteStep={5}
            disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4] })}/>
        </Form.Item>

        <Form.Item 
          name={"date"} 
          label={"Date"} 
          rules={[{ required: true }]}>
          <DatePicker.RangePicker 
            style={{width: '100%'}}  
            disabledDate={disabledDate} 
            inputReadOnly={true}/>
        </Form.Item>

        <Form.Item
          name={"note"}
          label={"Note"}>
          <Input placeholder='Note...' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

StudioCourseRentModal.defaultProps = {
  open: false,
  studioOptions: [],
  onCreated: () => {},
  onClose: () => {},
}

StudioCourseRentModal.propTypes = {
  open: propTypes.bool,
  studioOptions: propTypes.array,
  onCreated: propTypes.func,
  onClose: propTypes.func,
}