import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal, Select, theme } from "antd";
import Header from '../common/Header';
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE, URL_TRAINER } from '../../constants/urls';

export default function ChangePTPackageModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [trainerOptions, setTrainerOptions] = React.useState([]);
  const [form] = Form.useForm();
  const {
    token: { colorWarning },
  } = theme.useToken();
  
  // Handle change PT
  const handleChangePT = async () => {
    let data = await form.validateFields();
    if (!props.package) {
      setErrorMessages("Package no found!");
      return;
    }

    setErrorMessages(null);
    const currentTrainer = props.package.trainer;
    if (data.trainer !== currentTrainer) {
      const tempData = {package: props.package.id, new_trainer: data.trainer}
      setLoading(true);
      let response = null;
      try {
        response = await POST(URL_PACKAGE.MEMBER_PT_SESSION_PACKAGE_UPDATE_LOG, tempData);
      } catch (error) {
        setErrorMessages(error.errorMessages);
        return;
      }
      finally { setLoading(false) }
      // Close modal
      if (response)
        props.onSuccess();
      
    } else {
      // Close modal
      props.onClose();
    }
  };

  // Fetch trainer options
  const fetchTrainer = async () => {
    setErrorMessages(null);
    try {
      const response = await GET(URL_TRAINER.TRAINERS);
      let temp = response.data.results.map(item => ({ ...item, value: item.id, label: item.full_name }));
			setTrainerOptions(temp);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (props.open && props.package) {
      fetchTrainer();
      form.setFieldsValue({trainer: props.package.trainer})
    } else {
      if (!props.open) {
        form.resetFields();
        setErrorMessages(null);
      }
    }
  }, [props.open, props.package]);

  return (
    <Modal
      open={props.open}
      okText={'Change'}
      okButtonProps={{
        loading: loading,
        style: {background: colorWarning}
      }}
      onOk={handleChangePT}
      onCancel={props.onClose}>

      <Header title={"Change PT"} />
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginTop: "12px", textAlign: "left" }}
        />
      }

      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
        
        <Form.Item label={"Package"}>
          <Input autoComplete="off" value={props.package ? props.package.package_name : "Unknown"} disabled/>
        </Form.Item>

        <Form.Item name={"trainer"} label="Trainer" rules={[{ required: true }]}>
          <Select options={trainerOptions}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

ChangePTPackageModal.defaultProps = {
  package: null,
  open: false,
  onClose: () => {},
  onSuccess: () => {},
}

ChangePTPackageModal.propTypes = {
  package: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
}