import React from 'react';
import propTypes from "prop-types";
import ReactPlayer from 'react-player'
import "../css/Player.css";


export default function VideoFullScreenView (props) {
  return(
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        width="100%"
        height="100%"
        url={props.url}
        controls={false}
        playing={true}
        loop={true}
        muted={true}
        fullscreen={true}
      />
    </div>
  )
}


VideoFullScreenView.defaultProps = {
  url: null,
}

VideoFullScreenView.propTypes = {
  url: propTypes.string
}

