import React from 'react'
import { Row, Col, Image, Layout, Menu, theme, Avatar, Badge, Tag } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import {
  UserOutlined,
  IdcardOutlined,
  FileDoneOutlined,
  DollarOutlined,
  CalendarOutlined,
  ProfileOutlined,
  KeyOutlined,
  BellOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import logo from "../assets/ahc_logo.png"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PATH } from '../routes/CustomRoutes';
import ReceptionView from '../views/ReceptionView';
import MemberListView from '../views/MemberListView';
import PaymentListView from '../views/PaymentListView';
import PTClassView from '../views/PTClassView';
import PTCalendarView from '../views/PTCalendarView';
import ReportsView from '../views/ReportsView';
import "./../styles.css"
import LogoutModal from '../components/user/LogoutModal';
import useUserData from '../hooks/useUserData';
import ChangePasswordModal from '../components/user/ChangePasswordModal';
import { USER_GROUP } from '../constants/string';
import GateManagerView from '../views/GateManagerView';
import LaundryGateView from '../views/LaundryGateView';
import { useMenuBadgeContext } from '../hooks/MenuBadgeProvider';
import * as _ from 'lodash';
import PTShiftView from '../views/PTShiftView';
import Cookies from 'js-cookie';
import { COOKIE_AUTH } from '../constants/cookies';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';


export default function MenuScreen() {
	const {user} = useUserData();
	// Main menu
	const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = React.useState(null);
  const [collapsed, setCollapsed] = React.useState(false);
	// User menu
	const [openLogout, setOpenLogout] = React.useState(false);
	const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const { menuBadges, fetchMenuBadges } = useMenuBadgeContext();
  const { lessThanOrEqual } = useWindowDimensionContext();
	const isOnMobile = lessThanOrEqual('sm');

  const {
    token: { colorPrimary },
  } = theme.useToken();

	const handleUserSelected = (e) => {
		switch (e.key) {
			case "logout":
				setOpenLogout(true);
				break;
			
			case "changePassword":
				setOpenChangePassword(true);
				break;
			
			default: break;
		}
	}

  const onMenuSelected = (e) => {
    if (e.key !== current)
      setCurrent(e.key)
      navigate(`/${e.key}`)
  };

  const renderContent = () => {
    switch (pathname) {
      case PATH.RECEPTION: return <ReceptionView />
      case PATH.MEMBERS: return <MemberListView />
      case PATH.PAYMENTS: return <PaymentListView />
      case PATH.CLASS: return (
        <PTClassView 
          isManager={permissions[USER_GROUP.PT_MANAGER]} 
          canBooking={permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.SALE]}
          partner={permissions[USER_GROUP.CLASS_PARTNER] ? user : null}/>
      )
      case PATH.CALENDAR: return (
        <PTCalendarView 
          isManager={permissions[USER_GROUP.PT_MANAGER]} 
          isReception={permissions[USER_GROUP.RECEPTION]} 
          isSale={permissions[USER_GROUP.SALE]}/>
      )
      case PATH.PT_SHIFT: return <PTShiftView/>
      case PATH.DOOR: return <GateManagerView />
      case PATH.LAUNDRY_GATE: return <LaundryGateView/>
      case PATH.REPORTS: return <ReportsView />
      default: return null;
    }
  }

	const permissions = {
    'reception': user.groups.find(group => group === USER_GROUP.RECEPTION) ? true : false,
    'sale': user.groups.find(group => group === USER_GROUP.SALE) ? true : false,
    'accountant': user.groups.find(group => group === USER_GROUP.ACCOUNTANT) ? true : false,
    'pt': user.groups.find(group => group === USER_GROUP.PT) ? true : false,
    'pt_manager': user.groups.find(group => group === USER_GROUP.PT_MANAGER) ? true : false,
    'pt_freelancer': user.groups.find(group => group === USER_GROUP.PT_FREELANCER) ? true: false,
    'class_partner': user.groups.find(group => group === USER_GROUP.CLASS_PARTNER) ? true : false,
  }

  const menuItems = [
    { 
      key: 'reception', 
      icon: <UserOutlined />, 
      label: 'Reception',
      path: PATH.RECEPTION,
      permission: (permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.SALE])
    },
    { 
      key: 'members', 
      icon: <IdcardOutlined />, 
      label: 'Member',
      path: PATH.MEMBERS,
      permission: (permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.SALE]|| permissions[USER_GROUP.ACCOUNTANT])
    },
    { 
      key: 'payments', 
      icon: <DollarOutlined />,
      label: <span>
        Payment { menuBadges.rejected_receipt_badge_count > 0 && <Tag color='#faad14'>{menuBadges.rejected_receipt_badge_count}</Tag>}
      </span>,
      path: PATH.PAYMENTS,
      permission: (permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.SALE] || permissions[USER_GROUP.ACCOUNTANT])
    },
    { 
      key: 'class', 
      icon: <ProfileOutlined />, 
      label: 'Class Management',
      path: PATH.CLASS,
      permission: (
        permissions[USER_GROUP.PT] || permissions[USER_GROUP.PT_MANAGER] || permissions[USER_GROUP.PT_FREELANCER] ||
        permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.SALE] || permissions[USER_GROUP.CLASS_PARTNER]
      )
    },
    { 
      key: 'calendar', 
      icon: <CalendarOutlined />, 
      label: 'PT Calendar',
      path: PATH.CALENDAR,
      permission: (
        permissions[USER_GROUP.PT] || permissions[USER_GROUP.PT_MANAGER] || permissions[USER_GROUP.SALE] ||
        permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.PT_FREELANCER]
      )
    },
    {
      key: 'pt-shift',
      icon: <ClockCircleOutlined/>,
      label: 'PT Shift',
      path: PATH.PT_SHIFT,
      permission: (permissions[USER_GROUP.PT] || permissions[USER_GROUP.PT_MANAGER])
    },
    { 
      key: 'door', 
      icon: <KeyOutlined />, 
      label: 'Gate Manager',
      path: PATH.DOOR,
      permission: (permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.SALE])
    },
    {
      key: 'laundry-gate',
      icon: <BellOutlined />,
      label: 'Laundry Alarm',
      path: PATH.LAUNDRY_GATE,
      permission: (permissions[USER_GROUP.RECEPTION] || permissions[USER_GROUP.SALE])
    },
    { 
			key: 'reports', 
			icon: <FileDoneOutlined />, 
			label: 'Report',
			path: PATH.REPORTS,
      permission: !(permissions[USER_GROUP.CLASS_PARTNER])
		}
  ]

  const reRouteMenu = () => {
    const menu = params.menu
    const firstMenu = menuItems.find(menu => menu.permission)
    if (firstMenu && !menu) {
      setCurrent(_.get(firstMenu, 'key', 'reports'))
      navigate(_.get(firstMenu, 'path', PATH.REPORTS))
    }
  }

	React.useEffect(() => {
		if (user) {
      // For: staff login
      if (!user.member) {
        reRouteMenu();
      } else {
        // For: member login
        navigate(`${PATH.MEMBER}${PATH.PROFILE}`, { replace: true });
      }
      fetchMenuBadges();
		}
	}, [user]);

	React.useEffect(() => {
		const menu = params.menu
		if (menu) 
			setCurrent(menu);
	}, [params])

  React.useEffect(() => {
    const auth = Cookies.get(COOKIE_AUTH.TOKEN)
    if(auth) {
      // Refresh cookie timeout
      const u = Cookies.get(COOKIE_AUTH.USER)
      Cookies.set(COOKIE_AUTH.TOKEN, auth, { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
      Cookies.set(COOKIE_AUTH.USER, u, { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
    }
    return () => {}
  }, [])

  return (
    <div>
      <Layout style={{minHeight: "100vh"}}>
        <Header 
          className={"shadow"} 
          style={{ 
            position: 'sticky', 
            top: 0, 
            zIndex: 1, 
            width: '100%', 
            background: "#FFFFFF", 
            color: colorPrimary, 
            fontWeight: "bold", 
            paddingInline: "28px" }}>
          <Row>
            <Col span={20}>
              <div style={{textAlign: "left"}}>
                <Image style={{height: "56px", width: "auto"}} src={logo} preview={false} onClick={() => setCollapsed(!collapsed)}/>
                <label style={{marginLeft: 16}}>{"ALPHA HEALTH CLUB PHUKET"}</label>
              </div>
            </Col>
            <Col span={4}>
              <Menu 
								style={{justifyContent: "end"}}
                mode={"horizontal"}
								onClick={handleUserSelected}
                items={[
                  {
                    key: 'user',
                    label: <Avatar style={{ color: '#fff', backgroundColor: colorPrimary }}>{user ? user.full_name.substring(0, 1) : "?"}</Avatar>,
                    children: [
                      {
                        label: user.full_name,
                        type: 'group'
                      },
                      {
                        label: 'Change Password',
                        key: 'changePassword',
                      },
                      {
                        label: 'Logout',
                        key: 'logout',
                      },
                    ]
                  }
                ]} />
            </Col>
          </Row>
        </Header>

        <Layout>
          <Sider
            style={{background: colorPrimary}} 
            trigger={null} 
            collapsible 
            collapsed={collapsed} 
            collapsedWidth={0}>
            <Menu
              style={{background: colorPrimary, color: "#fff", textAlign: "left"}}
              mode="inline"
              defaultSelectedKeys={[current]}
              selectedKeys={[current]}>
              {menuItems.map(menu => menu.permission != false ? (
                <Menu.Item key={menu.key} onClick={onMenuSelected}>
                  {menu.icon}
                  <span>{menu.label}</span>
                </Menu.Item>
              ) : null )}
            </Menu>
          </Sider>
          <Content style={{margin:16}}>
            {renderContent()}
          </Content>
        </Layout>

				<LogoutModal 
					open={openLogout}
					onClose={() => setOpenLogout(false)}/>

				<ChangePasswordModal 
					open={openChangePassword}
					onClose={() => setOpenChangePassword(false)}/>
      </Layout>
      </div>
  )
}