import React from "react";
import propTypes from "prop-types";
import { CheckCircleTwoTone, CheckSquareTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { Alert, Card, Col, Image, Input, Layout, Row, Spin, theme } from "antd";
import ItemCheckIn from "../../components/member/ItemCheckIn";
import logo from "../../assets/ahc_logo.png";
import HeaderScreen from "../../components/common/HeaderScreen";
import Title from "antd/es/typography/Title";

const MemberCheckInView = React.forwardRef((props, ref) => {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loadingContent, setLoadingContent] = React.useState(false);

	const {
		token: { colorSuccessText, colorWarningText, colorTextSecondary, colorPrimary }
	} = theme.useToken();

	React.useImperativeHandle(ref, () => ({
    setLoading: () => setLoadingContent(true),
  }));

	React.useEffect(() => {
		if (props.member) {
			setLoadingContent(false);
		}
	}, [props.member]);
	
	return (
		<Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
			<HeaderScreen 
				title={"ALPHA HEALTH CLUB PHUKET"}
				showBackIcon={false} />
			
			<div align='center' style={{ marginTop: 24 }}>
				{!props.isCheckedIn && (
					<Image
						style={{borderRadius: 8, height: 180, width: "auto"}}
						preview={false}
						src={(props.member && props.member.profile_image) ? props.member.profile_image : logo}/>
				)}

				{props.isCheckedIn && (
					<div style={{margin: 38}}>
						<div style={{fontSize: 38, fontWeight: "bold", color: colorPrimary}}>{"ขอบคุณที่ใช้บริการ"}</div>
						<div style={{fontSize: 48, fontWeight: "bold", color: colorPrimary}}>{"..."}</div>
						<div style={{fontSize: 38, fontWeight: "bold", color: colorPrimary}}>{"Thank you for your visiting"}</div>
					</div>
				)}
				

				<p style={{
					margin: 24,
					marginTop: 16,
					fontSize: 18,
					fontWeight: 'bold'
				}}>{props.member ? props.member.full_name : "Unknown"}</p>

				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
				<Row justify={"center"}>
					<Col md={12} sm={24} xs={24}>
						<Input
							autoComplete="off"
							size={"large"}
							className={"custom-input-font-weight"}
							style={{fontWeight: "bold"}}
							addonBefore={
								<CheckSquareTwoTone 
									twoToneColor={colorSuccessText} 
									style={{fontSize: 16}}/>
							}
							addonAfter={props.package && props.package.end_date
								?
								<div style={{textAlign: "left"}}>
									<CheckCircleTwoTone
										twoToneColor={colorSuccessText}/>
									<label 
										style={{marginLeft: 8, color: colorTextSecondary}}>
											{props.package ? `${props.package.start_date} Start` : "Unknown"}
									</label>
									<br></br>
									
									<InfoCircleTwoTone style={{marginTop: 4}}
										twoToneColor={colorWarningText}/>
									<label 
										style={{marginLeft: 8, color: colorWarningText}}>
											{props.package ? `${props.package.end_date} Exp.` : "Unknown"}
									</label>
								</div>
								: null
							} 
							value={`Package: ${
								(props.package && props.package.package_name) ? 
								`${props.package.package_name} ${props.package.variant_type ? "- " + props.package.variant_type : ""}` : 
								"Unknown"
							}`}
						/>
					</Col>
				</Row>

				{loadingContent && (
					<Row justify={"center"} style={{marginTop: 16}}>
						<Col md={12} sm={24} xs={24}>
							<Card 
								className={"custom-card-actions-blue custom-card-actions-text"}>
								<Spin tip={"Checking In"} size={"large"} style={{margin: 46}}/>
							</Card>
						</Col>
					</Row>
				)}
				
				{!loadingContent && (
					<ItemCheckIn 
						isSecondary={true}
						package={props.package}
						isCheckedIn={props.isCheckedIn}
						onCheckedOut={props.onCheckedOut}
						onCheckedIn={props.onCheckedIn}
						onUpdated={props.onUpdated}
						checkedIn={props.checkedIn}
						onError={(message) => setErrorMessages(message)}
						isMemberScreen={true}
						onMemberCheckIn={(data) => {
							setLoadingContent(true);
							props.onMemberCheckIn(data);
						}}/>
				)}
			</div>
		</Layout>
	)
});

MemberCheckInView.defaultProps = {
	member: null,
	package: null,
	isCheckedIn: false,
	checkedIn: null,
	onCheckedIn: () => {},
	onCheckedOut: () => {},
	onUpdated: () => {},
	onError: (message) => {},
	
	// Return check-in
	onMemberCheckIn: (data) => {},
}
MemberCheckInView.propTypes = {
	member: propTypes.object,
	package: propTypes.object,
	isCheckedIn: propTypes.bool,
	checkedIn: propTypes.object,
	onCheckedIn: propTypes.func,
	onCheckedOut: propTypes.func,
	onUpdated: propTypes.func,
	onError: propTypes.func,

	// Return check-in
	onMemberCheckIn: propTypes.func,
}

export default MemberCheckInView;