import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderScreen from "../../components/common/HeaderScreen";
import PackageHistory from "../../components/member/PackageHistory";

export default function PackagesHistoryScreen() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Layout style={{ minHeight: "100vh", }}>
      <HeaderScreen
        title={"Packages History"}
        onClick={() => navigate(-1)} />
      <Content style={{ margin: 24 }}>
        <PackageHistory member={params.id}></PackageHistory>
      </Content>
    </Layout>
  )
}             