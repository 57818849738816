import React from "react";
import propTypes from "prop-types";
import { Card, Col, Divider, List, Modal, Space, theme } from "antd";
import Header from "../../components/common/Header";
import { HeartOutlined, TeamOutlined, CoffeeOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";

const TYPE_PT_SESSION = "pt_session";
const TYPE_PACKAGE_DROP = "package_drop";


const EventCard = (props) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const renderIcon = (type) => {
    const style = { fontSize: 24, color: colorPrimary }
    if(type === TYPE_PT_SESSION) {
      return <HeartOutlined style={style} /> 
    }
    else if (type === TYPE_PACKAGE_DROP) {
      return <CoffeeOutlined style={style} />
    }
    else {
      return <TeamOutlined style={style} />
    }
  }

  const renderHeader = (type) => {
    if (type === TYPE_PT_SESSION) {
      return "Training"
    }
    else if (type === TYPE_PACKAGE_DROP) {
      return "Drop"
    }
    else {
      return "Class"
    }
  }

  const renderContent = (data) => {
    if(data.type === TYPE_PACKAGE_DROP) {
      return <>
      {data.duration} <br/>
      Drop by : {data.drop_by}<br/>
      Note : {data.note}
      </>
    }
    else if (data && (data.type === TYPE_PT_SESSION) && data.trainer_name) {
      return <>
      Time : {data.start_time.substring(0, 5)} - {data.end_time.substring(0, 5)}<br/>
      Trainer : {data.trainer_name}<br/>
      Note : {data.note ? data.note : "-" } <br/>
      Status : {(data.is_checked === true) ? "Joined" : (data.is_checked === false) ? "Missed" : "Waiting" }
      </>
    }
    else{
      return <>
      Time : {data.start_time.substring(0, 5)} - {data.end_time.substring(0, 5)}<br/>
      Studio : {data.studio_name} <br/>
      Trainer : {data.trainer_name}<br/>
      Status : {(data.is_member_checked === true) ? "Joined" : 
      (data.is_member_checked === false && (new Date() >= new Date(`${data.date} , ${data.end_time}`))) ? "Missed" : "Waiting" }
      </>
    }
  }

  return (
    <Card style={{ marginTop: 16 }}>
      <Meta
        avatar={
          <Space>
            <Col style={{ textAlign: 'center', width: 52 }}>
              
              {props.data && renderIcon(props.data.type)}
              
              <div style={{color: colorPrimary, fontWeight: "bold", fontSize: 12}}>
                {props.data && renderHeader(props.data.type)}
              </div>
            </Col>
            <Divider type="vertical" style={{ height: 60 }} />
          </Space>
        }
        title={
          props.data.type === TYPE_PACKAGE_DROP ? "Package Drop" : props.data.event_name 
        }
        description={props.data 
          ? renderContent(props.data)
          : ""
        }/>
    </Card>
  )
}

export default function EventModal(props) {
  return (
    <Modal
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}>
      <Header title={props.data ? props.data.date : "Events"} />

      <List
        dataSource={props.data ? props.data.events : []}
        renderItem={(item) => (
          <EventCard 
            key={item.id} 
            data={item}/>
        )}/>
    </Modal>
  )
}

EventModal.defaultProps = {
  data: null,
  open: false,
  onClose: () => {},
}

EventModal.propTypes = {
  data: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
}