export const URL_COMMON = {
	LOCATION_SEARCH: "/api/common/location-search/",
	CORPORATE: "/api/common/corporate/",
	GATE_LIST: "/api/common/gate-list/",
	RECEPTION_ASSET: "/api/common/reception-assets/",
	NATIONALITY: "/api/common/nationality/",
	REAWARD_POINT_MULTIPLIER: "/api/common/reward-point-multiplier/",
}

export const URL_AUTH = {
  	API_TOKEN_AUTH: "/api/users/api-token-auth/",
  	MEMBER_TOKEN_AUTH: "/api/users/member-token-auth/",
  	API_LIFF_TOKEN_AUTH: "/api/users/liff-token-auth/",
	CHECK_LIFF_AUTH: "/api/users/checking-liff-auth/",
  	CHANGE_PASSWORD: "/api/users/change-password/",
  	SIGN_OUT: "/api/users/sign-out/",
};

export const URL_NOTIFICATION = {
	MENU_BADGES: "/api/notification/menu-badges/"
}

export const URL_MEMBER = {
  	MEMBER: "/api/member/member/",
	VALIDATE_NAME: "/api/member/register-validate-name/",
	VALIDATE_PHONE: "/api/member/register-validate-phone/",
	VALIDATE_EMAIL: "/api/member/register-validate-email/",
	USER_UPDATE_LOG: "/api/member/user-update-log/",
	CORPORATE_CARD: "/api/member/corporate-card/",
	MEMBER_CODE: "/api/member/member-code/",
	TAX_INVOICE: "/api/member/tax-invoice/",
	REFERRAL_UPDATE_LOG: "/api/member/referral-update-log/",
	MEMBER_GATE_TAG: "/api/member/member-gate-tag/",
	MEMBERSHIP_PACKAGE_CONTRACT: "/api/member/membership-package-contract/",
	MEMBER_BLACKLIST_LOG: "/api/member/member-blacklist-log/",
}

export const URL_PACKAGE = {
	PACKAGES: "/api/package/packages/",
	PACKAGES_MEMBER: "/api/package/packages-member/",
	PACKAGE_USAGE: "/api/package/package-usage/",
	PACKAGE_USAGE_SUMMARY: "/api/package/package-usage/summary/",
	PACKAGE_VARIANT: "/api/package/package-variant/",
	PACKAGE_DROP: "/api/package/package-drop/",
	PACKAGE_EXTEND: "/api/package/package-extend/",
	PACKAGE_TRANSFER: "/api/package/package-transfer/",
	PACKAGE_EXCHANGE_LIST: "/api/package/package-exchange-rule-list/",
	PACKAGE_EXCHANGE: "/api/package/package-exchange-create/",
	PACKAGE_CATEGORY: "/api/package/package-category/",
	PT_SESSION_PACKAGE: "/api/package/pt-session-package/",
	PT_SESSION_PACKAGE_VARIANT: "/api/package/pt-session-package-variant/",
	ALL_CLUB_PACKAGE: "/api/package/all-club-package/",
	MEMBER_PACKAGE: "/api/package/member-package/",
	MEMBER_PACKAGE_USAGE_STATUS: "/api/package/member-package-usage-status/",
	MEMBER_PT_SESSION_PACKAGE: "/api/package/member-pt-session-package/",
	MEMBER_PT_SESSION_PACKAGE_UPDATE_LOG: "/api/package/member-pt-session-package-update-log/",
	POINT_REDEEMABLE_PACKAGES: "/api/package/point-redeemable-packages/",
	PACKAGE_PENALTY: "/api/package/package-penalty/",
}

export const URL_PRODUCT ={
	PRODUCTS: "/api/product/general-product/"
}

export const URL_USERS = {
	SALES: "/api/users/sales/",
	TRAINERS: "/api/users/trainers/",
	CASHIER: "/api/users/cashier/",
	PARTNERS: "/api/users/partners/",
}

export const URL_REPORT = {
	MEMBER_SHIP_CARD_REPORT: "/api/report/membershipcard-report/",
	REAL_MEMBER_SHIP_CARD_REPORT: "/api/report/real-membershipcard-report/",
	NEW_MEMBER_SHIP_CARD_REPORT: "/api/report/new-membershipcard-report/",
	NEW_REAL_MEMBER_SHIP_CARD_REPORT: "/api/report/new-real-membershipcard-report/",
	RECEIPT_REPORT: "/api/report/receipt-report/",

	// Sale
	PACKAGE_REPORT: "/api/report/package-report/", // (1)
	CHECKINTIME_REPORT: "/api/report/checkintime-report/", // (2)
	DROPPACKAGE_REPORT: "/api/report/droppackage-report/", // (3)
	FREETRIALPACKAGE_REPORT: "/api/report/freetrialpackage-report/", // (4)
	CHECKIN_CHECKOUT_MEMBER_REPORT: "/api/report/checkin-checkout-member-report/", // (5)
	RENEW_RATE_REPORT: "/api/report/renew-rate-report/", // (6)
	REWARDPOINT_MEMBERS_REPORT: "/api/report/rewardpoint-members-report/", // (7)
	MEMBERPACKAGE_REPORT: "/api/report/memberpackage-report/", // (8)
	MEMBERPACKAGEACTIVEGROUP_REPORT: "/api/report/memberpackageactivegroup-report/", // (9)
	MEMBERSHIP_PACKAGE_SUMMARY_REPORT: "/api/report/membership-package-summary-report/", // (10)
	PT_PACKAGE_SUMMARY_REPORT: "/api/report/pt-package-summary-report/", // (11)
	SALE_MONTHLY_REPORT: "/api/report/sales-monthly-report/", // (12)
	MEMBER_ABSENT_REPORT: "/api/report/member-absent-report/", // (13)
	EXPIRING_PACKAGE_REPORT: "/api/report/expiring-package-report/", // (14)

	// PT
	PT_CLASS_REPORT: "/api/report/pt-class-report/", // (1)
	PT_SESSION_REPORT: "/api/report/pt-session-report/", // (2)
	PT_GREETING_REPORT: "/api/report/pt-greeting-report/", // (3)
	PT_CLASS_MONTHLY_REPORT: "/api/report/pt-class-monthly-report/", // (4)

	// Accountant
	PAYMENT_BY_TYPE_OF_MONEY_REPORT: "/api/report/payment-by-type-of-money-report/", // (1)
	INCOME_CASHIER_BY_MONEY_TYPE_REPORT: "/api/report/income-cashier-by-money-type-report/", // (2)
	PAYMENT_REPORT: "/api/report/payment-report/", // (3)
	PACKAGE_VOUCHER_REPORT: "/api/report/package-voucher-report/", // (4)
	VOUCHER_BUY_REPORT: "/api/report/voucher-buy-report/", // (5)
	MANUAL_ADD_REWARD_POINT_REPORT: "/api/report/manual-add-reward-point-report/", // (6)
	GIFT_POINT_REPORT: "/api/report/gift-point-report/", // (7)
	REJECT_RECRIPT_BY_ACCOUNTING_REPORT: "/api/report/reject-receipt-by-accounting-report/", // (8)
	SALES_DYNAMIC_PACKAGE_REPORT: "/api/report/sales-dynamic-package-report/", // (9)
	INVOICE_REPORT: "/api/report/invoice-report/", // (10)

	// Others
	SALES_DAILY_REPORT: "/api/report/sales-daily-report/", // (1)
	CORPORATE_REPORT: "/api/report/corporate-report/", // (2)
	REVENUE_REPORT: "/api/report/revenue-report/", // (4)
	STUDIO_BOOKING_REPORT: "/api/report/studio-booking/", // (2)

	// Ticket
	STORETICKET_REPORT: "/api/report/storeticket-report/",
}

export const URL_SALE = {
	CART: "/api/sale/cart/",
	CART_ITEM: "/api/sale/cart-item/",
	CART_MEMBER: "/api/sale/cart-member/",
	CART_TAX_INVOICE: "/api/sale/cart-tax-invoice/",
	PROMOTION: "/api/sale/promotion/",
	PROMOTION_ITEM: "/api/sale/promotion-item/",
	DISCOUNT_VALIDATE: "/api/sale/discount-validate/",
	POINT_PURCHASE: "/api/sale/point-purchase/",
	CART_UPDATE_LOG_LIST: "/api/sale/cart-update-log/",
	MULTIPLE_PAYMENT_UPDATE: "/api/sale/multiple-payment-update/"
}

export const URL_TRAINER = {
	STUDIO: "/api/trainer/studio/",
	CLASS_TYPE: "/api/trainer/class-type/",
	TRAINERS: "/api/users/trainers/",
	TRAINER_CLASS: "/api/trainer/trainer-class/",
	TRAINER_COURSE: "/api/trainer/trainer-class/course/",
	CLASS_RENTAL: "/api/trainer/class-rental/",
	CLASS_RENTAL_COURSE: "/api/trainer/class-rental/course/",
	// TRAINER_RENT: "/api/trainer/trainer-class/rent/",
	// TRAINER_RENT_COURSE: "/api/trainers/trainer-class/rent_course/",
	TRAINER_CALENDAR: "/api/trainer/trainer-calendar/",
	TRAINER_CLASS_MEMBER: "/api/trainer/trainer-class-member/",
	TRAINER_SESSION_MEMBER: "/api/trainer/trainer-session-member/",
	PT_PACKAGE_TRAINER: "/api/trainer/pt-package-trainer/",
	SESSION_APPOINTMENT: "/api/trainer/session-appointment/",
	MEMBER_BOOKING: "/api/trainer/member-booking/",
	MEMBER_ACTIVITY_HISTORY: "/api/trainer/member-activity-history/",
	MEMBER_BOOKING_HISTORY: "/api/trainer/member-booking-history/",
	MEMBER_CALENDAR: "/api/trainer/member-calendar/",
	MEMBER_INTERACTION: "/api/trainer/member-interaction/",
	MEMBER_ATTENDANCE: "/api/trainer/member-attendance/",
	TRAINER_SHIFT: "/api/trainer/trainer-shift/",
	SESSION_APPOINTMENT_TRANSFER: "/api/trainer/session-appointment-transfer/",
	FREE_TRAINING_APPOINTMENT: "/api/trainer/free-training-appoinment/",
}

export const URL_REWARD = {
	REWARD_HISTORY: "/api/reward/reward-history/"
}

export const URL_VOUCHER = {
	COUPON_CODE: "/api/voucher/coupon-code/"
}

export const URL_STORE = {
	STORE: "/api/store/store/",
	TICKET: "/api/store/ticket/"
}

export const URL_GATE = {
	TRACKING_COUNT: "/api/gate/TrackingCount",
	TRACKING_DELETE: "/api/gate/TrackingDelete",
	TRACKING_LOG: "/api/gate/TrackingLog",
	GATE_PASS: "/api/gate/RequestCardEvent",
}