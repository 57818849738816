import React from 'react';
import { GET } from '../frameworks/HttpClient';
import { URL_NOTIFICATION } from '../constants/urls';


const MenuBadgeContext = React.createContext({});

export function useMenuBadgeContext() {
  return React.useContext(MenuBadgeContext);
}

// มีประเภท notification เพิ่มให้เพิ่มที่นี่
const defaultBadgeMenu = {
	rejected_receipt_badge_count: 0,
}

export default function MenuBadgeProvider({ children }) {
  // global state สำหรับใช้จัดการ feature ที่ขึ้นกับ menu notification
  // component นี้ ถูกเรียกบน App.js ให้ component ล่าง ๆ เรียก MenuBadgeContext ได้
  // component อื่น ๆ เรียกใช้ state จาก useMenuBadgeContext
  // เช่น const { menuBadges, fetchMenuBadges } = useMenuBadgeContext();
  const [menuBadges, setMenuBadges] = React.useState({ ...defaultBadgeMenu })

  const fetchMenuBadges =  async () => {
    try {
      const response = await GET(URL_NOTIFICATION.MENU_BADGES)
      setMenuBadges({...defaultBadgeMenu, ...response.data})
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  return (
    <MenuBadgeContext.Provider value={{ menuBadges, fetchMenuBadges }}>
      {children}
    </MenuBadgeContext.Provider>
  )
}