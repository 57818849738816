import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal } from "antd";
import Header from '../common/Header';
import { POST } from '../../frameworks/HttpClient';
import { URL_REWARD } from '../../constants/urls';

export default function AddPointModal(props) {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleOk = async () => {
    if (!props.memberID) {
      setErrorMessages("Member not found!");
      return;
    }

    // Prepare data
    let data = await form.validateFields();
    data = { ...data, member: props.memberID }

    setErrorMessages(null);
    setLoading(true);
    let response = null;
    try {
      response = await POST(URL_REWARD.REWARD_HISTORY, data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setLoading(false) }

    if (response)
      props.onAdded()
  };

  React.useEffect(() => {
    if (props.open) {
      setErrorMessages(null);
      form.resetFields();
      console.log(props)
    }
  }, [props.open]);

  return (
    <Modal
      okText={"Confirm"}
      okButtonProps={{loading: loading}}
      open={props.open}
      onOk={handleOk}
      onCancel={props.onClose}>

      <Header title={"Add Point"} />
      {errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginTop: "12px", textAlign: "left"}}
				/>
			}

      <Form 
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}>
          
        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true }]}>
          <Input autoComplete='false' type='number' addonAfter={"Point"}/>
        </Form.Item>

        <Form.Item
          name="note"
          label="Note"
          rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

AddPointModal.defaultProps = {
  memberID: null,
  open: false,
  onAdded: () => {},
  onClose: () => {},
}

AddPointModal.propTypes = {
  memberID: propTypes.string,
  open: propTypes.bool,
  onAdded: propTypes.func,
  onClose: propTypes.func,
}