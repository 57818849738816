import React from 'react';
import logo from '../assets/ahc_logo.png';
import { Col, Image, Input, Row, Tag } from 'antd';
import * as _ from 'lodash';
import { GET } from '../frameworks/HttpClient';
import { URL_GATE } from '../constants/urls';
import { LogoutOutlined } from '@ant-design/icons';


export default function GateCheckOutScreen() {
  const inputRef = React.useRef();
  const [value, setValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (card) => {
    if (!card && isLoading) { return }
    setIsLoading(true);
    try {
      await GET(URL_GATE.GATE_PASS, { Card: card, IP: '010101CD'});
      if (inputRef.current) {
        setValue('');
        inputRef.current.focus();
      }
      } catch (e) {} finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef])

  return (
    <div style={{ marginTop: 50 }}>
      <Image src={logo} width={150} preview={false}/>

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Tag
          bordered={false}
          style={{ marginTop: '1rem', padding: '1rem 2rem 1rem 2rem', fontSize: '6rem', borderRadius: '1rem' }}
          color='red'
        > <LogoutOutlined/> Check Out Gate</Tag>
      </div>

      <Row>
        <Col sm={24} md={{ span: 16, offset: 4 }} xl={{ span: 8, offset: 8 }}>
          <Input
            style={{ fontSize: '2.5rem', textAlign: 'center' }}
            // size='large'
            ref={inputRef}
            value={value}
            onChange={(e) => { setValue(e.target.value) }}
            onPressEnter={(e) => {
              if (e.key == 'Enter') { handleSubmit(_.get(inputRef, 'current.input.value')); }
            }}/>
        </Col>
      </Row>

    </div>
  )
}