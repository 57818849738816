import { Alert, Divider, List, Skeleton } from "antd";
import React from "react";
import propTypes from "prop-types";
import { GET } from "../../frameworks/HttpClient";
import InfiniteScroll from 'react-infinite-scroll-component';

const ITEM_PER_PAGE = 10;  // Default Item display per page

const TSListScroll = React.forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [maxPage, setMaxPage] = React.useState(1);
  const [page, setPage] = React.useState(0);

  React.useImperativeHandle(ref, () => ({
    fetch: () => {
      fetchItems()
    },
    reset: () => {
      setItems([]);
      setMaxPage(1)
      setPage(0);
    }
  }));

  const fetchItems = async () => {
    setErrorMessages(null);
    if ((loading || page > maxPage) && props.url != null) {
      return;  // Prevent multiple loading
    }

    try {
      setLoading(true)
      const response = await GET(`${props.url}`, { ...props.fetchParams, page: page + 1, page_size: ITEM_PER_PAGE });
      setPage(page + 1)
      setMaxPage(response.data.pages)
      setItems([...items, ...response.data.results]);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    setItems([]);
    setMaxPage(1)
    setPage(0);
  }, [])

  return (
    <InfiniteScroll
      dataLength={page * ITEM_PER_PAGE}
      next={fetchItems}
      hasMore={page < maxPage}
      loader={
        <Skeleton
          avatar
          paragraph={{
            rows: 1,
          }}
          active
        />
      }
      endMessage={<Divider plain></Divider>}
    >
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ textAlign: "left" }}
        />
      }

      <List
        itemLayout="horizontal"
        dataSource={items}
        renderItem={props.renderItem} />

    </InfiniteScroll>
  )
})

TSListScroll.defaultProps = {
  url: null,
  fetchParams: {},
  renderItem: (item) => null  // Function to render display component on list loaded
}

TSListScroll.propTypes = {
  url: propTypes.string,
  fetchParams: propTypes.object,
  renderItem: propTypes.func,
}

export default TSListScroll;