import React from 'react';
import { AppstoreOutlined,RightOutlined, HeartOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu , Layout , Divider , Grid, ConfigProvider, Typography  } from 'antd';
import './Sidebar.css'
import { icons } from 'antd/es/image/PreviewGroup';
import { GET } from '../../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../../constants/urls';



export default function Sidebar(props) {
  const { showPackages, setLoading } = props;
  const { Header, Footer, Sider, Content } = Layout;
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [mainPackages, setMainPackages] = React.useState(null)
  const [menuItems , setMenuItems] = React.useState([]);
  const [selectedKeys, setSelectedKeys] = React.useState('')

  const { Text, Paragraph } = Typography;



  const headerStyle = {
    textAlign: 'center',
    color: '#FFFFFF',
    height: 76,
    fontSize: 48,
    backgroundColor: '#BF1E2D',
    fontWeight: 800,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};


  const fetchCatePackages = async () => {
    setLoading(true)
		setErrorMessages(null);
		try {
			const response = await GET(`${URL_PACKAGE.PACKAGE_CATEGORY}?type=package&type=allclub_package&kiosk=true`)
      let totalCate = []
      response.data.results.map((cate, idx) => {
        totalCate.push(
          {
            key: cate.id,
            name: cate.name,
            label: (
              <>
              <div style={{ display: 'flex', alignItems:'center' , height: 67, width : 300 }}>
                <HeartOutlined style={{ fontSize: '22px', display: 'flex', alignItems:'center', width : 40 }} />
                <hr className='vertical'/>
                  <Text style={{  display: 'flex',   fontSize: '22px',   marginLeft: 10,   width: '100%' ,   textAlign: 'left',   fontWeight: 'bold',  whiteSpace: 'nowrap',  overflow: 'hidden',  textOverflow: 'ellipsis'}}>{cate.name}</Text> 
                <RightOutlined style={{ fontSize: '22px' , float:'right', fontWeight: 'bold' }} />
              </div>
              </>
            )
          },
        )
      })
      setMenuItems(totalCate)
      setSelectedKeys(totalCate.length > 0 ? totalCate[0].key : '')
      showPackages(totalCate.length > 0 ? {id : totalCate[0].key, name : totalCate[0].name} : null)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
      setLoading(false)
    }
	}


  const onMenuChange = (key) => {
    setSelectedKeys(key)
    showPackages(key)
  }

  React.useEffect(() => {
			fetchCatePackages();
	}, [])

  React.useEffect(() => {
    
  },[selectedKeys])

  return (
    <Layout style={{ backgroundColor : 'transparent'}}>
      {/* <Header style={headerStyle}>
      <p>PACKAGES</p>
      </Header> */}
      <Content >
        <Menu
          className='custom-menu-item-kiosk'
          style={{ height : 1400, width : '100%', backgroundColor: '#F5F5F5', overflow: 'auto', paddingBottom: 180, paddingTop: 10}}
          selectedKeys={[`${selectedKeys}`]}
          items={menuItems}
          onSelect={(item) => onMenuChange({ id : item.key, name: menuItems.find((el) => el.key === item.key).name})}
        />
      </Content>
      <Footer style={{backgroundColor:'#F5F5F5'}}>

      </Footer>
    </Layout>
  );
};


export const theme = Object.freeze({
  components: {
    menu: {
      // orange color
      activeBarHeight : 1000,
      colorPrimary: "orange"
    },
}});
