import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Modal,  Select, Spin, theme } from "antd";
import Header from '../../common/Header';
import { GET, POST } from '../../../frameworks/HttpClient';
import { URL_MEMBER } from '../../../constants/urls';
import _ from "lodash";


export default function UpdateReferralModal (props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [fetching, setFetching] = React.useState(false);
	const [search, setSearch] = React.useState("");
	const [memberOptions, setMemberOptions] = React.useState([]);

	const {
		token: { colorWarning },
	} = theme.useToken();

	// Update sale
	const updateUser = async () => {
		try {
			let data = await form.validateFields();
			data = {
				...data, 
				new_referral: data.new_referral.value,
				member: props.member
			}
			await POST(URL_MEMBER.REFERRAL_UPDATE_LOG, data);
			props.onUpdated()
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Delay search member
  const debounceMember = React.useCallback(
    _.debounce((_searchVal) => { setSearch(_searchVal) }, 800),
    []
  );

	// Search member
  const fetchMembers = async () => {
    setFetching(true);
    setErrorMessages(null);
		try {
			const response = await GET(`${URL_MEMBER.MEMBER}?search=${search}`)
			setMemberOptions(response.data.results.map((item) => {
				return {
					...item,
					label: `[${item.code}] ${item.full_name}`,
					value: item.id
				}
			}))
		} catch (error) {
			setErrorMessages(error.errorMessages)
		}

		finally {
			setFetching(false);
		}
  }

	React.useEffect(() => {
		if (search)
			fetchMembers();

	}, [search]);

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			form.resetFields();
			setMemberOptions([]);
			if (props.data) {
				form.setFieldsValue({new_referral: props.data})
			}
		}
	}, [props.open]);

  return (
    <Modal
    okText={"Update"}
		okButtonProps={{ style: {background: colorWarning}}}
    open={props.open}
    onOk={updateUser}
    onCancel={props.onClose}>
			<Header title={"Member Referral"}></Header>
			
			<Form 
				form={form}
				style={{marginTop: 16}}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}>
				
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}

				<Form.Item 
					name={"new_referral"}
					label={"Member"}
					rules={[{ required: true }]}>
					<Select 
            placeholder="Name" 
            showSearch
            labelInValue
            filterOption={false}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onSearch={debounceMember}
            options={memberOptions} />
				</Form.Item>
			</Form>
    </Modal>
  )
}
UpdateReferralModal.defaultProps = {
  open: false,
	member: null,
	data: null,
	onUpdated: () => {},
	onClose: () => {},
}

UpdateReferralModal.propTypes = {
  open: propTypes.bool,
	member: propTypes.string,
	data: propTypes.object,
  onUpdated: propTypes.func,
	onClose: propTypes.func,
}