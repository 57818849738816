import React from 'react';
import { PATH } from '../../routes/CustomRoutes';
import { Button, Checkbox, Form, Popover, QRCode, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import * as _ from 'lodash';

const MemberConsentQRCode =  React.forwardRef((props, ref) => {
  const { type, member, consentRequired, showCheckbox, onConsentSigned, transferAmount } = props;

  const CONTRACT_PATH = `${window.location.host}${PATH.CONTRACT_SIGN}/${member}/${type}?transferAmount=${transferAmount}`;

  const WEBSOCKET_URL = {
    'prod': `wss://${window.location.hostname}/ws/member-contract/?id=${member}`,
    'dev': `ws://localhost:8000/ws/member-contract/?id=${member}`,
  };
  const [sendEmail, setSendEmail] = React.useState(true);
  const [connected, setConnected] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { lastMessage } = useWebSocket(WEBSOCKET_URL['prod'], {}, connected);

  const listen = async () => {
    await setConnected(false);
    await setConnected(true);
  }

  React.useImperativeHandle(ref, () => ({
    getSendEmail: () => sendEmail,
    listen: listen,
  }));

  React.useEffect(() => {
    if (consentRequired) {
      setConnected(true);
    }
  }, [consentRequired])

  React.useEffect(() => {
    try {
      const messageData = JSON.parse(_.get(lastMessage, 'data', "{}"));
      if (_.get(messageData, 'status', false) == 'signed') {
        onConsentSigned()
      }
    } catch {}
  }, [lastMessage])

  return (
    <div>
      {contextHolder}
      { showCheckbox && (
        <Form.Item>
          <Checkbox onChange={(e) => setSendEmail(e.target.checked)} checked={sendEmail}>
            Send E-mail
          </Checkbox>
        </Form.Item>
      )}
      { consentRequired && (
        <div style={{ margin:"auto", height: "auto", width: "auto", textAlign:"center", display: "flex", justifyContent: "center" }}>
          <QRCode value={CONTRACT_PATH}/>
          <Popover content='Copy contract link to clipboard'  trigger='hover' placement='bottomRight'>
            <Button style={{ marginLeft: '0.5em' }} onClick={() => {
              navigator.clipboard.writeText(CONTRACT_PATH);
              messageApi.info('Copied to your clipboard.');
            }} shape='circle'><CopyOutlined/></Button>
          </Popover>
        </div>
      )}
    </div>
  )
})

export default MemberConsentQRCode;
