import { GiftOutlined, MailOutlined, PhoneOutlined, CheckCircleFilled } from "@ant-design/icons";
import { Image, message, theme, Card, Spin, QRCode } from "antd";
import { Swiper, AutoCenter } from "antd-mobile";
import { Content } from "antd/es/layout/layout";
import React from "react";
import propTypes from "prop-types";
import Title from "antd/es/typography/Title";
import logo from "../../assets/ahc_logo.png";
import { URL_PACKAGE } from "../../constants/urls";
import { GET } from '../../frameworks/HttpClient';
import * as _ from 'lodash'
import moment from "moment";
import { DATE_FORMAT } from "../../constants/string";
import { formatComma } from "../../frameworks/Util";
import Cookies from "js-cookie";
import { COOKIE_AUTH } from "../../constants/cookies";

export default function MyProfileView(props) {
  const [member, setMember] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [packageItems, setPackageItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { Meta } = Card;
  const {
    token: { colorSuccess, colorWarning, colorPrimary },
  } = theme.useToken();

  const fetchPackages = async () => {
    if (member == null) {
      return;
    }

    try {
      setLoading(true)
      const url = `${URL_PACKAGE.PACKAGES_MEMBER}${member.id}/`
      const response = await GET(url)
      const mPackages =  response.data.membership_packages
                          .filter(pkg => pkg.is_activated)
                          .sort((p1, p2) => p1.package_name - p2.package_name)
      const addPackages = response.data.additional_packages
      packageRender(mPackages, addPackages);
    }catch(error) {
      messageApi.open({
        type:'error',
        content: error.errorMessages
      })
    }finally{
      setLoading(false)
    }
  }

  const packageRender = (mainPkg, additionalPkg) => {
    let myPackages = []
    if (mainPkg != null) {
      if (mainPkg.length > 0) {
        mainPkg.map(pkg => {
          const using_extra = (pkg.extra_duration > 0) && (pkg.extra_duration > pkg.duration_remain)
          myPackages.push(
            <Swiper.Item key={pkg.id}>
              <Card 
                style={{borderColor: colorPrimary}}
                headStyle={{borderColor: colorPrimary}}
                title={(
                  <>
                    {pkg.has_dynamic && `[ALL CLUB - ${pkg.dynamic_package.name} ] `}
                    {pkg.package_name ? pkg.package_name : "Unknown"}
                    {pkg.variant_type && ` (${pkg.variant_type})`}
                    {pkg.is_activated && (<CheckCircleFilled style={{ marginLeft: 4, color: colorSuccess }} />)}
                  </>
                )}>
                <Meta description={`Start: ${(pkg.start_date) ? pkg.start_date : "-"}`}/>
                <Meta description={`Exp: ${(pkg.end_date) ? pkg.end_date : "-"}`} />
                <Meta description={
                  <div>
                    <label>Duration: </label>
                    <label style={{color: using_extra ? "red" : null}}>
                      {`${pkg.duration_remain ? pkg.duration_remain : "-"} (${using_extra ? "Extra " : ""}days)`}
                    </label>
                  </div>
                }/>
                <Meta description={`Usage Remain: ${pkg.usage_remain != null ? pkg.usage_remain : "Unlimited"}`} />
              </Card>
            </Swiper.Item>
            
          )
        })
      } else {
        myPackages.push(
          <Swiper.Item key={'warning'}>
            <Card
              style={{borderColor: colorPrimary}}
              headStyle={{borderColor: colorPrimary, color: colorWarning}}
              title={"Warning"}>
              <Meta
                style={{ fontSize: 15}}
                description={"Please contact our staff to activate your packages"}/>
            </Card>
          </Swiper.Item>
        )
      }
    }

    // PT Package
    additionalPkg.map(apkg => {
      myPackages.push(
        <Swiper.Item key={apkg.id}>
          <Card 
            style={{ borderColor: colorPrimary }}
            headStyle={{ borderColor: colorPrimary }}
            title={(
              (apkg && apkg.package_name) ? `[ ${(apkg.variant_type === "12") ? "1:2" : "1:1"} ] ${apkg.package_name}` : "Unknown"
            )}>
            <Meta description={`Trainer: ${(apkg && apkg.trainer_name) ? apkg.trainer_name : "Unknown"}`}/>
            <Meta description={`Start: ${(apkg.start_date) ? apkg.start_date : "-"}`}/>
            <Meta description={`Exp: ${(apkg.end_date) ? apkg.end_date : "-"}`} />
            <Meta description={`Balance: ${apkg.usage_remain}`} />
          </Card>
        </Swiper.Item>
    )})

    setPackageItems(myPackages);
  }

  React.useEffect(() => {
    if(member != null) {
      fetchPackages()
      setPackageItems([]);
    }
  }, [member])

  React.useEffect(() => {
    setMember(props.member);
    fetchPackages()
  }, [props.member])

  React.useEffect(() => {
    const auth = Cookies.get(COOKIE_AUTH.TOKEN)
    if(auth) {
      // Refresh cookie timeout
      Cookies.set(COOKIE_AUTH.TOKEN, auth, { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
      const u = Cookies.get(COOKIE_AUTH.USER)
      if (u) {
        Cookies.set(COOKIE_AUTH.USER, u, { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
      }
    }
    return () => {}
  }, [])

  return (
    <div>
      {contextHolder}
      <Content
        style={{
          textAlign: 'center',
          color: colorPrimary,
          fontWeight: 'bold'
        }}>
      <Swiper>
        <Swiper.Item>
          <Card bordered={false}>
          <div Align={'center'}>
          <QRCode size={180} value={`${member ? member.id : "-"}`} />
          </div>
          </Card> 
        </Swiper.Item>
        <Swiper.Item>
        <Card bordered={false} >
          <Image
          style={{borderRadius: 8, height: 180, width: "auto"}}
          src={(member && member.profile_image_thumb) ? member.profile_image_thumb : logo}/>
        </Card>
        </Swiper.Item>
      </Swiper>
        <Title level={3} style={{color: colorPrimary}}>
          {(member && member.full_name) ? member.full_name : null}
        </Title>
        <Title level={5} style={{color: colorPrimary, marginTop: 0}}>
          ({(member && member.code) ? member.code : null})
        </Title>
        <Title level={5} style={{ color: colorPrimary, marginTop: 4 }}>
          <GiftOutlined style={{fontSize: 18, marginRight: 8}}/>
          {(member && member.reward_point) ? formatComma(member.reward_point) : "0"} Point
          <div>
						{_.get(props, 'member.expiring_point', 0) > 0 ? (
							<span>(Expiring: {formatComma(_.get(props, 'member.expiring_point', 0))} on {moment(_.get(props, 'member.expiring_point_date', '0000-00-00'), DATE_FORMAT).format('DD/MM/YYYY')})</span>
						) : (
							_.get(props, 'member.reward_point', 0) > 0 && <span>(Expiring on {_.get(props, 'member.next_expiring_point_date', '-')})</span>
						)}
					</div>
        </Title>
        <Title level={5} style={{color: colorPrimary, marginTop: 4}}>
          <PhoneOutlined style={{fontSize: 18, marginRight: 8}}/>
          {(member && member.mobile_phone) ? member.mobile_phone : "-"}
        </Title>
        <Title level={5} style={{color: colorPrimary, marginTop: 4}}>
          <MailOutlined style={{fontSize: 18, marginRight: 8}}/>
          {(member && member.email) ? member.email : "-"}
        </Title>

        {/* Activate Package */}
        <div>
          <Swiper>
            {packageItems}
          </Swiper>
        </div>

        {loading && 
          <AutoCenter>
            <Spin style={{margin: "50px"}}/>
          </AutoCenter>
        }
      </Content>
    </div>
  )
}
MyProfileView.defaultProps = {
  member: null,
  liff: null,

}
MyProfileView.propTypes = {
  member: propTypes.object,
  liff: propTypes.object,
}