import React from 'react';
import propTypes from "prop-types";
import Header from '../common/Header';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/string';
import { Alert, Button, DatePicker, Form, Input, Modal, Select, theme, TimePicker } from 'antd';
import { DELETE, GET, PATCH, POST } from '../../frameworks/HttpClient';
import { URL_TRAINER, URL_USERS } from '../../constants/urls';
import dayjs from 'dayjs';

const CancelRentModal = (props) => {
  const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);

  const prepareData = async () => {
    try {
      // Validate data
      let data = await form.validateFields();
      setErrorMessages(null);
      props.onDelete(data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } 
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
    } else {
      setErrorMessages(null);
    }
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      okText={"Confirm"}
      onOk={prepareData}
      onCancel={props.onClose}>
      <Header title={"Cancel Booking"} />
			{errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginTop: "12px", textAlign: "left"}}/>
			}
      <Form 
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}>
        <Form.Item
          name="cancel_note"
          label="Note"
          rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default function StudioRentModal(props) {
  const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [studioOptions, setStudioOptions] = React.useState([]);
  const [partnerOptions, setPartnerOptions] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(null);
  const [detail, setDetail] = React.useState(null);
  const {
    token: { colorSuccess, colorWarning, colorPrimary },
  } = theme.useToken();

  const renderFooter = () => {
		let footer = [<Button onClick={props.onClose}>Close</Button>];
    if (!detail) {
      footer.push(
        <Button 
          style={{ background: colorSuccess }} 
          type='primary' 
          loading={loading} 
          onClick={handleBooking}>Create</Button>)
    } else {
      if (!props.readOnly) {
        let canEdit = false;
        if (detail) {
          const date = dayjs(detail.date);
          let days = date.diff(dayjs(), 'days');
          canEdit = (days < -1) ? false : true
        }

        footer.push(
          <Button
            style={{ background: colorPrimary}}
            type='primary'
            loading={loading}
            onClick={() => setOpenDelete(true)}>Delete</Button>)
        
        if (canEdit) {
          footer.push(
            <Button 
              style={{ background: colorWarning }} 
              type='primary' 
              loading={loading} 
              onClick={handleBooking}>Update</Button>)
        }
      }
    }
		return footer;
	}

  // Handle date picker
  const disabledDate = (current) => {
    return current && current <= dayjs().startOf('day');
  };
  
  // Create/Update Event: Booking Studio
  const handleBooking = async () => {
    let response = null;
    
    try {
      // Validate data
      let data = await form.validateFields();
      setErrorMessages(null);
      setLoading(true);
      // Prepare data
      data["date"] = data.date.format(DATE_FORMAT);
      data["start_time"] = data.time[0].format(TIME_FORMAT);
      data["end_time"] = data.time[1].format(TIME_FORMAT);
      delete data["time"]

      const rentId = (detail && detail.id) ? detail.id : null;
      if (rentId) {
        // Update event
        response = await PATCH(`${URL_TRAINER.CLASS_RENTAL}${rentId}/`, data)
      } else {
        // Create event
        response = await POST(URL_TRAINER.CLASS_RENTAL, data);
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
    finally { setLoading(false) }

    if (response)
      props.onUpdated();
  }

  // Delete Event: Booking Studio
  const handleDelete = async (data) => {
    const rentId = (detail && detail.id) ? detail.id : null
    if (!rentId) {
      setErrorMessages("Booking ID not found!")
      return;
    }
    
    setErrorMessages(null);
    setLoading(true);
    try {
      // Upadate cancel note
      await PATCH(`${URL_TRAINER.CLASS_RENTAL}${rentId}/`, data);
      // Delete booking
      await DELETE(`${URL_TRAINER.CLASS_RENTAL}${rentId}/`);
      props.onUpdated();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  /** Manage partner options */
  const fetchPartners = async (partner) => {
    // CASE: create with partner
    if (partner) {
      setPartnerOptions([{ ...partner, value: partner.id, label: partner.full_name }])
      form.setFieldValue("trainer", partner.id)
      return;
    }

    // Fetch partner options
    try {
			const response = await GET(`${URL_USERS.PARTNERS}`, { page_size: 999 })
			setPartnerOptions(response.data.results.map(item => ({ ...item, value: item.id, label: item.full_name })));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  }

  // Fetch booking detail
  const fetchData = async (id) => {
    if (!id) {
      setErrorMessages("Booking ID not found!")
      return;
    }

    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_TRAINER.TRAINER_CLASS}${id}/`);
      setDetail(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  // Filter studio for booking
  React.useEffect(() => {
    if (props.studioOptions) {
      const result = props.studioOptions.filter((studio) => studio.allow_booking);
      setStudioOptions(result)
    }
  }, [props.studioOptions])

  React.useEffect(() => {
    if (detail) {
      setPartnerOptions([{ value: detail.trainer, label: detail.trainer_name }])
      form.setFieldsValue({
        ...detail,
        time: detail ? [dayjs(detail.start_time, TIME_FORMAT), dayjs(detail.end_time, TIME_FORMAT)] : null,
        date: detail.date ? dayjs(detail.date, DATE_FORMAT) : null
      });
    }
  }, [detail])

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
      if (props.id) {
        fetchData(props.id)
      } else {
        fetchPartners(props.partner);
      }
    } else {
      setErrorMessages(null);
      setDetail(null);
    }
  }, [props.open])

  return (
    <Modal
      open={props.open}
      onCancel={props.onClose}
      footer={renderFooter()}>
			
      <Header title={"Booking Studio"} />
			{errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{
						marginBottom: "12px", 
						marginTop: "12px", 
						textAlign: "left"}}/>
			)}

      {detail && (
        <Alert 
          style={{ marginTop: 12, textAlign: 'left' }}
          message={`Reserved by: ${detail.reserved_by} on ${dayjs(detail.created).format(DATE_FORMAT)}`} 
          type='info' />
      )}

      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
        <Form.Item 
					name={"studio"} 
					label={"Studio"} 
					rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            options={studioOptions}
            disabled={props.readOnly}/>
        </Form.Item>

        <Form.Item 
					name={"trainer"} 
					label={"Partner"} 
					rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            options={partnerOptions}
            disabled={props.readOnly || props.partner}/>
        </Form.Item>

        <Form.Item 
					name={"time"} 
					label={"Time"}
					rules={[{ required: true }]}>
          <TimePicker.RangePicker 
            format={TIME_FORMAT} 
            style={{ width: '100%' }} 
            inputReadOnly={true}
            minuteStep={5}
            disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 22, 23] })}
            disabled={props.readOnly}/>
        </Form.Item>

        <Form.Item 
					name={"date"} 
					label={"Date"}
					rules={[{ required: true }]}>
          <DatePicker 
            style={{ width: '100%' }}  
            disabledDate={disabledDate} 
            inputReadOnly={true}
            disabled={props.readOnly}/>
        </Form.Item>
        
        <Form.Item
          name={"note"}
          label={"Note"}>
          <Input placeholder='Note...' disabled={props.readOnly} />
        </Form.Item>
      </Form>

      <CancelRentModal 
        open={(openDelete && detail) ? true : false}
        onClose={() => setOpenDelete(false)}
        onDelete={(params) => {
          setOpenDelete(false);
          handleDelete(params)
        }}/>
    </Modal>
  )
}

StudioRentModal.defaultProps = {
  open: false,
  id: null, // for item edit
  studioOptions: [],
  partner: null,
  readOnly: false,
	onUpdated: () => {},
  onClose: () => {},
}

StudioRentModal.propTypes = {
  open: propTypes.bool,
  id: propTypes.string,
  studioOptions: propTypes.array,
  partner: propTypes.object,
  readOnly: propTypes.bool,
	onUpdated: propTypes.func,
  onClose: propTypes.func,
}