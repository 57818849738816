import React from 'react'
import { Alert, theme, Space, Typography, Button, Modal, Spin } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { dateTimeToHumanString } from '../frameworks/Util';
import Header from '../components/common/Header'
import { DELETE } from '../frameworks/HttpClient';
import { URL_GATE } from '../constants/urls';
import TSTable from '../components/common/TSTable';

const { Title } = Typography;

export default function LaundryGateView() {
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [totalTag, setTotalTag] = React.useState(null);
  const logRef = React.useRef(null);
  const { confirm } = Modal;

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const columns = [
    {
      title: 'Alarm At',
      dataIndex: 'created',
      render: (value) => (<label>{value ? dateTimeToHumanString(value) : ""}</label>)
    },
    {
      title: 'Tag',
      dataIndex: 'tag_id'
    },
    {
      title: 'Status',
      dataIndex: 'is_tag_active',
      width: 80,
      render: (value) => (
        <div style={{textAlign: "center"}}>
          {value 
          ? <CheckCircleOutlined style={{fontSize: 18, color: "green"}}/> 
          : <CloseCircleOutlined style={{fontSize: 18, color: "red"}}/>}
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'is_tag_active',
      width: 120,
      render: (value, original) => (
        <div style={{textAlign: "center"}}>
          {value ? <Button onClick={() => confirmDelete(original.tag)}>Inactive</Button> : null}
        </div>
      )
    },
  ]

  // Show confirm modal
  const confirmDelete = (tagId) => {
    confirm({
      title: 'Confirm Inactive',
      content: 'Are you sure you want to inactive this tag?',
      okButtonProps: { style: {background: colorPrimary} },
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteTag(tagId);
      },
      onCancel() {},
    });
  };

  const deleteTag = async (tagId) => {
    if (!tagId) {
      setErrorMessages("Tag not found!");
      return;
    }

    setErrorMessages(null);
    setLoading(true)
    try {
      await DELETE(`${URL_GATE.TRACKING_DELETE}/${tagId}/`)
      // Refresh
      if (logRef && logRef.current) {
        logRef.current.fetch();
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ textAlign: "left" }}>
      <Header title={"Laundry Gate Alarm History"} />
      <Spin spinning={loading} tip='Loading...'>
        {errorMessages &&
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{ marginTop: "12px", textAlign: "left" }} />
        }

        <Space wrap style={{ marginTop: 16 }}>
          {totalTag && 
            <Title level={2}>Total Tag : {totalTag}</Title>
          }
        </Space>
        <TSTable
          ref={logRef}
          columns={columns}
          url={`${URL_GATE.TRACKING_LOG}`}
          rowKey={"id"}
          size={"small"}
          style={{ marginTop: 16 }}
          />
      </Spin>
    </div>
  )
}