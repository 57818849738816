import React from "react";
import * as _ from 'lodash';


const WindowDimensionContext = React.createContext({});

export function useWindowDimensionContext() {
  return React.useContext(WindowDimensionContext);
}

const SIZE_BREAKPOINT = {
  xxl: 1600, // 1600 ~
  xl: 1200, // 1200 - 1599
  lg: 991,  // 992 - 1199
  md: 768, // 768 - 991
  sm: 576, // 576 - 767
  xs: 0, // 0 - 575
}

const getWindowDimensions = () => {
  const {innerWidth: width, innerHeight: height} = window;

  const getSize = () => {
    const found = Object.entries(SIZE_BREAKPOINT).find(([_, breakpoint]) => width >= breakpoint);
    return found ? found[0] : 'xs'
  }

  const moreThan = (size) => {
    return width > _.get(SIZE_BREAKPOINT, size, 0)
  } 

  const lessThan = (size) => {
    return width < _.get(SIZE_BREAKPOINT, size, 0)
  }

  const moreThanOrEqual = (size) => {
    return width >= _.get(SIZE_BREAKPOINT, size, 0)
  }

  const lessThanOrEqual = (size) => {
    return width <= _.get(SIZE_BREAKPOINT, size, 0)
  }

  return {
    width,
    height,
    size: getSize(),
    moreThan,
    lessThan,
    moreThanOrEqual,
    lessThanOrEqual,
  };
}

export default function WindowDimensionProvider({ children }) {
  const [windowDimension, setWindowDimension] = React.useState(getWindowDimensions);

  React.useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <WindowDimensionContext.Provider value={windowDimension}>
      {children}
    </WindowDimensionContext.Provider>
  )
}