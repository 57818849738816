import React from 'react'
import propTypes from "prop-types";
import { Divider } from 'antd';

export default function Header(props) {
	return (
		<>
			<p 
				style={{
					fontSize: props.fontSize, 
					fontWeight: "bold", 
					marginBottom: 8, 
					marginTop: 0, 
					textAlign: "left"}}>
						{props.title}{props.icon}
			</p>
			{!props.withoutUnderline && (
				<Divider style={{margin: 0}}/>
			)}
		</>
	)
}

Header.defaultProps = {
	fontSize: 16,
	title: "Title",
	withoutUnderline: false,
  icon:false
}

Header.propTypes = {
	fontSize: propTypes.number, 
	title: propTypes.oneOfType([
		propTypes.string,
		propTypes.element
	]),
	withoutUnderline: propTypes.bool,
  icon: propTypes.bool,
}