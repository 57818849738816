import React from 'react'
import propTypes from "prop-types";
import "./../../styles.css"

export default function Segment(props) {
	return (
		<div 
			className="section-wrapper" 
			style={props.style}>
				{props.detail}
		</div>
	)
}

Segment.defaultProps = {
	style: {},
	detail: null,
}

Segment.propTypes = {
	style: propTypes.object,
	detail: propTypes.element,
}