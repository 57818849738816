import React from 'react'
import propTypes from "prop-types";
import { Button, Descriptions, Divider, Modal, theme } from "antd";
import Header from '../common/Header';
import { CheckCircleFilled } from '@ant-design/icons';

export default function DetailPackageModal(props) {
	const {
		token: { colorSuccess }
	} = theme.useToken();

	return (
		<Modal
			open={props.open}
			onOk={props.onClose}
			onCancel={props.onClose}
			footer={[<Button type={"primary"} onClick={props.onClose}>{"OK"}</Button>]}>
				
			<Header 
				title={
					<div> 
						{props.title}
						{(props.package && props.package.is_activated) && (
							<CheckCircleFilled style={{marginLeft: 8, color: colorSuccess}}/>
						)}
					</div>
				}/>

			<Descriptions column={1} style={{marginTop: 16}}>
				<Descriptions.Item 
					label={"Package"} 
					style={{fontWeight: "bold"}}>
						{props.package ? props.package.package_name : "-"}
				</Descriptions.Item>

				{/* Handle PT Package */}
				{props.package && props.package.trainer_name && (
					<Descriptions.Item 
						label={"Trainer"} 
						style={{fontWeight: "bold"}}>
							{props.package ? props.package.trainer_name : "-"}
					</Descriptions.Item>
				)}
				
				{props.package && props.package.payment_date && (
					<Descriptions.Item 
						label={"Payment Date"} 
						style={{fontWeight: "bold"}}>
							{(props.package && props.package.payment_date) ? props.package.payment_date : "-"}
					</Descriptions.Item>
				)}
				{/* Handle other package without start/end date */}
				{(!props.isOther || (props.package && props.package.start_date)) && (
					<Descriptions.Item 
						label={"Start Date"}
						style={{fontWeight: "bold"}}>
							{(props.package && props.package.start_date) ? props.package.start_date : "-"}
					</Descriptions.Item>
				)}
				
				<Descriptions.Item 
					label={"End Date"}
					style={{fontWeight: "bold"}}>
						{(props.package && props.package.end_date) 
							? props.package.end_date 
							: (props.package && props.package.expired)
							? props.package.expired 
							: "-"}
				</Descriptions.Item>

				{props.package && 
					<Descriptions.Item
						label={"Duration"}
						style={{ fontWeight: "bold" }}>
						{(props.package.duration_remain) ? props.package.duration_remain : "-"} (days)
					</Descriptions.Item>
				}

				{props.package &&
					<Descriptions.Item
						label={"Usage Remain"}
						style={{ fontWeight: "bold" }}>
						{(props.package.usage_remain) ? props.package.usage_remain : "Unlimited"}
					</Descriptions.Item>
				}

				{(props.package && props.package.max_drop_count > 0) &&
					<Descriptions.Item
						label={"Drop Remain"}
						style={{ fontWeight: "bold" }}>
						{`${props.package.drop_remain} of ${props.package.max_drop_count}`}
					</Descriptions.Item>
				}
			</Descriptions>

			{(props.package && props.package.detail) && (
				<div>
					<Divider style={{margin: 0}} plain>Detail</Divider>
					<div 
						style={{
							marginTop: 8,
							lineHeight: "1.5em",
							whiteSpace: "pre-wrap",}}>
						{(props.package && props.package.detail) ? props.package.detail : "-"}
					</div>
				</div>
			)}
		</Modal>
	)
};

DetailPackageModal.defaultProps = {
	open: false,
	onClose: () => {},
	title: "Package Detail",
	package: null,
	isOther: false,
}

DetailPackageModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	title: propTypes.string,
	package: propTypes.object,
	isOther: propTypes.bool,
}