import React, { useEffect } from 'react';
import { Layout, Image, Typography } from 'antd';
import './MenuMain.css';
import logo from '../../../assets/ahc_logo.png';
import PurcheseSuccess from '../CssScreen/PurcheseSuccess.png';
import { Content } from 'antd/es/layout/layout';
import AutoSlider from './AutoSlider';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../routes/CustomRoutes';
import Lottie from 'lottie-react';
import LottieSuccess from '../CssScreen/lottie/datasc.json';
import LottieSuccessGym from '../CssScreen/lottie/Gymdata.json';

export default function KioskPaymentSuccess(props) {
  const navigate = useNavigate();
  const { Text } = Typography;

  const delayNavigateToIndex = () => {
    setTimeout(() => {
      navigate(PATH.KIOSK_USER);
    }, 4000);
  };

  useEffect(() => {
    delayNavigateToIndex();
  }, []);

  const LottieAnimation = () => {
   return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' , zIndex: -1}}>
        <Lottie animationData={LottieSuccess} style={{ height: 800, width: 800 }} />
      </div>
    );
  };
  const LottieAnimationGymA = () => {
    return (
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' , zIndex: -1}}>
         <Lottie animationData={LottieSuccessGym} style={{ height: 800, width: 800 }} />
       </div>
     );
   };

  return (
    <Layout style={{ width: 1080, height: 1920 }}>
      <Content>
        <div>
          {/* <Image src={PurcheseSuccess} style={{ width: '100%', marginTop: 420 }} preview={false} /> */}
          <LottieAnimation />
          <LottieAnimationGymA /> 

        </div>
        <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: 15 }}>
          <Text style={{ color: '#363636', fontSize: 50, marginTop: 30 }}>Payment Success, Yayy!</Text>
        </div>
      </Content>
      {/* <AutoSlider /> */}
    </Layout>
  );
}
