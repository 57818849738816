import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal, Select, theme } from "antd";
import Header from '../common/Header';
import { URL_REPORT, URL_STORE } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';

export default function CreateTicketModal(props) {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [storeOptions, setStoreOptions] = React.useState([]);

  const {
    token: { colorSuccess },
  } = theme.useToken();

  // Create Appointment
  const generateTicket = async () => {
    let response = null;
    try {
      // Validate data
      let data = await form.validateFields();
      console.log(data);
    
    setErrorMessages(null);
    setLoading(true);
    response = await GET(`${URL_REPORT.STORETICKET_REPORT}pdf/`, data, false, true) // Download as blob
    window.open(URL.createObjectURL(response.data));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
    finally { setLoading(false) }

    if (response)
      props.onCreated();
  }

  const fetchStore = async () => {
    try {
      const response = await GET(URL_STORE.STORE);
      console.log(response);
      setStoreOptions(response.data.results.map((item) => {
        return {
          ...item,
          value: item.id,
          label: `${item.name ? item.name : "Unknown"}`
        }
      }));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
      fetchStore();
      setErrorMessages(null); 
    }
  }, [props.open])

  return (
    <Modal
      open={props.open}
      okButtonProps={{ 
        style: { background: colorSuccess },
        loading: loading,
      }}
      okText={"Create"}
      onOk={generateTicket}
      onCancel={props.onClose}>
      
      <Header title={"Entry Ticket"} />
      {errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{
						marginBottom: "12px", 
						marginTop: "12px", 
						textAlign: "left"}}/>
			)}
      
      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
          <Form.Item 
            name={"store"} 
            label={"Store"} 
            rules={[{ required: true }]}>
            <Select
              placeholder={'Select...'}
              options={storeOptions}
              // showSearch
              optionFilterProp="children"
              filterOption={(input, option) => 
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} />
          </Form.Item>

          <Form.Item 
            name={"duration"} 
            label={"Duration"}
            rules={[{ required: true }]}>
            <Input autoComplete="off" placeholder={0} type={"number"}></Input>
          </Form.Item>
      </Form>
    </Modal>
  )
}

CreateTicketModal.defaultProps = {
  open: false,
  onClose: () => { },
  onCreated: () => { },
}

CreateTicketModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  onCreated: propTypes.func,
}