import React from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

/**
 * Use for keep track of user page stack.
 * Can check if this is last page
 * 
 * function shouldBackButton() {
 *   const historyStack = useHistoryStack();
 *   return ({historyStack.length > 0 && (
 *      <button onClick={() => navigate("/")}>back</button>
 *   )
 * }
 * @returns historyStack
 */
export default function useHistoryStack() {
  const [stack, setStack] = React.useState([]);
  const { pathname } = useLocation();
  const type = useNavigationType();

  React.useEffect(() => {
    if (type === "POP") {
      setStack(prev => prev.slice(0, prev.length - 1));
    } else if (type === "PUSH") {
      setStack(prev => [...prev, pathname]);
    } else {
      setStack(prev => [...prev.slice(0, prev.length - 1), pathname]);
    }
  }, [pathname, type]);

  return stack;
}