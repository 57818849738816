import React from "react";
import propTypes from "prop-types";
import { Card } from "antd";
import "./../../styles.css"
import DetailPackageModal from "./DetailPackageModal";

const ActionLabel = ({label, onClick}) => {
  return (<p style={{fontSize: 12, margin: 0}} onClick={onClick}>{label}</p>)
}

export default function CardOtherPackage(props) {
  const [data, setData] = React.useState(null);
  // Handle Package modal
  const [openMore, setOpenMore] = React.useState(false);
  const { Meta } = Card;

  React.useEffect(() => {
    if (props.data)
      setData(props.data);

  }, [props.data]);

  return (
    <div>
      <Card actions={[
        <ActionLabel label={"More"} onClick={() => setOpenMore(true)}/>
      ]}>
        <div 
          style={{
            marginBottom: 16,
            fontWeight: 'bold',
            lineHeight: "1.5em",
            height: "3em",
            overflow: "hidden"}}>
              {(data && data.title) ? data.title : "Unknown"}
        </div>
        {data && data.label && (
          <Meta description={data.label}/>
        )}
        {data && data.start_date && (
          <Meta description={`Start: ${data.start_date}`} />
        )}
        
        <Meta description={`Exp: ${(data && data.end_date) ? data.end_date : "-"}`} />
      </Card>

      <DetailPackageModal 
        title={data ? data.title : "Other Package"}
        package={data}
        isOther={true}
        open={openMore}
        onClose={() => setOpenMore(false)} />
    </div>
  )
}

CardOtherPackage.defaultProps = {
  data: {},
}

CardOtherPackage.propTypes = {
  data: propTypes.object,
}