import React from "react";
import propTypes from "prop-types";
import { Col, DatePicker, Row, Skeleton,Divider} from "antd";
import dayjs from 'dayjs';
import { URL_TRAINER } from "../../constants/urls";
import { DATE_FORMAT } from "../../constants/string";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../routes/CustomRoutes";
import { List } from 'antd-mobile'
import { useWindowDimensionContext } from "../../hooks/WindowDimensionProvider";
import { GET } from "../../frameworks/HttpClient";
import InfiniteScroll from 'react-infinite-scroll-component';

const ClassListViewMobile = React.forwardRef((props, ref) => {
	const navigate = useNavigate();
	const [date, setDate] = React.useState(dayjs());
	const { lessThanOrEqual } = useWindowDimensionContext();
	const isOnMobile = lessThanOrEqual('sm');
	const [dataTrainer ,setDataTrainer] = React.useState([]);
	const [datalazylord, setDatalazylord] = React.useState([]);

	const fetchDateSelectTrainer = async() => {
    if(date){
      const response = await GET(`${URL_TRAINER.TRAINER_CLASS}?date=${date ? date.format(DATE_FORMAT) : null}&page_size=100`)
      setDataTrainer(response.data.results)
      setDatalazylord(response.data.links.next)
    }
  }

  const fetchDataTrainer = async() => {
    if(datalazylord){
      const response = await GET(datalazylord)
      setDataTrainer([...dataTrainer,...response.data.results])
      setDatalazylord(response.data.links.next)
    }
  }

	const fetchMoreData= () => {
		setTimeout(fetchDataTrainer, 1000);
	};

	const handleDateChange = (date) => {
		if(date){
			setDate(date)
		}
	};
				
	React.useEffect(()=>{
		fetchDateSelectTrainer();
	},[date])

	const handleClick = (data) => {
		const isClass = (data && data.class_type) ? true : false;
		if (isClass && props.partner == null) {
			navigate(`${PATH.CLASS_DETAIL}/${data.id}/`)
			// fetchDataTrainer();
		} else {
			props.onRentDetail(data.id);
		}
	}
	
  return (
  	<div>
			<Row 
        gutter={[16, 16]}
        style={{marginBottom: 16}}>
				<Col xl={6} sm={9} xs={24}>
          <label>{"Date: "}</label>
          <DatePicker 
						style={{width: "76%"}}
						onChange={handleDateChange} 
						inputReadOnly={true}
						allowClear={false}
						value={date}/>
				</Col>
      </Row>

	  	{isOnMobile &&
		 		<InfiniteScroll
					dataLength={dataTrainer.length}
					next={fetchMoreData}
					hasMore={datalazylord}
					loader={<Skeleton paragraph={{ rows: 1 }} active/>}>
					<List style={{textAlign:'left'}}>
						{dataTrainer.map((item)=>
							<List.Item
								onClick={() => handleClick(item)}
								key={item.id}
								extra={<div>{item.trainer_name}</div>}
								description={
									<div>
										<p>Time: {`${item.start_time.substring(0, 5)}-${item.end_time.substring(0, 5)}`}</p>
										<p>Date: {item.date}</p>
									</div>
								}>
								{item.studio_name} - {item.class_type_name ?? item.note}
							</List.Item>
						)}
					</List>
				</InfiniteScroll>
      }
		</div>
  )
});

ClassListViewMobile.defaultProps = {
	partner: null,
	onRentDetail: (id) => {},
}

ClassListViewMobile.propTypes = {
	partner: propTypes.object,
	onRentDetail: propTypes.func,
}

export default React.memo(ClassListViewMobile);