import { Alert, Form, Modal } from 'antd';
import React from 'react';
import Header from '../common/Header';
import * as _ from 'lodash';
import { ellipsisString } from '../../frameworks/Util';
import PTSelect from './PTSelect';
import { SwapOutlined } from '@ant-design/icons';
import { POST } from '../../frameworks/HttpClient';
import { URL_TRAINER } from '../../constants/urls';


export default function TransferAppointmentModal(props) {
  const {
    open,
    target,
    onSuccess,
    onClose,
  } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let data = await form.validateFields();
      await POST(URL_TRAINER.SESSION_APPOINTMENT_TRANSFER, { ...data, appointment: _.get(target, 'id', '') })
      onSuccess();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
      form.resetFields();
      setErrorMessages(null);
    }
  }, [open])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      okText='Transfer'
      onOk={() => handleSubmit()}
      cancelButtonProps={{ loading: isLoading }}
      okButtonProps={{ icon: <SwapOutlined/>,  loading: isLoading, type: 'primary' }}
    >
      <Header title={ellipsisString(`Transfer appointment: ${_.get(target, 'member_package_name', '')}`, 50)}/>
      { errorMessages && 
        <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/> 
      }
      <Form form={form} style={{ marginTop: '1rem' }}>
        <Form.Item name='new_pt' label='Trainer' rules={[{ required: true }]}>
          <PTSelect />
        </Form.Item>
      </Form>
    </Modal>
  )  
}