import React from 'react';
import propTypes from "prop-types";
import { Alert, Image, Row, Col, Tag } from 'antd';
import ActivateMainPackageSegment from '../ActivateMainPackageSegment';
import logo from "./../../../assets/ahc_logo.png"
import ReceptionCheckIn from '../ReceptionCheckIn';
import { GET } from '../../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../../constants/urls';
import { BroadcastChannel } from 'broadcast-channel';
import { CHANNEL, DATETIME_FORMAT } from '../../../constants/string';
import dayjs from 'dayjs';

export default function CheckInView(props) {
	const channel = new BroadcastChannel(CHANNEL.NAME);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [packages, setPackages] = React.useState([]);
	const [activePackage, setActivePackage] = React.useState(null);
	const [packageUsage, setPackageUsage] = React.useState(null);
	const [isCheckedIn,  setCheckedIn] = React.useState(false);
	const [sendCheckInRequest, setSendCheckInRequest] = React.useState(false);
	const checkInRef = React.useRef(null);

	const initialChannel = () => {
		if(channel != null) {
			channel.addEventListener('message', handleMessage)
		}
	}

	const handleMessage = msg => {
		switch (msg != null && msg.key) {
			case CHANNEL.RESPONSE_CHECK_IN:
				props.onMemberCheckIn(msg.data);
				setPackageUsage(msg.data);
				if (checkInRef) {
					checkInRef.current.setManual(true);
				}
				break
			case CHANNEL.RESPONSE_CHECK_OUT:
				console.log(msg)
				break
			default:
				break
		}
	}

	const destroyChannel = () => {
		if (channel != null) {
			channel.postMessage({key: CHANNEL.CANCEL});
			channel.removeEventListener('message', handleMessage)
		}
	}

	// Fetch member package
	const fetchPackages = async () => {
		if (!props.member) {
			setErrorMessages("Member not found!");
			return;
		}

		setErrorMessages(null);
		try {
			const response = await GET(`${URL_PACKAGE.MEMBER_PACKAGE}?member=${props.member.id}&is_available=${true}&ordering=start_date`);
			const target = response.data.results.find(item => item.is_activated);
			if (target) 
				setActivePackage(target);

			setPackages(response.data.results);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	/* Fetch package usage 
		isWorking
		* true => set active package
		* false => fetch member package
	*/
	const fetchData = async () => {
		if (!props.member) {
			setErrorMessages("Member not found!");
			return;
		}

		setErrorMessages(null);
		try {
			const response = await GET(`${URL_PACKAGE.MEMBER_PACKAGE_USAGE_STATUS}${props.member.id}/`);
			const isWorking = response.data.is_working;
			setCheckedIn(isWorking);
			if (isWorking) {
				const usage = response.data.package_usage
				setPackageUsage(usage);
				// Case: Membership package
				if (usage && usage.member_package)
					setActivePackage(usage.member_package);
				// Case: Corporate
				else if (usage && usage.corporate_card) {
					setActivePackage({
						...usage.corporate_card,
						is_corporate: true,
						package_name: usage.corporate_card.card_type_name,
						end_date: null,
						allow_gym_access: true,
						allow_pool_access: true
					})
				} 
				// Case: Free trail
				else if (usage && usage.is_free_trial) {
					setActivePackage({
						...usage,
						package_name: "Free Trail",
						end_date: null,
						allow_gym_access: usage.access_gym,
						allow_pool_access: usage.access_pool
					})
				}
			} else {
				fetchPackages();
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.member) {
			fetchData();
		}
			
	}, [props.member]);

	React.useEffect(() => {
		if (!sendCheckInRequest && activePackage && props.member) {
			channel.postMessage({ 
				key: CHANNEL.REQUEST_CHECK_IN,
				member: props.member,
				package: activePackage, 
				checkedIn: packageUsage,
			})

			setSendCheckInRequest(true);
		}
	}, [activePackage])

	React.useEffect(() => {
		if (props.isView) {
			setSendCheckInRequest(false);
			initialChannel();
		} else {
			destroyChannel();
		}
	}, [props.isView])

	React.useEffect(() => {
		return destroyChannel
	}, [])

  return (
    <div align='center'>
			<Row justify="center" align="bottom" style={{marginBottom: 16}}>
				<Col md={12} sm={24} xs={24} style={{textAlign: "right"}}>
					<Image
						style={{borderRadius: 8, height: 180, width: "auto"}}
						preview={false}
						src={(props.member && props.member.profile_image) ? props.member.profile_image : logo}/>
				</Col>
				<Col md={12} sm={24} xs={24} style={{textAlign: "left"}}>
					<p style={{
						marginLeft: 24,
						fontSize: 18,
						fontWeight: 'bold'
					}}>{props.member ? props.member.full_name : "Unknown"}</p>
					{props.member && props.member.last_checked_in && 
						<Tag color="blue" style={{marginLeft: 24}}>
							{`Checked In: ${(props.member && props.member.last_checked_in) ? dayjs(props.member.last_checked_in).format(DATETIME_FORMAT) : "-"}`}
						</Tag>
					}
				</Col>
			</Row>

			{errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginBottom: "12px", textAlign: "left"}}
				/>
			}

			{!activePackage && (
				<ActivateMainPackageSegment 
					member={props.member} 
					packages={packages}
					onSelected={(data) => setActivePackage(data)}
					onError={(message) => setErrorMessages(message)}/>
			)}

			{activePackage && (
				<ReceptionCheckIn
					isPrimary={true}
					ref={checkInRef}
					package={activePackage}
					isCheckedIn={isCheckedIn}
					checkedIn={packageUsage}
					onCheckedIn={async () => {
						channel.postMessage({key: CHANNEL.CANCEL});
						//await fetchData();
						props.onCheckedIn();
					}}
					onCheckedOut={props.onCheckedOut}
					onUpdated={props.onUpdated}
					onError={(message) => setErrorMessages(message)}
					onManualInputClick={() => {
						//Send message
						channel.postMessage({key: CHANNEL.REQUEST_MANUAL_INPUT});
					}}/>
			)}
    </div>

  )
}

CheckInView.defaultProps = {
	member: null,
	isView: false,
	onSelected: (data) => {},
	onCheckedOut: () => {},
	onCheckedIn: () => {},
	onUpdated: () => {},

	// Return from member
	onMemberCheckIn: (data) => {},
}
CheckInView.propTypes = {
	member: propTypes.object,
	isView: propTypes.bool,
	onSelected: propTypes.func,
	onCheckedOut: propTypes.func,
	onCheckedIn: propTypes.func,
	onUpdated: propTypes.func,

	// Return from member
	onMemberCheckIn: propTypes.func,
}
