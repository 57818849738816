import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Card, Col, Empty, Row, theme } from 'antd';
import InformationCardView from '../InformationCardView';
import { GiftOutlined, SyncOutlined } from '@ant-design/icons';
import AddPointModal from '../AddPointModal';
import ExchangeModal from '../../package/ExchangeModal';
import { GET } from '../../../frameworks/HttpClient';
import { URL_REWARD } from '../../../constants/urls';
import { USER_GROUP } from '../../../constants/string';
import RewardCard from '../RewardCard';
import useUserData from '../../../hooks/useUserData';

export default function RewardView(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [rewardDetail, setRewardDetail] = React.useState([]);
  // Handle Package modal
  const [openAddPointModal, setOpenAddPointModal] = React.useState(false);
  const [openExchangeModal, setOpenExchangeModal] = React.useState(false);

  const {
    token: { colorSuccess, colorInfoTextHover }
  } = theme.useToken();

  const fetchData = async () => {
    if (!props.member) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    const params = { member: props.member.id, page_size: 20 }
    try {
      const response = await GET(URL_REWARD.REWARD_HISTORY, params);
      setRewardDetail(response.data.results);
    }
    catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    fetchData();
    return setErrorMessages(null);
  }, [props.member]);

  const user = useUserData();
  const isAccountant = user.user.groups.includes(USER_GROUP.ACCOUNTANT)
  
  return (

    <div>
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginBottom: "12px", textAlign: "left" }}
        />
      }

      <Row gutter={[16, 16]}>
        <Col md={18} sm={24} xs={24}  >
          <Card style={{ textAlign: 'center', width: '100%' }}>
            <Row justify={'top'} >
              <Col md={8} sm={24} xs={24}  >
                <p style={{ fontWeight: "bold", textAlign: 'left', marginBlockEnd: 0 }}>History</p>
              </Col>
              <Col md={16} sm={24} xs={24} style={{ textAlign: 'right', marginTop: 0 }}>
                {isAccountant ?
                <Button
                  onClick={() => setOpenAddPointModal(true)}
                  type={"primary"}
                  icon={<GiftOutlined />}
                  style={{ background: colorSuccess }}>
                    Add Point
                </Button> : <></>}
                <Button
                  onClick={() => setOpenExchangeModal(true)}
                  type={"primary"}
                  icon={<SyncOutlined />}
                  style={{ background: colorInfoTextHover, marginLeft: 8 }}>
                    Exchange
                </Button>
              </Col>
            </Row>

            <Row
              gutter={[16, 16]}
              style={{ marginTop: 16 }} >
              {rewardDetail.map(item => {
                return (
                  <Col key={item.id} span={24}>
                    <RewardCard data={item} />
                  </Col>
                )
              })}
            </Row>
            {rewardDetail && (rewardDetail.length === 0) && (<Empty />)}
          </Card>
        </Col>

        <Col span={6} style={{ textAlign: 'center' }}>
          <InformationCardView
            member={props.member}
            onUpdated={props.onUpdateSuccess} />
        </Col>
      </Row>

      <AddPointModal
        memberID={props.member ? props.member.id : null}
        open={openAddPointModal}
        onAdded={() => {
          props.onUpdateSuccess();
          setOpenAddPointModal(false);
          fetchData();
        }}
        onClose={() => setOpenAddPointModal(false)}
      />
      <ExchangeModal
        memberId={props.member ? props.member.id : null}
        rewardPoint={props.member ? props.member.reward_point : 0}
        open={openExchangeModal}
        onClose={() => setOpenExchangeModal(false)}
        onSuccess={() => {
          setOpenExchangeModal(false);
          fetchData();
          props.onUpdateSuccess();
        }}
      />
    </div>
  )
}

RewardView.defaultProps = {
  member: null,
  onUpdateSuccess: () => {}
}

RewardView.propTypes = {
  member: propTypes.object,
  onUpdateSuccess: propTypes.func
}