import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Card, Col, Divider, List, Modal, Popconfirm, Popover, Row, Space, theme } from "antd";
import Header from '../common/Header';
import Meta from 'antd/es/card/Meta';
import { 
  CheckCircleOutlined, 
  DeleteOutlined, 
  FileSearchOutlined, 
  HeartOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
  TeamOutlined, 
  CarryOutOutlined,
  SwapOutlined
} from '@ant-design/icons';
import { DELETE } from '../../frameworks/HttpClient';
import { URL_TRAINER } from '../../constants/urls';
import dayjs from 'dayjs';
import * as _ from 'lodash'
import { ellipsisString } from '../../frameworks/Util';
import TransferAppointmentModal from './TransferAppointmentModal';
import useUserData from '../../hooks/useUserData';
import { DATE_FORMAT } from '../../constants/string';


const EventCard = (props) => {
  const [eventEndTime, setEventDate] = React.useState(null)
  const [checkTime, setCheckTime] = React.useState(null)
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [appointmentTransferTarget, setAppointmentTransferTarget] = React.useState(null);
  const { user } = useUserData();
  const nowDate = dayjs();
  const {
    token: { colorSuccess, colorPrimary, colorError, colorInfoTextHover, colorFill, colorWarning },
  } = theme.useToken();

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    const result = await props.onDelete(id);
    setDeleteLoading(false);

    if (result)
      props.onUpdated();
  }
  
  React.useEffect(() => {
    const end_time = dayjs(`${props.data.date},${props.data.end_time}`);
    const start_time = dayjs(`${props.data.date},${props.data.start_time}`);
    let checkHours = start_time.diff(dayjs(), 'hour');
    setCheckTime((checkHours >= 1) ? true : false);
    setEventDate(end_time)
  }, [props.data])

  return (
    <Card style={{ marginTop: 16 }}>
      <Row gutter={[16, 16]} align="middle">
        <Col md={18} sm={24} xs={24}>
          <Meta
            avatar={<Space>
              <Col style={{ textAlign: 'center', width: 74 }}>
                {(props.data && props.data.type === "pt_session") 
                  ? <HeartOutlined style={{ fontSize: 30, color: colorPrimary }} /> 
                  : props.data.class_type_name 
                  ? <TeamOutlined style={{ fontSize: 30, color: colorPrimary }} />
                  : <CarryOutOutlined style={{ fontSize: 30, color: colorPrimary }} />
                }
                
                <div style={{color: colorPrimary, fontWeight: "bold"}}>
                  {(props.data && props.data.type === "pt_session") ? "Training" : props.data.class_type_name ? "Class" : "Booking"}
                </div>
              </Col>
              <Divider type="vertical" style={{ height: 60, }} />
            </Space>
            }

            title={(props.data && props.data.trainer && props.data.type === "pt_class") 
              ? props.data.class_type_name  
              : (props.data.type === "pt_session") 
              ? props.data.event_name  
              : props.data.note }
            description={props.data 
              ? 
              <>
                <div>Time: {props.data.start_time.substring(0, 5)} - {props.data.end_time.substring(0, 5)}</div>
                {props.data && props.data.trainer && (
                  <div>Trainer : {props.data.trainer_name}</div>
                )}
                <div>
                  { (props.data && props.data.type === "pt_session") ? 
                    <>
                      <div>
                        <Popover content={props.data.note ? props.data.note : "-"} trigger='hover'>
                          Note : {props.data.note ? ellipsisString(props.data.note, 15) : "-"}
                        </Popover>
                      </div>
                      <div>
                        <Popover content={props.data.member_full_name ? props.data.member_full_name : "-"} trigger='hover'>
                          Member : {props.data.member_full_name ? ellipsisString(props.data.member_full_name, 15) : "-"}
                        </Popover>
                      </div>
                      <div>Usage remain : {props.data.usage_remain ? props.data.usage_remain : "-"}</div>
                    </> :
                    `Studio : ${props.data.studio_name ? props.data.studio_name : props.data.studio }` 
                  }
                </div>
                <div>
                  {!((props.data.type === "pt_class") && !props.data.class_type_name) ? ` Status :
                  ${(props.data && props.data.is_checked === true) ? "Approve" : 
                  (props.data.type ==="pt_session" && props.data.is_checked === false) ? "Reject" :
                  (props.data.type ==="pt_session" && !props.data.is_checked && nowDate < eventEndTime ) ? "Waiting" : 
                  (props.data.type ==="pt_session" && !props.data.is_checked && nowDate >= eventEndTime ) ? "Missed" : 
                  (props.data.type ==="pt_class" && props.data.is_checked === false && nowDate <= eventEndTime) ? "Waiting" : "Missed" }`: ""}
                </div>
                {/* Display on booking */}
                {props.data && (props.data.type === "pt_class") && !props.data.class_type_name && (
                  <div>Reserved by: {props.data.reserved_by}</div>
                )}
                {props.data && (props.data.type === "pt_class") && !props.data.class_type_name && (
                  <div>Reserved date: {dayjs(props.data.created).format(DATE_FORMAT)}</div>
                )}
              </>
              : ""
            }/>
        </Col>
        <Col md={6} sm={24} xs={24} style={{ textAlign: 'right' }}>
          {props.data && props.data.type === "pt_class" && (
            <Button
              style={{background: colorInfoTextHover, margin: 4}}
              type={"primary"}
              icon={<FileSearchOutlined />}
              onClick={() => props.gotoDetail(props.data.id, props.data.class_type_name ? true : false)}/>
          )}
          {/* Display Button for handle event (delete) */}
          {props.data && props.data.type === "pt_session" && (props.data.is_checked === null) && (checkTime === true) && (
            <Popconfirm
              title={"Do you want to delete appointment?"}
              placement={"topRight"}
              onConfirm={() => handleDelete(props.data.id)}
              onCancel={() => null}>
              <Button
                style={{background: colorError, margin: 4}}
                type={"primary"}
                icon={<DeleteOutlined />}
                loading={deleteLoading}
                onClick={() => {}}/>
            </Popconfirm>
          )}
          {/* Display Button for transfer appointment to other trainer */}
          {/* must not in need check time and must be owner of appointment to be abel to transfer  */}
          { props.data && props.data.type === "pt_session" && (props.data.is_checked === null) && (checkTime === true) && (user.id == props.data.trainer) && (
              <Button
                style={{ background: colorWarning }}
                type="primary"
                icon={<SwapOutlined/>}
                onClick={() => { setAppointmentTransferTarget(props.data) }}/>
          )}
          {/* Display Button for handle event (check/uncheck) */}
          {props.data && props.data.type === "pt_session" && (props.data.is_checked === null) && (checkTime === false) && (
          <Popconfirm
              title={"To change status of Appointment."}
              placement={"topRight"}
              okText={"Approve"}
              okButtonProps={{
                type: "primary",
                style: {background: colorSuccess},
                icon: <CheckCircleOutlined />
              }}
              cancelText={"Reject"}
              cancelButtonProps={{
                type: "primary",
                style: {background: colorError},
                icon: <MinusCircleOutlined />
              }}
              onConfirm={() => props.onApprove(props.data.id)}
              onCancel={() => props.onReject(props.data.id)}>
            
              <Button
                style={{background: colorFill, margin: 4}}
                type={"primary"}
                icon={<QuestionCircleOutlined />}
                onClick={() => {}}/>
            </Popconfirm>
          )}
          {/* Display Button when checked */}
          {props.data && props.data.type === "pt_session" && (props.data.is_checked) && (
            <Button
              style={{background: colorSuccess, margin: 4}}
              type={"primary"}
              icon={<CheckCircleOutlined />}
              onClick={() => {}}/>
          )}
          {/* Display Button when un-checked */}
          {props.data && props.data.type === "pt_session" && (props.data.is_checked === false) && (
            <Button
              style={{background: colorError, margin: 4}}
              type={"primary"}
              icon={<MinusCircleOutlined />}
              onClick={() => {}}/>
          )}
        </Col>
      </Row>

      <TransferAppointmentModal
        open={appointmentTransferTarget != null}
        onSuccess={() => {
          setAppointmentTransferTarget(null)
          props.onUpdated();
        }}
        onClose={() => setAppointmentTransferTarget(null)}
        target={appointmentTransferTarget}/>

    </Card>
  )
}

export default function EventPTSessionModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);

  // Delete PT Session
  const handleDelete = async (id) => {
    if (!id) {
      setErrorMessages("Session not found!");
      return false;
    }

    setErrorMessages(null);
    try {
      await DELETE(`${URL_TRAINER.SESSION_APPOINTMENT}${id}/`);
      return true;
    } catch (error) {
      setErrorMessages(error.errorMessages);
      return false;
    }
  }

  React.useEffect(() => {
    if (props.open) {
      setErrorMessages(null);
    }
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}>
      <Header title={props.data ? props.data.date : "Events"} />

      {errorMessages && 
        <Alert
          style={{marginTop: "12px", textAlign: "left"}}
          showIcon
          message={errorMessages}
          type="error"/>
      }

      <List
        dataSource={props.data ? props.data.events : []}
        renderItem={(item) => (
          <EventCard 
            key={item.id} 
            data={item}
            onDelete={handleDelete}
            gotoDetail={props.gotoDetail}
            onUpdated={props.onUpdated}
            onApprove={props.onEventApprove}
            onReject={props.onEventReject}/>
        )}/>
    </Modal>
  )
}

EventPTSessionModal.defaultProps = {
  data: null,
  open: false,
  onClose: () => {},
  onUpdated: () => {},
  onEventApprove: (id) => {},
  onEventReject: (id) => {},
  gotoDetail: (id) => {},
}

EventPTSessionModal.propTypes = {
  data: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
  onUpdated: propTypes.func,
  onEventApprove: propTypes.func,
  onEventReject: propTypes.func,
  gotoDetail: propTypes.func,
}