import React from 'react';
import propTypes from "prop-types";
import { Card, Col, Input, Row, Spin, theme } from 'antd';
import { CheckCircleTwoTone, CheckSquareTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import ItemCheckIn from './ItemCheckIn';
import "../../styles.css";

const ReceptionCheckIn = React.forwardRef((props, ref) => {
  const [isManual, setManual] = React.useState(true);
	const [checkedIn, setCheckedIn] = React.useState(null);

	const {
		token: { colorSuccessText, colorWarningText, colorTextSecondary },
	} = theme.useToken();

	React.useImperativeHandle(ref, () => ({
    setManual: (value) => setManual(value),
  }));

	React.useEffect(() => {
		setCheckedIn(props.checkedIn);
	}, [props.checkedIn]);

  return (
    <div align='center'>
			<Row justify={"center"}>
				<Col md={12} sm={24} xs={24}>
					<Input
						autoComplete="off"
						size={"large"}
						className={"custom-input-font-weight"}
						style={{fontWeight: "bold"}}
						addonBefore={
							<CheckSquareTwoTone 
								twoToneColor={colorSuccessText} 
								style={{fontSize: 16}}/>
						}
						addonAfter={props.package && props.package.end_date
							?
							<div style={{textAlign: "left"}}>
								<CheckCircleTwoTone
									twoToneColor={colorSuccessText}/>
								<label 
									style={{marginLeft: 8, color: colorTextSecondary}}>
										{props.package ? `${props.package.start_date} Start` : "Unknown"}
								</label>
								<br></br>
								
								<InfoCircleTwoTone style={{marginTop: 4}}
									twoToneColor={colorWarningText}/>
								<label 
									style={{marginLeft: 8, color: colorWarningText}}>
										{props.package ? `${props.package.end_date} Exp.` : "Unknown"}
								</label>
							</div>
							: null
						} 
						value={`Package: ${
							(props.package && props.package.package_name) ? 
							`${props.package.package_name} ${props.package.variant_type ? "- " + props.package.variant_type : ""}` : 
							"Unknown"
						}`}
					/>
				</Col>
			</Row>
			
      {!isManual && (
					<Row justify={"center"} style={{marginTop: 16}}>
						<Col md={12} sm={24} xs={24}>
							<Card 
								className={"custom-card-actions-blue custom-card-actions-text"}
								actions={[
									<p 
										style={{margin: 0}} 
										onClick={() => {
											setManual(true);
											props.onManualInputClick();
										}}>
											{"MANUAL INPUT"}
									</p>
								]}>
								<Spin tip={"Waiting"} size={"large"} style={{margin: 42}}>
								</Spin>
							</Card>
						</Col>
					</Row>
			)}
     
    {isManual && (
			<ItemCheckIn 
				package={props.package}
				isCheckedIn={props.isCheckedIn}
				checkedIn={checkedIn}
				onCheckedIn={props.onCheckedIn}
				onCheckedOut={props.onCheckedOut}
				onUpdated={props.onUpdated}
				onError={props.onError}/>
		)} 

    </div>
  )
}); 

ReceptionCheckIn.defaultProps = {
	package: null,
	isCheckedIn: false,
	checkedIn: null,
	onCheckedIn: () => {},
	onCheckedOut: () => {},
	onUpdated: () => {},
	onError: (message) => {},
	onManualInputClick: () => {},
}
ReceptionCheckIn.propTypes = {
	package: propTypes.object,
	isCheckedIn: propTypes.bool,
	checkedIn: propTypes.object,
	onCheckedIn: propTypes.func,
	onCheckedOut: propTypes.func,
	onUpdated: propTypes.func,
	onError: propTypes.func,
	onManualInputClick: propTypes.func // event manual check-in click
}

export default ReceptionCheckIn;