import React from "react";
import propTypes from "prop-types";
import { Modal, Button } from "antd";
import "./../../styles.css"
import Header from "../common/Header";
import TSTable from "../common/TSTable";
import { timeToString } from "../../frameworks/Util";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../routes/CustomRoutes";

export default function ModalSummary(props) {
	const tableRef = React.useRef(null);
	const navigate = useNavigate();

	const gotoMemberDetail = (original) => {
		navigate(`${PATH.MEMBER_DETAIL}${PATH.MEMBER_INFO}/${original.member_id}`)
	}

	const columns = [
		{
			title: 'Member ID',
			dataIndex: 'member_code',
			align: 'center',
			searcher: true,
			render: (value, original) => (
				<Button type='link' onClick={() => gotoMemberDetail(original)}>{value}</Button>
			)
		},
		{
			title: 'Name',
			dataIndex: 'member_full_name',
			width: "40%",
			align: 'left',
			searcher: true,
		},
		{
			title: 'Mobile Phone',
			dataIndex: 'member_mobile_phone',
			align: 'center',
			searcher: true,
		},
		{
			title: 'Time - Check In',
			dataIndex: 'check_in',
			align: 'center',
			render: (value) => (<label>{value ? timeToString(value) : ""}</label>)
		},
		{
			title: 'Time - Check Out',
			dataIndex: 'check_out',
			align: 'center',
			render: (value) => (<label>{value ? timeToString(value) : ""}</label>)
		}
	]

	React.useEffect(() => {
		if (props.open && tableRef) {
			tableRef.current.fetch();
		}
	}, [props.open])


	return (
		<>
			<Modal open={props.open} onOk={props.onOk} onCancel={props.onCancel} width={'80%'} >
				<Header title={props.title}></Header>
				<TSTable 
					ref={tableRef}
					initialFetch={false}
					columns={props.hideCheckOut
						? columns.filter(col => col.dataIndex !== 'check_out')
						: columns}
					url={props.url}
					rowKey={"id"}
					size={"small"}
					style={{ marginTop: 16 }}
					onRow={(record, rowIndex) => ({
						onClick: _ => window.open(`${PATH.MEMBER_DETAIL}/info/${record.member_id}/`, "_blank")
					})}/>
			</Modal>
		</>
	)
}

ModalSummary.defaultProps = {
	title: '',
	hideCheckOut: false,
	open: false,
	onOk: () => { },
	onCancel: () => { },
	content: "",
	url: "",
}

ModalSummary.propTypes = {
	title: propTypes.string,
	hideCheckOut: propTypes.bool,
	open: propTypes.bool,
	onOk: propTypes.func,
	onCancel: propTypes.func,
	content: propTypes.string,
	url: propTypes.string,
}