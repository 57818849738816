import React from 'react'
import axios from "axios";
import propTypes from "prop-types";
import { Alert, Button, Col,  Modal, Row, Spin, theme } from "antd";
import Header from '../common/Header';
import { GET } from '../../frameworks/HttpClient';
import { URL_COMMON } from '../../constants/urls';
import Segment from '../common/Segment';

const GateCard = (props) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <Segment 
      style={{background: colorPrimary}}
      detail={
        <div style={{textAlign: "center"}}>
            <h3 style={{color: "#fff"}}>{props.name}</h3>
            <Button onClick={() => props.onOpen(props.item)}>{"Open"}</Button>
        </div>
      }/>
  )
}

export default function GateModal(props) {
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [doors, setDoors] = React.useState([]);

  // Gate List
  const fetchGate = async () => {
    try {
      const response = await GET(URL_COMMON.GATE_LIST);
      setDoors(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Open Gate
  const handleOpenGate = async (gate) => {
    if (!gate || !gate.open_url) {
      setErrorMessages("Gate not found!")
      return;
    }

    setErrorMessages(null)
    setLoading(true)
    try {
      await GET(gate.open_url)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally { setLoading(false) }
  }

  React.useEffect(() => {
    if (props.open) {
      setErrorMessages(null);
      setLoading(false);
      fetchGate();
    }
  }, [props.open])

  return (
    <Modal
      open={props.open}
      onCancel={props.onClose}
      footer={[
        <Button key="cancel" onClick={props.onClose}>{"Cancel"}</Button>
      ]}>
      
      <Header title={"Gate"} />
      {errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{
						marginBottom: "12px", 
						marginTop: "12px", 
						textAlign: "left"}}/>
			)}
      
      {doors && (doors.length > 0) && (
        <Spin spinning={loading} tip='Loading...'>
          <div style={{marginTop: 16, marginBottom: 16}}>
            <Row gutter={[16, 8]} justify={"center"}>
              {doors.map((item) => {
                return (
                  <Col md={8} sm={24} xs={24} key={item.id}>
                    <GateCard 
                      name={`${item.direction.toUpperCase()} : ${item.id}`} 
                      item={item}
                      onOpen={handleOpenGate}/>
                  </Col>
                )
              })}
            </Row>
          </div>
        </Spin>
      )}
    </Modal>
  )
}

GateModal.defaultProps = {
  open: false,
  onClose: () => { },
}

GateModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
}