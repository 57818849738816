import React from 'react';
import propTypes from "prop-types";
import { Button, Card, Col, Image, Input, message, Row, Space, theme } from 'antd';
import { CheckSquareOutlined, BorderOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import towel from "../../assets/towel.jpg";
import napkin from "../../assets/napkin.jpg";
import key from "../../assets/key.jpg";
import SegmentHeader from '../common/SegmentHeader';
import Meta from 'antd/es/card/Meta';
import { PATCH, POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../constants/urls';
import { set } from 'lodash';
import * as _ from 'lodash';


const ItemCard = React.forwardRef(({data, isCheckedIn, isMemberScreen}, ref) => {
	const [amount, setAmount] = React.useState(0);
	const [checked, setChecked] = React.useState(false);
	const {
    token: { colorSuccessText, colorErrorText },
  } = theme.useToken();

	React.useImperativeHandle(ref, () => ({ 
		getData: async () => {
			const target = {}
			if (data.isInput) {
				target[data.value] = amount;
			} else {
				target[data.value] = checked;
			}
			return target;
		},
		getKey: () => data.key,
		setData: (value) => {
			if (data.isInput) {
				setAmount(value);
			} else {
				setChecked(value);
			}
		}
	}));

	React.useEffect(() => {
		console.log(checked)
	}, [checked])

	React.useEffect(() => {
		if (data) {
			setAmount(data.amount);
			setChecked(data.checked);
		}
	}, [data])

	const minusAmount = () => {
		const target = (amount > 0) ? (amount - 1) : 0;
		setAmount(target);
	}

	const addAmount = () => setAmount(amount + 1);

	const handleChecked = () => setChecked(!checked);

	const minusIcon = {
		fontSize: isMemberScreen ? '36px' : '28px',
		justifyContent: 'flex-start', 
		marginLeft: '12px', 
		marginRight: '12px', 
	}

	const plusIcon = {
		fontSize: isMemberScreen ? '36px' : '28px',
		justifyContent: 'flex-start', 
		marginLeft: '12px', 
		marginRight: '12px', 
	}

	const inputStyle = {
		fontSize: isMemberScreen ? '26px' : '18px',
		width: '60%',
		pointerEvents: 'none',
		textAlign: 'center',
		margin: '0 5px',
	}

	const checkSquare = {
		fontSize: isMemberScreen ? 48 : 40,
	}

	const renderActions = (item) => {
		const actions = [];
		if (item && item.isInput) {
			actions.push(
				<Input.Group compact >
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{(!isCheckedIn || !isMemberScreen) && <MinusCircleTwoTone
							twoToneColor={colorErrorText}
							style={ minusIcon }
							onClick={minusAmount}/>
						}
						<Input
							autoComplete="off"
							value={amount}
							style={ inputStyle }/>
						{(!isCheckedIn || !isMemberScreen)  && <PlusCircleTwoTone
							twoToneColor={colorSuccessText}
							style={ plusIcon }
							onClick={addAmount}/>
						}
					</div>
				</Input.Group>
			);
		} else {
			actions.push(
				<div>	
					{!checked ? 
						<BorderOutlined 
							style={{ ...checkSquare, color: null }}
							onClick={handleChecked} /> : 
						<CheckSquareOutlined
							style={{ ...checkSquare, color: colorSuccessText }}
							onClick={handleChecked} />
					}
				</div>
			)
		}
		return actions;
	}

	return (
		<Card
			className={"custom-card-body"}
      		onClick={() => { }}
			actions={renderActions(data)}
			cover={
				<Image
					src={data.image}
					preview={false}
					width={"auto"}
					height={isMemberScreen ? 220 : 100} 
					style={{textAlign: 'center'}}/>
			}>
			<Meta title={data ? data.label : "Unknown"} style={{ fontSize: "72px" }}/>
		</Card>
	)
});

const gymItems = [
	{key: "gym_large_towel", value: "gym_large_towel", label: "Towel", image: towel, isInput: true, amount: 0, checked: false},
	{key: "gym_small_towel", value: "gym_small_towel", label: "Napkin", image: napkin, isInput: true, amount: 0, checked: false},
	{key: "gym_locker_key", value: "gym_locker_key", label: "Locker Key", image: key, isInput: false, amount: 0, checked: false},
];

const poolItems = [
	{key: "pool_large_towel", value: "pool_large_towel", label: "Towel", image: towel, isInput: true, amount: 0, checked: false},
	{key: "pool_locker_key", value: "pool_locker_key", label: "Locker Key", image: key, isInput: false, amount: 0, checked: false},
];

const headerStyles = {
	fontSize: 24
}

const buttonStyles = {
	fontSize: 24,
	display: 'flex',
	alignItems: 'center',
	padding: '24px 24px',
}  

export default function ItemCheckIn(props) {
	const [updateLoading, setUpdateLoading] = React.useState(false);
	const [checkInLoading, setCheckInLoading] = React.useState(false);
	const [checkOutLoading, setCheckOutLoading] = React.useState(false);
	const [confirmLoading, setConfirmLoading] = React.useState(false);
	const [gyms, setGyms] = React.useState(gymItems);
	const [pools, setPools] = React.useState(poolItems);
	const gymRef = React.useRef([]);
	const poolRef = React.useRef([]);
	const checkOutButtonRef= React.useRef();
	const checkInButtonRef= React.useRef();

  const {
    token: { colorSuccessText, colorWarning },
  } = theme.useToken();

	const prepareData = async () => {
		let data = {};
		// Gym
		if (props.package.allow_gym_access) {
			for (let index in gymItems) {
				const itemData = await gymRef.current[index].getData();
				data = {...data, ...itemData}
			}
		}
		// Pool
		if (props.package.allow_pool_access) {
			for (let index in poolItems) {
				const itemData = await poolRef.current[index].getData();
				data = {...data, ...itemData}
			}
		}

		return data;
	}

	// Check-In button
	const handleCheckIn = async () => {
		if (!props.package) {
			props.onError("Package not found!");
			return;
		}

		props.onError(null);
		// Goto Check-In
		let data = await prepareData();
		// Case: Corporate
		if (props.package.is_corporate) 
			data["corporate_card"] = props.package.id;
		// Case: Free trail
		else if (props.package.is_free_trial) {
			data["trial_member"] = props.package.id;
			data["send_email"] = _.get(props, 'package.send_email', false)
		}
		// Case: Membership package
		else 
			data["member_package"] = props.package.id;
		
		try {
			await POST(URL_PACKAGE.PACKAGE_USAGE, data);
			message.success("Check In successfully");
			props.onCheckedIn();
		} catch (error) {
			props.onError(error.errorMessages);
		}
	}

	// Update button
	const handleUpdate = async () => {
		if (!props.checkedIn) {
			props.onError("Package not found!");
			return;
		}

		props.onError(null);
		setUpdateLoading(true);
		// Goto Update
		let data = await prepareData(props.package)
		try {
			await PATCH(`${URL_PACKAGE.PACKAGE_USAGE}${props.checkedIn.id}/`, data);
			message.success("Update successfully");
			props.onUpdated()
		} catch (error) {
			props.onError(error.errorMessages);
		}

		finally {
			setUpdateLoading(false);
		}
	}

	// Check-Out button
	const handleCheckOut = async () => {
		if (!props.checkedIn) {
			props.onError("Package not found!");
			return;
		}

		props.onError(null);
		setCheckOutLoading(true);
		try {
			await POST(`${URL_PACKAGE.PACKAGE_USAGE}${props.checkedIn.id}/check-out/`);
			props.onCheckedOut();
		} catch (error) {
			props.onError(error.errorMessages);
		}

		finally {
			setCheckOutLoading(false);
		}
	}

	// Confirm button
	const handleMemberSelected = async () => {
		// Goto Check-In
		setConfirmLoading(true);
		let data = await prepareData()
		data = {
			...data,
			access_gym: props.package ? props.package.allow_gym_access : false,
			access_pool: props.package ? props.package.allow_pool_access : false,
		}
		props.onMemberCheckIn(data);
		setConfirmLoading(false);
	}
	
	const updateCheckInItems = async (data) => {
		if (data) {
			// Gym
			if (data.access_gym && gymRef) {
				for (let index in gymItems) {
					const target = gymRef.current[index];
					const key = await target.getKey();
					await target.setData(data[key]);
				}
			}

			// Pool
			if (data.access_pool && poolRef) {
				for (let index in gymItems) {
					const target = poolRef.current[index];
					const key = await target.getKey();
					await target.setData(data[key]);
				}
			}
		}
	}

	React.useEffect(() => {
		if (props.checkedIn) {
			// Update check-in items
			updateCheckInItems(props.checkedIn);

			if (!props.isMemberScreen && checkOutButtonRef.current) {
				// focus on check out button
				checkOutButtonRef.current.focus();
			}
		} else if (!props.isMemberScreen && checkInButtonRef.current) {
			// focus on check in button
			checkInButtonRef.current.focus();
		}  
	}, [props.checkedIn]);

  return (
    <div style={{marginTop: 16}}>
			<Row gutter={[24, 16]} justify={"center"}>
				{props.package && props.package.allow_pool_access && (
					<Col md={8} sm={24} xs={24}>
						<SegmentHeader
							headStyle={(props.isMemberScreen ? headerStyles : null)} 
							header={"Pool Access"}
							content={
								<Row justify={"center"} gutter={[16, 16]}>
									{pools.map((item, index) => {
										return (
											<Col key={item.key} md={12} sm={24} xs={24}>
												<ItemCard 
													ref={el => (poolRef.current[index] = el)}
													data={item}
													isCheckedIn={props.isCheckedIn}
													isMemberScreen={props.isMemberScreen}/>
											</Col>
										)
									})}
								</Row>
							}/>
					</Col>
				)}

				{props.package && props.package.allow_gym_access && (
					<Col md={12} sm={24} xs={24}>
						<SegmentHeader 
							headStyle={(props.isMemberScreen ? headerStyles : null)} 
							header={"Gym Access"}
							content={
								<Row justify={"center"} gutter={[16, 16]}>
									{gyms.map((item, index) => {
										return (
											<Col key={item.key} md={8} sm={24} xs={24}>
												<ItemCard 
													ref={el => (gymRef.current[index] = el)}
													data={item}
													isCheckedIn={props.isCheckedIn}
													isMemberScreen={props.isMemberScreen}/>
											</Col>
										)
									})}
								</Row>
							}/>
					</Col>
				)}
			</Row>
     
			<Space wrap style={{marginTop: 16}}>
				{/* Update Button */}
				{props.isCheckedIn && !props.isMemberScreen && (
					<Button 
						loading={updateLoading}
						type={"primary"}
						style={{background: colorWarning, ...props.isMemberScreen ? buttonStyles : null}}
						onClick={handleUpdate}>
							Update
					</Button>
				)}
				{/* Check In Button */}
				{!props.isCheckedIn && !props.isMemberScreen && (
					<Button 
						ref={checkInButtonRef}
						loading={checkInLoading}
						type={"primary"}
						style={{background: colorSuccessText, ...props.isMemberScreen ? buttonStyles : null}}
						onClick={handleCheckIn}>
							Check In
					</Button>
				)}
				{/* Check Out Button */}
				{props.isCheckedIn && !props.isMemberScreen && (
					<Button
						ref={checkOutButtonRef}
						loading={checkOutLoading}
						type={"primary"}
						style={{background: colorSuccessText, ...props.isMemberScreen ? buttonStyles : null}}
						onClick={handleCheckOut}
						onKeyDown={(e) => {
							if (e === 'Enter') {
								console.log('checkout')
							}
						}}>
							Check Out
					</Button>
				)}
				{/* OK Button for member screen check-in */}
				{props.isMemberScreen && !props.isCheckedIn && (
					<Button 
						loading={confirmLoading}
						type={"primary"}
						style={{background: colorSuccessText, ...props.isMemberScreen ? buttonStyles : null}}
						onClick={handleMemberSelected}>
							Confirm
					</Button>
				)}
			</Space>
    </div>
  )
}

ItemCheckIn.defaultProps = {
	package: null,
	isCheckedIn: false,
	checkedIn: null,
	onCheckedIn: () => {},
	onCheckedOut: () => {},
	onUpdated: () => {},
	onError: (message) => {},

	// Member Page
	isMemberScreen: false,
	onMemberCheckIn: (data) => {},
}

ItemCheckIn.propTypes = {
	package: propTypes.object,
	isCheckedIn: propTypes.bool,
	checkedIn: propTypes.object,
	onCheckedIn: propTypes.func,
	onCheckedOut: propTypes.func,
	onUpdated: propTypes.func,
	onError: propTypes.func,

	// Member Page
	isMemberScreen: propTypes.bool,
	onMemberCheckIn: propTypes.func,
}
