import Cookies from 'js-cookie';
import React from 'react';
import { COOKIE_AUTH } from '../constants/cookies';


const defaultUserData = {
	created: "",
	date_joined: "",
	email: "",
	full_name: "",
	is_active: "",
	is_staff: "",
	is_superuser: "",
	last_login: "",
	signature: null,
  groups: [],
}

export default function useUserData() {

  const getCookieUserData = () => {
    try {
      return JSON.parse(Cookies.get(COOKIE_AUTH.USER) != null ? Cookies.get(COOKIE_AUTH.USER) : null)
    } catch(e) {
      return null
    }
  }

  const [user, setUser] = React.useState({
    ...defaultUserData, 
    ...getCookieUserData()
  });

  const setUserData = (userData) => {
    Cookies.set(COOKIE_AUTH.USER, JSON.stringify(userData), { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
    setUser({...defaultUserData, ...userData})
  }

  const removeUserData = () => {
    Cookies.remove(COOKIE_AUTH.USER)
    setUser(defaultUserData)
  }

  return { user, setUserData, removeUserData }
}