import React, { useState, useCallback } from 'react';
import propTypes from "prop-types";
import { Row, Form, Input, Select, DatePicker, Card, Col, Alert, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons'
import { CHOICES_NAME_PREFIX, DATE_FORMAT } from '../../constants/string';
import { POST, GET } from '../../frameworks/HttpClient';
import { URL_MEMBER } from '../../constants/urls';
import { SelectDatepicker } from 'react-select-datepicker';

const RegisterCorporateView = React.forwardRef((props, ref) => {
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [warningPhoneMessages, setWarningPhoneMessages] = React.useState(null);
	const [warningEmailMessages, setWarningEMailMessages] = React.useState(null);
	const [value, setValue] = React.useState(null);

	const onDateChange = useCallback((date) => {
	  if(date){
	  const d = new Date(date)
	  const day = d.getDate()
	  const month = d.getMonth() +1
	  const year = d.getFullYear()
	  const formatDate = `${year}-${month}-${day}`
	  form.setFieldsValue({birth_date : formatDate })
	  }else{
		  form.setFieldsValue({birth_date : date })
	  }
  
	});

	const handleRegister = async () => {
		setErrorMessages(null);
		try {
			props.onLoading(true);
			let data = await form.validateFields();
			const response = await POST(URL_MEMBER.MEMBER, data);
			props.onSuccess(response.data);
		} catch (error) {
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
		} finally {
			props.onLoading(false);
		}
	}

	React.useImperativeHandle(ref, () => ({
		save: async () => await handleRegister()
	}));

	const handleNameChanged = async () => {
		const first_name = form.getFieldValue('first_name')
		const last_name = form.getFieldValue('last_name')
		if(first_name == null || last_name == null) {
			return
		}

		// validate first_name and last_name
		try {
			setErrorMessages(null)
			props.onLoading(true)
			await GET(URL_MEMBER.VALIDATE_NAME, {
				first_name: first_name,
				last_name: last_name
			});
			props.onValidation(false)
		} catch (error) {
			props.onValidation(true)
			setErrorMessages(error.errorMessages)
		} finally {
			props.onLoading(false)
		}
	}

	const handleMobilePhoneChanged = async () => {
		const mobile_phone = form.getFieldValue('mobile_phone')
		if(mobile_phone == null) {
			return;
		}

		// Validate mobile phone
		try {
			setWarningPhoneMessages(null)
			props.onLoading(true)
			await GET(URL_MEMBER.VALIDATE_PHONE, {mobile_phone: mobile_phone})
		} catch (error) {
			modal.warning({
				title: 'Phone number already been used by',
				content: error.errorMessages,
			});
			setWarningPhoneMessages(error.errorMessages)
		} finally {
			props.onLoading(false)
		}
	}

	const handleEmailChanged = async () => {
		const email = form.getFieldValue('email')
		if (email == null) {
			return;
		}

		// Validate Email
		try {
			setWarningEMailMessages(null)
			props.onLoading(true)
			await GET(URL_MEMBER.VALIDATE_EMAIL, { email: email })
		} catch (error) {
			modal.warning({
				title: 'Email already been used by',
				content: error.errorMessages,
			});
			setWarningEMailMessages(error.errorMessages)
		} finally {
			props.onLoading(false)
		}
	}

	return (
		<Card>
			{errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginBottom: "12px", textAlign: "left"}}
				/>
			}
			{contextHolder}
			<Form 
				form={form}
				layout="vertical"
				autoComplete="off">
				<Row gutter={16} >
					<Col md={4} sm={24} xs={24}>
						<Form.Item
							name="name_prefix" 
							label={"Title"}
							rules={[{ required: true }]}>
							<Select options={CHOICES_NAME_PREFIX} />
						</Form.Item>
					</Col>

					<Col md={10} sm={24} xs={24}>
						<Form.Item 
							name={"first_name"} 
							label="Firstname" 
							rules={[{ required: true }]}>
							<Input autoComplete="off" placeholder="Firstname..." onBlur={handleNameChanged}/>
						</Form.Item>
					</Col>

					<Col md={10} sm={24} xs={24}>
						<Form.Item
							name={"last_name"}
							label="Surname"
							rules={[{ required: true }]}>
							<Input autoComplete="off" placeholder="Surname..." onBlur={handleNameChanged} />
						</Form.Item>
					</Col>

					<Col md={12} sm={24} xs={24}>
						<Form.Item 
								name={"birth_date"} 
								label="Date of Birth"
								rules={[{ required: true }]} >
						<SelectDatepicker
						className={'dropdown-date custom-mount'}
						order = "day/month/year"
						selectedDate={value}
						onDateChange={onDateChange}
						hideLabels={true}
						labels = {{
							"dayPlaceholder": "Day",
							"monthPlaceholder": "Month",
							"yearPlaceholder": "Year",
						}}
						/>
						</Form.Item>
					</Col>

					<Col md={12} sm={24} xs={24}>
						<Form.Item
							name={"mobile_phone"} 
							label="Mobile Phone" 
							rules={[{ required: true }]}>
							<Input 
								status={warningPhoneMessages && "warning"}
								prefix={warningPhoneMessages && <WarningOutlined />}
								autoComplete="off" 
								type='tel' 
								placeholder='Mobile Phone...' 
								onBlur={handleMobilePhoneChanged}/>
						</Form.Item>
					</Col>
					<Col md={24} sm={24} xs={24}>
						<Form.Item
							name={"email"} 
							label="email" 
							rules={[{ required: true }]}>
							<Input 
								status={warningEmailMessages && "warning"}
								prefix={warningEmailMessages && <WarningOutlined />}
								autoComplete="off" 
								type='email' 
								placeholder='Email...' 
								onBlur={handleEmailChanged}/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Card>
	)
})

RegisterCorporateView.defaultProps = {
	onSuccess: (data) => null,
	onLoading: (isLoading) => null,
	onValidation: (isError) => null,
}

RegisterCorporateView.propTypes = {
	onSuccess: propTypes.func,
	onLoading: propTypes.func,
	onValidation: propTypes.func,
}

export default React.memo(RegisterCorporateView);