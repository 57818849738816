import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Container from '../components/common/Container';
import { Button, Col, Image, Layout, Result, Row, theme } from 'antd';
import logo from '../assets/ahc_logo.png';
import { Header } from 'antd/es/layout/layout';


export default function VerifyPhoneResultScreen() {
  const [searchParams, _] = useSearchParams();

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const resultRender = () => searchParams.get('is_success') == 'True' ? (
    <div style={{ marginTop: '10rem' }}>
      <Result
        status='success'
        title='Successfully verfiy your phone number!'
        subTitle='Thank you for your cooperation.'
        extra={[
          <Button type='primary' href='https://alphahealthclub.com/'>
            Home page
          </Button>,
        ]}/>
    </div>
  ) : (
    <div>
      <Result
        status="500"
        title="Sorry, something went wrong. please try again later."
        subTitle={searchParams.get('error_msg')}
        extra={[
          <Button type='primary' href='https://alphahealthclub.com/'>
            Home page
          </Button>,
        ]}/>
    </div>
  )

  // React.useEffect(() => {
  //   console.log(is_success, error_msg)
  // }, [is_success, error_msg])

  return (
    <div>
      <Header 
        className={'shadow'} 
        style={{ 
          position: 'sticky', 
          top: 0, 
          zIndex: 1, 
          width: '100%', 
          background: '#FFFFFF', 
          color: colorPrimary, 
          fontWeight: 'bold', 
          paddingInline: '28px' }}>
        <Row>
          <Col>
            <div style={{textAlign: 'left'}}>
              <Image style={{height: '56px', width: 'auto'}} src={logo} preview={false}/>
              <label style={{marginLeft: 16}}>ALPHA HEALTH CLUB PHUKET</label>
            </div>
          </Col>
        </Row>
      </Header>

      <Container>
        { resultRender() }
      </Container>
    </div>
  )
}