import React from "react";
import propTypes from "prop-types";
import { Card } from "antd";
import "./../../styles.css"
import ChangePTPackageModal from "./ChangePTPackageModal";
import DelayPackageModal from "./DelayPackageModal";
import DetailPackageModal from "./DetailPackageModal";
import PartnerPackageModal from "./PartnerPackageModal";
import PackageRefTag from "./PackageRefTag";
import FreeTrainingAppointmentModal from "./FreeTrainingAppointmentModal";
import * as _ from 'lodash';
import SelfTrainingConfirmModal from "./SelfTrainingConfirmModal";


const ActionLabel = ({label, onClick}) => {
  return (<p style={{fontSize: 12, margin: 0}} onClick={onClick}>{label}</p>)
}

export default function CardAdditionalPackage(props) {
  const [data, setData] = React.useState(null);
  // Handle Package modal
  const [openMore, setOpenMore] = React.useState(false);
  const [openPartner, setOpenPartner] = React.useState(false);
  const [openChangPT, setOpenChangPT] = React.useState(false);
  const [openDelay, setOpenDelay] = React.useState(false);
  const [openFreeTrainingAppointment, setOpenFreeTrainingAppointment] = React.useState(false);
  const [openSelfTrainingConfirm, setOpenSelfTrainingConfirm] = React.useState(false);
  const { Meta } = Card;

  const renderActions = (item) => {
    if (!item) {
      return [];
    }
    if (_.get(item, 'is_free_training', false)) {
      return [
        <ActionLabel label="Appointment" onClick={() => setOpenFreeTrainingAppointment(true)}/>,
        <ActionLabel label="Self Training" onClick={() => setOpenSelfTrainingConfirm(true)}/>
      ]
    }

    const actions = [];
    // Delay package (ซ่อนไว้ก่อน)
    // if (item.end_date && !item.isPartner) {
    //   actions.push(<ActionLabel label={"Delay"} onClick={() => setOpenDelay(true)}/>);
    // }
    if (item.variant_type === "12" && !item.isPartner 
      && (!item.partners || (item.partners && item.partners.length === 0))) {
      actions.push(<ActionLabel label={"Partner"} onClick={() => setOpenPartner(true)}/>);
    }
    if (!item.isPartner) {
      actions.push(<ActionLabel label={"PT"} onClick={() => setOpenChangPT(true)}/>)
    }
    
    actions.push(<ActionLabel label={"More"} onClick={() => setOpenMore(true)}/>)
    return actions;
  }

  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <div>
      <Card actions={renderActions(data)}>
        <span style={{ float: 'right' }}><PackageRefTag data={data}/></span>
        <div 
          style={{
            fontWeight: 'bold',
            lineHeight: "1.5em", 
            height: "3em", 
            overflow: "hidden"}}
          onClick={() => setOpenMore(true)}>
            {(data && data.package_name) ? `[ ${(data.variant_type === "12") ? "1:2" : "1:1"} ] ${data.package_name}` : "Unknown"}
        </div>
        <div style={{fontSize: 14}}>
          {`${(data && data.trainer_name) ? data.trainer_name : "Unknown"}`}
        </div>

        <Meta
          style={{ marginTop: 16 }}
          description={`Start: ${(data && data.start_date) ? data.start_date : "-"}`}
        />
        <Meta description={`Exp: ${(data && data.end_date) ? data.end_date : "-"}`} />
        <Meta description={`Balance: ${data ? data.usage_remain : "-"}`} />
      </Card>

      <DetailPackageModal 
        title={"PT Session Detail"}
        package={data}
        open={openMore}
        onClose={() => setOpenMore(false)} />

      <PartnerPackageModal 
        package={data}
        open={openPartner}
        onClose={() => setOpenPartner(false)}
        onSuccess={() => {
          setOpenPartner(false);
          props.refresh();
        }}/>

      <ChangePTPackageModal
        package={data}
        open={openChangPT}
        onClose={() => setOpenChangPT(false)}
        onSuccess={() => {
          setOpenChangPT(false);
          props.refresh();
        }} />

      <DelayPackageModal
        package={data}
        isMainPackage={false}
        open={openDelay}
        onClose={() => setOpenDelay(false)}
        onSuccess={() => {
          setOpenDelay(false);
          props.refresh();
        }} />

      <FreeTrainingAppointmentModal
        open={openFreeTrainingAppointment}
        target={_.get(data, 'id', null)}
        onClose={() => {
          setOpenFreeTrainingAppointment(false)
          props.refresh();
        }}/>
      
      <SelfTrainingConfirmModal
        open={openSelfTrainingConfirm}
        target={_.get(data, 'id', null)}
        onClose={() => {
          setOpenSelfTrainingConfirm(false)
          props.refresh();
        }}/>
    </div>
  )
}

CardAdditionalPackage.defaultProps = {
  data: null,
  refresh: () => {},
}

CardAdditionalPackage.propTypes = {
  data: propTypes.object,
  refresh: propTypes.func,
}
