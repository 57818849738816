import React from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import { Alert, Checkbox, Form, Input, Modal, Button, Popover, QRCode, message } from "antd";
import { CopyOutlined } from '@ant-design/icons';
import Header from "../common/Header";
import { PATH } from "../../routes/CustomRoutes";
import { CONTRACT_TYPE, DATE_FORMAT } from "../../constants/string";
import { currencyFormat } from "../../frameworks/Util";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_SALE, URL_USERS, URL_REPORT } from "../../constants/urls";
import { useParams } from "react-router-dom";
import MemberConsentQRCode from "../member/MemberConsentQRCode";
import * as _ from 'lodash';


export default function PaymentModal(props) {
  const PROMPT_PAY_PATH = `${window.location.host}${PATH.PROMPTPAY_QR}`;

  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [cashierCode, setCashierCode] = React.useState("");
  const [cashier, setCashier] = React.useState(null);
  const [isPrint, setIsPrint] = React.useState(true);
  const [isEmailSend, setIsEmailSend] = React.useState(true);
  const cashierRef = React.useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [consentRequired, setConsentRequired] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false)
  const [transferAmount, setTransferAmount] = React.useState(0);
  const params = useParams();
  
  React.useEffect(() => {
    if (props.payments != null) {
      setTransferAmount(
        props.payments
        .filter(item => item.type === 'TRANSFER')
        .reduce((acc, item) => acc += item.amount, 0)
      )
    }
  }, [props.payments])

  // Paid
  const handlePaid = async () => {
    if (!cashier) {
      setErrorMessages("Cashier not found!");
      return;
    }
    if (!props.cartID) {
      setErrorMessages("Cart not found!");
      return;
    }
    setErrorMessages(null);
    setLoading(true);
    // Prepare data
    const data = {
      approved_by: cashier.id,
      payments: props.payments,
      discounts: props.discounts,
      send_email: isEmailSend,
    }
    // Create payment
    try {
      await POST(`${URL_SALE.CART}${props.cartID}/approve-payment/`, data);
      if(isPrint) 
        await printReceipt();
      setIsPaid(true);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  // Get cashier with code
  const handleCashierChange = (data) => {
    setCashierCode(data.target.value);
  }

  const validateCashier = async () => {
    if (!cashierCode) return;

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_USERS.CASHIER}${cashierCode}/`);
      setCashier(response.data);
    } catch (error) {
      setErrorMessages("Cashier code not found!");
      setCashier(null);
    }
  };

  // Print receipt
  const printReceipt = async () => {
    if (!props.cartID) {
      setErrorMessages("Cart ID not found!");
      return;
    }

    // Prepare data
    setErrorMessages(null);
    try {
      // Goto export
      const response = await GET(`${URL_REPORT.RECEIPT_REPORT}pdf/`, { cart: props.cartID}, false, true) // Download as blob
      window.open(URL.createObjectURL(response.data));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (!props.open) {
      setErrorMessages(null);
      setCashierCode("");
      setCashier(null);
      setIsPaid(false);
    } 
    else {
      if(cashierRef.current) {
        cashierRef.current.focus()
      }
      setConsentRequired(props.is_required);
  }}, [props.open]);

  return (
    <Modal
      okText={"Paid"}
      okButtonProps={{ 
        loading: loading, 
        disabled: (cashier && !consentRequired && !isPaid) ? false : true
      }}
      open={props.open}
      onOk={handlePaid}
      onCancel={()=> isPaid ? props.onSuccess() : props.onClose()}>
      <Header title={"Payment"} />
      {contextHolder}
      <Form
        style={{marginTop: 16}}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="new-password">
        {errorMessages && (
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{ marginBottom: "12px", textAlign: "left" }}
          />
        )}

        <Form.Item autoComplete={"off"} style={{ marginBottom: 16 }} label={"Cashier"}>
          <Input
            disabled={isPaid ? true : false}
            ref={cashierRef}
            type={"password"}
            autoComplete={"off"}
            placeholder={"Code..."}
            addonAfter={cashier ? cashier.full_name : "..."}
            onChange={handleCashierChange}
            onPressEnter={validateCashier}
            value={cashierCode}/>
        </Form.Item>

        <Form.Item style={{ marginBottom: 16 }} label={"Date"}>
          <Input disabled value={dayjs().format(DATE_FORMAT)}/>
        </Form.Item>

        <Form.Item style={{ marginBottom: 16 }} label="Grand Total">
          <Input
            disabled
            addonAfter={"Baht"}
            value={currencyFormat(props.paid)}/>
        </Form.Item>

        <Form.Item style={{ marginBottom: 16 }} label="Received">
          <Input
            disabled
            addonAfter={"Baht"}
            value={currencyFormat(props.received)}/>
        </Form.Item>

        <Form.Item label="Change">
          <Input
            disabled
            style={{fontSize: 32}}
            value={currencyFormat(props.received - props.paid)}/>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox 
            onChange={(e) => setIsPrint(e.target.checked)} 
            checked={isPrint}>{"Print Receipt"}</Checkbox>
          { props.send_email_show &&
            <Checkbox 
              onChange={(e) => setIsEmailSend(e.target.checked)} 
              checked={isEmailSend}>{"Send email"}</Checkbox>
          }
          <Popover title="Scan QRCode for generated Promptpay QR" trigger="click" placement="bottomRight" content={
            (<div>
              <QRCode size={200} value={`${PROMPT_PAY_PATH}/${transferAmount}`}/>
              <Popover content='Copy promptpayQR link to clipboard' trigger='hover' placement='bottomRight'>
                <Button style={{ marginLeft: '0.5em' }} onClick={() => {
                  navigator.clipboard.writeText(`${PROMPT_PAY_PATH}/${transferAmount}`);
                  messageApi.info('Copied to your clipboard.');
                }} shape='circle'><CopyOutlined /></Button>
              </Popover>
            </div>)
          } >
            <Button size='small'>PromptPay</Button>
          </Popover>
        </Form.Item>

      </Form>

      <MemberConsentQRCode
        transferAmount={transferAmount}
        type={CONTRACT_TYPE.PACKAGE}
        member={_.get(params, 'id', null)}
        consentRequired={consentRequired}
        onConsentSigned={ () => setConsentRequired(false)}
      />

    </Modal>
  )
}

PaymentModal.defaultProps = {
  cartID: null,
  paid: 0,
  received: 0,
  discounts: [],
  payments: [],
  open: false,
  onClose: () => {},
  onSuccess: () => {},
}

PaymentModal.propTypes = {
  cartID: propTypes.string,
  paid: propTypes.number,
  received: propTypes.number,
  discounts: propTypes.array, // array of discount
  payments: propTypes.array, // array of payment method
  open: propTypes.bool,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
}