import React from "react";
import propTypes from "prop-types";
import { Content } from "antd/es/layout/layout";
import Header from "../common/Header";

const SubHeader = ({title}) => (<ins><p style={{ fontWeight: 'bold', fontSize:20 , textAlign:'center'}}>{title}</p></ins>)
const LittelHeader = ({title}) => (<p style={{ fontWeight: 'bold' }}>{title}</p>)

export default function MemberContractTHView(props) {
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        setData(props.detail)
	},[props.detail]);

    return(
        <div>
            <Header
            fontSize={25}
                title={"สัญญาสมาชิก Alpha Health Club (อัลฟ่า เฮลธ์ คลับ)"}
            /> 
            <Content style={{ marginRight: 15, marginLeft: 10, marginTop: 0, textAlign: 'left', fontSize: 18 }}>
			
            <LittelHeader title={"1. ข้อกำหนดและเงื่อนไขการเป็นสมาชิก อัลฟ่า เฮลธ์ คลับ"}/>
            <ul>
                <li>1.1 คู่สัญญาตกลงกันว่า เงื่อนไขข้อตกลงเป็นส่วนหนึ่งของสัญญาสมาชิก อัลฟ่า เฮลธ์ คลับ</li>
                <li>1.2 สมาชิกได้อ่านสัญญาและเอกสารแนบท้ายสัญญา และยินยอมผูกพันตามข้อกำหนดและเงื่อนไขของสัญญาและเอกสารแนบท้ายสัญญาการเป็นสมาชิกของ
                อัลฟ่า เฮลธ์ คลับ หากสมาชิกไม่ปฏิบัติตามสัญญาและเอกสารแนบท้ายสัญญาสมาชิก ในกรณีเกิดความเสียหายแก่ชีวิต ร่างกาย ทรัพย์สิน หรือสิทธิอื่น ๆ 
                สมาชิกตกลงยินยอมที่จะไม่ฟ้องร้องดำเนินคดีทางกฎหมายทั้งทางแพ่งและอาญาหรือดำเนินการในทางกฎหมาย</li>
                <li>1.3 สิทธิและหน้าที่ทั้งหมดที่ระบุไว้ในหนังสือฉบับนี้จะมีผลใช้บังคับนับจากวันที่สมาชิกลงชื่อในสัญญาการเป็นสมาชิกและชำระเงินค่าสมาชิกครบถ้วน</li>
                <li>1.4 ในกรณีที่สมาชิกอายุต่ำกว่า 18 ปี สมาชิกต้องมีบิดา หรือมารดา หรือผู้ปกครองโดยชอบตามกฎหมาย ยินยอมในการสมัครเข้าเป็นสมาชิก
                โดยจะต้องลงชื่อในเอกสารเพื่อยินยอมให้สมัครสมาชิก และจะต้องเข้ารับผิดชอบในค่าใช้จ่าย และหนี้ใด ๆ ที่เกิดกับสมาชิก ทั้งปัจจุบันและอนาคต</li>
                <li>1.5 สมาชิกเข้าใจว่าภาระผูกพันของสมาชิกตามสัญญาฉบับนี้ จะสิ้นสุดลงก็ต่อเมื่อระยะเวลาที่กำหนดไว้ตามสัญญาฉบับนี้สิ้นสุดลง 
                หรือได้มีการบอกเลิกสัญญาฉบับนี้ภายใต้ข้อกำหนดและเงื่อนไขที่ระบุไว้ในสัญญานี้</li>
            </ul>

            <LittelHeader title={"2. การให้บริการและการชำระเงินค่าสมัครสมาชิก"}/>
            <ul>
                <li>2.1 สมาชิกตกลงสมัครสมาชิก และบริษัทตกลงที่จะอนุญาตให้สมาชิกใช้บริการในสถานที่ออกกำลังกายของบริษัท
                (ต่อไปในสัญญาเรียกว่า “อัลฟ่า เฮลธ์ คลับ”) ภายในระยะเวลา {data ? data.duration : '-'} วัน
                โดยสมาชิกได้ชำระค่าสมัคร สมาชิกเป็นจำนวนเงิน {data ? data.price : '-'} บาท (“ค่าสมาชิก”) ให้กับอัลฟ่า เฮลธ์ คลับ
                ในวันที่ลงนามในสัญญาฉบับนี้</li>
                <li>2.2 ระยะเวลาตามสัญญาฉบับนี้จะต่อระยะเวลาออกไปโดยการแจ้งต่ออายุและคู่สัญญามีหน้าที่ต้องปฏิบัติตามสัญญาฉบับนี้
                ต่อไปอีกตามระยะเวลาที่สมาชิกแจ้งต่ออายุสัญญาสมาชิก (“ระยะเวลาต่อสัญญา”) ภายหลังจากระยะเวลาตามสัญญานี้ได้สิ้นสุดลง 
                เว้นแต่คู่สัญญาไม่ประสงค์ที่จะต่อสัญญาต่อไป ในระหว่างใช้บริการตามสัญญาฉบับนี้ สมาชิกมีหน้าที่จะต้องปฏิบัติตามเงื่อนไขข้อตกลงตามที่
                ได้ตกลงไว้ในสัญญาฉบับนี้ ทั้งนี้ หากสมาชิกเลิกสัญญาก่อนครบกำหนดระยะเวลาไม่ว่ากรณีใดๆ สมาชิกไม่มีสิทธิเรียกร้องให้คลับคืนค่าสมาชิก
                แก่ลูกค้าสมาชิกไม่ว่าทั้งหมดหรือบางส่วน</li>
            </ul>
            
            <LittelHeader title={"3. คุณสมบัติของสมาชิกของ อัลฟ่า เฮลธ์ คลับ"}/>
            <ul>
                <li>3.1 สมาชิกขอรับรองว่า สมาชิกได้ตรวจร่างกายแล้ว ไม่ได้มีปัญหาเกี่ยวกับสุขภาพก่อนที่จะเริ่มเข้าใช้บริการในสถานที่ให้บริการของ อัลฟ่า เฮลธ์ คลับ</li>
                <li>3.2 สมาชิกมีหน้าที่ต้องแจ้งให้เจ้าหน้าที่หรือผู้ฝึกสอนของ อัลฟ่า เฮลธ์ คลับ ทราบหากสมาชิกมีปัญหาเรื่องสุขภาพทุกครั้งก่อนที่จะเข้าใช้บริการ อัลฟ่า เฮลธ์ คลับ</li>
                <li>3.3 สมาชิกที่สมัครใหม่ หรือผู้ที่รับโอนสิทธิสมาชิก จะต้องเป็นบุคคลที่ไม่เคยเป็นสมาชิกที่ค้างชำระค่าสมาชิก หรือค่าบริการอื่น ๆ กับ อัลฟ่า เฮลธ์ คลับ
                ต้องไม่เป็นบุคคลที่มีคุณสมบัติต้องห้ามหรือฝ่าฝืนตามข้อ 4.1 – 4.5 ของสัญญาฉบับนี้</li>
                <li>3.4 สมาชิกตกลงจะไม่เรียกร้องให้อัลฟ่า เฮลธ์ คลับรับผิดชอบในกรณีเกิดความเสียหายแก่ชีวิต ร่างกาย ทรัพย์สิน หรือสิทธิอื่น ๆ 
                ที่เกิดขึ้นในระหว่างการใช้บริการในสถานที่ให้บริการของ อัลฟ่า เฮลธ์ คลับ</li>
                <li>3.5 สมาชิกจะไม่นำบุคคลที่อายุต่ำกว่า 18 ปี ซึ่งไม่ได้รับอนุญาตให้เข้าใช้บริการในสถานที่ของ อัลฟ่า เฮลธ์ คลับ</li>
            </ul>
            
            <LittelHeader title={"4. สาเหตุที่ อัลฟ่า เฮลธ์ คลับ จะยกเลิกสมาชิกภาพของท่าน"}/>
            <ul>
                อัลฟ่า เฮลธ์ คลับ มีสิทธิที่จะบอกเลิกการเป็นสมาชิกภาพของสมาชิก หากตรวจพบว่า สมาชิกมีเหตุ หรือกระทำการใด ๆ ดังต่อไปนี้
                <li>4.1 สมาชิกไม่ปฏิบัติตามสัญญาสมาชิกที่สมาชิกได้ลงนามไว้กับอัลฟ่า เฮลธ์ คลับ กฎและระเบียบการใช้บริการของอัลฟ่า เฮลธ์ คลับ</li>
                <li>4.2 การกระทำการอันใดที่เป็นความผิดต่อกฎหมาย</li>
                <li>4.3 สมาชิกมีพฤติกรรมรบกวน ก่อกวน และก่อความรำคาญ ต่อการใช้บริการของสมาชิกท่านอื่น เช่นการอนาจาร เป็นต้น</li>
                <li>4.4 เป็นโรคติดต่อร้ายแรง เช่น โรคผิวหนังหรือโรคอื่น ๆ ตามที่อัลฟ่า เฮลธ์ คลับเห็นสมควร</li>
                <li>4.5 มีอาการผิดปกติทางจิตซึ่งอาจก่อให้เกิดความเสียหายต่ออัลฟ่า เฮลธ์ คลับ หรืออาจก่อให้เกิดอันตรายแก่สมาชิกท่านอื่น</li>
            </ul>
            
            <LittelHeader title={"5. การหยุดพักสถานะเป็นสมาชิกชั่วคราว"}/>
            <ul>
                <li>5.1 อัลฟ่า เฮลธ์ คลับมีสิทธิหยุดพักสถานะเป็นสมาชิกชั่วคราว ในกรณีที่ไม่ปฏิบัติตามหลักเกณฑ์ที่กำหนดไว้ในระเบียบการของ อัลฟ่า เฮลธ์ คลับ</li>
                <li>5.2 สมาชิกมีสิทธิหยุดพักสถานะชั่วคราวได้ตามเอกสารแนบท้ายสัญญา (ระเบียบการของอัลฟ่า เฮลธ์ คลับ) ในหมวดสมาชิกภาพ ข้อ 9</li>
            </ul>
            
            <LittelHeader title={"6. การโอนสิทธิในการเป็นสมาชิก"}/>
            <ul>
                <li>6.1 สมาชิกสามารถโอนสิทธิการเป็นสมาชิกได้ โดยสมาชิกต้องมีระยะเวลาการเป็นสมาชิกเหลืออยู่ไม่ต่ำกว่า 1 เดือน 
                โดยอัลฟ่า เฮลธ์ คลับขอสงวนสิทธิ์ในการโอนสิทธิเป็นจำนวนเต็มเดือน (30 วัน) เท่านั้น</li>
                <li>6.2 สมาชิกต้องชำระค่าธรรมเนียมการโอนสิทธิตามอัตราที่อัลฟ่า เฮลธ์ คลับกำหนด </li>
            </ul>
            
            <LittelHeader title={"7. บัญชีสมาชิก (Line OA)"}/>
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;กรณีบัญชีสมาชิก เสียหาย ชำรุด หรือ สูญหาย ไม่อาจเข้าถึงได้ สมาชิกมีหน้าที่ต้องติดต่อเพื่อขอออกใหม่ และจะต้องเสียค่าธรรมเนียมใน
                การสร้างบัญชีใหม่ตามอัตราที่อัลฟ่าเฮลธ์ คลับกำหนด ณ วันที่สมาชิกติดต่อดำเนินการออกบัญชีสมาชิกใหม่ 
            </ul>
            
            <LittelHeader title={"8. สถานที่ให้บริการ"}/>
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;อัลฟ่า เฮลธ์ คลับขอสงวนสิทธิ์ในการเปิดสถานที่ให้บริการใหม่ ย้ายสถานที่ตั้ง ปิดปรับปรุงการให้บริการบางส่วนของสถานที่ให้บริการในแต่ละสาขา 
                หรือหากในกรณีที่เจ้าของอาคารได้ทำการปรับปรุง หรือเข้ามาถือสิทธิในสถานที่ให้บริการ อัลฟ่า เฮลธ์ คลับจะแจ้งให้สมาชิกของแต่ละสาขาที่ให้บริการนั้น ๆ 
                ทราบตามช่องทางการติดต่อที่ท่านได้ให้ไว้ เมื่อสมาชิกรับทราบแล้ว สมาชิกสามารถไปใช้บริการในสาขาอื่นได้ตามที่สมาชิกสะดวก
            </ul>
            
            <LittelHeader title={"9. ห้องล็อกเกอร์เก็บของ"}/>
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;อัลฟ่า เฮลธ์ คลับได้มีการจัดเตรียมตู้ล็อกเกอร์เก็บของไว้ให้บริการแก่สมาชิกทุกท่านโดยไม่เสียค่าบริการเพิ่ม ซึ่งตู้ล็อกเกอร์เก็บของ
                นี้อนุญาตให้สมาชิกใช้ได้เฉพาะเวลาที่สมาชิกเข้ามาใช้บริการเท่านั้น และห้ามมิให้ใช้เก็บของข้ามคืนเด็ดขาด และอัลฟ่า เฮลธ์ คลับไม่
                แนะนำให้ท่านสมาชิกนำของมีค่ามาเก็บไว้ในตู้ ล็อกเกอร์เก็บของ อัลฟ่า 
                เฮลธ์ คลับขอสงวนสิทธิ์ที่จะไม่รับผิดชอบหากเกิดความเสียหาย
            </ul>

            <br/>
            <SubHeader title={"บทเบ็ดเตล็ด"} />
            <LittelHeader title={"10. การบังคับใช้ของสัญญา"}/>
            <ul>
                เอกสารแนบท้ายฉบับนี้เป็นส่วนหนึ่งของสัญญาการสมัครเข้าเป็นสมาชิกของอัลฟ่า เฮลธ์ คลับและให้นำกฎหมายแห่งราชอาณาจักรไทยบังคับใช้กับสัญญา
            </ul>
            
            <LittelHeader title={"11. การปฏิบัติตามกฎหมายและระเบียบการ"}/>
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;สมาชิกตกลงที่จะปฏิบัติตามและดำเนินการต่าง ๆ ที่เกี่ยวข้องกับการใช้สิทธิของการเป็นสมาชิกให้เป็นไปตามกฎหมาย และระเบียบการที่กำหนดให้ถูกต้องทุกประการ 
                หากสมาชิกไม่ปฏิบัติตามกฎหมายหรือระเบียบการ สมาชิกต้องรับผิดตามกฎหมายหรือหากการกระทำอันใดก่อให้เกิดความเสียหายแก่อัลฟ่า เฮลธ์ คลับหรือสมาชิกท่านอื่น 
                สมาชิกยินยอมที่จะชดใช้ค่าเสียหายที่เกิดขึ้นและเป็นผลโดยตรง และความเสียหายอื่นๆ แม้มิอาจคำนวณเป็นราคาเงินได้ให้กับบุคคลที่ได้รับผลกระทบอันเนื่องมาจาก
                การที่ไม่ปฏิบัติตามกฎหมายหรือระเบียบการ สมาชิกตกลงและยอมรับว่าอัลฟ่า เฮลธ์ คลับหรือพนักงานของอัลฟ่า เฮลธ์ คลับไม่ต้องรับผิดในความเสียหายที่เกิดขึ้นจากการใช้บริการ 
                รวมถึงการละเมิด หรือการให้บริการตามสัญญาฉบับนี้ไม่ว่ากรณีใดๆทั้งสิ้น 
            </ul>
            
            <LittelHeader title={"12. การแก้ไขเพิ่มเติมสัญญา"}/>
            <ul>
        	    สัญญาฉบับนี้อาจแก้ไขเปลี่ยนแปลง เพิ่มเติม ตีความ หรือคู่สัญญาอาจผ่อนผันหรือสละสิทธิโดยการตกลงร่วมกันเป็นหนังสือลงลายมือชื่อของคู่สัญญาหรือผู้มีอำนาจกระทำการแทนของคู่สัญญาทั้งสองฝ่ายเท่านั้น
            </ul>
            
            <LittelHeader title={"13. โมฆะแยกส่วน"}/>
            <ul>
                ในกรณีที่ส่วนหนึ่งส่วนใดของสัญญาตกเป็นโมฆะ ให้ถือว่าคู่สัญญาเจตนาให้ส่วนที่ไม่เป็นโมฆะแยกออกจากส่วนที่เป็นโมฆะ
            </ul>
            
            <LittelHeader title={"14. การโอนสิทธิเรียกร้อง"}/>
            <ul>
                คู่สัญญาตกลงที่จะไม่โอนสิทธิหรือหน้าที่ใด ๆ ตามสัญญาฉบับนี้ให้บุคคลอื่น ๆ เว้นแต่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากคู่สัญญาอีกฝ่าย
            </ul>

            <br/>
            <SubHeader title={"ข้อมูลส่วนบุคคล"} />
            <LittelHeader title={"15. การขอความยินยอมในการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบคุคล"}/>
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;สมาชิกมีสิทธิที่จะปฏิเสธการยินยอมให้อัลฟ่า เฮลธ์ คลับเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของสมาชิกเพื่อวัตถุประสงค์ตามที่ระบุไว้ด้านล่างนี้ 
                ทั้งนี้ โปรดพิจารณาประกาศความเป็นส่วนตัวเกี่ยวกับกรณีที่สมาชิกไม่สามารถให้ข้อมูลส่วนบุคคลแก่อัลฟ่า เฮลธ์ คลับ ได้<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;เพื่อเป็นการปฏิบัติให้เป็นไปตามข้อกำหนดของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562อัลฟ่า เฮลธ์ คลับ จึงใคร่ขอความกรุณา
                จากท่านในการให้ความยินยอมตามข้อความด้านล่างเพื่อที่อัลฟ่า เฮลธ์ คลับจะได้ดำเนินการให้เป็นไปตามกฎหมายและส่งมอบบริการที่ดีที่สุดให้แก่สมาชิกได้ต่อไป
            </ul>

            <ul>
                <li><LittelHeader title={"15.1 เก็บรวบรวมข้อมูลส่วนบุคคล"}/></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;ข้อมูลส่วนบุคคลจากการกรอกใบสมัครของสมาชิกรวมถึงข้อมูลส่วนบุคคลที่จัดว่าเป็นข้อมูลอ่อนไหว ที่อัลฟ่า เฮลธ์ คลับได้รับมา 
                เช่น ชื่อ-สกุล, วันเดือนปีเกิด, อายุ, เลขบัตรประจำตัวประชาชน, เชื้อชาติ, ศาสนา, ที่อยู่ที่สามารถติดต่อได้, 
                เบอร์โทรศัพท์, อีเมล์, รูปถ่าย หรือเอกสารใดๆ ที่เกี่ยวข้องกับการสมัคร เป็นต้น<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;อัลฟ่า เฮลธ์ คลับ จะใช้ข้อมูลสำหรับจัดทำเป็นประวัติของผู้สมัคร เป็นทะเบียนข้อมูลของผู้สมัคร และ/หรือจะนำมาใช้ในการให้บริการ/ปรับปรุงการให้บริการ ประมวลผลข้อมูล 
                โดยจะมีการจัดเก็บเป็นไฟล์ข้อมูลอิเล็กทรอนิกส์ และมีการจัดเก็บเป็นแฟ้มข้อมูลแยกตามรายบุคคล<br/>
            </ul>

            <ul>
                <li><LittelHeader title={"15.2 การสื่อสารทางการตลาด"}/></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;เพื่อแจ้งสมาชิกเกี่ยวกับข้อมูลข่าวสารโปรโมชั่นและกิจกรรมต่าง ๆ รวมถึงนำเสนอข้อมูลของผลิตภัณฑ์และบริการที่น่าสนใจของอัลฟ่า 
                เฮลธ์ คลับ โดยอัลฟ่า เฮลธ์ คลับอาจส่งข้อมูลข่าวสารเป็นข้อความ SMS ไปยังเบอร์โทรศัพท์ จดหมายอิเล็กทรอนิกส์ (E-mail) 
                สื่อสังคมออนไลน์ โทรศัพท์ ข้อความทางโทรศัพท์ และ/หรือทางไปรษณีย์ รวมถึงช่องทางอื่นใดในอนาคตที่อัลฟ่า เฮลธ์ คลับ
                เห็นว่าเหมาะสมของสมาชิกหรือให้พนักงานฝ่ายขายติดต่อสมาชิกเพื่อติดตามดูแลหลังการขาย และแจ้งให้ทราบถึงข้อมูลข่าวสาร 
                โปรโมชั่นและกิจกรรมต่าง ๆ รวมถึงนำเสนอข้อมูลของผลิตภัณฑ์และบริการที่น่าสนใจข้างต้นเป็นรายบุคคล<br/>
            </ul>

            <ul>
                <li><LittelHeader title={"15.3 การสำรวจความพึงพอใจ"}/></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;เพื่อใช้ข้อมูลในการวิเคราะห์ เสนอ ให้แสดงความคิดเห็น โดยอัลฟ่า เฮลธ์ คลับจะส่งแบบสำรวจความพึงพอใจ 
                รับฟังข้อเสนอ ความคิดเห็นต่าง ๆ โดยประเมินผลจากภาพรวมและประสบการณ์การเข้าใช้บริการของสมาชิก 
                เพื่อนำมาพัฒนาและปรับปรุงการให้บริการและจัดกิจกรรมส่งเสริมการขายให้ตรงตามความต้องการของสมาชิก<br/>
            </ul>

            <ul>
                <li><LittelHeader title={"15.4 การสำรวจข้อมูลสุขภาพ"}/></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;อัลฟ่า เฮลธ์ คลับ อาจสอบถามหรือขอเอกสารรับรองเกี่ยวกับสุขภาพของสมาชิกเพื่อตรวจสอบประวัติสุขภาพ เช่น 
                โรคติดต่อทางผิวหนัง โรคติดต่อทางเดินหายใจ โรคติดต่อทางสารคัดหลั่ง เป็นต้น เพื่อทำการประเมินว่าสมาชิกมี
                ความเหมาะสมกับการเข้าใช้บริการของอัลฟ่า เฮลธ์ คลับ<br/>
            </ul>

            <ul>
                <li><LittelHeader title={"15.5 เปิดเผยข้อมูลส่วนบุคคล"}/></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;ข้าพเจ้าอนุญาตให้อัลฟ่า เฮลธ์ คลับเปิดเผยข้อมูลส่วนบุคคลของสมาชิก เพื่อเป็นการปฏิบัติตามข้อบังคับ หรือข้อกำหนดอื่น ๆ 
                ตามกฎหมาย เพื่อดำเนินการตรวจสอบข้อมูลส่วนบุคคลของสมาชิกให้กับหน่วยงานหรือองค์กรต่าง ๆ เช่น โรงพยาบาล 
                หน่วยงานทางการแพทย์ สำนักงานตำรวจแห่งชาติ หรือหน่วยงานอื่น ๆ<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;ข้าพเจ้าได้อ่านประกาศความเป็นส่วนตัวของของอัลฟ่า เฮลธ์ คลับแล้ว และข้าพเจ้ารับทราบว่า ข้าพเจ้ามีสิทธิถอนความยินยอมในการเก็บรวบรวม 
                ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ตามที่ระบุไว้ได้ตลอดเวลา โดยแจ้งให้กับของอัลฟ่า เฮลธ์ คลับ
                ทราบเป็นลายลักษณ์อักษรถึงการถอนความยินยอมเช่นว่านั้นได้<br/>
            </ul>
            <br/>
            
            {data && (data.conditions.length > 0) && (
                <div>
                    <SubHeader title={"เงื่อนไขแพคเกจเพิ่มเติม"} />
                    {data.conditions.map((item, index) => {
                        return (
                            <div>
                                <p style={{ fontWeight: 'bold' }}>{item.name}</p>
                                <ul>
                                    <span style={{whiteSpace: "pre-line"}}>{item.term_conditions_th}</span>
                                </ul>
                            </div>
                        )
                    })}
                </div>
            )}
            
            <br/>
            ตามที่กล่าวมาข้างต้น ข้าพเจ้าได้อ่านและเข้าใจเงื่อนไขและข้อตกลงตามข้อ 1-15 อย่างดีแล้วจึงได้ลงลายมือชื่ออิเลกทรอนิกส์
            ไว้ในช่องลงนามเพื่อเป็นหลักฐานแห่งการตกลงยอมรับเงื่อนไขข้อตกลงการเป็นสมาชิกอัลฟ่า เฮลธ์ คลับ<br/>

            </Content>
        </div>
    )
}

MemberContractTHView.defaultProps = {
    detail: null,
}
  
MemberContractTHView.propTypes = {
    detail: propTypes.object,
}