import React from "react";
import { Alert, Button, Col, Form, Image, Layout, Result, Row, Select, Spin, theme } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import HeaderScreen from "../components/common/HeaderScreen";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import logo from "../assets/ahc_logo.png";
import { GET, POST } from "../frameworks/HttpClient";
import { URL_MEMBER, URL_TRAINER, URL_USERS } from "../constants/urls";
import { PhoneFilled } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { PATH } from "../routes/CustomRoutes";
import Container from "../components/common/Container";
import { MEMBER_TRAINING_STATUS } from "../constants/string";
import * as _ from 'lodash';


export default function FreeTrainingScreen() {
	const navigate = useNavigate();
	const params = useParams();
	const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [member, setMember] = React.useState(null);
	const [trainers, setTrainers] = React.useState([]);
	const [isSuccess, setIsSucess] = React.useState(false);
	const allowSelfTrainingOnly = _.get(member, 'training_status', '') == MEMBER_TRAINING_STATUS.REQUIRE_SELF_TRAINING;
	const {
    token: { colorPrimary }
  } = theme.useToken();

	const closeTab = () => {
		window.opener = null;
		window.open("", "_self");
		window.close();
	}

	// Free training
	const handleFreeTraining = async () => {
		if (!member) {
			setErrorMessages("Member not found!");
			return;
		}

		let data = await form.validateFields();
		data["member"] = member.id;
		setErrorMessages(null);
		setLoading(true);
		try {
			await POST(URL_TRAINER.MEMBER_INTERACTION, data);
			setIsSucess(true);
			closeTab();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	// Fetch member detail
	const fetchMember = async (code) => {
    if (!code) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.MEMBER}${code}/`);
			setMember(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

	// Fetch trainer list
	const fetchTrainers = async () => {
		try {
			const response = await GET(`${URL_USERS.TRAINERS}?page_size=100`);
			setTrainers(response.data.results.map(item => ({...item, value: item.id, label: item.full_name})));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (member) {
			form.setFieldsValue({trainer: member.pt});
			form.setFieldValue('action', allowSelfTrainingOnly ? 'self_training' : null)
		}
	}, [member]);

	React.useEffect(() => {
    if (params && params.id) {
			form.resetFields();
      fetchMember(params.id);
			fetchTrainers();
    }
  }, [params]);

	return (
		<Layout style={{minHeight: "100vh"}}>
      <HeaderScreen 
				title={"ALPHA HEALTH CLUB"}
				onClick={() => navigate(PATH.MAIN)}
			/>

			<Content>
				<Container style={{ textAlign: 'center', marginTop: '2rem' }}>
					<Image
						style={{borderRadius: 8, height: 140, width: "auto"}}
						src={(member && member.profile_image) ? member.profile_image : logo}/>
					<Title level={5} style={{color: colorPrimary, fontWeight: 'bold'}}>
						{`${(member && member.full_name) ? member.full_name : "Unknown"} (${(member && member.code) ? member.code : null})`}
					</Title>
					<Title level={5} style={{color: colorPrimary, marginTop: 4, fontWeight: 'bold'}}>
						<PhoneFilled style={{fontSize: 18, marginRight: 8}}/>
						{(member && member.mobile_phone) ? member.mobile_phone : "-"}
					</Title>
					{errorMessages && 
						<Alert
							message={errorMessages}
							type="error"
							showIcon
							style={{textAlign: "left", marginBottom: 12}}/>
					}

					{ isSuccess ? (
						<Result
							status='success'
							title="PT Interaction has been submit"
							extra={[
								<Button onClick={closeTab}>Close Tab</Button>
							]}/>
					) : (
						<Spin spinning={loading} tip='Loading...'>
							<Form 
								form={form}
								style={{ textAlign: "left" }}
								labelAlign="right"
								labelCol={{ span: 4 }}
								wrapperCol={{ span: 20 }}
								onFinish={handleFreeTraining}
								autoComplete="off">
								
								<Form.Item
									name="trainer" 
									label={"PT"}
									rules={[{ required: true }]}>
									<Select placeholder={"Select..."} options={trainers}/>
								</Form.Item>

								<Form.Item
									name="action" 
									label={"Action"}
									rules={[{ required: true }]}>
									<Select 
										placeholder={"Select..."}
										disabled={allowSelfTrainingOnly}
										options={[
											{value: "greet", label: "Greet"},
											{value: "standard_training", label: "Standard Training"},
											{value: "self_training", label: "Self Training"}
										]}/>
								</Form.Item>

								<Form.Item 
									name={"note"} 
									label="Note">
									<TextArea placeholder='Note...' />
								</Form.Item>

								<div style={{ width: "100%", textAlign: "center" }}>
									<Button type="primary" htmlType="submit" loading={loading}>
										Submit
									</Button>
								</div>
							</Form>
						</Spin>
					) }
				</Container>
			</Content>
   </Layout>
	)
}