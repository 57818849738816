import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal, Select, Spin } from "antd";
import Header from '../common/Header';
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_MEMBER, URL_PACKAGE } from '../../constants/urls';
import _ from "lodash";

export default function PartnerPackageModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
	const [debouncedMember, setDebouncedMember] = React.useState("");
	const [fetching, setFetching] = React.useState(false);
  const [memberOptions, setMemberOptions] = React.useState([]);
  const [form] = Form.useForm();
  
  // Handle change buddy
  const handleChangeBuddy = async () => {
    if (!props.package) {
      setErrorMessages("Package not found!");
      return;
    }

    const id = props.package.id
    let data = await form.validateFields();
    data["member"] = data.member.value;
    let response = null;
    setErrorMessages(null);
    setLoading(true);
    try {
      response = await POST(`${URL_PACKAGE.MEMBER_PT_SESSION_PACKAGE}${id}/partner/`, data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }

    if (response) 
      props.onSuccess();
  };

	// Delay search member
  const debounceMember = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedMember(_searchVal) }, 800),
    []
  );

  // Search member
  const fetchMembers = async () => {
    setFetching(true);
    setErrorMessages(null);
		try {
			const response = await GET(`${URL_MEMBER.MEMBER}?search=${debouncedMember}`)
			setMemberOptions(response.data.results.map((item) => {
				return {
					...item,
					label: `[${item.code}] ${item.full_name}`,
					value: item.id
				}
			}))
		} catch (error) {
			setErrorMessages(error.errorMessages)
		}

		finally {
			setFetching(false);
		}
  }

	React.useEffect(() => {
		if (debouncedMember)
			fetchMembers();
	}, [debouncedMember]);

  React.useEffect(() => {
    if (!props.open) {
        form.resetFields();
        setErrorMessages(null);
				setMemberOptions([]);
    }
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      okText={'Confirm'}
      okButtonProps={{ loading: loading }}
      onOk={handleChangeBuddy}
      onCancel={props.onClose}>

      <Header title={"Partner"} />
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginTop: "12px", textAlign: "left" }}
        />
      }

      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
        
        <Form.Item label={"Package"}>
          <Input autoComplete="off" value={props.package ? props.package.package_name : "Unknown"} disabled/>
        </Form.Item>

        <Form.Item name={"member"} label="Partner" rules={[{ required: true }]}>
					<Select 
            placeholder="Name" 
            showSearch
            labelInValue
            filterOption={false}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onSearch={debounceMember}
            options={memberOptions} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

PartnerPackageModal.defaultProps = {
  package: null,
  open: false,
  onClose: () => {},
  onSuccess: () => {},
}

PartnerPackageModal.propTypes = {
  package: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
}