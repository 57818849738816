import { Alert, DatePicker, Form, Modal, TimePicker, theme } from 'antd';
import React from 'react';
import Header from '../common/Header';
import PTSelect from '../pt/PTSelect';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/string';
import dayjs from 'dayjs';
import { POST } from '../../frameworks/HttpClient';
import { URL_TRAINER } from '../../constants/urls';
import * as _ from 'lodash';


export default function FreeTrainingAppointmentModal (props) {
  const {
    open,
    onClose,
    target,
  } = props;

  const { token: { colorSuccess } } = theme.useToken();
  const [form] = Form.useForm();
  const selectRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [params, setParams] = React.useState({});

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = await form.validateFields();
      await POST(URL_TRAINER.FREE_TRAINING_APPOINTMENT, parseFormToData(data));
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const parseFormToData = (data) => ({
    trainer: _.get(data, 'trainer', null),
    member_package: target,
    start_time: _.get(data, 'time.0', dayjs()).format(TIME_FORMAT),
    end_time: _.get(data, 'time.1', dayjs()).format(TIME_FORMAT),
    date: _.get(data, 'date', dayjs()).format(DATE_FORMAT)
  })

  const onFilterChange = (event) => {
    if (_.get(event, '0.name.0', '') == 'trainer') {
      return
    }
    form.setFieldValue('trainer', null);
    let { date, time } = form.getFieldsValue();
    setParams({
      is_available: true,
      date: date ? date.format(DATE_FORMAT) : null,
      start_time: _.get(time, 0, null) ? time[0].format(TIME_FORMAT) : null,
      end_time: _.get(time, 1, null) ? time[1].format(TIME_FORMAT) : null,
    })
  }

  React.useEffect(() => {
    console.log(form.getFieldsValue())
  }, [form])

  React.useEffect(() => {
    if (open) {
      form.resetFields();
      setErrorMessages(null);
      setParams({ is_available: true });
    }
  }, [open])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      okText='Create'
      onOk={() => handleSubmit()}
      cancelButtonProps={{ loading: isLoading }}
      okButtonProps={{ loading: isLoading, type: 'primary', style: { background: colorSuccess } }}
    >
      <Header title='Appointment'/>
      { errorMessages && 
        <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/>
      }
      <Form form={form} style={{ marginTop: '1rem' }} labelCol={{ span: 4 }} onFieldsChange={onFilterChange}>
        <Form.Item name='date' label='Date' rules={[{ required: true }]}>
          <DatePicker
            disabledDate={(current) => current && current < dayjs().subtract(1, 'day').endOf('day')}
            style={{ width: '100%' }}
            inputReadOnly={true}/>
        </Form.Item>
        <Form.Item name='time' label='Time' rules={[{ required: true }]}>
          <TimePicker.RangePicker
            format={TIME_FORMAT}
            style={{ width: '100%' }}
            inputReadOnly={true}
            minuteStep={15}
            disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4] })}/>
        </Form.Item>
        <Form.Item name='trainer' label='PT' rules={[{ required: true }]}>
          <PTSelect ref={selectRef} params={params}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}