import React from 'react'
import { Alert, Button, Col, Input, Row, Spin, theme, Card, Image, Space } from 'antd'
import { ExportOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Header from '../components/common/Header'
import Segment from '../components/common/Segment';
import logo from "../assets/ahc_logo.png"
import Meta from 'antd/es/card/Meta';
import CreateTicketModal from '../components/store/CreateTicketModal';
import GateModal from '../components/store/GateModal';
import { GET } from '../frameworks/HttpClient';
import { URL_STORE } from '../constants/urls';

export default function GateManagerView() {
    const [loading, setLoading] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState(null);
    const [openDoor,  setOpenDoor] = React.useState(false);
    const [openTicket, setOpenTicket] = React.useState(false);
    const [targetTicket, setTargetTicket] = React.useState(null);

    const {
		token: { colorPrimary },
    } = theme.useToken();

    const searchTicket = async (search) => {
        setErrorMessages(null);
        setTargetTicket(null);
        setLoading(true);
        try {
            const response = await GET(`${URL_STORE.TICKET}${search}/`);
            setTargetTicket(response.data);
        } catch (error) {
            setErrorMessages(error.errorMessages);
        } finally {
            setLoading(false);
        }
    }

    const {
		token: { colorSuccess },
    } = theme.useToken();
  
    return (
        <div style={{textAlign: "left"}}>
			<Header title={"Gate Manager"}/>
            {errorMessages && 
                <Alert
                    message={errorMessages}
                    type="error"
                    showIcon
                    style={{marginTop: "12px", textAlign: "left"}} />
			}
            
            <Space wrap style={{marginTop: 16}}>
                <Button
                    style={{borderColor: colorPrimary, color: colorPrimary}}
                    icon={<ExportOutlined />} 
                    onClick={() => setOpenDoor(true)}>{"Gate"}</Button>
                
                <Button 
                    style={{background: colorSuccess}} 
                    type="primary" 
                    icon={<PlusCircleOutlined />} 
                    onClick={() => setOpenTicket(true)}>{"Ticket"}</Button>
            </Space>

            <p style={{ fontWeight: "bold", marginTop: 24, textAlign: "left" }}>Ticket ID :</p>
			<Spin tip="Loading" size="default" spinning={loading}>
				<Segment
					style={{ padding: 16, background: "#fff", margin: "auto" }}
					detail={
                        <Input 
                            style={{ fontSize: 28 }}
                            placeholder="Ticket ID"
                            autoComplete="off"
                            bordered={false}
                            allowClear
                            onChange={(e) => e.target.value ? null : setTargetTicket(null)}
                            onPressEnter={(e) => searchTicket(e.target.value)}/>
					}
				/>
			</Spin>

            {targetTicket && (
                <Card style={{width: "50%", margin: "auto", marginTop: 24}}>
                    <Row align={"middle"}>
                        <Col md={8} sm={24} xs={24} style={{textAlign: "center"}}>
                            <Image 
                                style={{height: 86, width: "auto"}} 
                                src={targetTicket.store_logo ? targetTicket.store_logo : logo}/>
                        </Col>
                        <Col md={16} sm={24} xs={24}>
                            <p style={{fontWeight: "bold", marginTop: 0}}>
                                {targetTicket.store_name ? targetTicket.store_name : "Unknown"}
                            </p>
                            <Meta description={`Start: ${targetTicket.start_date ? targetTicket.start_date : "-"}`} />
                            <Meta description={`Exp: ${targetTicket.end_date ? targetTicket.end_date : "-"}`} />
                            <Meta description={`Duration: ${targetTicket.duration} (days)`} />
                            <Meta description={`Usage Remain: ${targetTicket.remain}`} />
                        </Col>
                    </Row>
                </Card>
            )}

            {/* Open Door */}
            <GateModal open={openDoor} onClose={() => setOpenDoor(false)}/>

            {/* Create Ticket */}
            <CreateTicketModal 
                open={openTicket}
                onClose={() => setOpenTicket(false)}
                onCreated={() => setOpenTicket(false)}/>
        </div>
    )
}