import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Modal, Select } from "antd";
import Header from '../common/Header';
import { URL_REPORT } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';


export default function PrintModal(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [selected, setSelected] = React.useState("trial");
	const [isLoading, setLoading] = React.useState(false);

	const handlePrint = async () => {
		if (props.memberCode && selected) {
			// Goto print
			setLoading(true);
			if (selected === "trial") {
				await doPrint(URL_REPORT.MEMBER_SHIP_CARD_REPORT, {member: props.memberCode})
			} else if (selected === "member") {
				await doPrint(URL_REPORT.REAL_MEMBER_SHIP_CARD_REPORT, {member: props.memberCode})
			} else if (selected === "new_trial") {
				await doPrint(URL_REPORT.NEW_MEMBER_SHIP_CARD_REPORT, {member: props.memberCode})
			} else if (selected === "new_member") {
				await doPrint(URL_REPORT.NEW_REAL_MEMBER_SHIP_CARD_REPORT, {member: props.memberCode})
			}
			setLoading(false);
		}

		props.onClose();
	}

	const doPrint = async (url, params) => {
		setErrorMessages(null);
		try {
      const response = await GET(url, params, false, true) // Download as blob
			window.open(URL.createObjectURL(response.data));
		} catch(error) {
			setErrorMessages(error.errorMessages);
		}
	}

  const handleChange = (value) => setSelected(value);

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			setSelected("trial")
		}
	}, [props.open])

  return (
    <Modal
    	okText={"Print"}
			okButtonProps={{loading: isLoading}}
      open={props.open}
      onOk={handlePrint}
      onCancel={props.onClose}>
      <Header title={"Member Card"}></Header>
      
      <Form
				style={{marginTop: 16}}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}>
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
        <Form.Item label="Card Type">
          <Select
            value={selected}
            onChange={handleChange}
            options={[
              { value: "trial", label: "Trial Card" },
							{ value: "member", label: "Member Card" },
							{ value: "new_trial", label: "New Trial Card" },
							{ value: "new_member", label: "New Member Card" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
PrintModal.defaultProps = {
  open: false,
	memberCode: null,
  onClose: () => {},
}

PrintModal.propTypes = {
  open: propTypes.bool,
	memberCode: propTypes.string,
  onClose: propTypes.func,
}