import { Modal } from 'antd';
import React from 'react';
import { POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../constants/urls';
import Header from '../common/Header';


export default function SelfTrainingConfirmModal(props) {
  const {
    open,
    onClose,
    target,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await POST(`${URL_PACKAGE.MEMBER_PT_SESSION_PACKAGE}${target}/self-training/`)
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      okText='Confirm'
      onOk={() => handleConfirm()}
      cancelButtonProps={{ loading: isLoading }}
      okButtonProps={{ loading: isLoading, type: 'primary' }}
    >
      <Header title='Self training confirmation'/>
      <div style={{ marginTop: '1rem' }}>
        Are you sure to choose self training for following member?
      </div>
    </Modal>
  )
}