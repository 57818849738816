import React from 'react'
import propTypes from "prop-types";
import { Alert, Card, Col, Empty, Row, Button, theme, Modal } from 'antd';
import { TeamOutlined, HistoryOutlined, GiftOutlined } from '@ant-design/icons';
import "./../../../styles.css"
import InformationCardView from '../InformationCardView';
import CardPTClassBooking from '../booking/CardPTClassBooking';
import { URL_TRAINER } from '../../../constants/urls';
import { GET } from '../../../frameworks/HttpClient';
import MyBookingsView from '../../../views/member/MyBookingsView';

export default function BookingView(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [classBooking, setClassBooking] = React.useState([]);
  const [sessionBooking, setSessionBooking] = React.useState([]);
  const [showBoooking ,setShowBooking] = React.useState(false);

  const {
    token: { colorSuccess, }
  } = theme.useToken();

  // Fetch class booking
  const fetchBookingClass = async () => {
    if (!props.member) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_TRAINER.MEMBER_BOOKING_HISTORY}`, { 'member_id': props.member.id });
      const bookedData = response.data.booked_classes.map((item) => {
        return {
          ...item.trainer_class_detail,
          id: item.id,
          start_time: item.trainer_class_detail.start_time,
          end_time: item.trainer_class_detail.end_time,
          date: item.trainer_class_detail.date,
          class_type_name: item.trainer_class_detail.class_type_name,
          trainer_name: item.trainer_class_detail.trainer_name,
          studio_name: item.trainer_class_detail.studio_name,
          type : "pt_class"
        }
      })

      setClassBooking(bookedData);

    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Fetch session booking
  const fetchBookingSession = async () => {
    if (!props.member) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_TRAINER.TRAINER_SESSION_MEMBER}${props.member.code}/?page_size=999`);
      setSessionBooking(response.data.results.map(item => {
        return {...item, class_type_name: "Appointment" , type : "pt_session"}
      }));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (props.member) {
      fetchBookingClass();
      fetchBookingSession();
      setShowBooking(false);
    }
  }, [props.member]);


  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={18} sm={24} xs={24}>
          {errorMessages && 
            <Alert
              message={errorMessages}
              type="error"
              showIcon
              style={{marginBottom: "12px", textAlign: "left"}}/>
          }
          <Card style={{textAlign: 'center', width: '100%'}}>
            {/* Class Booking */}
            <Row>
              <Col md={8} sm={24} xs={24}  >
                <p style={{ fontWeight: "bold", textAlign: 'left', }}>PT Class</p>
              </Col>
              <Col md={16} sm={24} xs={24} style={{ textAlign: 'right', }}>
                <Button
                  onClick={() => setShowBooking(true)}
                  icon={<TeamOutlined style={{ fontSize: 18 }} />}
                  type={"primary"}
                  style={{ background: colorSuccess, marginRight: 8 }}>
                  {"Manage Booking"}
                </Button>
              </Col>
            </Row>
            <Card style={{width: '100%', textAlign: 'left'}}>
              <Row justify={"left"} gutter={[16, 16]} >
                {classBooking.map(item => {
                  return (
                    <Col key={item.id} md={8} sm={24} xs={24}  >
                      <CardPTClassBooking 
                        data={item} 
                        onCancel={fetchBookingClass}
                      />
                    </Col>
                  )
                })}
              </Row>
              {classBooking && (classBooking.length === 0) && (<Empty />)}
            </Card>
            <br />

            {/* Session Booking */}
            <p style={{fontWeight: "bold", textAlign: 'left'}}>{"PT Session"}</p>
            <Card style={{width: '100%', textAlign: 'left'}}>
              <Row justify={"left"} gutter={[16, 16]} >
                {sessionBooking.map(item => {
                  return (
                    <Col key={item.id} md={8} sm={24} xs={24} >
                      <CardPTClassBooking 
                      data={item} 
                      onCancel={fetchBookingSession}/>
                    </Col>
                  )
                })}
              </Row>
              {sessionBooking && (sessionBooking.length === 0) && (<Empty />)}
            </Card>
          </Card>
        </Col>
        <Col
          span={6}
          style={{ textAlign: 'center' }}>
          <InformationCardView member={props.member} onUpdated={props.onUpdateSuccess} />
        </Col>
      </Row>

      <Modal
        title="Manage Class Booking"
        open={showBoooking}
        closeIcon={true}
        destroyOnClose={true}
        onCancel={() => {
          setShowBooking(false)
          fetchBookingClass();
          fetchBookingSession();
        }}
        footer={[
          <Button key="back" onClick={() => setShowBooking(false)}>
            Close
          </Button>
        ]}
      >
        <MyBookingsView member={props.member} />
      </Modal>
    </div>
  )
}

BookingView.defaultProps = {
  member: null,
  onUpdated: () => {},
}

BookingView.propTypes = {
  member: propTypes.object,
  onUpdated: propTypes.func,
}