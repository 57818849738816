import React from 'react'
import { Content, Footer, } from 'antd/es/layout/layout'
import { Button, Layout, Checkbox } from 'antd';
import HeaderScreen from '../components/common/HeaderScreen';
import Header from "../components/common/Header";
import RegisterCorporateView from '../components/member/RegisterCorporateView';
import RegisterMemberView from '../components/member/RegisterMemberView';
import { useNavigate } from 'react-router-dom';
import { POST } from '../frameworks/HttpClient';
import { PATH } from '../routes/CustomRoutes';
import { URL_MEMBER } from '../constants/urls';
import PDPAModal from '../components/member/PDPAModal';


export default function RegisterScreen() {
	const navigate = useNavigate();
	const memberRef = React.useRef(null);
	const corporateRef = React.useRef(null);
	const [signature, setSignature] = React.useState(null);
	const [checked, setChecked] = React.useState(false);
	const [registerError, setRegisterError] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [openPDPAModal, setOpenPDPAModal] = React.useState(true);

	const onChange = (e) => setChecked(e.target.checked); 

	const handleRegister = async () => {
		if (checked && corporateRef) {
			corporateRef.current.save();
		} 
		else if (!checked && memberRef) {
			memberRef.current.save();
		}
	}

	const handleRegisterSuccess = async (data) => {
			setErrorMessages(null);
		try{
			setLoading(true);
			await POST(`${URL_MEMBER.MEMBER}${data.id}/consent/`, signature);

			if (data && data.id)
				navigate(`${PATH.MEMBER_DETAIL}/info/${data.id}`, { replace: true });
		} catch (error){
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<HeaderScreen title={"ALPHA HEALTH CLUB PHUKET"} />

			<div style={{ margin: 24, textAlign: "left"}}>
				<Header
					title={"Register"} />

				<Checkbox 
					style={{ fontWeight: 'bold', marginTop: 16 }}
					checked={checked} 
					onChange={onChange}>{"Corporate Member"}
				</Checkbox>

				<Content style={{ marginTop: 16 }}>
					{checked 
						? (<RegisterCorporateView ref={corporateRef} onSuccess={handleRegisterSuccess} onValidation={(isError) => setRegisterError(isError)} onLoading={(isLoading) => setLoading(isLoading)}/>) 
						: (<RegisterMemberView ref={memberRef} onSuccess={handleRegisterSuccess} onValidation={(isError) => setRegisterError(isError)} onLoading={(isLoading) => setLoading(isLoading)} />)
					}
				</Content>

			</div>

			<Footer style={{ background: "#fff", textAlign: "center" }}>
				<Button disabled={registerError} loading={loading} type={"primary"} shape={"round"} onClick={handleRegister}>{"Register"}</Button>
			</Footer>

			<PDPAModal 
				open={openPDPAModal}
				signature={(sign) => {
					setSignature(sign)
				}}
				onUpdated={() => {
					setOpenPDPAModal(false);
				}}
				onClose={() => setOpenPDPAModal(false)}/>
		</Layout>
	)
}