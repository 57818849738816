import React, { useState, useEffect } from 'react';
import './AutoSlider.css'; // Import the CSS for styling

import img1 from '../CssScreen/img/bg-header-alphasphere.jpg';
import img2 from '../CssScreen/img/bg-header-classes-les.jpg';
import img3 from '../CssScreen/img/bg-header-classes.jpg';
import img4 from '../CssScreen/img/bg-header-holiday.jpg';
import img5 from '../CssScreen/img/bg-header-package.jpg';
import img6 from '../CssScreen/img/bg-header-pool.jpg';
import img7 from '../CssScreen/img/bg-header-service.jpg';
import img8 from '../CssScreen/img/bg-header-trainers.jpg';

const AutoSlider = () => {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 12000); // Adjust the interval as needed

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [images.length]);

  return (
    <div className="slider">
      <div
        className="slider-inner"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div className="slider-item" key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoSlider;
