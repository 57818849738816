import { theme } from "antd";
import React from "react";
import propTypes from "prop-types";
import { URL_PACKAGE } from "../../constants/urls";
import * as _ from 'lodash';
import TSTable from "../common/TSTable";


export default function PackageHistory(props) {
  const {
    member,
  } = props;

  const {
    token: { colorTextSecondary, colorSuccess, colorError }
  } = theme.useToken();

  const listRef = React.useRef(null)

	const columns = [
    {
      title: 'Package Name',
      dataIndex: 'package_name',
      render: (_, original) => (<label>
        {original.has_dynamic && `[ALL CLUB - ${original.dynamic_package.name}] `}
        {original.package_name ? original.package_name : "Unknown"}
        {original.variant_type && ` (${original.variant_type})`}
      </label>) 
    },
    {
      title: 'Start date',
      dataIndex: 'start_date'
    },
    {
      title: 'End date',
      dataIndex: 'end_date'
    },
    {
      title: 'Remaining',
      dataIndex: 'duration_remain'
    },
    {
      title: 'Drop days',
      dataIndex: 'drop_days'
    },
  ]


  React.useEffect(() => {
    if (member != null && listRef.current) {
      listRef.current.reset()
      listRef.current.fetch()
    }
  }, [member])

  return (
    <>
      <TSTable 
        columns={columns}
        url={`${URL_PACKAGE.MEMBER_PACKAGE}?member=${member}&ordering=-expired`}
        rowKey={"id"}
        size={"small"}
        style={{ marginTop: 16 }}
        rowClassName={(record) => {
          if (record.is_expired || record.is_transfered) {
            return "table-row-red"
          }
        }}/>
    </>
  )
}

PackageHistory.defaultProps = {
  member: null,
}

PackageHistory.propTypes = {
  member: propTypes.string,
}