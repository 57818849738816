import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal, Select, InputNumber } from "antd";
import Header from '../common/Header';
import { URL_PACKAGE, URL_SALE } from '../../constants/urls';
import { PRODUCT_TYPE } from '../../constants/string';
import { GET, POST } from '../../frameworks/HttpClient';
import { formatComma } from '../../frameworks/Util';

export default function ExchangeModal(props) {
  const [form] = Form.useForm();
  const amount = Form.useWatch('amount', form);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [packages, setPackages] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  // Exchange point to package
  const handleExchange = async () => {
    if (!props.memberId) {
      setErrorMessages("Member not found!");
      return;
    }

    // Prepare data
    const data = await form.validateFields();
    data["member"] = props.memberId;
    data['amount'] = data['amount'] != null ? data['amount'] : 1
    setErrorMessages(null);
    setLoading(true);
    try {
      await POST(URL_SALE.POINT_PURCHASE, data);
      props.onSuccess();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  };

  // Handle selected package
  const handleChange = (value, item) => {
    setSelected(item);
    form.setFieldValue('amount', 1)
  }

  // Fetch packages that redeemable with point
  const fetchData = async () => {
		setErrorMessages(null);
		try {
			const response = await GET(`${URL_PACKAGE.POINT_REDEEMABLE_PACKAGES}`);
			setPackages(response.data.results.map(item => ({...item, label: item.name, value: item.item})));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
      fetchData();
    } else {
      setSelected(null);
      setErrorMessages(null);
    }
  }, [props.open])

  return (
    <Modal
      okText={'Confirm'}
      okButtonProps={{
        loading: loading, 
        disabled: (selected && (parseInt(selected.price) > props.rewardPoint)) > 0 ? true : false}}
      open={props.open}
      onOk={handleExchange}
      onCancel={props.onClose} >

      <Header title={"Exchange"} />
      {errorMessages && (
				<Alert
          style={{ marginTop: "12px", textAlign: "left"}}
					message={errorMessages}
					type="error"
					showIcon />
			)}

      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
          
        <Form.Item 
          name={"item"} 
          label={"Package"}
          rules={[{ required: true }]}>
          <Select 
            options={packages}
            onChange={handleChange}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} />
        </Form.Item>

        {selected && selected.type === PRODUCT_TYPE.GENERAL &&
          <Form.Item 
            initialValue={1} 
            name="amount" 
            rules={[{ 
              required: selected && selected.type === PRODUCT_TYPE.GENERAL,
              validator: (_, value) =>
                parseInt(value) > 0 ? Promise.resolve() : Promise.reject(new Error('Please enter value more than 0')),
            }]}  
            label="amount">
            <InputNumber
              autoComplete="off"
              onFocus={evt => evt.target.select()}/>
          </Form.Item>
        }

        <Form.Item label="Total Points">
          <Input
            autoComplete="off"
            addonAfter={"Point"}
            value={selected ? formatComma(selected.price * parseInt(amount != null ? amount : 1)) : ""} />
        </Form.Item>
      </Form>

    </Modal>
  )
}
ExchangeModal.defaultProps = {
  memberId: null,
  rewardPoint: 0,
  open: false,
  onClose: () => {},
  onSuccess: () => {},
}

ExchangeModal.propTypes = {
  memberId: propTypes.string,
  rewardPoint: propTypes.number,
  open: propTypes.bool,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
}