import { Content } from "antd/es/layout/layout";
import Header from "../common/Header";

export default function ConsentTHView () {

	return (
		<div>
			<Header
				title={"หนังสือยินยอมสำหรับการประมวลผลข้อมูลและเปิดเผยข้อมูลส่วนบุคคล"}
			/>
			<Content style={{ margin: 24, textAlign: 'left', marginTop: 0, }}>
				<p style={{ fontWeight: 'bold' }}>1. ข้อกำหนดและเงื่อนไขการเป็นของสมาชิก อัลฟ่า เฮลธ์ คลับ </p>
				<ul>
					1.1 คู่สัญญาตกลงกันว่า เอกสารแนบท้ายสัญญาเป็นส่วนหนึ่งของสัญญาสมาชิก อัลฟ่า เฮลธ์ คลับ<br />
					1.2 สมาชิกได้อ่านสัญญาและเอกสารแนบท้ายสัญญา
					และยินยอมผูกพันตามข้อกำหนดและเงื่อนไขของสัญญาและเอกสารแนบท้ายสัญญาการเป็นสมาชิกของ
					อัลฟ่า เฮลธ์ คลับ หากสมาชิกไม่ปฏิบัติตามสัญญาและเอกสารแนบท้าย
					สัญญาสมาชิก ในกรณีเกิดความเสียหายแก่ชีวิต ร่างกาย ทรัพย์ หรือสิทธิอื่น ๆ
					สมาชิกตกลงยินยอมที่จะไม่ฟ้องร้องดำเนินคดีทางกฎหมายทั้งทางแพ่งและอาญาหรือดำเนินการในทางกฎหมาย
					<br></br>
					1.3 สิทธิและหน้าที่ทั้งหมดที่ระบุไว้ในหนังสือฉบับนี้จะมีผลใช้บังคับนับจากวันที่สมาชิกลงชื่อในสัญญาการเป็นสมาชิกและชำระเงินค่าสมาชิกครบถ้วน
					<br></br>
					1.4 ในกรณีที่สมาชิกยังไม่บรรลุนิติภาวะ สมาชิกต้องมีบิดา หรือมารดา หรือผู้ปกครองโดยชอบตามกฎหมาย
					ยินยอมในการสมัครเข้าเป็นสมาชิกโดยจะต้องลงชื่อในเอกสารยินยอมให้สมัครสมาชิก
					และจะต้องเข้ารับผิดชอบในค่าใช้จ่าย และหนี้ใด ๆ ที่เกิดกับสมาชิก ทั้งปัจจุบันและอนาคต <br />
					1.5 สมาชิกเข้าใจว่าภาระผูกพันของสมาชิกตามสัญญาฉบับนี้ จะสิ้นสุดลงก็ต่อเมื่อระยะเวลาที่กำหนดไว้ตามสัญญาฉบับนี้สิ้นสุดลง
					หรือได้มีการบอกเลิกสัญญาฉบับนี้ภายใต้ข้อกำหนดและเงื่อนไขที่ระบุไว้ในสัญญานี้
				</ul>
				<p style={{ fontWeight: 'bold' }} >2. การให้บริการและการชำระเงินค่าสมัครสมาชิก </p>
				<ul>
					2.1 สมาชิกต้องชำระค่าสมัครสมาชิกก่อนเข้ารับบริการ (“ค่าสมาชิก”)
					<br />
					2.2 บริษัทตกลงที่จะอนุญาตให้สมาชิกใช้บริการในสถานที่ออกกำลังกายของบริษัท (“อัลฟ่า เฮลธ์ คลับ”) ตามระยะเวลาที่สมาชิกตกลงไว้กับบริษัท<br />
					ระยะเวลาตามสัญญาฉบับนี้จะต่อระยะเวลาออกไปโดยอัตโนมัติและคู่สัญญามีหน้าที่ต้องปฏิบัติตามสัญญาฉบับนี้ต่อไปอีก 3 เดือน 6 เดือน หรือหนึ่งปี แล้วแต่กรณี (“ระยะเวลาต่อสัญญา”)
					ภายหลังจากระยะเวลาตามสัญญานี้ได้สิ้นสุดลง เว้นแต่คู่สัญญาไม่ประสงค์ที่จะต่อสัญญาต่อไปก่อนที่จะเริ่มระยะเวลา หรือระยะเวลาต่อสัญญา โดยคู่สัญญาจะต้องแจ้งล่วงหน้าเป็นลายลักษณ์อักษรอย่างน้อย 1 เดือนก่อนที่ระยะเวลาหรือระยะเวลาต่อสัญญาจะเริ่มขึ้นโดยต้องปฏิบัติตามขั้นตอนตามข้อ 4.3
					ในระหว่างใช้บริการตามสัญญาฉบับนี้ สมาชิกมีหน้าที่จะต้องปฏิบัติตามเงื่อนไขข้อตกลงตามที่ได้ตกลงไว้ในสัญญาฉบับนี้ <br />
				</ul>
				<p style={{ fontWeight: 'bold' }}>3. คุณสมบัตของสมาชิกของ อัลฟ่า เฮลธ์ คลับ </p>
				<ul>
					3.1 สมาชิกขอรับรองว่า สมาชิกได้ตรวจร่างกายแล้ว ไม่ได้มีปัญหาเกี่ยวกับสุขภาพก่อนที่จะเริ่มเข้าใช้บริการในสถานที่ให้บริการของ อัลฟ่า เฮลธ์ คลับ<br />
					3.2 สมาชิกมีหน้าที่ต้องแจ้งให้เจ้าหน้าที่หรือผู้ฝึกสอนของ อัลฟ่า เฮลธ์ คลับให้ทราบหากสมาชิกมีปัญหาเรื่องสุขภาพทุกครั้งก่อนที่จะเข้าใช้บริการ อัลฟ่า เฮลธ์ คลับ<br />
					3.3 สมาชิกที่สมัครใหม่ หรือผู้ที่รับโอนสิทธิสมาชิก จะต้องเป็นบุคคลที่ไม่เคยเป็นสมาชิกที่ค้างชำระค่าสมาชิกหรือค่าบริการอื่น ๆ กับ อัลฟ่ า เฮลธ์ คลับ
					ต้องไม่เป็นบุคคลที่มีคุณสมบัติต้องห้ามหรือฝ่าฝืนตามข้อ 5.1 – 5.5 ของสัญญาฉบับนี้ <br />
					3.4 สมาชิกตกลงจะไม่เรียกร้องให้บริษัทรับผิดชอบในกรณีเกิดความเสียหายแก่ชีวิต ร่างกาย ทรัพย์สิน หรือสิทธิอื่น ๆ ที่เกิดขึ้นในระหว่างการใช้บริการในสถานที่ให้บริการของ
					อัลฟ่า เฮลธ์ คลับ <br />
					3.5 สมาชิกจะไม่นำบุคคลที่อายุต่ากว่า 15 ปี ซึ่งไม่ได้รับอนุญาตให้เข้าใช้บริการในสถานที่ของ อัลฟ่า เฮลธ์ คลับ
				</ul>
				<p style={{ fontWeight: 'bold' }}>4. การขอยกเลิกสมาชิกภาพ </p>
				<ul>
					4.1 การยกเลิกสมาชิกภาพทุกประเภทสมาชิกสามารถทำได้ภายใน 3 วัน (สามวัน) นับจากวันที่สมัครเป็นสมาชิกเท่านั้น
					แต่ทางบริษัทขอสงวนสิทธิ์ที่จะไม่คืนค่าสมาชิกแก่ท่านสมาชิก สมาชิกจะต้องส่งคืนเอกสารเข้าเป็นสมาชิกทั้งหมด <br />
					4.2 สมาชิกภาพประเภทรายเดือนสามารถบอกยกเลิก สมาชิกภาพได้ต่อเมื่อ ครบกำหนดขั้นต่ำา
					(ตามประเภทสมาชิกภาพที่ท่านสมัคร) และ จะต้องดำเนินการแจ้งความต้องการตามวิธีการและขั้นตอนในการแจ้งยกเลิกสมาชิกภาพของ
					สมาชิก ตามข้อ 4.3 <br />
					4.3 วิธีการ และขั้นตอนในการแจ้งยกเลิกสมาชิกภาพประเภทรายเดือน มีดังต่อไปนี้ <br />
					<ul>
						4.3.1 สมาชิกจะต้องกรอกแบบฟอร์มการขอยกเลิกสมาชิกภาพของบริษัท เท่านั้น<br />
						4.3.2 สมาชิกจะต้องส่งแบบฟอร์มการขอยกเลิกสมาชิกภาพถึงบริษัท ล่วงหน้า 1 เดือน ก่อนถึงวันที่สมาชิกจะต้องชำระเงินค่าสมาชิก รายเดือน เพื่อให้มีผลเป็นการยกเลิก สมาชิกภาพในเดือนถัดไป โดยทางบริษัทจะดำเนินการเรียกเก็บค่าสมาชิกรายเดือน ๆ
						นั้นเป็นเดือนสุดท้าย ทั้งนี้ การนับวันแห่งการขอยกเลิกสมาชิกภาพจะคำนึงถึงวันที่บริษัท ได้รับทราบเป็นหลัก <br />
						4.3.3 ในกรณีที่สมาชิกขอยกเลิกสมาชิกภาพน้อยกว่า 1 เดือน ก่อนถึงกาหนดวันชำระเงินค่าสมาชิกในงวดถัดไปของสมาชิก
						สมาชิกจะถูกเรียกเก็บเงินค่าสมาชิกในงวดถัดไปอีก 1 เดือน <br />
						4.3.4 สมาชิกรับทราบว่า ในการยกเลิกสมาชิกภาพของสมาชิกนั้น ทางบริษัทจะไม่คืนเงิน ค่าสมาชิกแรกเข้า ค่าดำเนินการ ค่าสมาชิกที่ได้ชำระแล้วและภาษีมูลค่าเพิ่มให้กับทางสมาชิกแต่สมาชิกยังคงต้องรับผิดชอบในค่าสมาชิก ค่าบริการ ค่าธรรมเนียม และ/หรือเงินอื่นใดตามระยะเวลาการเป็นสมาชิกที่เหลืออยู่ / กรณี สมาชิกประสงค์จะยกเลิกสัญญาก่อนครบกำหนดสัญญาขั้นต่ำ
						สมาชิก ต้องรับผิดชอบในการชำระค่าสมาชิกรายเดือนให้ครบสัญญากำหนดขั้นต่ำของประเภทที่ท่านสมาชิกสมัคร
						สมาชิกจึงจะสามารถยกเลิกสมาชิกภาพได้
					</ul>
				</ul>
				<p style={{ fontWeight: 'bold' }}>5. สาเหตุที่ อัลฟ่า เฮลธ์ คลับ จะยกเลิกสมาชิกภาพของท่าน</p>
				<ul>
					บริษัท มีสิทธิที่จะบอกเลิกการเป็นสมาชิกภาพของสมาชิก หากตรวจพบว่า สมาชิกมีเหตุ หรือกระทำการใด ๆ  ดังต่อไปนี้<br />
					5.1 สมาชิกไม่ปฏิบัติตามสัญญาสมาชิกที่สมาชิกได้ลงนามไว้กับบริษัทกฎและระเบียบการใช้บริการ ของบริษัท <br />
					5.2 การกระทำการอันใดที่เป็นความผิดต่อกฎหมาย <br />
					5.3 มีพฤติกรรมรบกวน ก่อกวน และก่อความรำคาญ ต่อการใช้บริการของสมาชิกท่านอื่น <br />
					5.4 เป็นโรคติดต่อร้ายแรง เช่น โรคผิวหนังหรือโรคอื่น ๆ ตามที่บริษัทเห็นสมควร <br />
					5.5 มีอาการผิดปกติทางจิตซึ่งอาจก่อให้เกิดความเสียหายต่อบริษัทัหรืออาจก่อให้เกิดอันตรายแก่สมาชิกท่านอื่น <br />
				</ul>
				<p style={{ fontWeight: 'bold' }}>6. การหยุดพักสถานะเป็นสมาชิกชั่วคราว </p>
				<ul>
					6.1 บริษัทมีสิทธิหยุดพักสถานการณ์เป็นสมาชิกชั่วคราวของสมาชิกในกรณี
					ที่ไม่ปฏิบัติตามหลักเกณฑ์ที่กำหนดไว้ในระเบียบการของ อัลฟ่า เฮลธ์ คลับ <br />
					6.2 สมาชิกมีสิทธิหยุดพักสถานะชั่วคราวได้ตามเอกสารแนบท้ายสัญญาในหมวดสมาชิกภาพ ข้อ 9 <br />
				</ul>
				<p style={{ fontWeight: 'bold' }}>7. การโอนสิทธิในการเป็นสมาชิก </p>
				<ul>
					ห้ามมิให้โอนสิทธิการเป็นสมาชิก อัลฟ่า เฮลธ์ คลับ เว้นแต่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากบริษัท
					บริษัทขอสงวนสิทธิ์ในการโอนสิทธิ์ทุกกรณีให้อยู่ในดุลพินิจของบริษัท
				</ul>
				<p style={{ fontWeight: 'bold' }}>8. การชำระค่าสมาชิก</p>
				<ul>สำหรับสมาชิกประเภทรายเดือน หากชำระค่าสมาชิกรายเดือน ค่าธรรมเนียมอื่น ๆ ล่าช้ากว่าวันที่ระบุในสัญญา  บริษัทขอสงวนสิทธิ์ในการเรียกเก็บเบี้ยปรับรายวันสาหรับการชำระเงินล่าช้าในอัตราร้อยละ 1 ของค่าสมัครสมาชิกรายเดือน
				</ul>
				<p style={{ fontWeight: 'bold' }}>9. บัตรสมาชิก</p>
				<ul>
					กรณีบัตรสมาชิก เสียหาย ชำรุด หรือ สูญหายสมาชิก มีหน้าที่ ต้องติดต่อเพื่อขอออกใหม่ และจะต้องเสียค่าธรรมเนียมในการออกใหม่ตามอัตราที่ บริษัทกำหนด ณ วันที่สมาชิกติดต่อดำเนินการออกใหม่
					<p style={{ fontWeight: 'bold' }}>**บัตรสมาชิกสามารถเข้าใช้บริการเฉพาะผู้เป็นสมาชิกที่ลงนามในสัญญาการเข้าเป็นสมาชิกภาพเท่านั้น
						**</p>(บริษัทขอสงวนสิทธิ์ในการปฏิเสธการเข้าใช้บริการ
					และดำเนินคดีในกรณี ที่มีผู้ใดกระทำการละเมิดสิทธิของสมาชิก ผู้ลงนามในสัญญาการเข้าเป็นสมาชิกภาพ)

				</ul>
				<p style={{ fontWeight: 'bold' }}>10. สถานที่ให้บริการ </p>
				<ul>
					บริษัทขอสงวนสิทธิ์ ในการเปิดสถานที่ให้บริการใหม่ ย้ายสถานที่ตั้ง ปิดปรับปรุงการให้บริการบางส่วนของสถานที่ให้บริการในแต่ละสาขา หรือหากในกรณีที่เจ้าของอาคารได้ทำการปรับปรุง หรือเข้ามาถือสิทธิในสถานที่ให้บริการ ทางบริษัทจะแจ้งให้สมาชิกของแต่ละสาขาที่ให้บริการนั้น ๆ ทราบ ไม่น้อยกว่า 45 วัน เมื่อสมาชิกรับทราบแล้ว
					สมาชิกสามารถไปใช้บริการในสาขาอื่นได้ตามที่สมาชิกสะดวก <br />
				</ul>
				<p style={{ fontWeight: 'bold' }}>11. ตู้ล็อกเกอร์เก็บของ</p>
				<ul>
					บริษัทได้มีการจัดเตรียมตู้ล็อกเกอร์เก็บของไว้ให้บริการแก่สมาชิกทุกท่านโดยไม่เสียค่าบริการเพิ่ม ซึ่งตู้ล็อกเกอร์เก็บของนี้อนุญาตให้สมาชิกใช้ได้เฉพาะเวลาที่สมาชิกเข้ามาใช้บริการเท่านั้น และห้ามมิให้ใช้เก็บของข้ามคืนเด็ดขาดและทางบริษัทไม่แนะนำให้ท่านสมาชิกนำของมีค่ามาเก็บไว้ในตู้ล็อกเกอร์เก็บของบริษัท
					ขอสงวนสิทธิ์ในการที่จะรับผิดชอบหากเกิดความเสียหาย
				</ul>
				<p style={{ fontWeight: 'bold' }}>12. การบังคับใช้ของสัญญา</p>
				<ul>เอกสารแนบท้ายฉบับนี้เป็นส่วนหนึ่งของสัญญาการสมัครเข้าเป็นสมาชิกของบริษัทและให้นำกฎหมายแห่งราชอาณาจักรไทยบังคับใช้กับสัญญา </ul>
				<p style={{ fontWeight: 'bold' }}>13. การปฏิบัติตามกฎหมายและระเบียบการ</p>
				<ul>
					สมาชิกตกลงที่จะปฏิบัติตามและดำเนินการต่าง ๆ ที่เกี่ยวข้องกับการใช้สิทธิของการเป็นสมาชิกให้เป็นไปตามกฎหมาย
					และระเบียบการที่กำหนดให้ถูกต้องทุกประการ
					สมาชิกยินยอมที่จะชดใช้ค่าเสียหายทั้งทางตรงและทางอ้อมให้กับบุคคลที่ได้รับผลกระทบอันเนื่องมาจากการที่ไม่ปฏิบัติตามกฎหมายหรือระเบียบการ
				</ul>
				<p style={{ fontWeight: 'bold' }}>14. การแก้ไขเพิ่มเติมสัญญา</p>
				<ul>สัญญาฉบับนี้อาจแก้ไขเปลี่ยนแปลง เพิ่มเติมตีความหรือคู่สัญญาอาจผ่อนผันหรือสละสิทธิโดยการตกลงร่วมกันเป็นหนังสือลงลายมือชื่อของคู่สัญญาหรือผู้มีอำนาจกระทำการแทนของคู่สัญญาทั้งสองฝ่ายเท่านั้น<br />
				</ul>
				<p style={{ fontWeight: 'bold' }}>15. โมฆะแยกส่วน</p>
				<ul>ในกรณีที่ส่วนหนึ่งส่วนใดของสัญญาตกเป็นโมฆะ ให้ถือว่าคู่สัญญาเจตนาให้ส่วนที่ไม่เป็นโมฆะแยกออกจากส่วนที่เป็นโมฆะ<br />
				</ul>
				<p style={{ fontWeight: 'bold' }}>16. การโอนสิทธิเรียกร้อง</p>
				<ul>คู่สัญญาตกลงที่จะไม่โอนสิทธิหรือหน้าที่ใด ๆ ตามสัญญาฉบับนี้ให้บุคคลอื่น ๆ เว้นแต่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากคู่สัญญาอีกฝ่าย
				</ul>
				<p style={{ fontWeight: 'bold' }}>17. การติดต่อสื่อสารระหว่างบริษัทกับสมาชิก</p>
				<ul>
					ในกรณีที่สมาชิกมีความประสงค์ที่จะติดต่อสื่อสารหรือบอกกล่าวทวงถามกับบริษัท ให้ส่งโดยใช้ข้อมูลข้างล่างนี้
					<p style={{ fontWeight: 'bold' }}>บริษัท</p>
					ที่อยู่ บ้านเลขที่ 9/56 หมู่ที่ 5 ตำบล วิชิต อำเภอ เมือง จังหวัดภูเก็ต 83000 <br />
					โทรศัพท์ 076 612 655-6 <br />
					E-mail: alphafitnessphuekt@gmail.com
				</ul> <br/>
				<ul style={{ fontWeight: 'bold' }}>คู่สัญญาได้อ่านและเข้าใจข้อความในสัญญาฉบับนี้โดยตลอดแล้ว เห็นว่าข้อเท็จจริงและข้อตกลงตรงตามเจตนารมณ์ของคู่สัญญาทุกประการ
				</ul>

			</Content>
			</div>
    )
}