import {Divider, List, Space, Tag, theme} from "antd";
import React from "react";
import propTypes from "prop-types";
import TSListScroll from "../common/TSListScroll";
import { CheckCircleOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { URL_TRAINER, } from "../../constants/urls";

export default function UsageHistory(props) {
  const {
    token: { colorPrimary, colorTextSecondary }
  } = theme.useToken();

  const listRef = React.useRef(null)

  React.useEffect(() => {
    if (props.id != null && listRef.current) {
      listRef.current.reset()
      listRef.current.fetch()
    }
  }, [props.id])

  return (
    <TSListScroll
      ref={listRef}
      fetchParams={props.isMember ? {} : {member_id: props.id}}
      url={URL_TRAINER.MEMBER_ACTIVITY_HISTORY}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          style={{ marginTop: 4, marginBottom: 4 }}>
          <Space align={"center"} style={{ marginRight: 16 }}>
            <div style={{ textAlign: "center", width: 64 }}>
              {(item.type === "CHECK_IN")
                ? <div style={{ textAlign: 'center', color: colorPrimary, fontWeight: "bold" }}>
                  <CheckCircleOutlined style={{ fontSize: 30 }} />
                  <div style={{}}>Check-In</div>
                </div>
                : (item.type === "TRAINING")
                  ? <div style={{ textAlign: 'center', color: colorPrimary, fontWeight: "bold" }}>
                    <UserOutlined style={{ fontSize: 30 }} />
                    <div>Training</div>
                  </div>
                  : <div style={{ textAlign: 'center', color: colorPrimary, fontWeight: "bold" }}>
                    <TeamOutlined style={{ fontSize: 30 }} />
                    <div>Class</div>
                  </div>
              }
            </div>
            <Divider type="vertical" style={{ height: 80 }} />

            {/* Detail */}
            <div>
              <h4 style={{ marginTop: 0, marginBottom: 8 ,textAlign: "left"}}>
                {(item && item.display_name) ? item.display_name : "Unknown"}
                { item && item.is_overtime && (
                  <Tag color='#f50' style={{ marginLeft: '0.5rem' }}>Overtime</Tag>
                ) }
              </h4>
              <div style={{ color: colorTextSecondary, textAlign: "left" }}>
                {`Date: ${(item && item.date) ? item.date : '-'}`}
              </div>
              <div style={{ color: colorTextSecondary, textAlign: "left" }}>
                {`Time: ${(item && item.start_time && item.end_time) ? (`${item.start_time.substring(0, 5)} - ${item.end_time.substring(0, 5)}`) : '-'}`}
              </div>
            </div>
          </Space>
        </List.Item>
      )}
    />
  )
}

UsageHistory.defaultProps = {
  id: null,
  isMember: true,
}

UsageHistory.propTypes = {
  id: propTypes.string,
  isMember:  propTypes.bool,
}