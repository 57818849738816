import React from 'react'
import propTypes from "prop-types";
import { Header } from 'antd/es/layout/layout'
import { Image, theme } from 'antd';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import "./../../styles.css"
import { useNavigate } from 'react-router-dom';

export default function HeaderScreen(props) {
	const navigate = useNavigate();

	const {
    token: { colorPrimary },
  } = theme.useToken();

	const goBack = () => navigate(-1)
	const goClose = () => { window.close(); navigate(-1) }

	return (
		<Header 
			className={"shadow"} 
			style={{ 
				position: 'sticky', 
				top: 0, 
				zIndex: 1, 
				width: '100%', 
				background: colorPrimary, 
				color: "#fff", 
				fontWeight: "bold", 
				paddingInline: "28px" }}>

			<div style={{float: "left"}}>
				{props.showBackIcon && (
					<ArrowLeftOutlined style={{fontSize: 18}} onClick={props.onClick ? props.onClick : goBack} />
				)}

				{props.showCloseIcon && (
					<CloseOutlined style={{ fontSize: 18 }} onClick={props.onClick ? props.onClick : goClose} />
				)}
				
				<label style={{marginLeft: 16}}>{props.title}</label>
			</div>
			{props.element}
			
		</Header>
	)
}

HeaderScreen.defaultProps = {
	title: null,
	showBackIcon: true,
	showCloseIcon: false,
	element: null,
	onClick: undefined
}

HeaderScreen.propTypes = {
	title: propTypes.string,
	showBackIcon: propTypes.bool,
	showCloseIcon: propTypes.bool,
	element: propTypes.element,
	onClick: propTypes.func,
}