import React from 'react';
import propTypes from "prop-types";
import {
  Button, Form, Input, DatePicker,
  Card, Col, Row, theme, Checkbox, Alert, Select, Spin, message, Modal
} from 'antd';
import "./../../../styles.css"
import dayjs from 'dayjs';
import { EditOutlined, ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import InformationCardView from '../InformationCardView';
import Segment from '../../common/Segment';
import { GET, PATCH, POST } from '../../../frameworks/HttpClient';
import { URL_COMMON, URL_MEMBER } from '../../../constants/urls';
import { CHOICES_NAME_PREFIX, DATE_FORMAT } from '../../../constants/string';
import _ from "lodash";
import moment from 'moment';

const HeaderSegment = (props) => {
	return (
		<Segment 
			style={{padding: 0, backgroundColor: props.color, ...props.style}} 
			detail={<p style={{textAlign: "center", margin: 4, color: "#fff", fontWeight: "bold"}}>{props.title}</p>}/>
	)
}

export default function InformationView(props) {
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const [isLoading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	// Handle subdistrict
	const [subdistricts, setSubdistricts] = React.useState([]);
	const [warningPhoneMessages, setWarningPhoneMessages] = React.useState(null);
	const [warningEmailMessages, setWarningEMailMessages] = React.useState(null);
	const [debouncedCode, setDebouncedCode] = React.useState("");
	const [fetching, setFetching] = React.useState(false);
	// Handle Nationality
	const [nationFetching, setNationFetching] = React.useState(false);
	const [nationOptions, setNationOptions] = React.useState([]);
	const [searchNation, setSearchNation] = React.useState("");


	const {
    token: { colorPrimary, colorWarning },
  } = theme.useToken();

	// Update member info
	const handleUpdateInfo = async () => {
		setErrorMessages(null);
		setLoading(true);
		try {
			let data = await form.validateFields();
			data["birth_date"] = data.birth_date.format(DATE_FORMAT);
			// Handle nationality
			if (!(typeof data["nationality"] === 'string' || data["nationality"] instanceof String)) {
				data["nationality"] = data.nationality.value
			}
			if (!data.email) 
				delete data['email'];
			// Handle sub district
			if (data.sub_district)
				data['sub_district'] = data.sub_district.value;
			else 
				delete data['sub_district'];
			// Handle Invoice
			if (data.invoice_name && data.invoice_id && data.invoice_address) {
				data['invoice'] = {
					invoice_name: data.invoice_name,
					invoice_id: data.invoice_id,
					invoice_address: data.invoice_address,
					is_head_office: data.is_head_office,
					is_branch_office: data.is_branch_office
				}
				if (data['branch_name'])
					data['invoice']['branch_name'] = data.branch_name
			}
			
			const response = await PATCH(`${URL_MEMBER.MEMBER}${props.member.id}/`, data);
			message.success("Update profile successfully");
			props.onUpdateSuccess(response.data);
		} catch (error) {
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
			else
				setErrorMessages("Something wrong!");
		}

		finally {
			setLoading(false);
			window.scrollTo(0, 0);
		}
	}

	// Verify Phone
	const handleVerifyPhone = async () => {
		setErrorMessages(null);
		try {
			 await POST(`${URL_MEMBER.MEMBER}${props.member.id}/send-verify/`);
			message.success("Message sent successfully");
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const debounceNation = React.useCallback(
		_.debounce((_searchVal) => { setSearchNation(_searchVal) }, 800), 
		[]
	)

	// Search nationality
	const fetchNationality = async () => {
		setNationFetching(true);
		try {
			const response = await GET(`${URL_COMMON.NATIONALITY}?search=${searchNation}`);
			setNationOptions(response.data.results.map((item) => {
				return {
					...item,
					label: item.name,
					value: item.name
				}
			}))
		} catch (error) {
			console.log(error.errorMessages)
		} finally {
			setNationFetching(false);
		}
	}

	const debounceSubdistrict = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

	// Search address
	const fetchSubdistrict = async () => {
		setFetching(true);
		try {
			const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}/`)
			setSubdistricts(response.data.results.map((item) => {
				return {
					...item,
					label: item.display_name,
					value: item.subdistrict_id
				}
			}))
		} catch (error) {
			console.log(error.errorMessages)
		}

		finally {
			setFetching(false);
		}
	}

	const handleMobilePhoneChanged = async () => {
		const mobile_phone = form.getFieldValue('mobile_phone')
		if (mobile_phone == null) {
			return;
		}

		// Validate mobile phone
		try {
			setWarningPhoneMessages(null)
			setLoading(true);
			await GET(URL_MEMBER.VALIDATE_PHONE, { mobile_phone: mobile_phone })
		} catch (error) {
			modal.warning({
				title: 'Phone number already been used by',
				content: error.errorMessages,
			});
			setWarningPhoneMessages(error.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	const handleEmailChanged = async () => {
		const email = form.getFieldValue('email')
		if (email == null) {
			return;
		}

		// Validate Email
		try {
			setWarningEMailMessages(null)
			setLoading(true)
			await GET(URL_MEMBER.VALIDATE_EMAIL, { email: email })
		} catch (error) {
			modal.warning({
				title: 'Email already been used by',
				content: error.errorMessages,
			});
			setWarningEMailMessages(error.errorMessages)
		} finally {
			setLoading(false)
		}
	}

	const getAgeFromBirthday = (birthday) => {
    if(birthday) {
      var totalMonths = moment().diff(birthday, 'months');
      var years = parseInt(totalMonths / 12);
      var months = totalMonths % 12;
        if (months !== 0) {
					return `${years} Years ${months} Months`
				}
    	return years;
		}
    return null;
}

React.useEffect(() => {
	fetchNationality();
}, [searchNation])

	React.useEffect(() => {
		if (debouncedCode)
			fetchSubdistrict();
	}, [debouncedCode])

	// Initial Form
	React.useEffect(() => {
		if (props.member) {
			const data = props.member
			const targetSubDistrict = data.sub_district 
				? {value: data.sub_district, label: data.sub_district_detail}
				: null
			
			form.setFieldsValue({
				...data,
				birth_date: data.birth_date ? dayjs(data.birth_date, DATE_FORMAT) : null,
				sub_district: targetSubDistrict, 
				is_head_office: data.invoice ? data.invoice.is_head_office : false,
				is_branch_office: data.invoice ? data.invoice.is_branch_office : false,
				branch_name: data.invoice ? data.invoice.branch_name : null,
				invoice_name: data.invoice ? data.invoice.invoice_name : null,
				invoice_id: data.invoice ? data.invoice.invoice_id : null,
				invoice_address: data.invoice ? data.invoice.invoice_address : null,
			})
		}
		
	}, [props.member])

  return (
    <div>
			{errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginBottom: "12px", textAlign: "left"}}
				/>
			}
      <Row gutter={24}>
        <Col span={18} >
          <Card style={{
            textAlign: 'center',
          }} >
			  {contextHolder}
            <Form 
							style={{textAlign: "left"}}
							form={form}
							layout="vertical"
							autoComplete="off">
              <Row gutter={16} >
								{/* Member Info */}
								<Col span={24}>
									<HeaderSegment 
										style={{marginBottom: 16}}
										color={colorPrimary}
										title={"ข้อมูลสมาชิก / Member - Information"}/>
								</Col>
                <Col md={4} sm={24} xs={24}>
                  <Form.Item
										name={"code"}
										label="Member ID">
										<Input autoComplete="off"  disabled={true}/>
                  </Form.Item>
                </Col>
								<Col md={4} sm={24} xs={24}>
									<Form.Item
										label="Title"
										name="name_prefix"
										rules={[{ required: true }]}>
											<Select options={CHOICES_NAME_PREFIX}/>
									</Form.Item>
								</Col>
                <Col md={8} sm={24} xs={24}>
                  <Form.Item 
										name={"first_name"}
										label="ชื่อ / Name"
										rules={[{ required: true }]}>
										<Input autoComplete="off" />
                  </Form.Item>
                </Col>
								<Col md={8} sm={24} xs={24}>
									<Form.Item
										name={"last_name"}
										label="นามสกุล / Surname "
										rules={[{ required: true }]}>
										<Input autoComplete="off" />
									</Form.Item>
								</Col>

                <Col md={6} sm={12} xs={12}>
                  <Form.Item
										name={"birth_date"}
										label="วันเกิด / Birth Date"
										rules={[{ required: true }]}>
                    <DatePicker style={{ width: '100%', }} format={DATE_FORMAT}/>
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={12}>
									<Form.Item label="Age" style={{ color: 'white' }}>
										<Input disabled value={getAgeFromBirthday(moment(_.get(props, 'member.birth_date'), 'YYYY-MM-DD'))}/>
									</Form.Item>
								</Col>

								<Col md={12} sm={24} xs={24}>
                  <Form.Item 
										name={"mobile_phone"}
										label="เบอร์โทรศัพท์ / Mobile"
										rules={[{ required: true }]}
                    tooltip={(props.member && !props.member.is_mobile_phone_verified) ? {
											title: "The phone number has not been verified.",
                      icon: <ExclamationCircleFilled
                        style={{color: colorWarning}}
												onClick={handleVerifyPhone}
                      />
                    }: null}>
							<Input
								status={warningPhoneMessages && "warning"}
								prefix={warningPhoneMessages && <WarningOutlined />}
								autoComplete="off"
								type='tel'
								placeholder='Mobile Phone...'
								onBlur={handleMobilePhoneChanged} />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item 
										name={"nationality"}
										label="สัญชาติ / Nationality" >
										<Select
											placeholder="Name" 
											showSearch
											labelInValue
											filterOption={false}
											notFoundContent={nationFetching ? <Spin size="small" /> : null}
											onSearch={debounceNation}
											options={nationOptions} />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item 
										name={"id_card_code"}
										label="Card ID / Passport ID" >
										<Input autoComplete="off" />
                  </Form.Item>
                </Col>
                
                <Col md={12} sm={24} xs={24}>
                  <Form.Item 
										name={"email"}
										label="Email" >
							<Input
								status={warningEmailMessages && "warning"}
								prefix={warningEmailMessages && <WarningOutlined />}
								autoComplete="off"
								type='email'
								placeholder='Email...'
								onBlur={handleEmailChanged} />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item 
										name={"line_id"}
										label="Line ID">
										<Input autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col span={24} >
                  <Form.Item 
										name={"note"}
										label="หมายเหตุ / Note" >
                    <Input.TextArea />
                  </Form.Item>
                </Col>

								{/* Address */}
								<Col span={24}>
									<HeaderSegment 
										style={{marginBottom: 16}}
										color={colorPrimary}
										title={"ข้อมูลที่อยู่สามารถติดต่อได้ / Address"}/>
								</Col>
                <Col span={24}>
                  <Form.Item 
										name={"address"}
										label="ที่อยู่ปัจจุบัน / Address No." >
                    <Input.TextArea />
                  </Form.Item>

									<Form.Item 
										name={"sub_district"} 
										label="ตำบล - อำเภอ - จังหวัด / Subdistrict - District - Province">
										<Select 
											showSearch
											labelInValue
											filterOption={false}
											notFoundContent={fetching ? <Spin size="small" /> : null}
											onSearch={debounceSubdistrict}
											options={subdistricts}
											/>
									</Form.Item>
                </Col>

								{/* Tax-Address */}
								<Col span={24}>
									<HeaderSegment 
										style={{marginBottom: 16}}
										color={colorPrimary}
										title={"ที่อยู่สำหรับออกใบกำกับภาษี / Tax Invoice Address"}/>
								</Col>
								<Col md={8} sm={24} xs={24} >
									<Form.Item name="is_head_office" valuePropName="checked">
										<Checkbox> สำนักงานใหญ่ / Head Office</Checkbox>
									</Form.Item>
								</Col>
								<Col md={6} sm={24} xs={24}>
									<Form.Item name="is_branch_office" valuePropName="checked">
										<Checkbox>สาขา / Branch</Checkbox>
									</Form.Item>
								</Col>
								<Col md={10} sm={24} xs={24} style={{ marginBottom: "auto" }}>
									<Form.Item 
										name="branch_name">
										<Input autoComplete="off" placeholder='Branch Name...' />
									</Form.Item>
								</Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item 
										name={"invoice_name"}
										label="ชื่อ / Name">
										<Input autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item 
										name={"invoice_id"}
										label="เลขประจำตัวผู้เสียภาษี / Tax ID">
										<Input autoComplete="off" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item 
										name={"invoice_address"}
										label="ที่อยู่ / Address" >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
            
            </Form>
            <Button
							loading={isLoading}
              style={{backgroundColor: colorWarning}}
              type={"primary"}
              shape={"round"}
							onClick={handleUpdateInfo}><EditOutlined />{"Update"}
            </Button>
          </Card>
        </Col>

        <Col 
					span={6}
          style={{textAlign: 'center'}}>
          <InformationCardView member={props.member} onUpdated={props.onUpdateSuccess}/>
        </Col>
      </Row>
    </div>

  )
}

InformationView.defaultProps = {
	member: null,
	onUpdateSuccess: (data) => {}
}
InformationView.propTypes = {
	member: propTypes.object,
	onUpdateSuccess: propTypes.func
}