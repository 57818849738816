import React from "react"
import propTypes from "prop-types";
import { Alert, Card, Col, Form, Input, Row, Select, Spin, theme } from "antd"
import SegmentHeader from "../common/SegmentHeader";
import "./../../styles.css"
import { currencyFormat } from "../../frameworks/Util";
import * as _ from 'lodash';
import { PAYMENT_TYPE, getPaymentOptions } from "../../constants/string";


const MethodDetailCard = ({data, onDelete}) => {
	const {
		token: { colorBorderSecondary }
	} = theme.useToken();

	return (
		<Card
			className="custom-card-actions-red custom-card-actions-text"
			size="small"
			style={{textAlign: "center", background: colorBorderSecondary}}
			actions={[<p style={{margin: 0, fontSize: 12}} onClick={onDelete}>{"Delete"}</p>]}>
			<p style={{fontWeight: "bold"}}>{data.title}</p>
			<p style={{margin: 0}}>{`${currencyFormat(data.amount)} Baht`}</p>
		</Card>
	)
}

const typeOptions = getPaymentOptions(true);

export default function PaymentMethodSegment(props) {
	const [form] = Form.useForm();
	const [typeSelected, setTypeSelected] = React.useState(null);
	const [methods, setMethods] = React.useState([]);
	const [trigger, setTrigger] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const amountInputRef = React.createRef();

	// Add payment method
	const handleAdd = async () => {
		let data = await form.validateFields();
		// TODO: check total amount before add
		setLoading(true);

		// Add payment method
		setMethods([
			...methods,
			{ 
				...data, 
				amount: parseFloat(data.amount),
				title: typeSelected ? typeSelected.label : "Unknown" 
			},
		]);
		setLoading(false);
		// Clear form
		form.resetFields();
		setTypeSelected(null);
	}

	// Delete payment method
	const handleDelete = (index) => {
		let temp = methods
		temp.splice(index, 1);
		setMethods(temp);
		setTrigger(!trigger);
	}

	const recalculatePayment = () => {
		const total = methods.reduce((sum, item) => sum + item.amount, 0);
		props.onPaymentMethodChange(total, methods);
	}

	const checkAmount = (_, value) => {
    if (value && value <= 0) {
			return Promise.reject(new Error('Amount must be greater than zero!'));
		}
		return Promise.resolve();
    
  };

	React.useEffect(() => {
		recalculatePayment();

	}, [methods, trigger])

	React.useEffect(() => {
		if (!props.open) {
			setErrorMessages(null);
			setTypeSelected(null);
			setMethods([]);
			form.resetFields();
		}
		
	}, [props.open]);

	React.useEffect(() => {
		if (typeSelected) {
			if (amountInputRef.current) {
				amountInputRef.current.focus();
			}
			form.setFieldValue('amount', _.get(props, 'balanceAmount', 0) || 0)
		}
	}, [typeSelected])

	return (
		<SegmentHeader 
			style={props.style}
			header={"วิธีการชำระเงิน / Payment Method"}
			content={
				<div>
					{errorMessages && 
						<Alert
							message={errorMessages}
							type="error"
							showIcon
							style={{marginBottom: "8px", textAlign: "left"}}/>
					}
				
					<Row gutter={[16, 16]}>
						<Col md={8} sm={24} xs={24}>
							<Spin spinning={loading}>
							<Card
								size="small"
								className="custom-card-actions-blue custom-card-actions-text"
								actions={[<p style={{margin: 0, fontSize: 12}} onClick={handleAdd}>{"Add"}</p>]}>
								<Form 
									form={form}
									labelCol={{span: 8}} 
									wrapperCol={{span: 16}}>
									<Form.Item 
										style={{marginBottom: 16}}
										rules={[{ required: true }]}
										name={"type"} 
										label={"Type"}>
										<Select options={typeOptions} onChange={(value, item) => setTypeSelected(item)}/>
									</Form.Item>

									{typeSelected && (
										<Form.Item
											style={{marginBottom: (typeSelected.value === PAYMENT_TYPE.CREDIT) ? 16 : 0}}
											rules={[
												{ required: true },
												{ validator: checkAmount }
											]}
											name={"amount"} 
											label={"Amount"}>
											<Input ref={amountInputRef} autoComplete="off" type={"number"} onPressEnter={handleAdd}/>
										</Form.Item>
									)}
								</Form>
							</Card>
							</Spin>
						</Col>

						{methods.map((item, index) => {
							return (
								<Col key={index} md={4} sm={24} xs={24}>
									<MethodDetailCard 
										data={item}
										onDelete={() => handleDelete(index)}/>
								</Col>
							)
						})}
						
					</Row>
				</div>
			}/>
	)
}

PaymentMethodSegment.defaultProps = {
	style: {},
	open: false,
	onPaymentMethodChange: (amount, list) => {},
}

PaymentMethodSegment.propTypes = {
	style: propTypes.object,
	open: propTypes.bool,
	onPaymentMethodChange: propTypes.func,
}