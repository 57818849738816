import { React, useState } from "react";
import propTypes from "prop-types";
import { theme, Spin } from "antd";
import "./../../styles.css"
import {
    EyeFilled,
} from "@ant-design/icons";
import ModalSummary from "./ModalSummary";

export default function CardSummary(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handle when ok button pressed
    const onOk = () => {
        setIsModalOpen(false);
    };

    const {
        token: { colorPrimary },
    } = theme.useToken();

    return (
        <Spin tip="Loading" size="small" spinning={props.loading}>
            < div
                className="section-wrapper "
                style={{
                    background: colorPrimary,
                    color: "white",
                    textAlign: "center",
                }}>
                <div align='right' style={{ fontSize: 20 }} >
                    <EyeFilled onClick={() => setIsModalOpen(true)} />
                </div>
                <p style={{ fontSize: 24, marginTop: 0 }}>{props.amount}</p>
                <p style={{ marginBottom: 0 }}>{props.subContent}</p>
                <p style={{ fontWeight: "bold", marginTop: 0 }}>{props.content}</p>

                <ModalSummary
                    open={isModalOpen}
                    onOk={onOk}
                    onCancel={() => setIsModalOpen(false)}
                    title={props.content}
                    url={props.url}
                    hideCheckOut={props.content == "Working" ? true : false}/>
            </div>
        </Spin>
    );
}


CardSummary.defaultProps = {
    amount: 0,
    subContent: " ",
    content: "",
	onClick: () => {},
	url: "",
    loading: false,
}

CardSummary.propTypes = {
    amount: propTypes.number,
    subContent: propTypes.string,
    content: propTypes.string,
    onClick: propTypes.func,
	url: propTypes.string,
    loading: propTypes.bool,
}