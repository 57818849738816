import React from 'react'
import { Card , Button, Modal, Space, Image } from 'antd';
import img_menu from '../CssScreen/cover.png'
import './CardMenu.css';
import PurchaseKioskModal from './PurchaseKioskModal';
import _ from 'lodash';
import Typography from 'antd/es/typography/Typography';
import { useTranslation } from 'react-i18next';

const { Meta } = Card;
const { Text }  = Typography ;

const buttonStyle = {
    width: 186,
    height: 40,
    top: 12,
    aling : 'center',
    marginBottom : 15,
}

export default function CardMenu(props) {
  const { data } = props;
  const [openPurchaseKioskModal, setOpenPurchaseKioskModal] = React.useState(false);
  const packageImage = _.get(data, 'image', null)
  const { t } = useTranslation();
  const { languageAdd } = props;

  return (
    <Card
      hoverable
      style={{ width: 'auto', margin : 25, minHeight: 360, paddingTop : 0, width: 246,}}
      cover={
        <Image style={{ objectFit: 'cover' }} src={packageImage ? packageImage : img_menu} height={230} preview={false}/>
      }
    >
    <Meta 
    title={<Text style={{fontSize:'20px'}}>{_.get(data, 'name', '-')}</Text> }
    description={<div style={{ height: '55px', overflow: 'hidden', textOverflow: 'ellipsis' , fontSize:'18px' }}>{_.get(data,languageAdd, '-')}</div>}
  />
    <Button className='buttoncalsspackages' style={buttonStyle} onClick={() => setOpenPurchaseKioskModal(true)}> {t("buy_now")}</Button>

    <PurchaseKioskModal
      open={openPurchaseKioskModal}
      packageData={data}
      languageUse={languageAdd}
      onClose={() => 
        setOpenPurchaseKioskModal(false)
      }
      target={null}
    />

    </Card>
  )
}
