import React from "react";
import { theme, Alert, Button, notification } from "antd";
import Lottie from "lottie-react";
import lottieComingsoon from "../../assets/lottie_coming_soon.json"
import Title from "antd/es/typography/Title";
import "../../styles.css";
import useUserData from "../../hooks/useUserData";
import { GET } from "../../frameworks/HttpClient";
import { URL_MEMBER } from "../../constants/urls";
import { useNavigate } from "react-router-dom";
import { useLiff } from "react-liff";
import { PATH } from "../../routes/CustomRoutes";
import { COOKIE_AUTH } from "../../constants/cookies";
import Cookies from "js-cookie";
import LogoutModal from "../../components/user/LogoutModal";

export default function ComingSoonScreen() {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const { user } = useUserData();
    const [showLogoutConfirm, setShowLogoutConfirm] = React.useState(false)
    const { error, isLoggedIn, isReady, liff } = useLiff();
    const [errorMessages, setErrorMessages] = React.useState(null);
    const [member, setMember] = React.useState(null);
    
    const {
        token: { colorPrimary },
      } = theme.useToken();

    const fetchMember = async (id) => {
        if (!id) {
            setErrorMessages("Member not found!");
            return;
        }

        setErrorMessages(null);
        try {
            const response = await GET(`${URL_MEMBER.MEMBER}${id}`);
            const myMember = response.data
            setMember(myMember);
            // Clear token
            if (!myMember || !myMember.code)
                logout();
            
        } catch (error) {
            if (error.status === 401) {
                logout();
            } else {
                setErrorMessages(error.errorMessages);
            }
        }
    }

    const logout = () => {
        try {
            // Clear data
            Cookies.remove(COOKIE_AUTH.TOKEN);
            if (user && user.member)
                user.removeUserData();
        } catch (error) {}
        
        // Find next path
        let page = PATH.LOGIN;
        if (liff && liff.isLoggedIn()) 
            page = PATH.LIFF_LANDING;
        
        navigate(page);
    }

    React.useEffect(() => {
        if (user && user.member) {
            fetchMember(user.member);
        }
    }, [user.member]);

    return (
        <div className='center-screen'>
            {contextHolder}
            <div>
                {errorMessages &&
                    <Alert
                        message={errorMessages}
                        type="error"
                        showIcon
                        style={{ marginBottom: 12, textAlign: "left" }}/>
                }
                <Title level={3} style={{color: colorPrimary}}>
                    {(member && member.full_name) ? member.full_name : null}
                </Title>
                <Title level={5} style={{color: colorPrimary, marginTop: 0}} onClick={() => {
                    if (`(${(member && member.code) ? member.code : null})`==='(null)') {
                        setShowLogoutConfirm(true)
                    }
                }}>
                    {`(${(member && member.code) ? member.code : null})`}    
                </Title>
                <Lottie 
                    style={{width: 240, margin: "auto"}}
                    animationData={lottieComingsoon} />
                <Title level={2} style={{color: colorPrimary}}>Coming Soon...</Title>
                <Title level={5} style={{color: colorPrimary, marginTop: 0}}>August 1, 2023</Title>

                <LogoutModal
                    isLiff={(liff && liff.isLoggedIn()) ? true : false}
                    open={showLogoutConfirm}
                    onClose={() => setShowLogoutConfirm(false)} />

                <Button 
                    type={"primary"}
                    onClick={() => window.location.reload(true)}>
                        {"Refresh"}
                </Button>
            </div>
        </div>
    )
}