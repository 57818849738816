import React from 'react'
import propTypes from "prop-types";
import { Alert, Modal, QRCode } from "antd";
import Header from '../common/Header';


export default function QRCodeModal(props) {

  React.useEffect(() => {
  }, [props.open])

  return (
    <Modal
      centered={true}
      open={props.open}
      footer={<></>}
      onOk={props.onClose}
      onCancel={props.onClose}>
      <Header title={"Line Login QR Code"} />
      
      {props.memberId === null &&
        <Alert
          message="No Member ID"
          type="error"
          showIcon
          style={{ marginTop: 16, textAlign: "left" }}
        />
      }
      {props.memberId != null && 
        <QRCode 
          style={{margin: "auto", marginTop: 24}}
          value={props.memberId} />
      }
    </Modal>
  )
}
QRCodeModal.defaultProps = {
  open: false,
  memberId: null,
  onClose: () => { },
}

QRCodeModal.propTypes = {
  open: propTypes.bool,
  memberId: propTypes.string,
  onClose: propTypes.func,
}