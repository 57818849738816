import React from 'react'
import propTypes from "prop-types";
import { DollarOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons'
import { Alert, Button, Col, Input, Layout, Row, theme } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Header from '../../common/Header'
import TSTable from '../../common/TSTable'
import PaymentDrawer from '../../payment/PaymentDrawer'
import "./../../../styles.css"
import { URL_SALE } from '../../../constants/urls';
import { DELETE, GET, POST } from '../../../frameworks/HttpClient';
import { currencyFormat } from '../../../frameworks/Util';

export default function CartView(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [openPayment, setOpenPayment] = React.useState(false);
	const [data, setData] = React.useState(null);
	const [cartItems, setCartItems] = React.useState([]);

	const {
		token: { colorSuccessText, colorErrorText, colorBorder, colorInfoTextHover }
	} = theme.useToken();

	const columns = [
    {
      title: 'No.',
    	dataIndex: 'index',
			width: 80,
    },
    {
      title: 'Package Code',
    	dataIndex: 'code',
			width: 160
    },
    {
      title: 'Description',
    	dataIndex: 'item_detail',
    },
    {
      title: 'Qty',
    	dataIndex: 'amount',
			width: 160,
			render: (value, original) => (
				<div style={{textAlign: "center"}}>
					{!original.stackable && (<label>{value}</label>)}

					{original.stackable && (
						<Input.Group compact style={{textAlign: "center"}}>
							<Button 
								style={{ marginRight: 0, width: "25%"}}
								icon={<MinusCircleTwoTone twoToneColor={colorErrorText}/>}
								onClick={() => minusQuantity(original)}/>
							<Input 
								style={{
									borderLeft: 0,
									textAlign: "center",
									width: "50%",
									pointerEvents: "none",
								}}
								autoComplete="off"
								value={value} />
							<Button
								style={{ marginRight: 0, width: "25%"}}
								icon={<PlusCircleTwoTone twoToneColor={colorSuccessText}/>}
								onClick={() => addQuantity(original)}/>
						</Input.Group>
					)}
				</div>
			)
    },
    {
      title: 'Amount',
    	dataIndex: 'price',
			width: 160,
			render: (value) => (<div style={{textAlign: "right"}}>{currencyFormat(value)}</div>)
    },
    {
      title: 'Action',
    	dataIndex: '',
			width: 90,
			render: (value, original) => (
				<Button 
					type={"primary"}
					onClick={() => deleteCartItem(original)}>
						{"Cancel"}
				</Button>
			)
    }
	];

	const minusQuantity = (data) => {
		const value = data.amount - 1;
		if (value > 0)
			updateQuantity(data, value);
	}

	const addQuantity = (data) => {
		updateQuantity(data, data.amount + 1);
	}

	// Update Amount Item
	const updateQuantity = async (item, value) => {
		const data = { amount: value }
		try {
			await POST(`${URL_SALE.CART_ITEM}${item.id}/update-amount/`, data);
			fetchData();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Delete Item
	const deleteCartItem = async (item) => {
		setErrorMessages(null);
		try {
			// Delete cart item
			await DELETE(`${URL_SALE.CART_ITEM}${item.id}/`);
			fetchData();
			props.onRefreshMenu();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Load Cart Detail
	const fetchData = async () => {
		if (!props.member) {
			setErrorMessages("Member not found!");
			return;
		}

		try {
			const response = await GET(`${URL_SALE.CART_MEMBER}${props.member.id}/`);
			setData(response.data);
			// initial cart item
			let items = [];
			if (response.data && response.data.items) {
				items = response.data.items.map((item, index) => ({ index: index + 1, key: item.id, ...item }));
			}
			setCartItems(items);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.member && !openPayment)
			fetchData();
		
	}, [props.member, openPayment]);

	return (
		<Layout>
			<Content>
				<Header title={"ตะกร้าสินค้า / Cart"}/>

				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{
							marginTop: "12px",
							textAlign: "left"}}/>
				}

				<TSTable 
					style={{marginTop: 16}}
					columns={columns}
					url={""}
					rowKey={"id"}
					size={"small"}
					data={cartItems}
					onRow={(record, rowIndex) => ({})}
				/>
			</Content>

			<div style={{position: "absolute", bottom: 24, width: "95%"}}>
				<Row justify={"end"} gutter={24} style={{margin: 0, padding: 0}}>
					<Col md={8} sm={24} xs={24}>
						<Input 
							autoComplete="off"
							className={"custom-addon"}
							style={{textAlign: "right"}}
							addonBefore={"Total Amount:"}
							addonAfter={"Baht"}
							value={data ? currencyFormat(data.net_price) : 0}/>
					</Col>
					<Col md={4} sm={24} xs={24}>
						<Button 
							style={{background: (cartItems && cartItems.length > 0) ? colorInfoTextHover : colorBorder, width: "100%"}}
							type="primary"
							disabled={(cartItems && cartItems.length > 0) ? false : true}
							onClick={() => setOpenPayment(true)}
							icon={<DollarOutlined />}>{"Payment"}
						</Button>
					</Col>
				</Row>
			</div>
			
			<PaymentDrawer 
				cart={data}
				member={props.member}
				open={openPayment}
				onClose={() => setOpenPayment(false)}
				onSuccess={() => {
					setOpenPayment(false);
					fetchData();
					props.onRefreshMenu();
				}}/>
		</Layout>
	)
}

CartView.defaultProps = {
	member: null,
	onRefreshMenu: () => {}
}

CartView.propTypes = {
	member: propTypes.object,
	onRefreshMenu: propTypes.func
}