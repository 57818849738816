import React from 'react';
import { Layout, Row, Col, Button, Typography, Image } from 'antd';
import { QrcodeOutlined, CreditCardOutlined, WalletOutlined } from '@ant-design/icons';
import { useNavigate, useParams  } from "react-router-dom";
import * as _ from 'lodash';
import './ChoosePaymentOptionFooter.css'
import { PATH } from '../../../routes/CustomRoutes';
import { PAYMENT_TYPE } from '../../../constants/string';
import thaiqr from '../../../assets/thaiqr02.png';
import wechat from '../../../assets/wechat.jpg';
import alipay from '../../../assets/alipay.png';
import dolfin from '../../../assets/dolfin.jpeg';
import creditCard from '../../../assets/creditcard.png';
import creditqr from '../../../assets/creditqr.png';
import wallet from '../../../assets/wallet.png';
import { useTranslation } from 'react-i18next';

function ChoosePaymentOptionFooter(props) {
  const { Text }  = Typography ;
  const { Header, Footer, Sider, Content } = Layout;
  const navigate = useNavigate();
  const { member,  } = props;
  const params = useParams();
  const { t } = useTranslation();

  const goToWaitingPurchesPage = (payType) => {
    navigate(`${PATH.KIOSK_WAITING_PAYMENT}/${payType}/${params.packageId}/${params.type}`, { state: { discount: props.discount, amount: props.amount } })
  }

  const buttonPaymentStyle = { 
    whiteSpace: 'normal', 
    wordWrap: 'break-word', 
    fontSize: '18px',  
    height : 170,
    width : 182,
  }

  return (
    <Layout style={{backgroundColor:'#F3F3F3' }} className='laoutPayment'>
      <Row justify="center" style={{ marginBottom: '20px' }}>
        <Text style={{ fontSize: '30px', padding: '20px', margin : 20, marginBottom : 15 }} className='TextChoosePaymentOption'>{t("choose_payment_options")}</Text>
      </Row>

      <Row justify="center" gutter={[32, 32]} style={{ paddingBottom:'50px'}}>
        <Col style={{ textAlign: 'right' }}>
          <Button className='kiosk-payment-button' type="dashed" style={buttonPaymentStyle} onClick={() => goToWaitingPurchesPage(PAYMENT_TYPE.TRANSFER)}>
              <Image
                width={150}
                height={150}
                src={thaiqr}
                preview={false}
              />
          </Button>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button className='kiosk-payment-button' type="dashed" style={buttonPaymentStyle} onClick={() => goToWaitingPurchesPage(PAYMENT_TYPE.CREDIT)}>
              <Image
                width={120}
                height={120}
                src={creditCard}
                preview={false}
              />
              <div style={{ fontWeight : 'bolder', fontSize : 20 }}>Credit Card</div>
          </Button>
        </Col>


        {/* <Col style={{ textAlign: 'right' }}>

          <Button className='kiosk-payment-button' type="dashed" style={buttonPaymentStyle} onClick={() => null}>
              <Image
                width={100}
                height={100}
                src={wallet}
                preview={false}
              />
              <div style={{ fontWeight : 'bolder', fontSize : 20, marginTop : 20}}>Other Wallet</div>
          </Button>
        </Col>

        <Col style={{ textAlign: 'right' }}>
             <Button className='kiosk-payment-button' type="dashed" style={buttonPaymentStyle} onClick={() => null}>
              <Image
                width={80}
                height={80}
                src={creditqr}
                preview={false}
                style={{marginTop : 20}}
              />
              <div style={{ fontWeight : 'bolder', fontSize : 17, marginTop : 38, }}>Credit Card QR</div>
          </Button>
        </Col> */}
      </Row>
      <Row justify="center" gutter={[32, 32]} style={{ paddingBottom:'50px'}}>
      <Col style={{ textAlign: 'right' }}>
          <Button className='kiosk-payment-button' type="dashed" style={buttonPaymentStyle} onClick={() => goToWaitingPurchesPage(PAYMENT_TYPE.ALIPAY)}>
              <Image
                width={160}
                height={160}
                src={alipay}
                preview={false}
              />
          </Button>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button className='kiosk-payment-button' type="dashed" style={buttonPaymentStyle} onClick={() => goToWaitingPurchesPage(PAYMENT_TYPE.DOLFIN)}>
              <Image
                width={150}
                height={150}
                src={dolfin}
                preview={false}
              />
          </Button>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button className='kiosk-payment-button' type="dashed" style={buttonPaymentStyle} onClick={() => goToWaitingPurchesPage(PAYMENT_TYPE.WECHAT)}>
              <Image
                width={150}
                height={145}
                src={wechat}
                preview={false}
              />
          </Button>
        </Col>
      </Row>
    </Layout>
  );
}

export default ChoosePaymentOptionFooter;
