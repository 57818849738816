import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, DatePicker, Form, Input, message, Modal, Popconfirm, Select, theme, TimePicker } from 'antd';
import dayjs from 'dayjs';
import Header from '../common/Header';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/string';
import { URL_TRAINER } from '../../constants/urls';
import { DELETE, GET, PATCH } from '../../frameworks/HttpClient';

export default function ClassDetailModal(props) {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [isLoadingDelete, setLoadingDelete] = React.useState(false);
  const [isLoadingUpdate, setLoadingUpdate] = React.useState(false);
  // Handle Options
  const [classOptions, setClassOptions] = React.useState([]);
  const [studioOptions, setStudioOptions] = React.useState([]);
  const [trainerOptions, setTrainerOptions] = React.useState([]);

  const {
    token: { colorPrimary, colorWarning },
  } = theme.useToken();

  // Handle date picker
  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };

  // Update Class
  const handleUpdate = async () => {
    if (!props.data || !props.data.id) {
      setErrorMessages("Class not found!")
      return;
    }

    setErrorMessages(null);
    setLoadingUpdate(true);
    // Prepare data
    let data = await form.validateFields();
    data["date"] = data.date.format(DATE_FORMAT);
    data["start_time"] = data.time[0].format(TIME_FORMAT);
    data["end_time"] = data.time[1].format(TIME_FORMAT);
    try {
      await PATCH(`${URL_TRAINER.TRAINER_CLASS}${props.data.id}/`, data);
      message.success("Class Updated!");
      props.onUpdated();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { 
      setLoadingUpdate(false) 
    }
  }

  // Delete PT Class
  const handleDelete = async () => {
    if (!props.data || !props.data.id) {
      setErrorMessages("Class not found!")
      return;
    }

    setErrorMessages(null)
    setLoadingDelete(true);
    try {
      await DELETE(`${URL_TRAINER.TRAINER_CLASS}${props.data.id}/`);
      message.success("Class Deleted!");
      props.onDelete();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { 
      setLoadingDelete(false) 
    }
  }

  // Fetch Class Type
  const fetchClass = async () => {
    try {
      const response = await GET(`${URL_TRAINER.CLASS_TYPE}?page_size=999`);
      setClassOptions(response.data.results.map(item => (
        { ...item, value: item.id, label: item.name }
      )));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }
  // Fetch PT
  const fetchTrainer = async () => {
    try {
      const response = await GET(`${URL_TRAINER.TRAINERS}?page_size=999`);
      setTrainerOptions(response.data.results.map(item => (
        { ...item, value: item.id, label: item.full_name }
      )));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }
  // Fetch Studio
  const fetchStudio = async () => {
    try {
      const response = await GET(`${URL_TRAINER.STUDIO}?page_size=999`);
      setStudioOptions(response.data.results.map(item => (
        { ...item, value: item.id, label: item.name }
      )));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  };

  React.useEffect(() => {
    if (props.open) {
      // Fetch options
      fetchClass();
      fetchTrainer();
      fetchStudio();
      // Set form
      if (props.data) {
        const data = props.data
        form.setFieldsValue({
          ...data,
          time: data ? [dayjs(data.start_time, TIME_FORMAT), dayjs(data.end_time, TIME_FORMAT)] : null,
          date: data.date ? dayjs(data.date, DATE_FORMAT) : null
        });
      }
    } else {
      form.resetFields();
      setErrorMessages(null);
    }
  }, [props.open, props.data]);

  return (
    <Modal
      open={props.open}
      onCancel={props.onClose}
      footer={[
        <Button
          onClick={props.onClose}
          style={{ color: 'GrayText' }}> 
            Cancel
        </Button>,

        <Popconfirm
          title="Do you want to delete this class?"
          onConfirm={handleDelete}
          onCancel={() => null}
          okText="Yes"
          cancelText="No">
          <Button
            type='primary'
            loading={isLoadingDelete}
            style={{ background: colorPrimary}}> 
              Delete
          </Button>
        </Popconfirm>,

        <Button
          type='primary'
          loading={isLoadingUpdate}
          onClick={handleUpdate}
          style={{ background: colorWarning }}> 
            Update 
        </Button>
      ]}>

      <Header title={"PT Class"} />
      {errorMessages && (
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginTop: "12px", textAlign: "left" }} />
      )}

      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
        <Form.Item
          name={"studio"}
          label={"Studio "}
          rules={[{ required: true }]}>
          <Select
            options={studioOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>

        <Form.Item
          name={"class_type"}
          label={"Class"}
          rules={[{ required: true }]}>
          <Select
            options={classOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>

        <Form.Item
          name={"trainer"}
          label={"PT"}
          rules={[{ required: true }]}>
          <Select
            options={trainerOptions}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>

        <Form.Item
          name={"members_limit"}
          label={"Limit"}
          rules={[{ required: true }]}>
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          name={"time"}
          label={"Class Time "}
          rules={[{ required: true }]}>
          <TimePicker.RangePicker
            format={TIME_FORMAT}
            inputReadOnly={true}
            style={{ width: '100%' }}/>
        </Form.Item>

        <Form.Item
          name={"date"}
          label={"Date"}
          rules={[{ required: true }]}>
          <DatePicker
            format={DATE_FORMAT}
            inputReadOnly={true}
            disabledDate={disabledDate}
            style={{ width: '100%' }}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

ClassDetailModal.defaultProps = {
  data: null,
  open: false,
  onClose: () => {},
  onDelete: () => {},
  onUpdated: () => {},
}

ClassDetailModal.propTypes = {
  data: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
  onDelete: propTypes.func,
  onUpdated: propTypes.func,
}