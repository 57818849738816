export const DATE_FORMAT = "YYYY-MM-DD"
export const HUMAN_DATE_FORMAT = "DD/MM/YYYY"
export const TIME_FORMAT = "HH:mm"
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm"
export const MONTH_FORMAT = "YYYY-MM"

export const SECONDARY_SCREEN_NAME = "ahc-secondary-screen";

export const USER_GROUP = {
	RECEPTION: "reception",
	SALE: "sale",
	ACCOUNTANT: "accountant",
	PT: "pt",
	PT_MANAGER: "pt_manager",
	PT_FREELANCER: "pt_freelancer",
	SALE_MANAGER: "sale_manager",
	CLASS_PARTNER: "class_partner",
}

export const CHOICES_NAME_PREFIX = [
	{ value: 'MR', label: 'Mr' },
	{ value: 'MRS', label: 'Mrs.' },
	{ value: 'MISS', label: 'Miss' },
	{ value: 'BOY', label: 'Boy' },
	{ value: 'GIRL', label: 'Girl' },
]

export const CHOICES_CARD_TYPE = [
	{ value: 'ID_CARD', label: 'ID CARD' },
	{ value: 'PASSPORT', label: 'Passport' },
]

export const REPORT_FILTER = {
	DATE_RANGE: "date_range",
	DATE: "date",
	MEMBER: "member",
	PACKAGE_STATUS: "status",
	GENDER: "gender",
	AGE: "age",
	NATIONALITY: "nationality",
	PT: "pt",
	MONTH: "month",
	PAYMENT_STATUS: "payment_status",
	REWARD_TYPE: "type",
	SALE: "sale",
	CASHIER: "cashier",
	ABSENT_DAY: "absent_day",
	EXPRIRING_DAY: "expiring",
}

export const TRAINER_TYPE = {
	PT: "PT",
	SENIOR_PT: "SENIOR_PT",
	MASTER_PT: "MASTER_PT",
	GRANDMASTER_PT: "GRANDMASTER_PT"
}

export const CHANNEL = {
	NAME: "ahc-channel",
	REQUEST_MANUAL_INPUT: "request-manual-input",
	REQUEST_CHECK_IN: "request-check-in",
	RESPONSE_CHECK_IN: "response-check-in",
	REQUEST_CHECK_OUT: "request-check-out",
	RESPONSE_CHECK_OUT: "response-check-out",
	CANCEL: "cancel",
}

export const MEMBER_PACKAGE_REF_TYPE = {
	PURCHASE: 'purchase',
	POINT_PURCHASE: 'point_purchase',
	TRANSFER: 'package_transfer',
	EXCHANGE: 'package_exchange',
}

export const PRODUCT_TYPE = {
	PACKAGE: "PackageVariant",
	PT_SESSION: "PTSessionPackageVariant",
	ALL_CLUB: "AllClubPackage",
	GENERAL: "GeneralProduct",
}

export const PAYMENT_TYPE = {
	CASH: "CASH",
	CREDIT: "CREDIT",
	TRANSFER: "TRANSFER",
	REWARD_POINT: "POINT",
	AR: "AR",
	ALIPAY:"ALIPAY",
	WECHAT:"WECHAT",
	DOLFIN:"DOLFIN",
	QR_OTHER:"QR OTHER",
}

export const getPaymentOptions = (on_add=false) => {
	const options = [
		{key: PAYMENT_TYPE.CASH, value: PAYMENT_TYPE.CASH, label: "Cash"},
		{key: PAYMENT_TYPE.CREDIT, value: PAYMENT_TYPE.CREDIT, label: "Credit Card"},
		{key: PAYMENT_TYPE.TRANSFER, value: PAYMENT_TYPE.TRANSFER, label: "Transfer"},
		{key: PAYMENT_TYPE.AR, value: PAYMENT_TYPE.AR, label: "AR", disabled: !on_add },
	];

	if (!on_add) {
		options.push({key: PAYMENT_TYPE.REWARD_POINT, value: PAYMENT_TYPE.REWARD_POINT, label: "Reward Point", disabled: true})
	}
	return options
}

export const DISCOUNT_TYPE = {
	POINT: "POINT",
	COUPON: "COUPON",
	PROMOTION: "PROMOTION",
	MANUAL: "MANUAL",
	FREE_VOUCHER: "FREE_VOUCHER",
	KIOSK_VOUCHER: "KIOSK_VOUCHER",
}

export const DISCOUNT_TYPE_OPTIONS = [
	{key: DISCOUNT_TYPE.POINT, value: DISCOUNT_TYPE.POINT, label: "Reward Point"},
	{key: DISCOUNT_TYPE.COUPON, value: DISCOUNT_TYPE.COUPON, label: "Coupon"},
	{key: DISCOUNT_TYPE.PROMOTION, value: DISCOUNT_TYPE.PROMOTION, label: "Promotion"},
	{key: DISCOUNT_TYPE.MANUAL, value: DISCOUNT_TYPE.MANUAL, label: "Cash"},
	{key: DISCOUNT_TYPE.FREE_VOUCHER, value: DISCOUNT_TYPE.FREE_VOUCHER, label: "Free Voucher"},
	{key: DISCOUNT_TYPE.KIOSK_VOUCHER, value: DISCOUNT_TYPE.KIOSK_VOUCHER, label: "Kiosk Voucher"},
];

export const CONTRACT_TYPE = {
	PACKAGE: 'package',
	FREE_TRIAL: 'free_trial',
	CORPORATE: 'corporate',
}

export const MEMBER_TRAINING_STATUS = {
	REQUIRE_INTERACT: 'REQUIRE_INTERACT',
	REQUIRE_SELF_TRAINING: 'REQUIRE_SELF_TRAINING',
	COMPLETED: 'COMPLETED',
}
