import React from 'react';
import Header from '../components/common/Header';
import { Alert, Button, Checkbox, Col, Form, Modal, Row, Space, theme } from 'antd';
import PTSelect from '../components/pt/PTSelect';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import TSTable from '../components/common/TSTable';
import { URL_TRAINER } from '../constants/urls';
import { DELETE } from '../frameworks/HttpClient';
import PTShiftModal from '../components/pt/PTShiftModal';
import moment from 'moment';
import * as _ from 'lodash';
import useUserData from '../hooks/useUserData';
import { USER_GROUP } from '../constants/string';


export default function PTShiftView() {
  const { token: { colorSuccess, colorWarning } } = theme.useToken();
  const { user } = useUserData();
  const isSearchable = user.groups.find(group => (group == USER_GROUP.PT_MANAGER)) ? true: false;
  const isAddable = user.groups.find(group => (group == USER_GROUP.PT_MANAGER) || (group == USER_GROUP.PT)) ? true : false;

  const tableRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [searchTarget, setSearchTarget] = React.useState('')
  const [target, setTarget] = React.useState(null);
  const [deleteTarget, setDeleteTarget] = React.useState(null);

  const columns = [
    {
      title: 'Trainer',
      dataIndex: 'trainer_name',
    },
    {
      title: 'Days',
      dataIndex: 'weekdays',
      render: (value) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          { ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => (
            <span><Checkbox checked={(value ? value : []).includes(index)}/> {day}</span>
          )) }
        </div>
      )
    },
    {
      title: 'From',
      dataIndex: 'start_time',
      render: (value) => (<div style={{ textAlign: 'center' }}>{moment(value, 'h:mm:ss').format('HH:mm')}</div>)
    },
    {
      title: 'To',
      dataIndex: 'end_time',
      render: (value) => (<div style={{ textAlign: 'center' }}>{moment(value, 'h:mm:ss').format('HH:mm')}</div>)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: 150,
      render: (value, original) => { 
        const editAble = user.id == _.get(original, 'trainer', '')
        return editAble ? (
          <div style={{ textAlign: 'center' }}>
            <Space>
              <Button
                type='primary'
                style={{ background: colorWarning }}
                icon={<EditOutlined/>} 
                onClick={() => setTarget(original)}/>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined/>}
                onClick={() => setDeleteTarget(value)}/>
            </Space>
          </div>
        ) : null
      }
    }
  ]

  const handleDelete = async (target) => {
    setIsLoading(true);
    try {
      await DELETE(`${URL_TRAINER.TRAINER_SHIFT}${target}`)
      closeDeleteModal();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const closeDeleteModal = () => {
    setDeleteTarget(null);
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const closeEditModal = () => {
    setTarget(null);
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  React.useEffect(() => {
    if (tableRef.current) {
      tableRef.current.fetch()
    }
  }, [searchTarget])

  return (
    <div>
      <Header title='PT Shift' />

      <Form style={{ marginTop: '1rem' }} layout='horizontal'>
        <Row gutter={16}>
          <Col span={8}>
            { isSearchable && (
              <Form.Item label='PT' >
                <PTSelect addDefault onChange={(value) => setSearchTarget(value)}/>
              </Form.Item>
            )}
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            { isAddable && (
              <Form.Item>
                <Button
                  style={{ background: colorSuccess }}
                  type='primary'
                  icon={<PlusCircleOutlined />}
                  onClick={() => setTarget('add')}
                >
                  Add Shift
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      
      <PTShiftModal
        open={target != null}
        target={target}
        onClose={() => closeEditModal()}/>

      <Modal
        open={deleteTarget != null}
        icon={<ExclamationCircleOutlined style={{ color: colorWarning }}/>}
        onClose={() => { closeDeleteModal() }}
        onCancel={() => { closeDeleteModal() }}
        title='Delete PT shift confirmation.'
        okText='Delete'
        onOk={() => handleDelete(deleteTarget)}
        cancelButtonProps={{ loading: isLoading }}
        okButtonProps={{ danger: true, icon: <DeleteOutlined/>,  loading: isLoading, type: 'primary' }}
      >
        Are you sure to delete following shift?
        { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/> }
      </Modal>

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey='id'
        url={`${URL_TRAINER.TRAINER_SHIFT}?trainer=${isSearchable ? searchTarget : user.id}`}/>
    </div>
  )
}
