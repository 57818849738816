import React from 'react'
import { Content, Footer, Header, } from 'antd/es/layout/layout'
import { Alert, Button, Image, Layout, Row, Form ,Affix } from 'antd';
import flagEng from "../../assets/eng.png";
import flagThai from "../../assets/thai.png";
import "../../styles.css"
import MemberContractTHView from '../../components/member/MemberContractTHView';
import MemberContractENView from '../../components/member/MemberContractENView';
import { GET, POST } from '../../frameworks/HttpClient';
import { PATH } from '../../routes/CustomRoutes';
import { useParams, useNavigate, } from 'react-router-dom';
import { URL_MEMBER, URL_SALE } from '../../constants/urls';
import { CONTRACT_TYPE } from '../../constants/string';
import useUserData from '../../hooks/useUserData';
import "../kiosk/CssScreen/KioskSignContractScreen.css";
import { useTranslation } from 'react-i18next';
import LogoutModalKiosk from "./kiosk-components/LogoutModalKiosk";

const SIGN_PAD_HEIGHT = 15; // Percent of sign pad on screen


const defaultPackageDetail = {
	duration: 1,
	price: 0,
	conditions: [],
}

export default function KioskSignContrackScreen() {
	const windowSize = React.useRef([window.innerWidth, window.innerHeight]);
  const [errorMessages, setErrorMessages] = React.useState(null);
	const [isSuccess, setIsSuccess] = React.useState(false);
	const [packageDetail, setPackageDetail] = React.useState(defaultPackageDetail);
	const [local, setLocal] = React.useState('EN');
	const padRef = React.useRef(null);
	const signatureRef = React.useRef(null);
	const [width, setWidth] = React.useState(0);
	const navigate = useNavigate();
	const params = useParams();
    const { user } = useUserData();
    const [ cart , setCart ] = React.useState(null);
	const { t } = useTranslation();
	

	// Fetch Packages
	const fetchPackageDetail = async () => {
		if (!params.id) {
			setErrorMessages("Member ID not found!")
			return
		}
		if (params.type != CONTRACT_TYPE.PACKAGE) {
			return
		}

		setErrorMessages(null);
		try {
			const response = await GET(`${URL_MEMBER.MEMBER}${params.id}/membership-package-detail/`)
			setPackageDetail(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const handleSubmit = async () => {
		// const transferAmount = searchParams.get('transferAmount')
		// let data = {
		// 	type: params.type,
		// 	member: params.id,
		// 	price: packageDetail.price,
		// 	duration: packageDetail.duration,
		// }

		// setErrorMessages(null);
		// try {
		// 	await POST(URL_MEMBER.MEMBERSHIP_PACKAGE_CONTRACT, data)
		// 	setIsSuccess(true);
		// 	if (transferAmount != null && transferAmount > 0) {
		// 		navigate(`${PATH.PROMPTPAY_QR}/${transferAmount}`)
		// 	}
		// 	else {
		// 		closeTab()
		// 	}
		// } catch (error) {
		// 	setErrorMessages(error.errorMessages);
		// }finally{
		// 	signatureRef.current.off();
		// }
        navigate(`${PATH.KIOSK_PAYMENT_METHOD}/${params.packageId}/${params.type}`)
  }

  const fetchCart = async (id) => {
    if (!user.member) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    try {
			const response = await GET(`${URL_SALE.CART_MEMBER}${id}/`);
			setCart(response.data);
			// initial cart item
			if (response.data && response.data.items) {
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  }

  const backToMenu = async() => {
    await POST(`${URL_SALE.CART}${cart.id}/clear/`)
    navigate(PATH.KIOSK_MENU_PACKAGES);
};

	React.useEffect(() => {
		if (params.id)
			fetchPackageDetail(params.id);
            fetchCart(params.id)
	}, [params.id])

	const [container, setContainer] = React.useState(null);

	const containerStyle = {
		overflow: 'auto',
		scrollbarColor: '#BF1E2D',		
	};
	const style={

	}

	const layouttuusenewlaout={
		borderRadius:'50px',
		width:'85%',
	}

	return (
		<div className='divtocontractscreenbackgg'>
		<Layout className='toplayoutcontractscreen'>
			<Header style={{backgroundColor:'transparent', paddingTop:'30%'}} />
		<Layout style={{ ...layouttuusenewlaout }} className='allleyouttuse'>
		<Header className='HeaderScreentext' showBackIcon={false}>
				<Row
					style={{
						display: 'flex',         
						justifyContent: 'center', 
						alignItems: 'center',   
						fontSize: '40px',
						textAlign: 'center',
						backgroundColor: 'transparent'
					}}
					>
					  ALPHA HEALTH CLUB PHUKET
				</Row>
		</Header>
		<Layout className='laoutcontractscreen' style={{height: '10%'}} >
			<Content className='KioskSignContractScreen' style={containerStyle}>
			<div style={style}> 
        		<Affix target={() => container}>
				<Row align='end' style={{marginBottom: 16}}>
					<Image
						src={flagEng}
						preview={false}
						style={{ borderRadius: 8, height: "24px", width: "auto"}}
						onClick={() => setLocal("EN")} /> &nbsp; &nbsp; 
					<Image
						src={flagThai}
						preview={false}
						style={{ marginLeft: 0, borderRadius: 8, height: "24px", width: "auto"}}
						onClick={() => setLocal("TH")} />
				</Row>
				{(local === "TH") && (
					<MemberContractTHView detail={packageDetail}/>  
				)}

				{(local === "EN") && (
					<MemberContractENView detail={packageDetail}/>
				)}
				
				{errorMessages && 
					<Alert
						style={{marginBottom: "12px", textAlign: "left"}}
						message={errorMessages}
						type="error"
						showIcon/>
				}
				{isSuccess && 
					<Alert
						style={{marginBottom: "12px", textAlign: "left"}}
						message="Success"
						type="success"
						showIcon/>
				}
			</Affix>
			</div>
			</Content>
		</Layout>	
		
			<Footer align = "center" style={{ marginBottom : 20 , backgroundColor:'transparent'}}>
        <Button key={"1"} type={"primary"} ghost onClick={() => backToMenu()} disabled={isSuccess} style={{marginRight: 12, width : '200px', height : '70px' , borderRadius: '300px', fontSize: '20px'}}>{t("cancel")}</Button>
        <Button key={"2"} type={"primary"} onClick={handleSubmit} style={{ width : '200px', height : '70px', borderRadius: '300px', fontSize: '20px'}} disabled={isSuccess} >{t("confirm")}</Button>
			</Footer>
		</Layout>
		<Footer style={{paddingBottom:'20%'}} />

		<LogoutModalKiosk
			isLiff={false}
			idleTime={3}
		/>

	</Layout>
	</div>
	)
}