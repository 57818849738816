import { Alert, Button, Col, Input, Row, Spin, theme, notification, AutoComplete } from 'antd'
import React from 'react'
import { debounce } from 'lodash';
import { validate as isValidUUID } from 'uuid';
import Header from '../components/common/Header'
import Segment from '../components/common/Segment'
import Highlighter from 'react-highlight-words';
import dayjs from 'dayjs';
import { UserAddOutlined, DesktopOutlined, ExportOutlined, ImportOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { PATH } from '../routes/CustomRoutes'
import CardSummary from "../components/reception/CardSummary";
import { GET } from '../frameworks/HttpClient';
import { URL_MEMBER, URL_PACKAGE } from '../constants/urls';
import { DATE_FORMAT, SECONDARY_SCREEN_NAME } from '../constants/string';
import GateCheckInToast from '../components/reception/GateCheckInToast';
import * as _ from 'lodash';
import Cookies from 'js-cookie';
import { COOKIE_AUTH } from '../constants/cookies';


const headerStyle = {
	fontWeight: "bold", 
	marginTop: 24, 
	textAlign: "left"
}

const MEMBER_CODE_LENGTH = 6  // Default length of member code

const GATE_IN_1_URL = "https://1.1.1.203:9090/trigger"
const GATE_IN_2_URL = "https://1.1.1.205:9090/trigger"
const GATE_OUT_URL = "https://1.1.1.207:9090/trigger"

export default function ReceptionView() {
	const navigate = useNavigate();
	const inputRef = React.useRef(null);
	const today = dayjs().format(DATE_FORMAT);
	const [memberFindLoading, setMemberFindLoading] = React.useState(false);
	const [memberFetchLoading, setMemberFetchLoading] = React.useState(false);
	const [summaryLoading, setSummaryLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [summary, setSummary] = React.useState(null);
	const [api, contextHolder] = notification.useNotification();
	const [options, setOptions] = React.useState([]);

	const {
		token: { colorSuccessActive, colorPrimary },
  } = theme.useToken();

	const openGate = async (url) => {
		try {
			await GET(url)
		} catch (error) {
			setErrorMessages(error.errorMessages)
		}
	}

	const searchMember = async (memberId, params) => {
		setOptions([])
		setMemberFindLoading(true)
		setErrorMessages(null);

		try {
			const response = await GET(`${URL_MEMBER.MEMBER}`, params);
			if(response.data.total === 1) {  // Found!!
				navigate(`${PATH.MEMBER_DETAIL}${PATH.MEMBER_CHECKIN}/${response.data.results[0].id}`)
			} else {
				filterMember(memberId);
			}
		} catch (error) {
			if(error.status === 404) {
				// Search user not found!
				api.error({
					message: 'Member Not Found!',
					description: <>Cannot find member with ID <b>{memberId}</b></>,
					placement: 'bottomLeft',
				});
			}
			else {
				// Other errors
				setErrorMessages(error.errorMessages);
			}
		} finally {
			setMemberFindLoading(false)
		}
	}

	const filterMember = async (searchText) => {
		setOptions([])
		setMemberFetchLoading(true)
		setErrorMessages(null);

		try {
			const response = await GET(`${URL_MEMBER.MEMBER}`, { 'search': searchText, 'page_size': 50 });
			if (response.data.total > 0) {
				setOptions(response.data.results.map(data => ({
					data,
					value: data.id,
					label: (<Highlighter
						highlightStyle={{
							backgroundColor: '#ffc069',
							padding: 0,
						}}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={`[${data.code}] ${data.full_name} - ${data.mobile_phone}`}
					/>)
				})))
			} else {
				api.error({
					message: 'Member Not Found!',
					description: <>Cannot find member with keyword: <b>{searchText}</b></>,
					placement: 'bottomLeft',
				});
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setMemberFetchLoading(false)
		}
	}

	const handleKeyEnter = (text) => {
		// Check for input from barcode reader
		// It could be UUID or Code
		if(text.trim() === "") {
			return
		}
		else if (isValidUUID(text)) {
			searchMember(text, {'id': text})
		}
		else {
			searchMember(text, {'code': text})
		}
	}

	const fetchData = async () => {
		try {
			setSummaryLoading(true)
			const response = await GET(URL_PACKAGE.PACKAGE_USAGE_SUMMARY);
			setSummary(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setSummaryLoading(false)
		}
	}
	
	React.useEffect(() => {
		fetchData();
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [])

	return (
    <div>
			<Header title={"Reception"}/>
			{ contextHolder }
			<GateCheckInToast onMemberPass={() => fetchData()} />

			{errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginBottom: "12px", textAlign: "left"}}
				/>
			}

			{/* <Button
				style={{float: "right", marginTop: 12}}
				shape="round"
				icon={<DesktopOutlined />}
				onClick={() => {
					window.open(`${PATH.SECONDARY_SCREEN}`, SECONDARY_SCREEN_NAME, "popup=yes,location=no")
				}}>
				Screen
			</Button> */}

			<Button
				style={{
					float: "right",
					marginTop: 12,
					marginRight: 16,
					borderColor: colorPrimary,
					color: colorPrimary
				}}
				shape="round"
				icon={<ExportOutlined />}
				onClick={() => { openGate(GATE_OUT_URL) }}>{"Gate OUT"}</Button>

			<Button
				style={{
					float: "right",
					marginTop: 12,
					marginRight: 16,
					borderColor: colorPrimary,
					color: colorPrimary
				}}
				shape="round"
				icon={<ImportOutlined />}
				onClick={() => { openGate(GATE_IN_2_URL) }}>{"Gate IN 2"}</Button>

			<Button
				style={{
					float: "right",
					 marginTop: 12, 
					 marginRight: 16,
					 borderColor: colorPrimary, 
					 color: colorPrimary}}
				shape="round"
				icon={<ImportOutlined />} 
				onClick={() => { openGate(GATE_IN_1_URL) }}>{"Gate IN 1"}</Button>

      <p style={ headerStyle }>Summary :</p>
      <Segment
        style={{ padding: 16, background: "#fff" }}
        detail={
          <div>
            <Row gutter={[16, 8]}>
              <Col md={6} sm={24} xs={24}>
                <CardSummary
				  				loading={summaryLoading}
                  amount={(summary && summary.check_in) ? summary.check_in : 0}
                  content={"Check In"}
                  url={`${URL_PACKAGE.PACKAGE_USAGE}?status=check_in&date=${today}`}/>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <CardSummary 
				  				loading={summaryLoading}
                  amount={(summary && summary.working) ? summary.working : 0}
                  content={"Working"}
                  url={`${URL_PACKAGE.PACKAGE_USAGE}?status=working&date=${today}`}/>
              </Col>

              <Col md={6} sm={24} xs={24}>
                <CardSummary 
									loading={summaryLoading}
                  amount={(summary && summary.check_out) ? summary.check_out : 0}
                  content={"Check Out"}
                  url={`${URL_PACKAGE.PACKAGE_USAGE}?status=check_out&date=${today}&ordering=-check_out`}/>
              </Col>
							<Col md={6} sm={24} xs={24} style={{margin: "auto", textAlign: "center"}}>
                <Button 
                  style={{background: colorSuccessActive}} 
                  type="primary" 
                  shape="round" 
                  icon={<UserAddOutlined />} 
                  size={"large"}
				  				onClick={() => navigate(PATH.REGISTER,{ replace: true })}>{"Member"}</Button>
							</Col>
						</Row>
					</div>
				}
      />

      <p style={ headerStyle }>Member ID :</p>
			<Spin tip="Loading" size="default" spinning={memberFindLoading || memberFetchLoading}>
				<Segment
					style={{ padding: 16, background: "#fff", margin: "auto" }}
					detail={
						<AutoComplete
							autoFocus={true}
							options={options}
							onSelect={(value, option) => navigate(`${PATH.MEMBER_DETAIL}${PATH.MEMBER_CHECKIN}/${value}`)}
							onSearch={() => { setOptions([]) }}
							onKeyDown={(e) => { if (e.key == 'Enter') { handleKeyEnter(_.get(inputRef, 'current.input.defaultValue')) } }}
							style={{width: "100%"}}
							children={(
								<Input 
									placeholder="Member ID"
									autoComplete="off"
									bordered={false}
									allowClear
									style={{ fontSize: 28 }}
									ref={inputRef}
								/>
							)}
						/>
					}
				/>
			</Spin>
			{summary && (summary.corporates.length > 0) && (
				<div>
					<p style={ headerStyle }>Corporate Quota :</p>
					<Segment
						style={{ padding: 16, background: "#fff" }}
						detail={
							<div>
								<Row gutter={[16, 8]} justify={"center"}>
									{summary.corporates.map((item) => {
										return (
											<Col md={6} sm={24} xs={24} key={item.id}>
												<CardSummary
													amount={item.today_usages}
													subContent={`used ${item.today_usages}/${item.daily_usages}`}
													content={item.name}
													url={`${URL_PACKAGE.PACKAGE_USAGE}?corporate=${item.id}&date=${today}`}/>
											</Col>
										)
									})}
								</Row>
							</div>
						}
					/>
				</div>
			)}
    </div>
  );
}
