import React from 'react'
import propTypes from "prop-types";
import { Alert, DatePicker, Form, Input, Modal, theme } from "antd";
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants/string';
import Header from '../common/Header';
import { POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../constants/urls';

export default function DelayPackageModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const {
    token: { colorWarning },
  } = theme.useToken();

  // Handle change end date
  const handleEdit = async () => {
    if (!props.package) {
      setErrorMessages("Package not found!");
      return;
    }

    let data = await form.validateFields();
    console.log(data);
    setErrorMessages(null);
    setLoading(true);
    data = {
      ...data,
      new_end_date: data.end_date.format(DATE_FORMAT),
      member_package: `${props.isMainPackage ? URL_PACKAGE.MEMBER_PACKAGE : URL_PACKAGE.MEMBER_PT_SESSION_PACKAGE}${props.package.id}/`
    }
    delete data["end_date"];
    console.log(data);
    let response = null;
    try {
      response = await POST(URL_PACKAGE.PACKAGE_EXTEND, data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }

    if (response)
      props.onSuccess()
  };

  // Disable date into DatePicker
  const disabledDate = (current) => {
    let endDate = dayjs().endOf('day');
    if (props.package && props.package.end_date) {
      endDate = dayjs(props.package.end_date, DATE_FORMAT);
    }
    return current && current < endDate;
  };

  React.useEffect(() => {
    if (props.open && props.package && props.package.end_date) {
      // Initial data
      const endDate = dayjs(props.package.end_date, DATE_FORMAT)
      form.setFieldsValue({ end_date: endDate });
    } else {
      if (!props.open) {
        form.resetFields();
        setErrorMessages(null);
      }
    }
  }, [props.package, props.open])

  return (
    <Modal
      open={props.open}
      okText={"Change"}
      okButtonProps={{ 
        style: {background: colorWarning},
        loading: loading,
      }}
      onOk={handleEdit}
      onCancel={props.onClose}>

      <Header title={"Delay"} />
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: "12px", textAlign: "left"}}/>
      }
      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>

        <Form.Item label={"Package"}>
          <Input 
            disabled 
            value={props.package ? props.package.package_name : "Unknown"}/>
        </Form.Item>

        <Form.Item 
          name={"end_date"} 
          label="End Date" 
          rules={[{ required: true }]}>
          <DatePicker
            style={{ width:'100%'}}
            format={DATE_FORMAT} 
            allowClear={false}
            disabledDate={disabledDate}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

DelayPackageModal.defaultProps = {
  package: null,
  isMainPackage: true,
  open: false,
  onClose: () => {},
  onSuccess: () => {},
}

DelayPackageModal.propTypes = {
  package: propTypes.object,
  isMainPackage: propTypes.bool,
  open: propTypes.bool,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
}