import { Alert, Button, Checkbox, Col, Form, Modal, Row, Select, TimePicker, theme } from 'antd';
import React from 'react';
import Header from '../common/Header';
import useUserData from '../../hooks/useUserData';
import { TIME_FORMAT } from '../../constants/string';
import { PATCH, POST } from '../../frameworks/HttpClient';
import { URL_TRAINER } from '../../constants/urls';
import * as _ from 'lodash';
import dayjs from 'dayjs';


export default function PTShiftModal(props) {
  const {
    open,
    target,
    onClose,
  } = props;
  
  const daysParser = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const isAdd = target == 'add';
  const { token: { colorSuccess, colorWarning } } = theme.useToken();
  const { user } = useUserData();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let data = await form.validateFields();
      if (isAdd) {
        await POST(URL_TRAINER.TRAINER_SHIFT, parseFormToData(data));
      } else {
        await PATCH(`${URL_TRAINER.TRAINER_SHIFT}${_.get(target, 'id', '')}/`, parseFormToData(data));
      }
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const parseFormToData = (data) => ({
    trainer: user.id,
    start_time: _.get(data, 'time.0', dayjs()).format(TIME_FORMAT),
    end_time: _.get(data, 'time.1', dayjs()).format(TIME_FORMAT),
    ...daysParser.reduce((acc, day, idx) => ({ ...acc, [day]: _.get(data, 'weekdays', []).includes(idx) }), {})
  })

  const parseDataToForm = (data) => ({
    weekdays: _.get(data, 'weekdays', []),
    time: [
      dayjs(_.get(data, 'start_time', '0:00'), TIME_FORMAT),
      dayjs(_.get(data, 'end_time', '0:00'), TIME_FORMAT),
    ]
  })

  React.useEffect(() => {
    if (open) {
      form.resetFields();
      if (!isAdd) {
        form.setFieldsValue(parseDataToForm(target));
      }
    }
  }, [open])

  return (
    <Modal
      width={600}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key='cancel' onClick={onClose} loading={isLoading}>Cancel</Button>,
        <Button
          style={{ background: isAdd ? colorSuccess : colorWarning }}
          key='submit'
          type='primary'
          onClick={() => handleSubmit()} loading={isLoading}
        >
          { isAdd ? 'Add' : 'Edit' }
        </Button>
      ]}
    >
      <Header title={`${isAdd ? 'Add' : 'Edit'} PT Shift`} />
      { errorMessages && 
        <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/> 
      }
      <Form form={form} style={{ marginTop: '1rem' }} labelCol={{ span: 4 }}>
        <Form.Item name='weekdays' label='Day' rules={[{ required: true }]}>
          <Checkbox.Group>
            <Row>
              <Col span={6}><Checkbox value={0}> Mon</Checkbox></Col>
              <Col span={6}><Checkbox value={1}> Tue</Checkbox></Col>
              <Col span={6}><Checkbox value={2}> Wed</Checkbox></Col>
              <Col span={6}><Checkbox value={3}> Thu</Checkbox></Col>
              <Col span={6}><Checkbox value={4}> Fri</Checkbox></Col>
              <Col span={6}><Checkbox value={5}> Sat</Checkbox></Col>
              <Col span={6}><Checkbox value={6}> Sun</Checkbox></Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          name='time'
          label='Shift Time'
          rules={[
            { required: true },
            { validator: async (__, value) => {
              if (_.get(value, '0', dayjs()) >= _.get(value, '1', dayjs())) {
                return Promise.reject('End time must be greater than start time.')
              }
            }}
          ]}
        >
          <TimePicker.RangePicker
            format={TIME_FORMAT}
            style={{ width: '100%' }}
            inputReadOnly={true}
            minuteStep={15}
            disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4] })}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}
