import React from 'react'
import { Content} from 'antd/es/layout/layout'
import { Alert, Badge, Layout, Menu, theme } from 'antd';
import HeaderScreen from '../components/common/HeaderScreen';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import "./../styles.css"
import InformationView from '../components/member/menu/InformationView';
import CheckInView from '../components/member/menu/CheckInView';
import PackagesView from '../components/member/menu/PackagesView';
import BookingView from '../components/member/menu/BookingView';
import CartView from '../components/member/menu/CartView';
import { GET } from '../frameworks/HttpClient';
import { URL_MEMBER } from '../constants/urls';
import RewardView from '../components/member/menu/RewardView';
import useHistoryStack from '../hooks/useHistoryStack';

export default function MemberDetailScreen() {
	const historyStack = useHistoryStack();
	const navigate = useNavigate();
	const params = useParams();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [current, setCurrent] = React.useState("");
	const [memberId, setMemberId] = React.useState(null);
	const [member, setMember] = React.useState(null);
	const [cartNum, setCartNum] = React.useState(0);

	const {
		token: { colorPrimary, colorWarning }
	} = theme.useToken();

	const menus = [
		{key: "info", label: "Information"},
		{key: "checkin", label: "Check-in / Check-out"},
		{key: "booking", label: "Class Booking"},
		{key: "reward", label: "Points"},
		{key: "packages", label: "Packages"},
		{
			key: "cart", 
			label: (
				<Badge count={cartNum} color={cartNum > 0 ? colorWarning : null}>
					<p 
						style={{
							color: (current === "cart") ? colorPrimary : "#fff",
							marginTop: 8 }}>
						{"Cart"}
					</p>
				</Badge>
			)
		},
	]

	const goBack = () => navigate(-1);
	// const goInfo = () => navigate(`${PATH.MEMBER_DETAIL}/info/${params["id"]}`, {replace: true}); // Navigate to Info View

	const onMenuSelected = (e) => {
    if (e.key !== current)
      navigate(`${PATH.MEMBER_DETAIL}/${e.key}/${params["id"]}`, {replace: true})
  };

	const fetchMember = async () => {
		const memberId = (params && params.id) ? params.id : null
		if (!memberId) {
			setErrorMessages("Member not found!")
			return;
		}

		setErrorMessages(null)
		try {
			const response = await GET(`${URL_MEMBER.MEMBER}${memberId}/`);
			setMember(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const fetchNotifyItems = async () => {
		const memberId = (params && params.id) ? params.id : null
		if (!memberId) {
			setErrorMessages("Member not found!")
			return;
		}

		setErrorMessages(null)
		try {
			const response = await GET(`${URL_MEMBER.MEMBER}${memberId}/menu/`);
			setCartNum(response.data.cart);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (memberId) {
			fetchMember();
			fetchNotifyItems();
		}
	}, [memberId]);

	React.useEffect(() => {
    const menu = params['menu']
    if (menu !== current) {
      setCurrent(menu)
    }

		if (params && params.id) {
			setMemberId(params.id);
			window.scrollTo(0, 0);
		}
      
  }, [params])

	return (
		<Layout style={{minHeight: "100vh"}}>
			<HeaderScreen 
				title={null}
				showBackIcon={historyStack.length > 0}
				showCloseIcon={historyStack.length <= 0}
				// onClick={() => historyStack.length >= 2 ? navigate(-1, { replace: true }) : window.close()}
				element={
					<Menu
						className={"primary-background custom-menu-item"}
						mode={"horizontal"}
						onClick={onMenuSelected} 
						defaultSelectedKeys={[current]}
						selectedKeys={[current]}
						items={menus}/>
				}/>
				
			<Content style={{margin: 24}}>
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
				{params && (params['menu'] === "info") && (<InformationView member={member} onUpdateSuccess={fetchMember}/>)}
				{params && (params['menu'] === "checkin") && (
					<CheckInView 
						member={member} 
						onCheckedIn={goBack}
						onCheckedOut={goBack}
						onUpdated={goBack}
						isView={current === "checkin"}/>
				)}
				{params && (params['menu'] === "packages") && (
					<PackagesView
						member={member}
						onUpdateSuccess={fetchMember}
						onRefreshMenu={fetchNotifyItems}/>
				)}
				{params && (params['menu'] === "booking") && (
					<BookingView
						member={member}
						onUpdateSuccess={fetchMember}/>
				)}
				{params && (params['menu'] === "reward") && (
					<RewardView
						member={member}
						onUpdateSuccess={fetchMember}/>
				)}
				{params && (params['menu'] === "cart") && (<CartView member={member} onRefreshMenu={fetchNotifyItems}/>)}
			</Content>
		</Layout>
	)
}
