import React from 'react'
import propTypes from "prop-types";
import { Modal, Button, theme, Row, Col, Typography, Alert } from "antd";
import { Dialog, List, Popup, Form, TextArea, DatePicker, Toast } from "antd-mobile"
import { dateToHumanString } from '../../frameworks/Util';
import { DATE_FORMAT } from '../../constants/string';
import dayjs from 'dayjs';
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../constants/urls';
import {
  CheckCircleFilled,
  CloseCircleFilled 
} from "@ant-design/icons";
import moment from 'moment';
import * as _ from 'lodash';


export default function MyDropPackageModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [datas, setDatas] = React.useState([]);
  const [showNewDrop, setShowNewDrop] = React.useState(false)
  const [startDateVisible, setStartDateVisible] = React.useState(false)
  const [startDate, setStartDate] = React.useState(null);
  const [endDateVisible, setEndDateVisible] = React.useState(false)
  const [endDate, setEndDate] = React.useState(null);
  const [note, setNote] = React.useState('');
  const [showEndDate, setShowEndDate] = React.useState(new Date());
  const [usingExtra, setUsingExtra] = React.useState(false);

  const { Text } = Typography;

  const {
    token: { colorSuccess, colorError },
  } = theme.useToken();

  const handleCreateDrop = async () => {
    if (!props.package) {
      setErrorMessages("Package not found!");
      return;
    }

    if(startDate == null) {
      Toast.show({
        content: "Please Enter Start Date"
      })
    }
    else if(endDate == null) {
      Toast.show({
        content: "Please Enter End Date"
      })
    }

    setErrorMessages(null);
    setLoading(true);
    const data = {
      start_date: startDate.format(DATE_FORMAT),
      end_date: endDate.format(DATE_FORMAT),
      note: note,
      member_package: props.package.id
    } 
    try {
      await POST(URL_PACKAGE.PACKAGE_DROP, data);
      setStartDate(null)
      setEndDate(null)
      setNote("")
      setErrorMessages(null);
      setShowNewDrop(false);
      Toast.show({
        icon: 'success',
        content: 'Create new drop success!'
      })
      fetchList()
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
    props.onUpdateSuccess();
  };

  const handleCancelDrop = async (row) => {
    setErrorMessages(null);
    setLoading(true);
    try {
      await POST(`${URL_PACKAGE.PACKAGE_DROP}${row.id}/cancel/`);
      setErrorMessages(null);
      Toast.show({
        icon: 'success',
        content: 'Cancel drop success!'
      })
      fetchList()
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
    props.onUpdateSuccess();
  }

  const fetchList = async () => {
    try {
      setLoading(true)
      const url = `${URL_PACKAGE.PACKAGE_DROP}?member_package=${props.package.id}`
      const response = await GET(url)
      setDatas(response.data.results)
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (!props.open) {
      setErrorMessages(null);
    }
    else {
      if(props.package) {
        const myPackage = props.package
        const isExtra = (myPackage.extra_duration > 0) && (myPackage.extra_duration > myPackage.duration_remain)
        setUsingExtra(isExtra)
        if (isExtra)
        setErrorMessages("Cannot drop on extra days")
        
        // Fetch Data
        fetchList()
      }
    }
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      width={900}
      okText={"Close"}
      okButtonProps={{ loading: loading }}
      onCancel={props.onClose}
      footer={[
        <Button type='primary' key="ok" onClick={props.onClose} loading={loading}>
          Close
        </Button>
      ]}
    >
      <Popup
        visible={showNewDrop}
        onMaskClick={() => {
          setShowNewDrop(false)
          setStartDate(null)
          setEndDate(null)
          setNote("")
        }}
        position='right'
        bodyStyle={{ width: '70vw' }}
      >
        <Form
          
          layout='horizontal'
          footer={
            <Button block type='primary' size='large' loading={loading} onClick={handleCreateDrop}>
              Create
            </Button>
          }
        >
          <Form.Header>New Drop</Form.Header>
          <Form.Item
            label='From'
            rules={[{ required: true, message: 'Required!' }]}
            onClick={() => setStartDateVisible(true)}
          >
            <DatePicker
              title='Start Date'
              visible={startDateVisible}
              confirmText="Confirm"
              cancelText="Cancel"
              onClose={() => {setStartDateVisible(false)}}
              min={moment().add(1, "days").toDate()}
              onConfirm={val => {
                setStartDate(dayjs(val)); 
                setShowEndDate(val); 
              }}
            />
            {startDate ? startDate.format(DATE_FORMAT) : ""}
          </Form.Item>

          <Form.Item
            label='To'
            rules={[{ required: true, message: 'Required!' }]}
            onClick={() => setEndDateVisible(true)}
          >
            <DatePicker
              title='End Date'
              visible={endDateVisible}
              confirmText="Confirm"
              cancelText="Cancel"
              onClose={() => { setEndDateVisible(false) }}
              min={showEndDate}
              onConfirm={val => { setEndDate(dayjs(val)) }}
            />
            {endDate ? endDate.format(DATE_FORMAT) : ""}
          </Form.Item>

          <Form.Item label='Note'>
            <TextArea
              placeholder='Note'
              maxLength={100}
              rows={2}
              showCount
              value={note}
              onChange={(val) => setNote(val)}
            />
          </Form.Item>
        </Form>
      </Popup>

      <List header={<span>Drop Package : {props.package ? props.package.package_name + " (" + props.package.variant_type + ")" : "-"}</span>}>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginTop: 8, marginBottom: 8, textAlign: "left"}}
          />
        }
        {!usingExtra && (
          <List.Item clickable onClick={() => setShowNewDrop(true)}>+ New Drop</List.Item>
        )}
        
        {
          datas.map(drop => {
            let allowCancel = (!drop.is_canceled && !dayjs().isAfter(dayjs(drop.start_date))) ? true : false
            return (
            <List.Item
              key={drop.id}
              prefix={
                drop.is_canceled ? <CloseCircleFilled style={{ color: colorError }} /> : <CheckCircleFilled style={{ color: colorSuccess }} />
              }
              description={`Note : ${_.get(drop, 'note', false) ? drop.note : '-'}`}
              clickable={allowCancel}
              onClick={async () => {
                const clickable = allowCancel
                  if (clickable) {
                  const result = await Dialog.confirm({
                    title: 'Confirm Cancel Drop ?',
                    content: `${dateToHumanString(drop.start_date)} - ${dateToHumanString(drop.end_date)}`,
                    cancelText: 'Close',
                    confirmText: 'Cancel Drop',
                  })
                  if (result) {
                    handleCancelDrop(drop)
                  }
                }
              }}
            >
              {dateToHumanString(drop.start_date)} - {dateToHumanString(drop.end_date)} ({drop.days})
            </List.Item>
          )})
        }
      </List>
      <Row gutter={12} style={{ marginTop: '1rem' }} >
        <Col>
          <Text type='secondary'>
            Total Drops: { _.get(props, 'package.max_drop_count', 0) - _.get(props, 'package.drop_remain', 0) }/{ _.get(props, 'package.max_drop_count', 0) }
          </Text>
        </Col>
        <Col>
          <Text type='secondary'>
            Total Drop Days: { _.get(props, 'package.drop_days', 0) }/{ _.get(props, 'package.max_drop_days', 0) } days
          </Text>
        </Col>
      </Row>

    </Modal>
  )
}

MyDropPackageModal.defaultProps = {
  package: null,
  open: false,
  onClose: () => { },
}

MyDropPackageModal.propTypes = {
  package: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
}