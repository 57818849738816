import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { COOKIE_AUTH } from '../../../constants/cookies';
import { PATH } from '../../../routes/CustomRoutes';
import { URL_AUTH } from '../../../constants/urls';
import { POST } from '../../../frameworks/HttpClient';
import useUserData from '../../../hooks/useUserData';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const LogoutKiosk = ({ isLiff, idleTime } ) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = useUserData();
  const { t } = useTranslation();

  const handleLogout = async () => {
    setLoading(true);
    setError(null);
    try {
      await POST(URL_AUTH.SIGN_OUT, { is_liff: isLiff });
    } catch (error) {
      console.error("Logout failed:", error);
      setError(t("logout_failed_Please_try_again."));
      return;
    } finally {
      setLoading(false);
      Cookies.remove(COOKIE_AUTH.TOKEN);
      user.removeUserData();
      const page = isLiff ? PATH.LIFF_LANDING : PATH.KIOSK;
      navigate(page);
    }
  };

  useIdleTimer({
    // timeout: 1000 * 60 * 15, // 15 minutes
    timeout: 1000 * 60 * (idleTime ? idleTime : 1), // 1 minute
    onIdle: handleLogout,
    debounce: 500,
  });
  
};


LogoutKiosk.defaultProps = {
  isLiff: false,
};

LogoutKiosk.propTypes = {
  isLiff: PropTypes.bool,
};

export default LogoutKiosk;
