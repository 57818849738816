import React from 'react';
import propTypes from "prop-types";
import "./../../styles.css";

export default function SegmentHeader(props) {

	return (
		<div style={props.style}>
			<div 
				className="section-wrapper" 
				style={{
					padding: 0, 
					paddingLeft: 16,
					paddingRight: 16,
					backgroundColor: props.headerBackgroundColor, 
					borderBottomLeftRadius: 0, 
					borderBottomRightRadius: 0,
					...props.headStyle}}>
					<p style={{margin: 4, color: props.headerColor, fontWeight: "bold"}}>{props.header}</p>
			</div>
			<div 
				className="section-wrapper" 
				style={{
					padding: 16,
					borderTopLeftRadius: 0, 
					borderTopRightRadius: 0,
					...props.contentStyle}}>
					{props.content}
			</div>
		</div>
	)
}

SegmentHeader.defaultProps = {
	style: {},
	header: "",
	headStyle: {},
	headerColor: "#fff",
	headerBackgroundColor: "#AF3033",
	contentStyle: {},
	content: null,
}

SegmentHeader.propTypes = {
	style: propTypes.object,
	header: propTypes.string,
	headStyle: propTypes.object,
	headerColor: propTypes.string,
	headerBackgroundColor: propTypes.string,
	contentStyle: propTypes.object,
	content: propTypes.element,
}