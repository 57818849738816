import React from "react";
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row, message, theme, Spin, Avatar, List, Skeleton } from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import HeaderScreen from "../components/common/HeaderScreen";
import SegmentHeader from "../components/common/SegmentHeader";
import ClassDetailModal from "../components/pt/ClassDetailModal";
import { GET, POST } from "../frameworks/HttpClient";
import { URL_TRAINER } from "../constants/urls";
import dayjs from 'dayjs';
import { UserOutlined } from "@ant-design/icons";
import useUserData from "../hooks/useUserData";
import { USER_GROUP } from "../constants/string";


export default function ClassDetailScreen() {
  const { user } = useUserData();
  const isGroupEditable = user.groups.find(group => group == USER_GROUP.PT_MANAGER || group == USER_GROUP.PT || group == USER_GROUP.PT_FREELANCER) ? true: false;

  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = React.useState(false);
  const [classDetail, setClassDetail] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [canEdit, setCanEdit] = React.useState(false);
  const tableRef = React.useRef(null);
  // Handle Package modal
  const [openClassModal, setOpenClassModal] = React.useState(false);
  const {
    token: { colorInfoTextHover }
  } = theme.useToken();

  // attendant list
  const [attendant, setAttendant] = React.useState([]);
  const [listLoading, setListLoading] = React.useState(false);
  const [listPage, setListPage] = React.useState(1);

  const [checkMembers, setCheckMembers] = React.useState([])
  const [join, setJoin] = React.useState(0)
  
  const columns = [
    {
      key: 'profile_image',
      title: 'Profile Image',
      dataIndex: 'member_profile_image',
      align: 'center',
      render: (value, _) => (
        <div>
          <Avatar src={value} size={64} shape='square'/>
        </div>
      )
    },
    {
      key: 'member_code',
      title: 'Member ID',
      dataIndex: 'member_code',
      align: 'center',
    },
    {
      key: 'member_name',
      title: 'Name',
      dataIndex: 'member_name',
      width: "30%",
      align: 'center',
    },
    {
      key: 'member_mobile_phone',
      title: 'Mobile Phone',
      dataIndex: 'member_mobile_phone',
      align: 'center',
    },
    {
      key: 'checked',
      title: 'Status',
      dataIndex: 'checked',
      align: 'center',
      render: (value, original) => (
        <Checkbox 
          disabled={canEdit ? false : true}
          checked={checkMembers.includes(original.id)} 
          onChange={(e) => onChange(e, original)} />
      )
    }
  ];
  const key = 'updatable';
  const MessageSuccess = () => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: 'success',
        content: 'Success!',
        duration: 2,
      });
    }, 500);
  };

  // Fetch class detail
  const fetchClassDetail = async () => {
    const id = (params && params.id) ? params.id : null
    if (!id) {
      setErrorMessages("PT Class not found!")
      return;
    }
    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_TRAINER.TRAINER_CLASS}${id}/`);
      setClassDetail(response.data);
      setJoin(response.data['join'])
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  const fetchAttendant = async () => {
    setListLoading(true);
    try {
      const response = await GET(`${URL_TRAINER.MEMBER_BOOKING}?trainer_class=${params ? params.id : ""}&page_size=100`)
      setAttendant(response.data.results)
      setCheckMembers(response.data.results.filter(d => d.checked).map(d => d.id))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setListLoading(false);
    }
  }

  const refreshTable = () => {
    if (tableRef) 
      tableRef.current.fetchWithoutResetPage();
  }

  // Handle member check-in
  const onChange = (e, item) => {
    if(e.target.checked && !checkMembers.includes(item.id)) {  // Add item
      setCheckMembers([...checkMembers, item.id])
    }
    else {  // Remove item
      setCheckMembers(checkMembers.filter(mId => mId !== item.id))
    }
  };

  const handleCheckInMembers = async () => {
    if (classDetail == null) {
      messageApi.open({
        type: 'error',
        content: 'Unknown class. Please refresh page!',
      });
      return;
    }
    if (parseInt(join) < checkMembers.length) {
      messageApi.open({
        type: 'error',
        content: "The attend value should be more than checked member.",
      });
      return;
      
    }

    setErrorMessages(null)
    setLoading(true)
    try {
      await POST(`${URL_TRAINER.MEMBER_ATTENDANCE}`, {
        'trainer_class':  classDetail.id,
        'member_bookings': checkMembers,
        'join': join
      })
      MessageSuccess();
      refreshTable();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  // Check can update class
  const checkUpdate = (dateString) => {
    const date = dayjs(dateString);
    let days = date.diff(dayjs(), 'days');
    setCanEdit((days < -2) ? false : true);
  }
  
  const renderHeader = () => {
    const header = "รายชื่อสมาชิกที่จอง"
    if(classDetail) {
      return header +
       ` (${classDetail.booked_count} จาก ${classDetail.members_limit} คน)` +
       ` (คงเหลือว่าง ${classDetail.balance} คน)`
    }
    else {
      return header
    }
  }

  React.useEffect(() => {
    if (classDetail) {
      form.setFieldsValue({
        ...classDetail,
        time: `${classDetail.start_time.substring(0, 5)} - ${classDetail.end_time.substring(0, 5)}`,
      });
      // Check day pass
      checkUpdate(classDetail.date);
    }
  }, [classDetail]);

  React.useEffect(() => {
    if (params && params.id) {
      fetchClassDetail();
      fetchAttendant();
    }
  }, [params]);

  return (
    <Spin spinning={loading} tip="Loading" size="large" >
    <Layout>
      {contextHolder}
      <HeaderScreen
        title={classDetail ? classDetail.class_type_name : "PT Class"}
        onClick={() => navigate(-1)} />

      <Content style={{ margin: 24 }}>
        {errorMessages &&
          <Alert
            style={{ marginBottom: "12px", textAlign: "left" }}
            showIcon
            message={errorMessages}
            type="error" />
        }
        <Form
          form={form}
          style={{ marginBlockEnd: 'auto' }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}>
          <Row gutter={16} >
            <Col md={6} sm={12} xs={12}>
              <Form.Item name={"date"} label={"Date"}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={12}>
              <Form.Item name={"time"} label={"Time"}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={12}>
              <Form.Item name={"class_type_name"} label={"Class"}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={12}>
              <Form.Item name={"studio_name"} label={"Studio"}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={12}>
              <Form.Item name={"trainer_name"} label={"Trainer"}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={12}>
              <Form.Item name={"members_limit"} label={"Limit"}>
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={12}>
              <Form.Item name={"join"} label={"Attend"}>
                <Input disabled />
              </Form.Item>
            </Col>

            {canEdit && isGroupEditable && (
              <Col md={6} sm={12} xs={18} style={{textAlign: "right", marginBottom: '1rem' }}>
                <Button
                  type={"primary"}
                  onClick={() => setOpenClassModal(true)}
                  style={{
                    background: colorInfoTextHover, 
                    width: '66.7%',
                  }}>Edit</Button>
            </Col>
            )}
          </Row>

          <SegmentHeader
            header={renderHeader()}
            content={
              <div>
                <List
                  loading={listLoading}
                  dataSource={attendant}
                  itemLayout="horizontal"
                  renderItem={(item) => {
                    return (
                    <List.Item extra  style={{ background: checkMembers.includes(item.id) ?  '#E8F6EA' : '' }}>
                      <Skeleton avatar loading={false}>
                        <List.Item.Meta
                          style={{ textAlign: "left", margin: 0 }}
                          avatar={item.member_profile_image ? (
                            <div style={{ margin: 10 }}> 
                              <Avatar shape="circle" size={64} src={item.member_profile_image}/>
                            </div>
                          ) : (
                            <div style={{ margin: 10 }}> 
                              <Avatar shape="circle" size={64} icon={<UserOutlined />} />
                            </div>
                          )}
                          title={<a>{item.member_name}</a>}
                          description={
                            
                            <>
                              <div>Member ID: {item.member_code}</div>
                              <div>Mobile phone : {item.member_mobile_phone}</div>
                            </>
                          }/>
                        <div style={{ margin: 10 }}>
                        <Checkbox 
                          disabled={canEdit ? false : true}
                          checked={checkMembers.includes(item.id)} 
                          onChange={(e) => {
                            onChange(e, item)
                          }} />
                        </div>
                      </Skeleton>
                    </List.Item>
                )}}/>
                
                <Row
                  gutter={[0, 8]}
                  style={{ marginTop : 16 }}
                  justify='end'>
                  <Col
                    lg={{ span: 8, offset: 1 }}
                    md={{ span: 10, offset: 1 }}
                    sm={{ span: 16, offset: 1 }}
                    xs={{ span: 24, offset: 1 }}
                  >
                    <Input 
                      autoComplete="off"
                      type="number"
                      style={{ textAlign: 'right', width: '100%' }}
                      addonBefore={"Attend :"}
                      addonAfter={"Person"} 
                      onFocus={evt => evt.target.select()}
                      onChange={evt => setJoin(evt.target.value)}
                      value={join}/>
                  </Col>
                  <Col
                    lg={{ span: 2, offset: 1 }}
                    md={{ span: 4, offset: 1 }}
                    sm={{ span: 4, offset: 1 }}
                    xs={{ span: 11, offset: 1 }}
                  >
                    {isGroupEditable && <Button
                      block
                      loading={loading}
                      type={"primary"}
                      onClick={handleCheckInMembers}
                      style={{
                        background: colorInfoTextHover,
                      }}>Save</Button>}
                  </Col>
                </Row>
              </div>
            }/>
        </Form>
      </Content>

      <ClassDetailModal
        id={params}
        data={classDetail}
        open={openClassModal}
        onUpdated={() => {
          setOpenClassModal(false);
          fetchClassDetail();
        }}
        onDelete={() => {
          setOpenClassModal(false);
          navigate(-1)
        }}
        onClose={() => setOpenClassModal(false)}
      />
    </Layout>
    </Spin>
  )
}
