import React from 'react'
import { Footer, } from 'antd/es/layout/layout'
import { Alert, Button, Image, Layout, Row, Form } from 'antd';
import HeaderScreen from '../components/common/HeaderScreen';
import flagEng from "../assets/eng.png";
import flagThai from "../assets/thai.png";
import "../styles.css"
import MemberContractTHView from '../components/member/MemberContractTHView';
import MemberContractENView from '../components/member/MemberContractENView';
import SignaturePad from 'react-signature-canvas'
import { GET, POST } from '../frameworks/HttpClient';
import { PATH } from '../routes/CustomRoutes';
import { closeTab } from '../frameworks/Util';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { URL_MEMBER } from '../constants/urls';
import { CONTRACT_TYPE } from '../constants/string';

const SIGN_PAD_HEIGHT = 15; // Percent of sign pad on screen


const defaultPackageDetail = {
	duration: 1,
	price: 0,
	conditions: [],
}

export default function SignContrackScreen() {
	const windowSize = React.useRef([window.innerWidth, window.innerHeight]);
  const [errorMessages, setErrorMessages] = React.useState(null);
	const [isSuccess, setIsSuccess] = React.useState(false);
	const [packageDetail, setPackageDetail] = React.useState(defaultPackageDetail);
	const [local, setLocal] = React.useState('EN');
	const padRef = React.useRef(null);
	const signatureRef = React.useRef(null);
	const [width, setWidth] = React.useState(0);
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = useParams();
	

	// Fetch Packages
	const fetchPackageDetail = async () => {
		if (!params.id) {
			setErrorMessages("Member ID not found!")
			return
		}
		if (params.type != CONTRACT_TYPE.PACKAGE) {
			return
		}

		setErrorMessages(null);
		try {
			const response = await GET(`${URL_MEMBER.MEMBER}${params.id}/membership-package-detail/`)
			setPackageDetail(response.data);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const handleSubmit = async () => {
		const transferAmount = searchParams.get('transferAmount')
		let data = {
			type: params.type,
			member: params.id,
			price: packageDetail.price,
			duration: packageDetail.duration,
		}
		let signatureError = "Signature is empty!"
		if (signatureRef && signatureRef.current) {
			const ref = signatureRef.current;
			if (!ref.isEmpty()) {
				data["signature"] = ref.getTrimmedCanvas().toDataURL("image/png");
				signatureError = null;
			}
		}

		if (signatureError) {
			setErrorMessages(signatureError);
			return;
		}
		setErrorMessages(null);
		try {
			await POST(URL_MEMBER.MEMBERSHIP_PACKAGE_CONTRACT, data)
			setIsSuccess(true);
			if (transferAmount != null && transferAmount > 0) {
				navigate(`${PATH.PROMPTPAY_QR}/${transferAmount}`)
			}
			else {
				closeTab()
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}finally{
			signatureRef.current.off();
		}
  }

	const handleClear = () => {
		if (signatureRef && signatureRef.current) {
			signatureRef.current.clear();
		}
        setErrorMessages(null);
	}

	React.useEffect(() => {
	// Initial width for signature pad
	if (padRef && (width === 0)) {
		setWidth(padRef.current.offsetWidth);

	}}, [padRef])

	React.useEffect(() => {
		if (params.id)
			fetchPackageDetail(params.id);

	}, [params.id])

	return (
		<Layout style={{ minHeight: "100vh", textAlign: "left"}}>
			<HeaderScreen title={"ALPHA HEALTH CLUB PHUKET"} showBackIcon={false}/>
			<div style={{ margin: 24, textAlign: "left"}}>
				<Row align='end' style={{marginBottom: 16}}>
					<Image
						src={flagEng}
						preview={false}
						style={{ borderRadius: 8, height: "24px", width: "auto"}}
						onClick={() => setLocal("EN")} /> &nbsp; &nbsp; 
					<Image
						src={flagThai}
						preview={false}
						style={{ marginLeft: 0, borderRadius: 8, height: "24px", width: "auto"}}
						onClick={() => setLocal("TH")} />
				</Row>
				{(local === "TH") && (
					<MemberContractTHView detail={packageDetail}/>  
				)}

				{(local === "EN") && (
					<MemberContractENView detail={packageDetail}/>
				)}
				
				<p style={{fontWeight: 'bold', marginTop: 24}} >{"Signature"}</p>
				{errorMessages && 
					<Alert
						style={{marginBottom: "12px", textAlign: "left"}}
						message={errorMessages}
						type="error"
						showIcon/>
				}
				{isSuccess && 
					<Alert
						style={{marginBottom: "12px", textAlign: "left"}}
						message="Success"
						type="success"
						showIcon/>
				}
				
				<div 
					className="section-wrapper" 
					ref={padRef}>
					<SignaturePad 
						style={{ margin: 24, textAlign: "left"}}
						penColor={"blue"} 
						ref={signatureRef} 
						canvasProps={{ 
							width: (width ? (width - 32) : 0),
							height: (windowSize.current ? (windowSize.current[1] * SIGN_PAD_HEIGHT) / 100 : 120),
						}}/>
				</div>
			</div>

      <Footer align = "right">
				<Button key={"1"} type={"primary"} ghost onClick={handleClear} disabled={isSuccess} style={{marginRight: 12}}>{"Clear"}</Button>
				<Button key={"2"} type={"primary"} onClick={handleSubmit} disabled={isSuccess} >{"Confirm"}</Button>
      </Footer>
		</Layout>
	)
}