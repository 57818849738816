import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Card, Col, Image, List, Modal, Row, Select, theme } from "antd";
import Header from '../common/Header';
import { ShoppingCartOutlined } from '@ant-design/icons';
import logo from "../../assets/ahc_logo.png"
import "../../styles.css"
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE, URL_SALE, URL_USERS } from '../../constants/urls';
import { formatComma } from '../../frameworks/Util';

const SessionItem = ({data, trainerOptions, onError, onPackageSelected}) => {
	const [sessionOptions, setSessionOptions] = React.useState([]);
	const [session, setSession] = React.useState(null);
	const [trainer, setTrainer] = React.useState(null);
	const [selected, setSelected] = React.useState(null);

	const {
    token: { colorSuccess, colorTextQuaternary },
  } = theme.useToken();

	const selectPackage = () => {
		if (!data) {
			onError("PT Package not found!");
			return;
		}

		onError(null);
		const results = data.variants.filter(el => el.name === session.label);
		const trainerType = trainer.trainer_type
		const minSession = results.reduce((prev, curr) => (parseFloat(prev.price) < parseFloat(curr.price)) ? prev : curr);
		let selected = minSession;
		// Find session match trainer_type
		if (trainerType) {
			const temp = results.find(el => el.trainer_type === trainerType);
			if (temp) selected = temp;
		}
		setSelected(selected);
	}

	React.useEffect(() => {
		if (session && trainer) {
			selectPackage();
		}
	}, [session, trainer]);

	React.useEffect(() => {
		if (data) {
			let options = [];
			for (let item of data.variants) {
				const found = options.find(el => el.label === item.name);
				if (!found) {
					options.push({
						id: item.id,
						value: item.id,
						label: item.name,
						max_usage: item.max_usage,
					})
				}
			}
			options.sort((a, b) => a.max_usage - b.max_usage);
			setSessionOptions(options);
		}
	}, [data]);

	return (
		<List.Item key={data ? data.id : "1"} style={{padding:0}}>
			<Card>
				<Row>
					<Col md={6} sm={24} xs={24} style={{textAlign: "center"}}>
						<Image style={{height: 86, width: "auto"}} src={logo}/>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<p 
							style={{fontWeight: "bold", marginTop: 0}}>
								{data ? data.label : ""}
						</p>
						<Select 
							style={{width: "90%"}}
							options={sessionOptions}
							placeholder={"Session"} 
							onSelect={(value, selected) => setSession(selected)}
							value={session ? session.label : null}/>
						<Select 
							style={{width: "90%", marginTop: 8}}
							options={trainerOptions}
							placeholder={"Trainer"} 
							onSelect={(value, selected) => setTrainer(selected)}
							value={trainer ? trainer.label : null}/>
					</Col>

					<Col md={6} sm={24} xs={24} style={{textAlign: "right"}}>
						<Button
							type={"primary"}
							icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
							style={{
								width: "100%",
								background: selected ? colorSuccess : "#fff",
								fontWeight: "bold", 
								margin: 0 }}
							disabled={selected ? false : true}
							onClick={() => onPackageSelected(selected, trainer)}>
								{"Purchase"}
						</Button>
						<p 
							style={{color: colorTextQuaternary, marginTop: 0,}}>
								{`${selected? formatComma(selected.price) : "0"} Bath`}
						</p>
					</Col>
				</Row>
			</Card>
		</List.Item>
	)
}

const PT_SESSION = [
	{key: 1, value: "11", label: "PT Package 1:1", variants: []},
	{key: 2, value: "12", label: "PT Package 1:2", variants: []},
]
export default function AdditionalPackageModal(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [packages, setPackages] = React.useState([]);
	const [trainers, setTrainers] = React.useState([]);

	// Add item to cart
	const addToCart = async (variant, trainer) => {
		if (!props.memberID || !variant || !trainer) {
			setErrorMessages("Data not found!");
			return;
		}

		setErrorMessages(null);
		// Prepare data
		let myData = {
			member: props.memberID,
			trainer_ref: trainer.id,
			item: `${URL_PACKAGE.PT_SESSION_PACKAGE_VARIANT}${variant.id}/`
		}
		try {
			await POST(URL_SALE.CART_ITEM, myData);
			props.onAdded();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const prepareData = (list) => {
		let myPackages = PT_SESSION;
		for (let item of list) {
			for (let variant of item.variants) {
				const temp = {
					...variant,
					detail: item.detail,
					name: item.name,
					max_usage: item.max_usage,
				}

				const found = myPackages.find(el => el.value === variant.type);
				if (found) {
					found.variants.push(temp);
				}
			}
		}
		setPackages(myPackages);
	}

	// Fetch trainer list
	const fetchTrainers = async () => {
		try {
			const response = await GET(`${URL_USERS.TRAINERS}?page_size=100`);
			setTrainers(response.data.results.map(item => ({...item, value: item.id, label: item.full_name})));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Fetch additional package list
	const fetchData = async () => {
		try {
			const response = await GET(`${URL_PACKAGE.PT_SESSION_PACKAGE}?page_size=100`);
			prepareData(response.data.results)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			fetchData();
			fetchTrainers();
		} else {
			setErrorMessages(null);
			setPackages([]);
			setTrainers([]);
			// Clear variants
			for (let item of PT_SESSION) {
				item.variants = [];
			}
		}
	}, [props.open])

  return (
    <Modal
			width={"760px"}
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}
			footer={[
				<Button type={"primary"} onClick={props.onClose}>{"Close"}</Button>
			]}>
      <Header title={"Additional Package"}></Header>

			{errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{ marginTop: "12px", textAlign: "left"}}/>
			)}
			
			<List 
				grid={{gutter: 8, column: 1}}
				style={{marginTop: 16}}
				dataSource={packages}
				renderItem={(item) => (
					<SessionItem 
						data={item}
						trainerOptions={trainers}
						onError={(error) => setErrorMessages(error)}
						onPackageSelected={(variant, trainer) => addToCart(variant, trainer)}/>
				)}>
			</List>
    </Modal>
  )
}

AdditionalPackageModal.defaultProps = {
  open: false,
	memberID: null,
	onAdded: () => {},
  onClose: () => {},
}

AdditionalPackageModal.propTypes = {
  open: propTypes.bool,
	memberID: propTypes.string,
	onAdded: propTypes.func,
  onClose: propTypes.func,
}