import { Content } from "antd/es/layout/layout";
import Header from "../common/Header";
import { Table } from "antd";
import dayjs from "dayjs";

export default function PDPAModalTHView (props) {
    
	const columns = [
		{
		  title: 'ข้อมูลส่วนบุคคล',
		  dataIndex: 'personal',
		  key: 'personal',
		},
		{
		  title: 'วัตถุประสงค์',
		  dataIndex: 'objective',
		  key: 'objective',
		},
		{
		  title: 'ระยะเวลาในการเก็บรวบรวม',
		  dataIndex: 'phase',
		  key: 'phase',
		},
	  ];

	  const dataSource = [
		{
		  personal: <>-รูปภาพของสมาชิก<br/>
		  -ชื่อ ที่อยู่ เบอร์โทรศัพท์ อีเมลล์ หรือช่องทางการสื่อสารอื่น<br/>
		  -ข้อมูลศาสนา</>,
		  objective: <>เพื่อใช้ในการส่งเสริมการให้บริการ การโฆษณา และประชาสัมพันธ์ การตลาดแบบตรง หรือการเข้าทำสัญญา</>,
		  phase: <>อัลฟ่า เฮลธ์ คลับจะเก็บข้อมูลส่วนบุคคลตลอดจนเอกสารอันเป็นข้อมูลส่วนบุคคลของสมาชิกไว้ตลอดระยะเวลาการเป็นสมาชิก เมื่อพ้นสภาพการเป็นสมาชิกแล้ว อัลฟ่า เฮลธ์ คลับจะเก็บข้อมูลส่วนบุคคลของสมาชิกไว้เป็นเวลา 10 ปี นับแต่วันที่เลิกสัญญา
		  </>,
		}
	  ]

	return (
		<div>
			<Header
				fontSize={25}
				title={"หนังสือยินยอมให้เก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลที่มีความอ่อนไหว"}
			/>
			<Content style={{ margin: 62, textAlign: 'left', marginTop: 0,fontSize: 15 }}>
				<p style={{ fontWeight: 'bold' }}>ข้าพเจ้ายินยอมให้ ห้างหุ้นส่วนจำกัด อัลฟ่า เฮลธ์ คลับ  (“อัลฟ่า เฮลธ์ คลับ”) เก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลของข้าพเจ้า ได้แก่</p>
				<Table textAlign={"center"} dataSource={dataSource} columns={columns} pagination={false}/>
				<p>
				<b>หมายเหตุ :</b> ในกรณีปรากฏมีข้อมูลที่ต้องได้รับความยินยอมอย่างชัดแจ้ง เช่น ศาสนา หมู่โลหิต ปะปนอยู่กับเอกสาร 
				หรือข้อมูลอื่นที่ข้าพเจ้าให้แก่อัลฟ่า เฮลธ์ คลับ เนื่องจากข้อมูลที่ข้าพเจ้าส่งให้มีลักษณะเป็นชุดข้อมูล ข้าพเจ้าให้ความยินยอมอย่างชัดแจ้งว่า 
				อัลฟ่า เฮลธ์ คลับสามารถทำลาย ทำให้ข้อมูลส่วนนั้นเสียหาย หรือเลือกที่จะไม่บันทึกข้อมูลดังกล่าวได้
				</p>
				<br/>
				<p>
				&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;ข้าพเจ้ารับทราบว่าอัลฟ่า เฮลธ์ คลับเป็นผู้ควบคุมข้อมูลส่วนบุคคลนี้ โดยอัลฟ่า เฮลธ์ คลับจะจัดให้มีระบบรักษาซึ่งความมั่นคงปลอดภัยให้แก่ข้อมูลส่วนบุคคล 
				เพื่อป้องกันมิให้มีการนำไปใช้หรือเปิดเผยโดยไม่เหมาะสมหรือเป็นผลร้ายต่อข้าพเจ้า หรือเปลี่ยนแปลงโดยไม่มีสิทธิหรือไม่ชอบด้วยกฎหมาย 
				หนังสือยินยอมฉบับนี้ให้มีผลนับจากวันที่ลงนามจนกว่าข้าพเจ้าจะมีการแจ้งยกเลิกเป็นลายลักษณ์อักษรไปยัง อัลฟ่า เฮลธ์ คลับ 
				เลขที่ 9/56 หมู่ที่ 5 ตำบลวิชิต อำเภอเมือง จังหวัดภูเก็ต 83000 หมายเลขโทรศัพท์ 076-612656 อีเมล์ info@alphahealthclub.com 
				</p>
			</Content>
			</div>
    )
}