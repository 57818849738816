import React from "react";
import propTypes from "prop-types";
import { Card, Descriptions, Divider, Row, Space, theme } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import { DATE_FORMAT, HUMAN_DATE_FORMAT } from "../../constants/string";

export default function RewardCard(props) {

  const { Meta } = Card;
  const {
    token: { colorSuccess, colorPrimary }
  } = theme.useToken();

  React.useEffect(() => {

  }, [props.data])
  return (
    <div>
      <Row key={props.data.id}>
        <Card
          style={{
            borderRadius: '0.3rem',
            border: '1px solid #e6e6e6',
            width: '100%',
            boxShadow: '0px 2px 4px #e6e6e6',
            textAlign: 'left', marginLeft: 0, marginRight: 0,
          }}
          onClick={() => { }}>
          <Meta
            avatar={
              <Space style={{height: "100%"}}>
                {(props.data.type == "DEPOSIT")
                  ? <DownloadOutlined style={{ fontSize: 46, color: colorSuccess }} />
                  : <UploadOutlined style={{ fontSize: 46, color: colorPrimary }} />
                }
                <Divider type="vertical" style={{ height: "80px" }} />
              </Space>
            }
            description={
              <Descriptions 
                className="small-descriptions" 
                size={"small"} 
                title={`${props.data.amount} Points`}>
                <Descriptions.Item style={{marginTop: 0}} label="Date">{props.data.created ? dayjs(props.data.created).format(DATE_FORMAT) : "-"}</Descriptions.Item>
                <Descriptions.Item label="Activity">{props.data.cause ? props.data.cause : "-"}</Descriptions.Item>
                <Descriptions.Item label="Balance Points">{props.data.curr ? props.data.curr : "0"}</Descriptions.Item>
                <Descriptions.Item label="Note">{props.data.note ? props.data.note : "-"}</Descriptions.Item>
              </Descriptions>
            }
          >
          </Meta>
        </Card>
      </Row>
    </div>
  )

}

RewardCard.defaultProps = {
  data: null,
}
RewardCard.propTypes = {
  data: propTypes.object,
}