import React from 'react'
import { Card, Modal, Typography, Row, Col, Image } from 'antd';
import img_menu from '../CssScreen/cover.png'
import { CloseCircleFilled } from '@ant-design/icons';
import * as _ from 'lodash'
import PackagesVariantsListKiosk from './PackagesVariantsListKiosk';
import { useTranslation } from "react-i18next";

export default function PurchaseKioskModal(props) {
  const { Text } = Typography;
  const { open, onClose } = props;
  const { packageData } = props;
  const { t } = useTranslation();
  const packageImage = _.get(packageData, 'image', null)
  const { languageUse } = props;


  return(
    <Modal
      width={880}
      closeIcon={<CloseCircleFilled className='custom-close-icon-modal' style={{ fontSize : 40 , paddingRight : 5 }}/>}
      open={open}
      onCancel={onClose}
      footer={null}
      centered
    >        
      <Row gutter={16} >
        <Col span={24}>
          <Text className='kiosk-purchase-title' style={{ marginTop : 50, justifyContent: 'center', color: "rgb(182, 31, 45)", marginBottom : 50}}>
            PACKAGES
          </Text>
        </Col>
        <Col span={10}>
          <center>
            <Image style={{ objectFit: 'cover' }} src={packageImage ? packageImage : img_menu} width={230} height={230} preview={false}/>
          </center>
        </Col>
        <Col span={14}>
          <Col span={24} style={{ marginRight : 24}}>
            <Text style={{fontSize: 40, fontWeight: 600, marginRight : 20}}>
              {_.get(packageData, 'name', 'Unknow')}
            </Text>
          </Col>
          <Col span={24} style={{ marginRight : 24}}>
            <Text style={{ fontSize: 25 }}>
            {_.get(packageData, languageUse, 'Unknow')}
            </Text>
          </Col>
        </Col>
        <Col span={24}>
          <div style={{ marginTop : 50 , textAlign : 'center'}}>
          <Text style={{ justifyContent: 'center',  fontSize: 25}}>
            {t('please_select_your_desired_package')}
          </Text>
          </div>
        </Col>
      </Row>
      <Col span={24}>   
        <Card>
          <PackagesVariantsListKiosk packageData={packageData} LanguageUse={languageUse} />
        </Card>
      </Col>
    </Modal>
  )
}