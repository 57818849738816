import React from "react";
import propTypes from "prop-types";
import { Alert, Col, DatePicker, Row, Select, theme } from "antd";
import TSCalendar from "../common/TSCalendar";
import dayjs from 'dayjs';
import { GET } from "../../frameworks/HttpClient";
import { URL_TRAINER } from "../../constants/urls";
import { PATH } from "../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";
import EventPTSessionModal from "./EventPTSessionModal";

const ClassMonthView = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [targetDate, setTargetDate] = React.useState(dayjs());
  const [studio, setStudio] = React.useState(null);
  const [trainer, setTrainer] = React.useState(null);
  const [studioOptions, setStudioOptions] = React.useState([]);
  const [trainerOptions, setTrainerOptions] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [eventsDetail, setEventsDetail] = React.useState(null);
  const nowDate = dayjs();
  const {
    token: { colorInfoTextHover, colorSuccess, colorTextTertiary }
  } = theme.useToken();

  React.useImperativeHandle(ref, () => ({
    fetchData: () => fetchEvent()
  }));

  const handleMonthChange = (date) => setTargetDate(date);

  const handleStudioChange = (value, selected) => setStudio(selected);

  const handlePTChange = (value, selected) => setTrainer(selected);

  const filterEvents = (date, list) => {
    
    if (list.length > 0) {
      setEventsDetail({
        date: date.format("DD MMMM YYYY"),
        events: list
      })
    }
  }

  const fetchEvent = async () => {
    // Prepare Data
    let params = {
      month: targetDate ? targetDate.month() + 1 : null,
      year: targetDate ? targetDate.year() : null,
      studio: studio ? studio.id : null,
      trainer: trainer ? trainer.id : null,
      page_size: 999
    }

    try {
      const response = await GET(URL_TRAINER.TRAINER_CLASS, params);
      setEvents(response.data.results.map(item => {
        const eventDate = new Date(`${item.date} , ${item.end_time}`);
        const label = (<>
          {item.class_type_name ? item.class_type_name : item.note} ({item.start_time.substr(0, 5)} - {item.end_time.substr(0, 5)}) <br/>
          {item.studio_name} - {item.trainer_name}
        </>)
        return {
          ...item,
          label: label,
          display_name: item.class_type_name,
          type: "pt_class",
          color: 
          (!item.class_type) ? colorTextTertiary :
          (item && item.is_checked === true) ? colorSuccess :
          (item.is_checked === false && nowDate <= eventDate) ? colorInfoTextHover : "#AFAFAF"
        }
      }));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (targetDate && studio) {
      fetchEvent();
    }
  }, [studio, trainer, targetDate])

  React.useEffect(() => {
    if (props.studioOptions && props.studioOptions.length > 0) {
      setStudioOptions([
        {id:null, value: null, label: "ALL"},
        ...props.studioOptions
      ]);
      setStudio(props.studioOptions[0]);
    }
  }, [props.studioOptions]);

  React.useEffect(() => {
    if (props.trainerOptions && props.trainerOptions.length > 0) {
      const allOption = { id: null, value: null, label: "ALL" }
      if (props.partner) {
        const partnerOption = {
          id: props.partner.id, 
          value: props.partner.id, 
          label: props.partner.full_name,
          ...props.partner
        }
        setTrainerOptions([allOption, partnerOption])
      } else {
        setTrainerOptions([ allOption, ...props.trainerOptions ])
      }
    }
  }, [props.trainerOptions]);

  const CustomCalendarHeader = ({ value, type, onChange, onTypeChange }) => {
    return (
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: 16 }}>
        <Col xl={8} sm={8} xs={24}>
          <label>{"Month: "}</label>
          <DatePicker
            style={{ width: "70%" }}
            inputReadOnly={true}
            allowClear={false}
            picker={"month"}
            value={targetDate}
            onChange={onChange} />
        </Col>
        <Col xl={8} sm={8} xs={24}>
          <label>{"Studio: "}</label>
          <Select
            style={{ width: "70%" }}
            options={studioOptions}
            value={studio}
            onChange={handleStudioChange} />
        </Col>
        <Col xl={8} sm={8} xs={24}>
          <label>{props.partner ? "Partner: " : "PT: "}</label>
          <Select
            style={{ width: "76%" }}
            value={trainer}
            options={trainerOptions}
            onChange={handlePTChange} />
        </Col>
      </Row>
    )
  }

  return (
    <div>
      {errorMessages && (
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{textAlign: "left", marginBottom: "8px"}}/>
      )}
      <TSCalendar
        data={events}
        headerRender={CustomCalendarHeader}
        onChange={handleMonthChange}
        onDateSelected={filterEvents} />
      
      <EventPTSessionModal
        open={eventsDetail ? true : false}
        onClose={() => setEventsDetail(null)}
        data={eventsDetail}
        gotoDetail={(id, isClass) => {
          setEventsDetail(null);
          if (isClass && props.partner == null)
            navigate(`${PATH.CLASS_DETAIL}/${id}/`);
          else
            props.onRentDetail(id);
        }}/>
    </div>
  )
});

ClassMonthView.defaultProps = {
  studioOptions: [],
  trainerOptions: [],
  partner: null,
  onRentDetail: (id) => {},
}

ClassMonthView.propTypes = {
  studioOptions: propTypes.array,
  trainerOptions: propTypes.array,
  partner: propTypes.object,
  onRentDetail: propTypes.func,
}

export default React.memo(ClassMonthView);