import { Alert, Button, Form, Input, Modal, Popconfirm } from "antd";
import React from "react";
import Header from "../../common/Header";
import { GET, POST } from "../../../frameworks/HttpClient";
import { URL_MEMBER, URL_USERS } from "../../../constants/urls";
import * as _ from "lodash";


export default function BlacklistModal (props) {
  const {
    member,
    open,
    onClose,
  } = props;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = await form.validateFields();
      await POST(URL_MEMBER.MEMBER_BLACKLIST_LOG, data)
      onClose();
    } catch (error) {
      setErrorMessages(error.errorMessages)
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const resetCashier = () => {
    form.setFieldValue('author', null)
    form.setFieldValue('cashier_name', '')
  }

  const getCashier = async () => {
    if (!form.getFieldValue('cashier')) {
      return
    }
    setIsLoading(true);
    try {
      const response = await GET(`${URL_USERS.CASHIER}${form.getFieldValue('cashier')}`)
      form.setFieldValue('author', _.get(response, 'data.id', ''))
      form.setFieldValue('cashier_name', _.get(response, 'data.full_name', ''))
    } catch (error) { } finally {
      setIsLoading(false);
    }
  }

  const getCashierName = () => {
    if (!form.getFieldValue('cashier_name')) {
      return '...'
    }
    return form.getFieldValue('cashier_name')
  }

  React.useEffect(() => {
    if (open) {
      form.setFieldValue('member', member);
    } else {
      setErrorMessages(null);
      form.resetFields();
    }
  }, [open])

  return (
    <div>
      <Modal
        open={open}
        onCancel={onClose}
        footer={[
          <Button loading={isLoading} onClick={onClose}>Cancel</Button>,
          <Popconfirm
            title='Blacklist confirm.'
            description='Are you sure to blacklist following member'
            onConfirm={handleSubmit}
          >
            <Button loading={isLoading} type='primary'>Blacklist</Button>
          </Popconfirm>
        ]}
      >
        <Header title='Blacklist'/>

        { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/> }

        <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginTop: '1rem' }}>
          <Form.Item name='member' hidden rules={[{ required: true }]}>
            <Input value={member}/>
          </Form.Item>
          <Form.Item name='author' hidden rules={[{ required: true }]}>
            <Input defaultValue={null}/>
          </Form.Item>
          <Form.Item name='cashier_name' hidden>
            <Input defaultValue={null}/>
          </Form.Item>

          <Form.Item name='cashier' label='Cashier' rules={[{ required: !form.getFieldValue('author') ? true : false }]}>
            <Input
              onChange={resetCashier}
              onPressEnter={getCashier}
              addonAfter={getCashierName()}/>
          </Form.Item>
     
          <Form.Item name='note' label='Note'>
            <Input.TextArea/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}