import React from "react";
import propTypes from "prop-types";
import { Alert, Button, Card, List, Modal, theme } from "antd";
import "./../../styles.css"
import DropPackageModal from "./DropPackageModal";
import DelayPackageModal from "./DelayPackageModal";
import TransferPackageModal from "./TransferPackageModal";
import ExchangePackageModal from "./ExchangePackageModal";
import DetailPackageModal from "./DetailPackageModal";
import { CheckCircleFilled } from "@ant-design/icons";
import * as _ from 'lodash';
import PackageRefTag from "./PackageRefTag";
import { URL_PACKAGE } from "../../constants/urls";
import { POST } from "../../frameworks/HttpClient";


const ActionLabel = ({label, onClick}) => {
  return (<p style={{fontSize: 12, margin: 0}} onClick={onClick}>{label}</p>)
}

export default function CardMemberPackage(props) {
  const [data, setData] = React.useState(null);
  // Handle Package modal
  const [openMore, setOpenMore] = React.useState(false);
  const [openDelay, setOpenDelay] = React.useState(false);
  const [openDrop, setOpenDrop] = React.useState(false);
  const [openTransfer, setOpenTransfer] = React.useState(false);
  const [openExchange, setOpenExchange] = React.useState(false);
  const [openDuplicateActivate, setOpenDuplicateActivate] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const { Meta } = Card;
  const {
    token: { colorSuccess },
  } = theme.useToken();

  const renderActions = (item) => {
    if (!item)
      return [];
    
    let actions = [];
    if (item.is_activated) {
      // Delay package (ซ่อนไว้ก่อน)
      // actions.push(<ActionLabel label={"Delay"} onClick={() => setOpenDelay(true)}/>);
      // Drop package
      if (item.max_drop_count > 0)
        actions.push(<ActionLabel label={"Drop"} onClick={() => setOpenDrop(true)}/>);
    }
    // Transfer package
    if ((item.usage_remain === null) && (item.duration_remain >= 30))  {
      actions.push(<ActionLabel label={"Transfer"} onClick={() => setOpenTransfer(true)}/>);
    }
    // Duplicate Activate
          if (item.allow_duplicate_activate && !item.is_activated) {
            actions.push(<ActionLabel label={"Activate"} onClick={() => setOpenDuplicateActivate(item)}/>)
          }
    // Exchange package
    if (item.allow_exchange && (item.usage_remain !== null) && (item.usage_remain > 0)) {
      actions.push(<ActionLabel label={"Exchange"} onClick={() => setOpenExchange(true)}/>);
    }
    // Package detail
    if (actions.length < 3) {
      actions.push(<ActionLabel label={"More"} onClick={() => setOpenMore(true)}/>)
    }

    return actions;
  }

	const handleActivate = async (item) => {
    const itemID = item.id
		// Handle Main Package
		setIsLoading(true);
		try {
			const response = await POST(`${URL_PACKAGE.MEMBER_PACKAGE}${itemID}/activate/`);
      setOpenDuplicateActivate(null)
      props.onRefresh();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
			setIsLoading(false)
		}
	}

  React.useEffect(() => {
    if (props.data) {
      const extra_duration = props.data.extra_duration
      const duration_remain = props.data.duration_remain
      setData(
        {
          ...props.data,
          using_extra: (extra_duration > 0) && (extra_duration > duration_remain)
        });
    }

  }, [props.data])

  return (
    <div>
      <Card 
        actions={renderActions(data)}>
				<div 
					style={{
            cursor: 'pointer',
						fontWeight: 'bold',
						lineHeight: "1.5em", 
						height: "3em", 
						overflow: "hidden"
          }}
          onClick={() => setOpenMore(true)}>
          <span style={{ float: 'right' }}><PackageRefTag data={data}/></span>
          {data && <>
            {data.has_dynamic && `[ALL CLUB - ${data.dynamic_package.name}] `}
            {data.package_name ? data.package_name : "Unknown"}
            {data.variant_type && ` (${data.variant_type})`}
          </> }
          {data && data.is_activated && (<CheckCircleFilled style={{marginLeft: 4, color: colorSuccess}}/>)}
        </div>

        <Meta
          style={{ marginTop: 5 }}
          description={`Start: ${(data && data.start_date) ? data.start_date : "-"}`}
        />
        <Meta description={`Exp: ${(data && data.end_date) ? data.end_date : "-"}`} />

        {data && 
          <Meta description={
            <div>
              <label>Duration: </label>
              <label style={{color: data.using_extra ? "red" : null}}>
                {`${data.duration_remain ? data.duration_remain : "-"} (${data.using_extra ? "Extra " : ""}days)`}
              </label>
            </div>
          }/>
        }
        
        {data &&
          <Meta description={`Usage Remain: ${data.usage_remain != null ? data.usage_remain : "Unlimited"}`} />
        }
      </Card>

      <DetailPackageModal 
        package={data}
        open={openMore}
        onClose={() => setOpenMore(false)} />
      
      <DelayPackageModal
        package={data}
        open={openDelay}
        onClose={() => setOpenDelay(false)}
        onSuccess={() => {
          setOpenDelay(false);
          props.onRefresh();
        }}/>

      <DropPackageModal
        package={data}
        open={openDrop}
        onClose={() => { 
          setOpenDrop(false)
          props.onRefresh();
        }} 
        />

      <TransferPackageModal
        package={data}
        open={openTransfer}
        onClose={() => setOpenTransfer(false)} 
        onSuccess={(another) => {
          setOpenTransfer(false);
          props.onCartUpdate();
        }}/>

      <ExchangePackageModal
        package={data}
        open={openExchange}
        onClose={(isSuccess) => {
          setOpenExchange(false);
          if(isSuccess) {
            // Reload browser
            window.location.reload(false);
          }
        }} />

      <Modal 
				title="Confirm Activate Package" 
				open={openDuplicateActivate != null}
        footer={[
          <Button loading={isLoading} onClick={() => setOpenDuplicateActivate(null)}>Cancel</Button>,
          <Button loading={isLoading} type="primary" onClick={() => handleActivate(openDuplicateActivate)}>Activate</Button>
        ]}
      >
        {errorMessages && 
        
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />
        }
				<p>Are you sure to activate package ?</p>
        <List>
          <List.Item>
            <List.Item.Meta
              title={<span>{`Name: ${ _.get(openDuplicateActivate, 'package_name', '') }`}</span>}
              description={
                <div>
                  <div>Type: { _.get(openDuplicateActivate, 'variant_type', '') }</div>
                  <div>Duration: { _.get(openDuplicateActivate, 'duration_remain', '') } Days</div>
                </div>
              }/>
          </List.Item>
        </List>
			</Modal>
    </div>
  )
}

CardMemberPackage.defaultProps = {
  data: null,
  onRefresh: () => {},
  onCartUpdate: () => {},
}

CardMemberPackage.propTypes = {
  data: propTypes.object,
  onRefresh: propTypes.func,
  onCartUpdate: propTypes.func,
}