import React from "react";
import propTypes from "prop-types";
import TSListScroll from "../common/TSListScroll";
import { URL_PACKAGE } from "../../constants/urls";
import { Divider, List, Space, theme } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { dateTimeToHumanString } from "../../frameworks/Util";


export default function PackagePenaltyList (props) {
  const {
    member,
  } = props;

  const {
    token: { colorPrimary, colorTextSecondary }
  } = theme.useToken();

  const ref = React.useRef();

  React.useEffect(() => {
    if (member && ref.current) {
      ref.current.reset()
      ref.current.fetch()
    }
  }, [member])

  return (
    <div>
      <TSListScroll
        ref={ref}
        fetchParams={{ member }}
        url={URL_PACKAGE.PACKAGE_PENALTY}
        renderItem={(item) => (
          <List.Item key={item.id} style={{ margin: "4px 0px" }}>
            <Space align="center" style={{ marginRight: 16 }}>
              <div style={{ textAlign: "center", width: 64, color: colorPrimary, fontWeight: "bold" }}>
                <ExclamationCircleOutlined style={{ fontSize: 30 }}/>
              </div>
              <Divider type="vertical" style={{ height: 80 }} />
              <div style={{ textAlign: "left" }}>
                <h4 style={{ marginTop: 0, marginBottom: 8 }}>{item.member_package_name}</h4>
                <div style={{ color: colorTextSecondary }}>Penalty days : {item.days}</div>
                <div style={{ color: colorTextSecondary }}>
                  Date : {dateTimeToHumanString(item.created)}
                </div>
                <div style={{ color: colorTextSecondary }}>{item.note}</div>
              </div>
            </Space>
          </List.Item>
        )}/>
    </div>
  )
}

PackagePenaltyList.defaultProps = {
  member: null,
}

PackagePenaltyList.propTypes = {
  member: propTypes.string,
}