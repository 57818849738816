import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal, Select } from "antd";
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../constants/urls';
import Header from '../common/Header';

export default function ExchangePackageModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false)
  const [exchangeOptions, setExchangeOption] = React.useState([]); 
  const [selectedRule, setSelectedRule] = React.useState(null)
  const [balance, setBalance] = React.useState('-');
  const [form] = Form.useForm();

  const fetchPackageExchangeRules = async (packageId) => {
    try {
      setErrorMessages(null)
      setIsLoading(true)
      const response = await GET(`${URL_PACKAGE.PACKAGE_EXCHANGE_LIST}${packageId}`)
      setExchangeOption(response.data.results.map(rule => ({
        value: rule.id,
        ...rule,
      })))
    } catch(error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false)
    }

  }

  const handleServiceChange = (_, rule) => {
    setBalance(props.package.usage_remain - parseFloat(rule.cost));
    setSelectedRule(rule)
  };

  const handleConfirm = async () => {
    try {
      setErrorMessages(null)
      setIsLoading(true)
      await POST(`${URL_PACKAGE.PACKAGE_EXCHANGE}`, {
        'member_package': props.package.id,
        'package_rule': selectedRule.id
      });
      props.onClose(true)
    } catch(error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false)
    }
  };

  React.useEffect(() => {
    if (!props.open) {
      form.resetFields();
      setErrorMessages(null);
    }
    else {
      fetchPackageExchangeRules(props.package.id)
      setSelectedRule(null)
      setBalance('-')
    }
  }, [props.open]);
  
  return (
    <Modal
      okText={"Confirm"}
      okButtonProps={{
        loading: isLoading, 
        disabled: (balance < 0) ? true : false
      }}
      open={props.open}
      onOk={handleConfirm}
      onCancel={() => props.onClose(false)}>

      <Header title={"Exchange Package"} />
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginTop: "12px", textAlign: "left" }}
        />
      }

      <Form
        
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
        
        <Form.Item label={"Package"}>
          <Input autoComplete="off" value={props.package ? props.package.package_name : "Unknown"} disabled/>
        </Form.Item>

        <Form.Item label={"Available"}>
          <Input autoComplete="off" value={props.package ? props.package.usage_remain : "0"} disabled/>
        </Form.Item>

        <Form.Item name={"exchange_package"} label={"Exchange To"} rules={[{ required: true }]}>
          <Select 
            loading={isLoading}
            disabled={isLoading}
            placeholder={"Select..."}
            onChange={handleServiceChange}
            options={exchangeOptions} />
        </Form.Item>

        <Form.Item label={"Cost"}>
          <Input autoComplete="off" value={selectedRule ? selectedRule.cost : "-"} disabled />
        </Form.Item>

        <Form.Item label={"Balance"}>
          <Input autoComplete="off" value={balance} disabled/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

ExchangePackageModal.defaultProps = {
  package: null,
  open: false,
  onClose: (isSuccess) => {},
}

ExchangePackageModal.propTypes = {
  package: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
}