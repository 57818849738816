import React from "react"
import propTypes from "prop-types";
import { Alert, Button, Card, Col, Image, Modal, Row, theme , Collapse, Form} from "antd";
import Header from "../common/Header";
import logo from "../../assets/ahc_logo.png"
import { URL_PACKAGE, URL_SALE } from "../../constants/urls";
import { GET, POST } from "../../frameworks/HttpClient";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { formatComma } from "../../frameworks/Util";

export default function AllClubPackageModal(props) {
	const { Panel } = Collapse;
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [catePackages, setCatePackages] = React.useState([]);
	const [packages, setPackages] = React.useState([]);

	const {
		token: {colorSuccess, colorTextSecondary}
	} = theme.useToken();

	const addToCart = async (data) => {
		if (!props.memberID || !data) {
			setErrorMessages("Data not found!");
			return;
		}
		let myData = {
			member: props.memberID,
			item: `${URL_PACKAGE.ALL_CLUB_PACKAGE}${data.id}/`
		}
		
		setErrorMessages(null);
		try {
			await POST(URL_SALE.CART_ITEM, myData);
			props.onAdded();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const fetchData = async () => {
		setErrorMessages(null);
		try {
			const response = await GET(`${URL_PACKAGE.ALL_CLUB_PACKAGE}?page_size=100`);
			setPackages(response.data.results);
			const cate = await GET(`${URL_PACKAGE.PACKAGE_CATEGORY}?type=allclub_package`)
			setCatePackages(cate.data.results);
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			fetchData();
		} else {
			setErrorMessages(null);
		}
	}, [props.open])

	return (
		<Modal
			width={"760px"}
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}
			footer={[
				<Button type={"primary"} onClick={props.onClose}>{"Close"}</Button>
			]}>
			<Header title={"All Club Package"}></Header>

			{errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{
						marginBottom: "12px", 
						marginTop: "12px", 
						textAlign: "left"}}/>
			)}
	<Form>
	  <Collapse 
	  	className='custom-collapse-header'
		style={{
			background: "#AF3033",
			border: "none",
			colorTextHeading : "#fffff"}}>

	  		{catePackages.map((cate) => (
	  		<Panel header={`${cate.name}`} key={`${cate.id}`}>
				{packages && packages.filter(f => f.categories.includes(`${cate.id}`)).map(item => 
					(<Card >
							<Row>
								<Col md={6} sm={24} xs={24} style={{textAlign: "center"}}>
									<Image style={{height: 86, width: "auto"}} src={item.image ? item.image : logo}/>
								</Col>
								<Col md={12} sm={24} xs={24}>
									<p 
										style={{fontWeight: "bold", marginTop: 0}}>
											{item.name}
									</p>
									<div 
										style={{
											lineHeight: "1.5em",
											whiteSpace: "pre-wrap",
											color: colorTextSecondary}}>
										{(item.detail) ? item.detail : ""}
									</div>
								</Col>
								<Col md={6} sm={24} xs={24} style={{textAlign: "right"}}>
									
										<div >
											<Button
												type={"primary"}
												icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
												style={{
													width: "100%",
													background: colorSuccess,
													fontWeight: "bold", 
													margin: 0 }}
												onClick={() => addToCart(item)}>
													{`${item.price ? formatComma(item.price) : "0"} Bath`}
											</Button>
										</div>
								</Col>
							</Row>
						</Card>
						))}

			  </Panel>))}

			  
			  <Panel header={"Other"} key={"Other"}>
			  {packages && packages.map(item => item.categories.length === 0 && 
			  (<Card >
				<Row>
					<Col md={6} sm={24} xs={24} style={{textAlign: "center"}}>
						<Image style={{height: 86, width: "auto"}} src={item.image ? item.image : logo}/>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<p 
							style={{fontWeight: "bold", marginTop: 0}}>
								{item.name}
						</p>
						<div 
							style={{
								lineHeight: "1.5em",
								whiteSpace: "pre-wrap",
								color: colorTextSecondary}}>
							{(item.detail) ? item.detail : ""}
						</div>
					</Col>
					<Col md={6} sm={24} xs={24} style={{textAlign: "right"}}>
						
							<div >
								<Button
									type={"primary"}
									icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
									style={{
										width: "100%",
										background: colorSuccess,
										fontWeight: "bold", 
										margin: 0 }}
									onClick={() => addToCart(item)}>
										{`${item.price ? formatComma(item.price) : "0"} Bath`}
								</Button>
							</div>
					</Col>
				</Row>
			</Card>
			))}
		</Panel>

		</Collapse>
	</Form>
    		</Modal >
	)
}

AllClubPackageModal.defaultProps = {
  open: false,
	memberID: null,
	onAdded: () => {},
  onClose: () => {},
}

AllClubPackageModal.propTypes = {
  open: propTypes.bool,
	memberID: propTypes.string,
	onAdded: propTypes.func,
  onClose: propTypes.func,
}