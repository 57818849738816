import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoutes from './routes/CustomRoutes';

import { Layout, Spin, ConfigProvider } from 'antd';
import { PRIMARY_COLOR, LAYOUT_COLOR_BG } from './constants/color';
import MenuBadgeProvider from './hooks/MenuBadgeProvider';
import WindowDimensionProvider from './hooks/WindowDimensionProvider';
const { Content } = Layout;


function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
          colorBgLayout: LAYOUT_COLOR_BG
        },
      }}>
     <WindowDimensionProvider>
      <MenuBadgeProvider>
        <React.Suspense fallback={
          <Layout>
            <Content>
              <Spin  size="large" tip="Loading..."/>
            </Content>
          </Layout>
        }>
          <Router>
            <div className="App">
              <CustomRoutes />
            </div>
          </Router>
        </React.Suspense>
      </MenuBadgeProvider>
      </WindowDimensionProvider>
    </ConfigProvider>
    
  );
}

export default App;
