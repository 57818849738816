import React from 'react'
import propTypes from "prop-types";
import { Table, Button, Empty, message, Input, Space, Tooltip } from 'antd'
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons"
import Highlighter from 'react-highlight-words';
import errorIcon from "../../assets/message_error.png"
import { GET } from "../../frameworks/HttpClient";
import { objectMap, removeEmpty, replaceKey } from '../../frameworks/Util';

/**
 * Table component which support server side rendering with Django
 * Sample columns
 * columns = [
      {
        title: 'Member ID',
        dataIndex: 'code',
        key: 'code',
        sorter: true,
        searcher: true,
      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
      }
    ]
 */

const TSTable = React.forwardRef((props, ref) => {
  const FLAG_COLUMN_SEARCH = "searcher"
  const [pagination, setPagination] = React.useState({ ...props.paginationOptions, total: 0, current: 1, pageSize: props.pageSize })
  const searchInput = React.useRef(null);
  const [searchTexts, setSearchTexts] = React.useState([]);
  const [searchedColumns, setSearchedColumns] = React.useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [errorMsg, setErrMsg] = React.useState(null);
  
  React.useImperativeHandle(ref, () => ({ 
    fetch: () => {
      fetchData(props.url, 1, props.pageSize)
    },
    fetchWithoutResetPage: () => {
      fetchData(props.url, pagination.current, props.pageSize)
    }
  }));

  const fetchData = async (url, page, pageSize, sort, filter) => {
    if (!url) {
      return;
    }
    setErrMsg(null)

    try {
      setIsLoading(true);
      const params = {
        page: page, 
        page_size: pageSize,
        ...(sort != null && {'ordering': sort}),
        ...(filter != null && replaceKey(removeEmpty(objectMap(filter, value => value != null ? value[0] : value))))
      }
      const response = await GET(url, params)
      setPagination({
        ...props.paginationOptions,
        total: response.data['total'],
        current: response.data['current_page'],
        pageSize: pageSize,
      })
      setData(response.data['results'].map(row => ({ ...row, 'key': row[props.rowKey] })))
    } catch (error) {
      setErrMsg(error['errorMessages'])
      messageApi.open({
        type: 'error',
        content: error['errorMessages'],
      });
    } finally {
      setIsLoading(false)
    }
  }

  const renderEmptyTable = () => {
    const errorContent = (
      <Empty
        image={errorIcon}
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            {errorMsg}
          </span>
      }>
        <Button type="default" icon={<ReloadOutlined />} onClick={() => fetchData(props.url, 1, props.PageSize)}>
          Reload
        </Button>
      </Empty>
    )
    
    const emptyContent = (<Empty></Empty>)

    return errorMsg ? errorContent : emptyContent
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTexts([selectedKeys[0]])
    setSearchedColumns([...searchedColumns, dataIndex])
  };

  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    setSearchTexts([])
    setSearchedColumns(searchedColumns.filter(col => col !== dataIndex))
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <Tooltip title="Click to show filter">
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : '#ffffff',
            fontSize: '150%'
          }}
        />
      </Tooltip>
    ),
    // onFilter: (value, record) => 
    //   (Array.isArray(dataIndex) ?
    //     dataIndex.reduce((acc, curr) => acc[curr] != null ? acc[curr] : [], record) :
    //     record[dataIndex]
    //   ).toString().toLowerCase().includes(value.toLowerCase())
    // ,
    onFilterDropdownOpenChange: (visible) => visible ? setTimeout(() => searchInput.current?.select(), 100) : null,
    render: (text) =>
      searchedColumns.includes(dataIndex) ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={searchTexts}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  React.useEffect(() => {
    props.onData(data)
  }, [data])

  React.useEffect(() => {
    if(props.autoFetch) {  // Auto fetch data if set autoFetch or rely on ref to trigger fetch data
      fetchData(props.url, 1, props.pageSize)
    }
  }, [])

  return (
    <>
      {contextHolder}
      <Table
        key={props.key}
        style={{...props.style}}
        loading={isLoading}
        size={props.size}
        bordered
        dataSource={data}
        columns={props.columns.map(col => ({
          ...col,
          ...(col[FLAG_COLUMN_SEARCH] && getColumnSearchProps(
              //Array.isArray(col['dataIndex']) ? col['dataIndex'].join('.') : col['dataIndex']
              col['dataIndex']
            )),
        }))}
        pagination={props.hidePagination ? false : pagination}
        onChange= {(pagination, filter, sorter) => {
          const sortDirection = sorter['order'] === 'descend' ? '-' : ''
          const sortField = sorter['field']
          fetchData(
            props.url, 
            pagination['current'], 
            pagination['pageSize'],
            sorter['column'] == null ? null : `${sortDirection}${sortField}`,
            filter
          )
        }}
        onShowSizeChange ={(current, size) => fetchData(URL, current, size)}
        onRow={props.onRow} 
        locale={{ emptyText: renderEmptyTable }}
        rowClassName={props.rowClassName}
      />
    </>
  )
})

TSTable.defaultProps = {
  columns: [], 
  url: null,  // URL to report e.g. /api/member/member/
  data: null, // For manual data
  autoFetch: true,  // set to true for fetch data on component mounted automatically or use ref to handle fetch action
  rowKey: "id",  // KEY props from data
  style: {},
  size: "middle",  // large, middle, small
  key: "", // for QueueAnim
  paginationOptions: {
    position: ["bottomCenter"],
    showTotal: total => `Total ${total} rows`,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"]
  },  // Pagination Options
  hidePagination: false, // Should we hide the pagination
  pageSize: 10,  // Default Page Size
  rowClassName: {},  // For setting bg color rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
  onRow: (record, rowIndex) => {},  // On row clicked
  onData: () => {} // On data fetch changed
}

TSTable.propTypes = {
  columns: propTypes.array.isRequired,
  url: propTypes.string.isRequired,
  initialFetch: propTypes.bool,
  rowKey: propTypes.string,
  style: propTypes.object,
  size: propTypes.string,
  key: propTypes.string,
  paginationOptions: propTypes.object,
  hidePagination: propTypes.bool,
  pageSize: propTypes.number,
  onRow: propTypes.func,
  onData: propTypes.func,
}

export default TSTable;