import React from 'react';
import Header from '../common/Header';
import { Alert, Button, Divider, Form, Input, List, Modal, theme } from 'antd';
import { DELETE, GET, POST } from '../../frameworks/HttpClient';
import { URL_MEMBER } from '../../constants/urls';
import { IdcardOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';


export default function GateTagModal(props) {
  const {
    open,
    member,
    onClose,
    defaultValue,
  } = props;

  const { token: { colorSuccess, colorError } } = theme.useToken();
	const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [tagList, setTagList] = React.useState([]);
  const inputRef = React.useRef(null);

  const handleReset = () => {
    form.resetFields();
    fetchTags();
  }

  const handleSubmitForm = async () => {
    setErrorMessages(null)
    setIsLoading(true)
    try {
      let data = await form.validateFields();
      await POST(`${URL_MEMBER.MEMBER_GATE_TAG}`, { 'member': member, ...data })
      handleReset();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchTags = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.MEMBER_GATE_TAG}?member=${member}`)
      setTagList(response.data.results)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleRemoveTag = async (id) => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await DELETE(`${URL_MEMBER.MEMBER_GATE_TAG}${id}/`)
      fetchTags(response.data.results)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (open) {
      setErrorMessages(null);
      handleReset();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [open])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={[
        // <Button key='cancel' onClick={onClose} loading={isLoading}>Cancel</Button>,
        // <Button style={{ background: colorSuccess }} key='submit' type='primary' onClick={handleSubmitForm} loading={isLoading}>Add</Button>
      ]}
    >
      <Header title='Add Gate Tag'/>

      { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type="error" showIcon/> }
      <Form form={form} style={{ marginTop: '1rem', maxWidth: 'none' }} layout='inline'>
        <Form.Item name='tag' label='Tag' rules={[{ required: true }]}>
          <Input ref={inputRef} defaultValue={defaultValue}/>
        </Form.Item>
        <Form.Item>
          <Button 
            type='primary'
            style={{ background: colorSuccess }}
            onClick={() => handleSubmitForm()}
          >
            <PlusCircleOutlined/>
            Add
          </Button>
        </Form.Item>
      </Form>

      <List>
        { tagList.map(tag => (
          <List.Item actions={[<Button onClick={() => handleRemoveTag(tag.id)} type='text' danger>Delete</Button>]}>
            <List.Item.Meta
              title={tag.tag}
              description={`cretaed on: ${moment(tag.created).format('MMMM Do YYYY, h:mm:ss a')}`}/>
          </List.Item>
        )) }
      </List>
    </Modal>    
  )
}