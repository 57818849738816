import React from "react";
import propTypes from "prop-types";
import { Content } from "antd/es/layout/layout";
import Header from "../common/Header";

const SubHeader = ({title}) => (<ins><p style={{ fontWeight: 'bold', fontSize:20 , textAlign:'center'}}>{title}</p></ins>)
const LittelHeader = ({title}) => (<p style={{ fontWeight: 'bold' }}>{title}</p>)

export default function MemberContractENView(props) {
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        setData(props.detail);
	},[props.detail]);

    return(
        <div>
            <Header
            fontSize={25}
                title={"Alpha Health Club Membership Agreement"}
            /> 
            <Content style={{ marginRight: 15, marginLeft: 10, marginTop: 0, textAlign: 'left', fontSize: 18 }}>
            
            <LittelHeader title={"1. Alpha Health Club Membership Terms and Conditions"}/>
            <ul>
                <li>1.1 The parties agree that these terms and conditions form part of the Alpha Health Club Membership Agreement.</li>
                <li>1.2 The member has read the Agreement and the Annex and agrees to be bound by the terms and conditions of the Agreement and the Annex 
                    to the Membership Agreement of Alpha Health Club. If the member fails to comply with the Agreement and the annex to the Membership Agreement, 
                    in the event of damage to life, body, property or other rights, the member agrees not to sue for legal proceedings,
                    both civil and criminal or take legal action.</li>
                <li>1.3 All rights and obligations set forth in this Agreement must be effective from the date on which the member signs the membership Agreement
                    and pays the membership fee in full.</li>
                <li>1.4 In case the member is under the age of 18, the member shall have a father, mother or legal guardian consenting to apply for the membership.
                    Such person shall sign a document to consent to such a member to apply for membership and shall be responsible for any expenses and debts incurred 
                    by the member both at present and in the future.</li>
                <li>1.5 The member understands that the member’s obligations under this Agreement shall terminate only after the term 
                    specified in this Agreement has ended or the termination of this Agreement is made subject to the terms and conditions 
                    set forth in this Agreement.</li>
            </ul>
            
            <LittelHeader title={"2. Provision of Services and Payment of Subscription Fee"}/>
            <ul>
                <li>2.1 The member agrees to subscribe and the Company agrees to allow the member to use the service in the Company’s 
                    fitness facility (hereinafter referred to as the “Alpha Health Club”) within a period of {data ? data.duration : '-'} day(s). 
                    The member has paid the subscription fee in the amount of {data ? data.price : '-'} Baht (hereinafter referred to as the “Membership Fee”) 
                    to the Company on the date of signing this Agreement.</li>
                <li>2.2 The term of this Agreement will be extended with notice of renewal, and the parties shall be obliged to continue to comply 
                    with this Agreement for the period for which the member notifies the renewal of the Membership Agreement 
                    (hereinafter referred to as the “Renewal Period”) after the expiration of the term of this Agreement, unless the parties do not wish 
                    to continue the Agreement. During the use of the services under this Agreement, the member is obliged to comply with the terms and agreements 
                    in this Agreement. If the member terminates the Agreement prior to the expiration of the term in any case, the member shall not be entitled 
                    to demand that the Club reimburse the membership fee to the member in whole or in part.</li>
            </ul>
            
            <LittelHeader title={"3. Qualifications of Members of Alpha Health Club"}/>
            <ul>
                <li>3.1 The member hereby certifies that the member has undergone a physical examination and has not had any health problems 
                prior to the use of the services at the Alpha Health Club premises.</li>
                <li>3.2 The member is obliged to notify the staff or trainers of Alpha Health Club if the member has any health issues every time 
                before using the services of the Alpha Health Club.</li>
                <li>3.3 A new member or a transferee must be a person who has never been a member who owes membership fees or other service fees 
                to Alpha Health Club and must not be a person who has prohibited qualifications or violates Clauses 4.1 – 4.5 of this Agreement.</li>
                <li>3.4 The member agrees not to demand that the Company be liable in the event of damage to life, body, property or other rights arising 
                during the use of the services at the Alpha Health Club premises.</li>
                <li>3.5 The member shall not bring any person under the age of 15 who is not permitted to use the services at the Alpha Health Club premises.</li>
            </ul>

            <LittelHeader title={"4. Reasons that Alpha Health Club Terminates Your Membership"} />
            <ul>
                Alpha Health Club reserves the right to terminate your membership if it is found that the member has any of the following causes or actions:
                <li>4.1 The member fails to comply with the Membership Agreement signed by the member with the Company and the Company’s Rules of Use of Services.</li>
                <li>4.2 The member commits any actions that is an offence against the law.</li>
                <li>4.3 The member disturbs, harasses or annoys other members’ use of the services, such as through obscenity, etc.</li>
                <li>4.4 The member has a serious contagious disease, such as a skin disease or other diseases as the Company deems appropriate.</li>
                <li>4.5 The member has mental disorders that may cause damage to Alpha Health Club or harm to other members.</li>
            </ul>
            
            <LittelHeader title={"5. Temporary Suspension of Membership Status"} />
            <ul>
                <li>5.1 The Company has the right to temporarily suspend the membership of a member in case of non-compliance with the rules 
                set out in the regulations of Alpha Health Club.</li>
                <li>5.2 The member has the right to suspend the status in accordance with the annex to the Agreement 
                (Alpha Health Club Regulations) under Article 9 of the Membership Section.</li>
            </ul>

            <LittelHeader title={"6. Transfer of Membership Rights"} />
            <ul>
                <li>6.1 The member may transfer membership rights when the member has at least one month of membership remaining. 
                The Company reserves the right to transfer the rights for a full month (30 days) only.</li>
                <li>6.2 The member shall pay the transfer fee at the rate specified by the Company</li>
            </ul>

            <LittelHeader title={"7. Member Account (Line OA)"} />
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;In the event that the member account is damaged, lost or inaccessible, 
                the member is obliged to contact the Company for a new account issuance and will be charged a fee for creating a new account
                at the rate specified by the Company on the date the member contacts the Company for the new member account issuance.
            </ul>

            <LittelHeader title={"8. Service Facility"} />
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;The Company reserves the right to open a new service location, relocate, 
                or renovate some parts of the service facility in each branch. If the owner of the building has renovated or acquired 
                rights in the facility, the Company will notify members of each service branch through the contact channels that you have provided.
                Once the member has acknowledged it, the member can go to use the services at other branches at their convenience.
            </ul>

            <LittelHeader title={"9. Storage Locker Room"} />
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;The Company has provided lockers for all members without additional charge.
                These lockers are allowed for members to use only during the time they use the services, and it is strictly prohibited to use the storage
                overnight. The Company does not recommend that members put valuables in the lockers. The Company reserves the right to take responsibility
                if there is damage.
            </ul>
            <br/>

            <SubHeader title={"Miscellaneous"} />
            <LittelHeader title={"10. Enforcement of the Agreement"} />
            <ul>
                This annex is part of the Company’s Membership Application Agreement and shall be governed by the laws of the Kingdom of Thailand.
            </ul>

            <LittelHeader title={"11. Compliance with Laws and Regulations"} />
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;The member agrees to duly comply with and take any actions related to the exercise of the rights 
                of membership in accordance with all laws and procedures required in all respects. If the member fails to comply with any laws or procedures,
                the member shall be liable under the law. If the member takes any action that causes damage to Alpha Health Club or other members,
                the member agrees to indemnify for any incurred and directly consequential damages and other damages, even if it cannot be calculated in
                price or money to any affected person due to non-compliance with any laws or procedures. The member agrees and accepts that the Company or 
                employees of Alpha Health Club shall not be liable for any damages arising from the use of the services, including any breach or 
                provision of services under this Agreement in any case.
            </ul>

            <LittelHeader title={"12. Amendments to the Agreement"} />
            <ul>
        	    This Agreement may be amended, changed, added, construed or the parties may pass up or waive their rights only by mutual agreement in writing signed by the parties or their authorized representatives.
            </ul>

            <LittelHeader title={"13. Severability"} />
            <ul>
                In the event that any part of the Agreement is void, it shall be deemed that the parties have deliberately provided that the non-void part is separate from the void part.
            </ul>

            <LittelHeader title={"14. Assignment of Claims"} />
            <ul>
                The parties agree not to assign any of their rights or obligations under this Agreement to any other person without the prior written consent of the other party.
            </ul>
            <br/>

            <SubHeader title={"Personal Data"} />
            <LittelHeader title={"15. Request for Consent to Collection, Use and/or Disclosure of Personal Data"} />
            <ul>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;The member has the right to refuse consent to Alpha Health Club collecting,
                using, and/or disclosing the member’s personal information for the purposes set out below. Please consider the Privacy Notice 
                in the event of the member’s failure to provide personal information to Alpha Health Club.<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;In order to comply with the requirements of the Personal Data Protection Act B.E. 2562 (2019),
                Alpha Health Club would like to ask for your consent to the statements below so that Alpha Health Club can comply with the law and continue to deliver
                the best service to its members.
            </ul>

            <ul>
            <li><LittelHeader title={"15.1 Collecting Personal Information"} /></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;Personal information from filling out the member’s application form,
                including sensitive information obtained by Alpha Health Club that is personal information such as name,
                surname, date of birth, age, national ID number, race and religion, as well as contact information like address,
                phone number, email and photo or any documents related to the application, etc. <br/>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;Alpha Health Club will use the information to prepare a profile of the applicant 
                as a registry of the applicant’s information and/or to provide/improve the service or data processing.
                It will be stored as an electronic data file in a separate data folder.<br/>
            </ul>

            <ul>
            <li><LittelHeader title={"15.2 Marketing Communications"} /></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;In order to inform members about news, promotions, and activities, as well as provide 
                information on interesting products and services of Alpha Health Club, the Club may send SMS messages to telephone numbers,
                electronic mail (E-mail), social media, telephone messages and/or postal mail, as well as any other channels in the future that
                Alpha Health Club deems appropriate, or have sales staff contact members to follow up after sales and inform them of news,
                promotions, and activities, including presenting information on interesting products and services individually.<br/>
            </ul>

            <ul>
            <li><LittelHeader title={"15.3 Satisfaction Survey"} /></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;In order to use the data for analysis, proposal and feedback, the Alpha Health Club
                will send a satisfaction survey to listen to recommendations and opinions based on an evaluation of the overall picture and experience
                of the members to develop and improve the services and promotional activities to meet the needs of the members.<br/>
            </ul>

            <ul>
            <li><LittelHeader title={"15.4 Health Data Survey"} /></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;The Alpha Health Club may ask for or request a document certifying the member’s health to check 
                the health history, for example, skin diseases, respiratory diseases, secretory diseases, etc., in order to assess whether or not the member 
                is suitable to use  the Alpha Health Club services.<br/>
            </ul>

            <ul>
            <li><LittelHeader title={"15.5 Disclosure of Personal Information"} /></li>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;I hereby authorize the Alpha Health Club to disclose the member’s personal information in order
                to comply with other statutory regulations or requirements or to conduct a verification of the member’s personal information for agencies
                or organizations, such as hospitals, medical agencies, the Royal Police, or other agencies.<br/>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;I have read the Alpha Health Club Privacy Notice and acknowledge that I have the right to withdraw 
                my consent to the collection, use and/or disclosure of my personal information for the purposes specified at any time by notifying 
                the Alpha Health Club in writing of such withdrawal of consent.<br/>
            </ul>
            <br/>

            {data && (data.conditions.length > 0) && (
                <div>
                    <SubHeader title={"Package Additional Terms and Conditions"} />
                    {data.conditions.map((item, index) => {
                        return (
                            <div>
                                <p style={{ fontWeight: 'bold' }}>{item.name}</p>
                                <ul>
                                    <span style={{whiteSpace: "pre-line"}}>{item.term_conditions_en}</span>
                                </ul>
                            </div>
                        )
                    })}
                </div>
            )}

            <br/>
            As abovementioned, I have read and understood the terms and conditions of Clauses 1-15 and have signed the electronic signature in 
            the signatory box as evidence of acceptance of the Alpha Health Club Membership Terms and Conditions.<br/>
            
            </Content>
            </div>
    )
}

MemberContractENView.defaultProps = {
    detail: null,
}
  
MemberContractENView.propTypes = {
    detail: propTypes.object,
}