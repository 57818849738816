import React from 'react';
import propTypes from "prop-types";
import { Card, Col, Empty, Image, Row, Spin, Modal } from 'antd';
import "./../../styles.css"
import SegmentHeader from '../common/SegmentHeader';
import logo from "../../assets/ahc_logo.png";
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_MEMBER, URL_PACKAGE } from '../../constants/urls';
import * as _ from 'lodash';
import { CONTRACT_TYPE } from '../../constants/string';
import MemberConsentQRCode from './MemberConsentQRCode';


const PackageCard = ({data, onClick}) => {
	return (
		<Card 
			style={{textAlign: "center"}}
			onClick={onClick}>
			<Image 
				style={{height: 84, width: "auto"}}
				preview={false}
				src={data.is_corporate ? data.card_type_image : logo}/>
				
			<div 
				style={{
					marginTop: 16,
					fontWeight: 'bold', 
					lineHeight: "1.5em", 
					height: "3em", 
					overflow: "hidden"}}>
				{data &&
					<>
						{data.has_dynamic && `[ALL CLUB - ${data.dynamic_package.name} ] `}
						{data.package_name ? data.package_name : "Unknown"}
						{data.variant_type && ` (${data.variant_type})`}
					</>
				}
			</div>
		</Card>
	)
}

export default function ActivateMainPackageSegment(props) {
	const [loading, setLoading] = React.useState(false);
	const [packages, setPackages] = React.useState([]);
	const [isConfirmOpen, setIsConfirmOpen] = React.useState(false)
	const [selected, setSelected] = React.useState(null);
	const qrRef = React.useRef();
	const isFreeTrial = _.get(selected, 'is_free_trial', false);
	const isMemberFreetrialConsentRequired = _.get(props, 'member.is_free_trial_consent_required', false)
	const [consentRequired, setConsentRequired] = React.useState(false);

	const startActivate = async () => {
		setSelected(null)

		// Handle corporate
		if (selected.is_corporate) {
			props.onSelected(selected);
			return;
		}
		// Handle free trail
		if (selected.is_free_trial) {
			let selectedData = selected
			if (qrRef.current) {
				selectedData['send_email'] = qrRef.current.getSendEmail();
			}
			props.onSelected(selectedData);
			return;
		}

		// Handle Main Package
		setLoading(true);
		try {
			const response = await POST(`${URL_PACKAGE.MEMBER_PACKAGE}${selected.id}/activate/`);
			props.onSelected(response.data);
		} catch (error) {
			props.onError(error.errorMessages);
		} finally {
			setLoading(false)
		}
	}

	const checkConsentRequired = async () => {
		setLoading(true);

		try {
			if (isFreeTrial) {
				const response = await GET(
					`${URL_MEMBER.MEMBER}${_.get(props, 'member.id', '')}/check-membership-consent/`, 
					{ consent_type: CONTRACT_TYPE.FREE_TRIAL }
				)
				setConsentRequired(_.get(response, 'data.is_required', false));
			} else {
				setConsentRequired(false)
			}
		} catch (error) { } finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (props.member && props.packages) {
			let otherPackage = [];
			// Case: Corporate
			let corp = (props.member && props.member.corporates) ? props.member.corporates : [];
			if (corp.length > 0) {
				otherPackage = corp.map(item => (
					{
						...item,
						is_corporate: true,
						package_name: item.card_type_name,
						end_date: null,
						allow_gym_access: true,
						allow_pool_access: true
					}
				))
			}
			// Case: Free trial
			const isFreeTrail = props.member.allow_trial_usage;
			if (isFreeTrail) {
				otherPackage.push({
					id: props.member.id,
					is_free_trial: true,
					package_name: "Free Trail",
					end_date: null,
					allow_gym_access: true,
					allow_pool_access: true
				})
			}

			setPackages((props.packages ? props.packages : []).concat(otherPackage))
		}
	}, [props.packages, props.member]);

	React.useEffect(() => {
		checkConsentRequired()
		setIsConfirmOpen(selected != null)
	}, [selected])

  return (
    <div>
			<Modal 
				title="Confirm Activate Package" 
				open={isConfirmOpen}
				onOk={startActivate}
				onCancel={() => setSelected(null)}
				okButtonProps={{
					loading: loading,
					disabled: consentRequired ? true : false
				}}
			>
				<p>Are you sure to activate package ?</p>
				<p>
					{selected &&
						<>
						{selected.has_dynamic && `[ALL CLUB - ${selected.dynamic_package.name} ] `}
						{selected.package_name ? selected.package_name : "Unknown"}
						{selected.variant_type && ` (${selected.variant_type})`}
						</>
					}
				</p>

				<MemberConsentQRCode
					ref={qrRef}
					type={CONTRACT_TYPE.FREE_TRIAL}
					member={_.get(props, 'member.id', null)}
					showCheckbox={isFreeTrial}
					consentRequired={consentRequired}
					onConsentSigned={ () => setConsentRequired(false)}
				/>
			</Modal>

			<SegmentHeader 
				header={"Activate Main Package"} 
				style={{width: "80%"}}
				content={
					<Spin size={"large"} spinning={loading}>
						<Row justify={"center"} gutter={[16, 16]}>
							{packages.map(item => {
								return (
									<Col key={item.id} md={6} sm={24} xs={24}>
										<PackageCard data={item} onClick={() => setSelected(item)}/>
									</Col>
								)
							})}
							{packages && packages.length == 0 && (<Empty />)}
						</Row>
					</Spin>
				}/>
     </div>
  )
}

ActivateMainPackageSegment.defaultProps = {
	member: null,
	packages: [],
	onSelected: (data) => {},
	onError: (message) => {}
}
ActivateMainPackageSegment.propTypes = {
	member: propTypes.object,
	packages: propTypes.array,
	onSelected: propTypes.func,
	onError: propTypes.func
}
