import { theme } from 'antd'
import React from 'react'
import Header from '../components/common/Header'
import { dateTimeToHumanString } from '../frameworks/Util'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import { URL_MEMBER } from '../constants/urls';
import TSTable from '../components/common/TSTable';

export default function MemberListView() {
	const navigate = useNavigate();
	const { name } = useParams();

	const {
    token: { colorSuccessActive, colorErrorActive },
  } = theme.useToken();

	const columns = [
		{
			title: 'Member ID',
    	dataIndex: 'code',
			sorter: true,
			searcher: true,
		},
		{
			title: 'Name',
			dataIndex: 'full_name',
			sorter: true,
			searcher: true,
		},
		{
			title: 'Email',
    	dataIndex: 'email',
			sorter: true,
		},
		{
			title: 'Checked-in',
			dataIndex: 'last_checked_in',
			render: (value) => (value && <p style={{ textAlign: "center", margin: 0 }}>{dateTimeToHumanString(value)}</p>)
		},
		{
			title: 'Phone Number',
			dataIndex: 'mobile_phone',
		},
		{
			title: 'Reward',
    	dataIndex: 'reward_point',
			render: (value) => (<p style={{textAlign: "center", margin: 0}}>{value}</p>)
		},
		{
			title: 'Sale',
			dataIndex: 'sale_full_name',
			sorter: true,
			searcher: true,
		},
		{
			title: 'Working',
    	dataIndex: 'is_working',
			render: (value) => {
				return (
					<div style={{textAlign: "center", fontSize: 18}}>
						{value 
						? <CheckCircleOutlined style={{color: colorSuccessActive}}/> 
						: <CloseCircleOutlined style={{color: colorErrorActive}}/>
						}
					</div>)
			}
		}
	]
	return (
		<div>
				<Header title={"Member - Information"} key="1"/>

				<TSTable 
					columns={columns}
					url={URL_MEMBER.MEMBER}
					rowKey={"id"}
					size={"small"}
					style={{ marginTop: 16 }}
					onRow={(record, rowIndex) => ({
						onClick: _ => navigate(`${PATH.MEMBER_DETAIL}${PATH.MEMBER_INFO}/${record.id}`)
					})}
				/>
		</div>
	)
}