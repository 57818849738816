import React from 'react'
import propTypes from "prop-types";
import {  Alert, Form, Input, Modal, Select, theme } from "antd";
import Header from '../common/Header';
import { DELETE, GET, POST } from '../../frameworks/HttpClient';
import { URL_COMMON, URL_MEMBER } from '../../constants/urls';
import * as _ from 'lodash';
import MemberConsentQRCode from './MemberConsentQRCode';
import { CONTRACT_TYPE } from '../../constants/string';


export default function CorporateModal (props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [list, setList] = React.useState([]);
	const qrRef = React.useRef();
	const [consentRequired, setConsentRequired] = React.useState(false);

	const {
		token: { colorInfo, colorError },
	} = theme.useToken();

	const handlePrivilege = async () => {
		if (props.target) {
			await deletePrivilege(props.target.id);
		} else {
			await createPrivilege();
		}
	}

	// Add corporate with member
	const createPrivilege = async () => {
		setErrorMessages(null);
		try {
			let data = await form.validateFields();
			data = {...data, member: props.member}
			if (qrRef.current) {
				data['send_email'] = qrRef.current.getSendEmail();
			}
			await POST(URL_MEMBER.CORPORATE_CARD, data);
			props.onUpdated()
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Delete corporate from member
	const deletePrivilege = async (privilegeId) => {
		setErrorMessages(null);
		try {
			await DELETE(`${URL_MEMBER.CORPORATE_CARD}${privilegeId}/`);
			props.onUpdated()
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Fetch corporate list
	const fetchData = async () => {
		try {
			const response = await GET(`${URL_COMMON.CORPORATE}?page_size=9999`);
			setList(
				response.data.results.map(item => {
					return {
						...item,
						value: item.id,
						label: item.name
					}
				})
			)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const checkConsentRequired = async () => {
		setLoading(true);

		try {
			if (!props.target) {
				const response = await GET(
					`${URL_MEMBER.MEMBER}${_.get(props, 'member', '')}/check-membership-consent/`, 
					{ consent_type: CONTRACT_TYPE.CORPORATE }
				)
				setConsentRequired(_.get(response, 'data.is_required', false));
			} else {
				setConsentRequired(false)
			}
		} catch (error) { } finally {
			setLoading(false);
		}
	}

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			fetchData();
			form.resetFields();
			
			if (props.target){
				form.setFieldsValue(props.target)
			}
			checkConsentRequired()
		}
	}, [props.open])
  

  return (
    <Modal
			okText={props.target ? "Delete" : "Add"}
			okButtonProps={{
				loading: loading,
				disabled: consentRequired ? true : false,
				style: { background: consentRequired ? null : props.target ? colorError : colorInfo },
			}}
			open={props.open}
			onOk={handlePrivilege}
			onCancel={props.onClose}>
        <Header title={"Corporate Privileges"}></Header>

				<Form 
					form={form}
					style={{marginTop: 16}}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}>

					{errorMessages && 
						<Alert
							message={errorMessages}
							type="error"
							showIcon
							style={{marginBottom: "12px", textAlign: "left"}}
						/>
					}

					<Form.Item 
						name={"card_type"}
						label="Card Type"
						rules={[{ required: true }]}>
						<Select options={list} disabled={props.target}/>
					</Form.Item>
						
					<Form.Item  
						name={"card_number"}
						label="Card ID"
						rules={[{ required: true }]}>
							<Input autoComplete="off" disabled={props.target}/>
					</Form.Item>
        </Form>

				<MemberConsentQRCode
					ref={qrRef}
					type={CONTRACT_TYPE.CORPORATE}
					member={_.get(props, 'member', null)}
					showCheckbox
					consentRequired={consentRequired}
					onConsentSigned={ () => setConsentRequired(false)}
				/>				
    </Modal>
  )
}
CorporateModal.defaultProps = {
  open: false,
  member: null,
	target: null,
  onUpdated: () => {},
	onClose: () => { },
}

CorporateModal.propTypes = {
  open: propTypes.bool,
  member: propTypes.string,
	target: propTypes.object,
  onUpdated: propTypes.func,
	onClose: propTypes.func,
}