import { Content } from "antd/es/layout/layout";
import Header from "../common/Header";

export default function ConsentENView() {

    return (
        <div>
            <Header
                title={"Letter of consent for data processing and disclosure of personal data"}
            />
            <Content style={{ margin: 16, textAlign: 'left'}}>
                <p style={{ fontWeight: 'bold' }}>1. Terms and conditions of Alpha Health club member </p>
                <ul>
                    1.1 Parties agree that the attachment of this agreement is deemed to be part of this
                    agreement.<br />
                    1.2 Member read this agreement and the attachment of this agreement. Member agrees to
                    comply with the terms and conditions under this agreement and its attachment. If Member fails to
                    comply with the agreement and its attachment and the damage was caused to the Member’s right of
                    life, body, properties or any other rights. Member agrees to hold the company harmless from filing the
                    lawsuit in civil case, criminal case or any other legal action.
                    <br></br>
                    1.3 Rights and duties under this agreement shall be enforced upon the date of signing this
                    agreement and made full payment for the membership fee to the Company.
                    <br></br>
                    1.4 If Member is still underage, Member must obtain the consent from his father or mother or
                    legal guardian according to the law to apply for the membership by way of signing the letter of
                    consent. Member’s parent and legal guardian shall be responsible for expenses and any debts at
                    present and in the future under this agreement. <br />
                    1.5 Member agrees that the Member’s duties under this agreement shall be terminated upon
                    the date of expiration of this agreement or the termination of this agreement with the terms and
                    conditions under this agreement.
                </ul>
                <p style={{ fontWeight: 'bold' }} >2. Service, Term of Service and Membership Fee</p>
                <ul>
                    2.1 Member shall pay for the membership fee before use the facility in Alpha Health Club (“Membership fee”)<br />
                    2.2 Company agrees to allow Member to use the facility in Alpha Health Club (“Alpha health
                    club”) as agreed<br />
                    The term of this Agreement will automatically renew the obligation to perform each Service for
                    a successive term of the same term 3 month / 6 months / 1 year or other terms as the case may be
                    (each, a "Renewal Term") following the expiration of the Term for the particular Service, unless either
                    party decides that it does not wish to renew this Agreement or any particular Service or Additional
                    Services hereunder before the expiration of the Initial Term or any Renewal Term, as applicable, by
                    notifying the other party in writing at least 1 month before the completion of the Initial Term or
                    Renewal Term, as applicable and comply with the procedure and protocol under Article 4.3. During
                    the term of service Member is subjected to and shall comply with the terms and conditions as agreed
                    under this agreement.<br />
                </ul>
                <p style={{ fontWeight: 'bold' }}>3. Alpha Health Club’s member qualifications</p>
                <ul>
                    3.1 Member warrants that the Member is checked up his health and Member did not have any
                    health condition or sickness before using the facility at Alpha Health Club.<br />
                    3.2 Member has duties to inform Alpha Health Club’s staffs and trainers regarding health
                    condition every time before using the Alpha Health Club’s facility.<br />
                    3.3 Member who applied for a new membership or the right transferred from the existing
                    Member shall not overdue Membership fee or any other service fees to the Alpha Health club.
                    Member shall not disqualified or in breach of Article 5.1-5.5 under this agreement. <br />
                    3.4 Member shall not claim the Company for any damages to the life, body, properties or any
                    other right during using Alpha Health Club’s facilities.<br />
                    3.5 Member shall not bring or accompany any person with the age under 15 years to use
                    Alpha Health Club’s facility.
                </ul>
                <p style={{ fontWeight: 'bold' }}>4. Termination of the Membership</p>
                <ul>
                    4.1 Termination of the membership may be made within 3 days of the applying the
                    membership. Company reserves the right to withhold the membership fee. In this regard, the Member
                    is obligated to return the related documents to Company.<br />
                    4.2 Monthly membership may be terminated provided that the Member complies with the
                    minimum requirement or period (depends on applied types of membership). Member is obligated to
                    comply and conform with the procedure and protocol of termination of the member as specified in
                    Article 4.3.<br />
                    4.3 Procedure and protocol of termination of monthly membership are as follows:<br />
                    <ul>
                        4.3.1 Member is required to fill the form for termination of the membership provided
                        by the Company.<br />
                        4.3.2 Member is required to send such form to the Company in advance for the
                        period of 1 month before the termination of the monthly membership. The termination shall be
                        effective in the next month. Company shall collect the membership fee in such month as the last fee.
                        In this regard, the date of membership termination shall base on the date of company’s
                        acknowledgement. <br />
                        4.3.3 If the membership is terminated less than the period of 1 month of the due date.
                        The membership in the next month shall be collected.<br />
                        4.3.4 Member acknowledges that upon termination of the membership, the paid
                        membership fee including value added tax, service fee and any other fees remaining for the
                        membership shall be withheld / Member may terminate the membership prior to minimum period.
                        However, Member shall be responsible for the monthly membership fee before termination of the
                        membership.
                    </ul>
                </ul>
                <p style={{ fontWeight: 'bold' }}>5. Termination of Membership by Alpha Health Club</p>
                <ul>
                    Company is entitled to terminate the membership if the Company discovers that Member
                    performs the following actions.<br />
                    5.1 Member fails to comply with the membership agreement signed with the Company and
                    rules and regulation of using the company’s facility.<br />
                    5.2 Any action against the law.<br />
                    5.3 Disturbance behavior, harassment, nuisance to other members.<br />
                    5.4 Severe contagious decease such as skin decease or any other decease as the company
                    deem proper.<br />
                    5.5 Any mental illness which may cause damage to the Company or may harm other
                    members.<br />
                </ul>
                <p style={{ fontWeight: 'bold' }}>6. Temporary suspension of the membership</p>
                <ul>
                    6.1 Company is entitled to temporarily suspend the membership if the Member fails to comply
                    with Alpha Health Club’s regulations.<br />
                    6.2 Member is entitled to suspend its membership according to the attachment of this
                    agreement in Article 9 Membership.<br />
                </ul>
                <p style={{ fontWeight: 'bold' }}>7. Assignment of membership </p>
                <ul>
                    Member shall not assign the membership to any person unless Member obtains written
                    consent from Company. In this regard, the Company shall have the absolute discretion to give
                    consent to Member.
                </ul>
                <p style={{ fontWeight: 'bold' }}>8. Payment of membership</p>
                <ul>For the monthly Member, if the monthly Member overdue the payment the membership fee,
                    other expenses later than the agreed date or specified in the agreement. The company reserves the
                    right to collect daily liquidated damages at the rate of 1 percent of monthly membership.
                </ul>
                <p style={{ fontWeight: 'bold' }}>9. Membership card</p>
                <ul>
                    In case the membership card is damaged or loss, the membership is obligated to contact for
                    issuing a new membership card and Member is obligated to pay for the fee as provided by the
                    Company on the date of request.
                    <p style={{ fontWeight: 'bold' }}>**membership card shall only be used by Member signing the
                        membership agreement**</p>(Company reserve the right to deny the service or commence any legal
                    action against the person who violate the Member’s right signing this membership agreement)

                </ul>
                <p style={{ fontWeight: 'bold' }}>10. Facility</p>
                <ul>
                    Company reserves the right to open a new location, relocate to new location, or partially close
                    for maintenance of the facility in each beach or if the owner of the building renovate or take
                    possession of the facility, the Company shall inform the Member to each branch not less than 45
                    days. Upon receiving the notification, Member may use the facility in other branch as convenience. <br />
                </ul>
                <p style={{ fontWeight: 'bold' }}>11. Locker</p>
                <ul>
                    Company prepares the locker for Member without additional charge. Lockers are for Member
                    only during using the Alpha Health Club’s facilities. Member shall not keep their belonging in the
                    locker overnight and Company recommend that Member should not keep their valuable properties in
                    the locker. Company shall not be responsible for any damages or loss to Member’s properties in the
                    locker.
                </ul>
                <p style={{ fontWeight: 'bold' }}>12. Governing law</p>
                <ul>Attachment of this agreement is deemed to be part of this membership agreement and the
                    law of Kingdom of Thailand shall be governed this agreement.</ul>
                <p style={{ fontWeight: 'bold' }}>13. Legal Compliance and the Regulations</p>
                <ul>
                    Member agrees to comply with right and duties of the membership according to related laws
                    and the regulations. If Member fails to comply with the related law or provided regulations, Member
                    shall be responsible for damages or any other penalties according to the law. If the damages cause to
                    the Company or other members, Member agrees to pay for direct or indirect or consequential
                    damages and compensation to the affected party due to Member’s failure to comply with the law or
                    the regulations.
                </ul>
                <p style={{ fontWeight: 'bold' }}>14. Amendment of Agreement</p>
                <ul>This agreement may be changed, amended or interpreted or compromise or forfeit its right
                    only by way of agreement in writing by the authorized representative.<br />
                </ul>
                <p style={{ fontWeight: 'bold' }}>15. Severability</p>
                <ul>If any part of this agreement is void, the parties agree that the remaining part of this
                    agreement shall be fully effective.<br />
                </ul>
                <p style={{ fontWeight: 'bold' }}>16. Assignment</p>
                <ul>The Parties agree not to assign the rights and duties under this agreement to other persons
                    unless the written consent is given by the other party.
                </ul>
                <p style={{ fontWeight: 'bold' }}>17. Communication between Company and Member</p>
                <ul>
                    If member wishes to contact or send the letter of notice to the company, the member shall
                    send such information or documents to the following address.
                    <p style={{ fontWeight: 'bold' }}>Company</p>
                    Located 9/56 Moo.5 , Vichit District , A.Muang , Phuket 83000<br />
                    Tel. 076 612 655-6 <br />
                    E-mail: alphafitnessphuekt@gmail.com
                </ul> <br />
                <ul style={{ fontWeight: 'bold' }}>IN WITNESS WHEREOF, Both parties read and understand the agreement and it is in accordance with both parties intention.
                </ul>

            </Content>
        </div>
    )
}