import { Button, Col, Collapse, Row, theme, Modal } from 'antd';
import { FilePdfFilled, FileExcelFilled } from '@ant-design/icons';
import React from 'react'
import Lottie from "lottie-react";
import lottieReport from "../assets/lottie_report.json"
import Header from '../components/common/Header';
import ReportModal from '../components/report/ReportModal';
import { REPORT_FILTER } from '../constants/string';
import { URL_REPORT } from '../constants/urls';

const SaleReports = [
	{
		id: 1, 
		label: "1. ยอดขายแต่ละแพ็คเกจ", 
		detail: "รายงานยอดขายแต่ละแพ็คเกจ",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.PACKAGE_REPORT
	},
	{
		id: 2, 
		label: "2. สรุปข้อมูลการเข้าใช้บริการรายวัน", 
		detail: "รายงานสรุปข้อมูลการเข้าใช้บริการสำหรับจัดทำสถิติรายวัน",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.CHECKINTIME_REPORT
	},
	{
		id: 3, 
		label: "3. การทำรายการขอพักแพ็คเกจ", 
		detail: "รายงานการทำรายการขอพักแพ็คเกจ (Drop)",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.DROPPACKAGE_REPORT
	},
	{
		id: 4, 
		label: "4. รายชื่อสมาชิกที่มาทดลองใช้บริการ", 
		detail: "รายงานรายชื่อสมาชิกที่มาทดลองใช้บริการ (Free Trial Package)",
		filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.MEMBER],
		required: false,
		url: URL_REPORT.FREETRIALPACKAGE_REPORT
	},
	{
		id: 5, 
		label: "5. เวลาการใช้บริการของสมาชิกต่อครั้ง", 
		detail: "รายงานเวลาการใช้บริการของสมาชิกต่อครั้งที่มาใช้บริการ เช็คอินน์ ถึงเช็คเอาท์",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.CHECKIN_CHECKOUT_MEMBER_REPORT
	},
	{
		id: 6, 
		label: "6. อัตราการต่ออายุแพ็คเกจฟิตเนสของสมาชิก", 
		detail: "รายงานจำนวนอัตราการต่ออายุแพ็คเกจฟิตเนสของสมาชิก (Renew Rate Report)",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.RENEW_RATE_REPORT
	},
	{
		id: 7, 
		label: "7. แต้มสะสมของสมาชิก", 
		detail: "รายงานแต้มสะสมของสมาชิก ระบุวันที่หมดอายุ",
		filter: [], // Use current date
		required: true,
		url: URL_REPORT.REWARDPOINT_MEMBERS_REPORT
	},
	{
		id: 8, 
		label: "8. ข้อมูลแพ็คเกจ (Active/Non-active)", 
		detail: "รายงานข้อมูลสมาชิก แพ็คเกจที่ยังไม่หมดอายุ Active และแพ็คเกจที่หมดอายุแล้ว Non-active",
		filter: [REPORT_FILTER.DATE, REPORT_FILTER.PACKAGE_STATUS, REPORT_FILTER.SALE, REPORT_FILTER.AGE, REPORT_FILTER.GENDER, REPORT_FILTER.NATIONALITY], // Use current date
		required: true,
		url: URL_REPORT.MEMBERPACKAGE_REPORT
	},
	{
		id: 9, 
		label: "9. แพ็คเกจที่มีสถานะ Active", 
		detail: "รายงานแพ็คเกจที่มีสถานะ Active",
		filter: [REPORT_FILTER.DATE],
		required: true,
		url: URL_REPORT.MEMBERPACKAGEACTIVEGROUP_REPORT
	},
	{
		id: 10, 
		label: "10. Membership Package Summary", 
		detail: "Membership Package Summary Report",
		filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.SALE],
		required: true,
		url: URL_REPORT.MEMBERSHIP_PACKAGE_SUMMARY_REPORT
	},
	{
		id: 11, 
		label: "11. PT Package Summary", 
		detail: "PT Package Summary Report",
		filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.SALE, REPORT_FILTER.PT],
		required: false,
		url: URL_REPORT.PT_PACKAGE_SUMMARY_REPORT
	},
	{
		id: 12,
		label: "12. Sale Monthly Report",
		detail: "Sale Monthly Report",
		hidePDF: true,
		filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.CASHIER],
		required: true,
		url: URL_REPORT.SALE_MONTHLY_REPORT
	},
	{
		id: 13,
		label: "13. สมาชิกที่ไม่ได้เช็คอินเกินกำหนด",
		detail: "รายงานสมาชิกที่ไม่ได้เช็คอินเกินกำหนด",
		hidePDF: true,
		filter: [REPORT_FILTER.SALE, REPORT_FILTER.ABSENT_DAY],
		required: false,
		url: URL_REPORT.MEMBER_ABSENT_REPORT
	},
	{
		id: 14,
		label: "14. สมาชิกที่กำลังจะหมดอายุ",
		detail: "รายงานสมาชิกที่กำลังจะหมดอายุ",
		hidePDF: true,
		filter: [REPORT_FILTER.SALE, REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.EXPIRING_PACKAGE_REPORT
	},
]

const PTReports = [
	{
		id: 1, 
		label: "1. PT Class", 
		detail: "รายงาน PT Class",
		filter: [REPORT_FILTER.PT, REPORT_FILTER.MONTH],
		required: true,
		url: URL_REPORT.PT_CLASS_REPORT
	},
	{
		id: 2, 
		label: "2. PT Session",
		detail: "รายงาน PT Session",
		filter: [REPORT_FILTER.PT, REPORT_FILTER.MONTH],
		required: true,
		url: URL_REPORT.PT_SESSION_REPORT
	},
	{
		id: 3, 
		label: "3. PT Greeting",
		detail: "รายงาน PT Greeting",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.PT_GREETING_REPORT
	},
	{
		id: 4, 
		label: "4. Monthly Report (PT Class)", 
		detail: "Monthly Report (PT Class)",
		filter: [REPORT_FILTER.MONTH],
		required: true,
		url: URL_REPORT.PT_CLASS_MONTHLY_REPORT
	},
]

const AccountReports = [
	{
		id: 1, 
		label: "1. การรับชำระตามประเภทเงิน", 
		detail: "รายงานการรับชำระตามประเภทเงิน",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.PAYMENT_BY_TYPE_OF_MONEY_REPORT
	},
	{
		id: 2, 
		label: "2. รายได้แยกตามรหัสแคชเชียร์และประเภทเงิน", 
		detail: "รายงานรายได้แยกตามรหัสแคชเชียร์ และจำแนกตามประเภทเงิน",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.INCOME_CASHIER_BY_MONEY_TYPE_REPORT
	},
	{
		id: 3, 
		label: "3. การรับชำระ", 
		detail: "รายงานการรับชำระ",
		filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.PAYMENT_STATUS],
		required: true,
		url: URL_REPORT.PAYMENT_REPORT
	},
	{
		id: 4, 
		label: "4. Package Coupon", 
		detail: "รายงาน Package Coupon",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.PACKAGE_VOUCHER_REPORT
	},
	{
		id: 5, 
		label: "5. การซื้อ Coupon", 
		detail: "รายงานการซื้อ Coupon",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.VOUCHER_BUY_REPORT
	},
	{
		id: 6, 
		label: "6. การบันทึกแต้มสะสมจำแนกตามประเภท", 
		detail: "รายงานการบันทึกแต้มสะสมจำแนกตามประเภท",
		filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.REWARD_TYPE],
		required: true,
		url: URL_REPORT.MANUAL_ADD_REWARD_POINT_REPORT
	},
	{
		id: 7, 
		label: "7. การแลกของขวัญด้วยแต้มสะสม", 
		detail: "รายงานการแลกของขวัญด้วยแต้มสะสม",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.GIFT_POINT_REPORT
	},
	{
		id: 8, 
		label: "8. ใบเสร็จรับเงินที่ถูก Reject", 
		detail: "รายงานใบเสร็จรับเงินที่ถูก Reject โดยเจ้าหน้าที่บัญชี",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.REJECT_RECRIPT_BY_ACCOUNTING_REPORT
	},
	{
		id: 9, 
		label: "9. การขาย Dynamic Package", 
		detail: "รายงานการขาย Dynamic Package",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.SALES_DYNAMIC_PACKAGE_REPORT
	},
	{
		id: 10, 
		label: "10. ภาษีขาย", 
		detail: "รายงานภาษีขาย",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.INVOICE_REPORT
	},
]

const OtherReports = [
	// {
	// 	id: 1, 
	// 	label: "1. Sale Daily Report", 
	// 	detail: "รายงาน Sale Daily Report",
	// 	filter: [REPORT_FILTER.DATE_RANGE, REPORT_FILTER.SALE],
	// 	required: true,
	// 	// url: URL_REPORT.SALES_DAILY_REPORT
	// 	url: ""
	// },
	{
		id: 1, 
		label: "1. Corporate", 
		detail: "รายงาน Corporate",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		url: URL_REPORT.CORPORATE_REPORT
	},
	{
		id: 2,
		label: "2. Studio Booking",
		detail: "รายงานการจอง Studio",
		filter: [REPORT_FILTER.DATE_RANGE],
		required: true,
		hidePDF: true,
		url: URL_REPORT.STUDIO_BOOKING_REPORT
	},
	// {
	// 	id: 3, 
	// 	label: "3. เปรียบเทียบรายได้", 
	// 	detail: "รายงานเปรียบเทียบรายได้",
	// 	filter: [REPORT_FILTER.MONTH],
	// 	required: true,
	// 	url: ""
	// },
	// {
	// 	id: 4, 
	// 	label: "4. Revenue", 
	// 	detail: "รายงาน Revenue",
	// 	filter: [REPORT_FILTER.MONTH],
	// 	required: true,
	// 	// url: URL_REPORT.REVENUE_REPORT
	// 	url: ""
	// },
]

export default function ReportsView() {
	const [targetReport, setTargetReport] = React.useState(null);
	const [loading, setLoading] = React.useState(null);
	
	const {
		token: {colorPrimaryText, colorWarningText, colorSuccessText, colorInfoText}
	} = theme.useToken();

	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			{targetReport && loading != null &&
				<Modal
					title={
					<>
						Generating - {targetReport.label} 
						{loading === "pdf" ? " PDF " : " Excel "}
						{loading === "pdf" ? <FilePdfFilled /> : <FileExcelFilled />}
					</>}
					open={loading != null}
					style={{
						top: 20,
					}}
					closable={false}
					footer={[]}
				>
					<Lottie animationData={lottieReport} />
				</Modal>
			}	
			<Header title={"Report"} />
			{/* Sale */}
			<Collapse defaultActiveKey={["1", "2", "3", "4"]}>
				<Collapse.Panel header={"Sales"} key="1">
					<Row 
						gutter={[16, 16]}
						style={{marginTop: 16, marginBottom: 16}}>
						{SaleReports.map(item => (
							<Col key={item.id} xl={8} sm={12} xs={24}>
								<Button
									style={{width: "100%", textAlign: "left", borderColor: colorInfoText}}
									disabled={item.url ? false : true}
									onClick={() => setTargetReport(item)}>
										{item.label}
								</Button>
							</Col>
						))}
					</Row>
				</Collapse.Panel>
				{/* PT */}
				<Collapse.Panel header={"Personal Trainer"} key="2">
					<Row 
						gutter={[16, 16]}
						style={{marginTop: 16, marginBottom: 16}}>
						{PTReports.map(item => (
							<Col key={item.id} xl={8} sm={12} xs={24}>
								<Button
									style={{width: "100%", textAlign: "left", borderColor: colorWarningText}}
									disabled={item.url ? false : true}
									onClick={() => setTargetReport(item)}>
										{item.label}
								</Button>
							</Col>
						))}
					</Row>
				</Collapse.Panel>
				{/* Account */}
				<Collapse.Panel header={"Accounting"} key="3">
					<Row 
						gutter={[16, 16]}
						style={{marginTop: 16, marginBottom: 16}}>
						{AccountReports.map(item => (
							<Col key={item.id} xl={8} sm={12} xs={24}>
								<Button
									style={{width: "100%", textAlign: "left", borderColor: colorSuccessText}}
									disabled={item.url ? false : true}
									onClick={() => setTargetReport(item)}>
										{item.label}
								</Button>
							</Col>
						))}
					</Row>
				</Collapse.Panel>
				{/* Others */}
				<Collapse.Panel header={"Others"} key="4">
					<Row 
						gutter={[16, 16]}
						style={{marginTop: 16, marginBottom: 16}}>
						{OtherReports.map(item => (
							<Col key={item.id} xl={8} sm={12} xs={24}>
								<Button
									style={{width: "100%", textAlign: "left", borderColor: colorPrimaryText}}
									disabled={item.url ? false : true}
									onClick={() => setTargetReport(item)}>
										{item.label}
								</Button>
							</Col>
						))}
					</Row>
				</Collapse.Panel>
			</Collapse>
			
			{/* Modal */}
			<ReportModal 
				namePrefix={targetReport ? targetReport.label : null}
				open={targetReport ? true : false}
				data={targetReport}
				onStartLoading={(type) => setLoading(type)}
				onEndLoading={() => setLoading(null)}
				onClose={() => setTargetReport(null)}/>
		</div>
	)
}