import React from 'react';
import propTypes from "prop-types";
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../frameworks/CropUtil';
import { Modal, Slider, Divider, Row, Col, Alert, Spin } from 'antd';
import { PATCH } from '../../frameworks/HttpClient';
import { URL_MEMBER } from '../../constants/urls';


export default function AvatarEditorModal(props) {
  const MAX_IMAGE_SIZE = 512;
  const [loading, setLoading] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [zoom, setZoom] = React.useState(1);

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleFinish = async () => {
    setLoading(true);
    // props.onOk(croppedImage)
		try {
			const croppedImage = await getCroppedImg(
				props.image,
				croppedAreaPixels,
				0
			);
			const response = await PATCH(`${URL_MEMBER.MEMBER}${props.member}/`, {profile_image: croppedImage});
			console.log(response);
			props.onUpdated();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
      setLoading(false);
    }
  }
	
	React.useEffect(() => {
		if (props.open) {
			setZoom(1);
			setCrop({ x: 0, y: 0 });
			setErrorMessages(null);
		}
	}, [props.open])
  
  return(
    <Modal 
      open={props.open}
      onOk={handleFinish}
			onCancel={props.onClose}
      confirmLoading={loading}
      centered={true}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}>
      <Spin spinning={loading} tip='Loading...'>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />
        }
        <Row style={{height: '300px'}}>
          <Col span={24}>
            <Cropper
              image={props.image}
              crop={crop}
              zoom={zoom}
              aspect={2 / 2.5}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoomWithScroll={false}
              showGrid={false}
            />
          </Col>
        </Row>
        <Divider/>
        <Row justify='center'>
          <Col span={3}>
            Zoom
          </Col>
          <Col span={16}>
            <Slider
              defaultValue={1}
              tooltip={{open: false}}
              min={1}
              max={3}
              step={0.01}
              onChange={value => setZoom(value)}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

AvatarEditorModal.defaultProps = {
  image: null,
	member: null,
  open: false,
  onClose: () => null,
}

AvatarEditorModal.prototype = {
  image: propTypes.any,
	member: propTypes.string,
  open: propTypes.bool,
  onClose: propTypes.func,
}