import React, { useState, useEffect } from 'react';
import './PackagesHomePage.css';
import img1 from '../CssScreen/photopromo1-7/promo-1.webp';
import img2 from '../CssScreen/photopromo1-7/promo-2.webp';
import img3 from '../CssScreen/photopromo1-7/promo-3.webp';
import img4 from '../CssScreen/photopromo1-7/promo-4.webp';
import img5 from '../CssScreen/photopromo1-7/promo-5.webp';
import img6 from '../CssScreen/photopromo1-7/promo-6.webp';
import img7 from '../CssScreen/photopromo1-7/promo-7.webp';

const PackagesHomePage = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 12000); // Slideshow interval

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="slidertop">
      <div className="slider-innertop">
        {images.map((image, index) => (
          <div
            className={`slider-itemtop ${index === currentIndex ? 'active' : ''}`}
            key={index}
          >
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackagesHomePage;
