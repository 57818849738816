import React from 'react'
import propTypes from "prop-types";
import { Alert, DatePicker, Form, Input, Modal, Select, theme, TimePicker } from "antd";
import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/string';
import Header from '../common/Header';
import { URL_TRAINER } from '../../constants/urls';
import { GET, POST } from '../../frameworks/HttpClient';
import _ from "lodash";
import { ellipsisString } from '../../frameworks/Util';

export default function AppointmentModal(props) {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [member, setMember] = React.useState();
  const [debouncedCode, setDebouncedCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const {
    token: { colorSuccess },
  } = theme.useToken();

  const debounceMember = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

  // Handle date picker
  const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, 'day').endOf('day');
  };

  const fetchMember = async () => {
    try {
      const response = await GET(`${URL_TRAINER.PT_PACKAGE_TRAINER}?search=${debouncedCode}&page_size=${50}`);
      setMember(response.data.results.map((item) => {
        return {
          ...item,
          value: item.id,
          label: `${ellipsisString(item.display_name, 30)} (${item.end_date ? item.end_date : "unactivated"})`
        }
      }));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Create Appointment
  const createAppointment = async () => {
    // Validate data
    let data = await form.validateFields();
    setErrorMessages(null);
    setLoading(true);
    // Prepare data
    data["date"] = data.date.format(DATE_FORMAT);
    data["start_time"] = data.time[0].format(TIME_FORMAT);
    data["end_time"] = data.time[1].format(TIME_FORMAT);
    delete data["time"];
    let response = null;

    try {
      response = await POST(URL_TRAINER.SESSION_APPOINTMENT, data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
    finally { setLoading(false) }

    if (response)
      props.onCreated();
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
      fetchMember();
      setErrorMessages(null); 
    }
  }, [props.open])

  React.useEffect(() => {
    if (props.open) {
      fetchMember();
    }
  }, [debouncedCode])

  return (
    <Modal
      open={props.open}
      okButtonProps={{ 
        style: { background: colorSuccess },
        loading: loading,
      }}
      okText={"Create"}
      onOk={createAppointment}
      onCancel={props.onClose}>
      <Header title={"Appointment"} />
      {errorMessages && (
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{
						marginBottom: "12px", 
						marginTop: "12px", 
						textAlign: "left"}}/>
			)}
      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}>
        <Form.Item
          name={"member_package"}
          label={"Member"}
          rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            showSearch
            optionFilterProp="children"
            options={member}
            onSearch={debounceMember}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} />
        </Form.Item>

        <Form.Item
          name={"date"}
          label={"Date"}
          rules={[{ required: true }]}>
          <DatePicker
            disabledDate={disabledDate}
            style={{ width: '100%' }}
            inputReadOnly={true}/>
        </Form.Item>

        <Form.Item
          name={"time"}
          label={"Time"}
          rules={[{ required: true }]}>
          <TimePicker.RangePicker
            style={{ width: '100%' }}
            format={TIME_FORMAT}
            inputReadOnly={true}
            minuteStep={15}
            disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4] })}/>
        </Form.Item>

      </Form>
    </Modal>
  )
}

AppointmentModal.defaultProps = {
  open: false,
  onClose: () => { },
  onCreated: () => { },
}

AppointmentModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  onCreated: propTypes.func,
}