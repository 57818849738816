import React from "react";
import propTypes from "prop-types";
import {Tabs} from "antd-mobile"
import { Alert, Button, Card, Image, List, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { GET } from "../../frameworks/HttpClient";
import { URL_PACKAGE } from "../../constants/urls";
import logo from "../../assets/ahc_logo.png"
import MyDropPackageModal from "../../components/package/MyDropPackageModal";
import DetailPackageModal from "../../components/package/DetailPackageModal";
import Meta from "antd/es/card/Meta";
import { CheckCircleFilled } from "@ant-design/icons";
import UsageHistory from "../../components/member/UsageHistory";


export default function MyPackagesView(props) {
  const navigate = useNavigate();
  const [my, setMy] = React.useState(null);
  const [additionalPackages, setAdditionalPackage] = React.useState([]);
  const [mainPackages, setMainPackages] = React.useState([]);
  const [otherPackages, setOtherPackages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState(null);

  // Handle Package modal
  const [dropPackage, setDropPackage] = React.useState(null);
  const [moreMainPackage, setMoreMainPackage] = React.useState(null);
  const [moreAdditionalPackage, setMoreAdditionalPackage] = React.useState(null);
  const [moreOtherPackage, setMoreOtherPackage] = React.useState(null);

  const {
    token: { colorSuccess }
  } = theme.useToken();

  const fetchPackages = async () => {
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PACKAGE.PACKAGES_MEMBER}info/`);
      setMainPackages(response.data.membership_packages);
      setAdditionalPackage(response.data.additional_packages);
      // Dynamic + Voucher
      let temp = [];
      const dynamics = response.data.other_packages ? response.data.other_packages.dynamic_packages : [];
      for (let dynamic of dynamics) {
        temp.push({
          ...dynamic,
          package_name: dynamic.name,
          title: `${dynamic.name}`,
          label: ``,
          detail: ``
        })
      }
      const coupons = response.data.other_packages ? response.data.other_packages.coupons : [];
      for (let coupon of coupons) {
        temp.push({
          ...coupon,
          package_name: coupon.name,
          title: `[coupon] ${coupon.name}`,
          label: `Code: ${coupon.code}`,
          detail: `[CODE] ${coupon.code} \n${coupon.detail}`
        })
      }
      setOtherPackages(temp);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Actions: Main package
  const renderMainActions = (item) => {
    const actions = [];
    if (item.drop_remain > 0 && item.is_activated === true)
      actions.push(<Button type={"link"} onClick={() => setDropPackage(item)}>{"Drop"}</Button>);

    actions.push(<Button type={"link"} onClick={() => setMoreMainPackage(item)}>{"More"}</Button>);
    return actions;
  }

  // Actions: Additional package
  const renderAdditionalActions = (item) => {
    return [
      <Button type={"link"} onClick={() => setMoreAdditionalPackage(item)}>{"More"}</Button>
    ];
  }

  // Actions: Other package
  const renderOtherActions = (item) => {
    return [
      <Button type={"link"} onClick={() => setMoreOtherPackage(item)}>{"More"}</Button>
    ];
  }

  React.useEffect(() => {
    if (my)
      fetchPackages();
    
  }, [my]);

  React.useEffect(() => {
    if (props.member) 
      setMy(props.member.id)

  }, [props.member]);

  return (
    <div>
      {errorMessages &&
        <Alert
          style={{ marginTop: "12px", textAlign: "left" }}
          message={errorMessages}
          showIcon
          type="error"/>
      }

      <Tabs>
        <Tabs.Tab title="Membership" key="main">
          <List
            dataSource={mainPackages}
            renderItem={(data) => (
              <Card
                key={data.id}
                style={{ marginTop: 16 }}
                actions={renderMainActions(data)}>
                <Meta 
                  Align = {"left"}
                  avatar={<Image style={{height: 60 , width: "auto", marginTop: 10}} src={data.image ? data.image : logo} />}
                  title={`${(data && data.has_dynamic) ? "[ALL CLUB - " + data.dynamic_package.name + "] " : ""}${(data && data.package_name) ? data.package_name : "Unknown"}`}
                  description={(
                    <div>
                      <div>{`Start: ${(data && data.start_date) ? data.start_date : "-"}`}</div>
                      <div>{`Exp: ${(data && data.end_date) ? data.end_date : "-"}`}</div>
                      {data && data.is_activated && (<CheckCircleFilled style={{color: colorSuccess}}/>)}
                    </div>
                  )} />
              </Card>
            )} />
        </Tabs.Tab>
        <Tabs.Tab title="Additional" key="additional">
          <List
            dataSource={additionalPackages}
            renderItem={(data) => (
              <Card
                style={{ marginTop: 16 }}
                actions={renderAdditionalActions(data)}>
                <Meta
                  Align = {"left"} 
                  avatar={<Image style={{height: 60, width: "auto",marginTop: 10}} src={data.image ? data.image : logo} />}
                  title={(data && data.package_name) ? `[ ${(data.variant_type === "12") ? "1:2" : "1:1"} ] ${data.package_name}` : "Unknown"}
                  description={(
                    <div>
                      <div>{`${(data && data.trainer_name) ? data.trainer_name : "Unknown"}`}</div>
                      <div>{`Start: ${(data && data.start_date) ? data.start_date : "-"}`}</div>
                      <div>{`Exp: ${(data && data.end_date) ? data.end_date : "-"}`}</div>
                    </div>
                  )} />
              </Card>
            )} />
        </Tabs.Tab>
      
        <Tabs.Tab title="Other" key="other">
          <List
            dataSource={otherPackages}
            renderItem={(data) => (
              <Card
                style={{ marginTop: 16 }}
                actions={renderOtherActions(data)}>
                <Meta
                  Align = {"left"}
                  title={(data && data.title) ? data.title : "Unknown"}
                  description={(data && data.expired) ? 
                  (<div>
                          <div>{(data && data.label) ? data.label : "-"}</div>
                          <div>{`Exp: ${(data && data.expired) ? data.expired : "-"}`}</div>
                      </div>)
                  :(<div>
                        <div>{`Start: ${(data && data.start_date) ? data.start_date : "-"}`}</div>
                        <div>{`Exp: ${(data && data.end_date) ? data.end_date : "-"}`}</div>
                      </div>)} />
              </Card>
            )} />
        </Tabs.Tab>

        <Tabs.Tab title="Usages History" key="usages">
          <UsageHistory id={props.member ? props.member.id : "unknown"}/>
        </Tabs.Tab>
      </Tabs> 
      
      <MyDropPackageModal
        package={dropPackage}
        open={dropPackage ? true : false}
        onClose={() => {
          setDropPackage(null); 
          fetchPackages();
        }}

      />

      {/* Detail: Main package */}
      <DetailPackageModal
        package={moreMainPackage}
        open={moreMainPackage ? true : false}
        onClose={() => setMoreMainPackage(null)}
      />

      {/* Detail: Additional package */}
      <DetailPackageModal
        title={"PT Session Detail"}
        package={moreAdditionalPackage}
        open={moreAdditionalPackage ? true : false}
        onClose={() => setMoreAdditionalPackage(null)}
      />

      {/* Detail: Other package */}
      <DetailPackageModal 
        title={"Other Package"}
        package={moreOtherPackage}
        isOther={true}
        open={moreOtherPackage ? true : false}
        onClose={() => setMoreOtherPackage(null)} />
    </div>
  )

  
}

MyPackagesView.defaultProps = {
  member: null,
}

MyPackagesView.propTypes = {
  member: propTypes.object,
}