import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import { isMobile } from 'react-device-detect';
import { Alert, Button, Col, DatePicker, Form, Input, Modal, Row, Select, theme } from "antd";
import Header from "../common/Header";
import { useForm } from "antd/es/form/Form";
import { DATE_FORMAT, MONTH_FORMAT, REPORT_FILTER } from "../../constants/string";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { GET } from "../../frameworks/HttpClient";
import { URL_USERS } from "../../constants/urls";

export default function ReportModal(props) {
	const [form] = useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [pdfLoading, setPDFLoading] = React.useState(false);
	const [excelLoading, setExcelLoading] = React.useState(false);
	const [data, setData] = React.useState(null);
	const [filters, setFilters] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(false);
	
	// Options
	const [ptOptions, setPTOptions] = React.useState([]);
	const [salesOptions, setSalesOptions] = React.useState([]);
	const [cashierOptions, setCashierOptions] = React.useState([]);

	const {
		token: { colorSuccessTextActive, colorErrorTextActive }
	} = theme.useToken();

	const updateFilters = (myData) => {
		if (myData) {
			setFilters({
				showDateRange: myData.filter.find(filter => filter === REPORT_FILTER.DATE_RANGE) ? true : false,
				showDate: myData.filter.find(filter => filter === REPORT_FILTER.DATE) ? true : false,
				showMember: myData.filter.find(filter => filter === REPORT_FILTER.MEMBER) ? true : false,
				showPackageStatus: myData.filter.find(filter => filter === REPORT_FILTER.PACKAGE_STATUS) ? true : false,
				showGender: myData.filter.find(filter => filter === REPORT_FILTER.GENDER) ? true : false,
				showAge: myData.filter.find(filter => filter === REPORT_FILTER.AGE) ? true : false,
				showNationality: myData.filter.find(filter => filter === REPORT_FILTER.NATIONALITY) ? true : false,
				showPT: myData.filter.find(filter => filter === REPORT_FILTER.PT) ? true : false,
				showMonth: myData.filter.find(filter => filter === REPORT_FILTER.MONTH) ? true : false,
				showPaymentStatus: myData.filter.find(filter => filter === REPORT_FILTER.PAYMENT_STATUS) ? true : false,
				showRewardType: myData.filter.find(filter => filter === REPORT_FILTER.REWARD_TYPE) ? true : false,
				showSales: myData.filter.find(filter => filter === REPORT_FILTER.SALE) ? true : false,
				showCashier: myData.filter.find(filter => filter === REPORT_FILTER.CASHIER) ? true : false,
				showAbsent: myData.filter.find(filter => filter === REPORT_FILTER.ABSENT_DAY) ? true : false,
				showExpriring: myData.filter.find(filter => filter === REPORT_FILTER.EXPRIRING_DAY) ? true : false,
			})
		} else {
			setFilters({});
		}
	}

	const getParams = (values) => {
		let params = {...values}
		if (values[REPORT_FILTER.DATE_RANGE]) {
			params["start_date"] = values[REPORT_FILTER.DATE_RANGE][0].format(DATE_FORMAT);
			params["end_date"] = values[REPORT_FILTER.DATE_RANGE][1].format(DATE_FORMAT);
			delete params[REPORT_FILTER.DATE_RANGE]
		}
		if (values[REPORT_FILTER.DATE]) {
			params["date"] = values[REPORT_FILTER.DATE].format(DATE_FORMAT);
		}
		if (values[REPORT_FILTER.MONTH]) {
			params["month"] = values[REPORT_FILTER.MONTH].format(MONTH_FORMAT);
		}

		return params;
	}

	const handleSubmit = async (exportType) => {
		let url = data.url
		if (!url) {
			setErrorMessages("Report not found!");
			return;
		}

		// Set loading
		props.onStartLoading(exportType)
		if (exportType === "pdf")
			setPDFLoading(true);
		else
			setExcelLoading(true);
	
		// Prepare data
		setErrorMessages(null);
		url = `${url}${exportType}/`
		try {
			const values = await form.validateFields();
			const params = getParams(values);
			// Goto export
			const response = await GET(url, params, false, true) // Download as blob
			
			if(exportType === "excel" && props.namePrefix != null) {
				const fileURL = URL.createObjectURL(response.data);
				const fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.download = `${props.namePrefix.replace(/ /g, "_")}_${moment().unix() }.xlsx`;
				fileLink.click();
				setTimeout(() => {
					fileLink.remove();
				}, 100);
			}
			else {
				setTimeout(() => {
					const target = isMobile ? '_top' : '_blank'
					window.open(URL.createObjectURL(response.data), target)
				})
			}
			props.onClose();
		} catch (error) {
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
		}

		finally {
			props.onEndLoading()
			if (exportType === "pdf")
				setPDFLoading(false);
			else
				setExcelLoading(false);
		}
	}

	// Fetch PT options
	const fetchPT = async () => {
		try {
			setIsLoading(true)
			const response = await GET(`${URL_USERS.TRAINERS}?page_size=100`);
			setPTOptions(response.data.results.map(item => ({...item, value: item.id, label: item.full_name})));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setIsLoading(false)
		}
	}

	const fetchSales = async () => {
		try {
			setIsLoading(true)
			const response = await GET(URL_USERS.SALES);
			setSalesOptions(response.data.results.map(item => ({...item, value: item.id, label: item.full_name})))
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setIsLoading(false)
		}
	}
	
	const fetchCashier = async () => {
		try {
			setIsLoading(true)
			const response = await GET(URL_USERS.CASHIER);
			setCashierOptions(response.data.results.map(item => ({ ...item, value: item.id, label: item.full_name })))
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setIsLoading(false)
		}
	}

	React.useEffect(() => {
		if (filters && (filters !== {})) {
			if (filters.showPT) {
				fetchPT();
			}

			if (filters.showSales) {
				fetchSales()
			}

			if (filters.showCashier) {
				fetchCashier()
			}
		}
	}, [filters])

	React.useEffect(() => {
		setData(props.data);
		updateFilters(props.data);
	}, [props.data])

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			form.resetFields();
		}
	}, [props.open])

	return (
		<Modal
			open={props.open}
			onOk={props.onClose}
			onCancel={props.onClose}
			footer={[
				<Button key={"1"} onClick={props.onClose}>{"Cancel"}</Button>,
				props.data && !props.data.hideExcel && (
					<Button
						key={"2"}
						type={"primary"}
						loading={excelLoading}
						icon={<FileExcelOutlined />}
						style={{ background: colorSuccessTextActive }}
						onClick={() => handleSubmit("excel")}>
						{"Excel"}
					</Button>
				)
				,
				props.data && !props.data.hidePDF && (
					<Button 
						key={"3"}
						type={"primary"}
						loading={pdfLoading}
						style={{background: colorErrorTextActive}}
						icon={<FilePdfOutlined />}
						onClick={() => handleSubmit("pdf")}>
						{"PDF"}
					</Button>
				)
			]}>
				<Header title={data ? data.detail : "Report"}></Header>

				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginTop: "12px", marginBottom: "12px", textAlign: "left"}}
					/>
				}
				<Form 
					style={{marginTop: 16}}
					form={form}
					layout={"vertical"}>
					<Row gutter={16} justify={"start"}>
						{data && filters["showDateRange"] && (
							<Col xl={12} sm={12} xs={24} key={"showDateRange"}>
								<Form.Item 
									name={REPORT_FILTER.DATE_RANGE} 
									label={"Date Range"} 
									rules={[{ required: data.required }]}>
									<DatePicker.RangePicker style={{ width: '100%' }} inputReadOnly={true} />
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showDate"] && (
							<Col xl={12} sm={12} xs={24} key={"showDate"}>
								<Form.Item 
									name={REPORT_FILTER.DATE} 
									label={"Date"}
									rules={[{ required: data.required }]}>
									<DatePicker style={{ width: '100%' }} inputReadOnly={true} />
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showMember"] && (
							<Col xl={12} sm={12} xs={24} key={"showMember"}>
								<Form.Item name={REPORT_FILTER.MEMBER} label={"Trial Member"}>
								<Input autoComplete="off" />
								</Form.Item>
							</Col>
						)}

						{data && filters["showPackageStatus"] && (
							<Col xl={12} sm={12} xs={24} key={"showPackageStatus"}>
								<Form.Item 
									name={REPORT_FILTER.PACKAGE_STATUS} 
									label={"Package Status"} 
									rules={[{ required: data.required }]}>
									<Select
										options={[
											{key: "active", value: "active", label: "Active"},
											{key: "non-active", value: "non-active", label: "Non-Active"}
										]}/>
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showSales"] && (
							<Col xl={12} sm={12} xs={24} key={"showSales"}>
								<Form.Item 
									name={REPORT_FILTER.SALE} 
									label={"Sale"}>
									<Select allowClear={true} inputReadOnly={true} options={salesOptions} loading={isLoading} disabled={isLoading}/>
								</Form.Item>
							</Col>
						)}

					{data && filters["showCashier"] && (
						<Col xl={12} sm={12} xs={24} key={"showCashier"}>
							<Form.Item
								name={REPORT_FILTER.CASHIER}
								label={"Cashier"}>
								<Select allowClear={true} inputReadOnly={true} options={cashierOptions} loading={isLoading} disabled={isLoading} />
							</Form.Item>
						</Col>
					)}

						{data && filters["showGender"] && (
							<Col xl={12} sm={12} xs={24} key={"showGender"}>
								<Form.Item name={REPORT_FILTER.GENDER} label={"Gender"}>
									<Select 
										options={[
											{key: "m", value: "m", label: "Male"},
											{key: "f", value: "f", label: "Female"}
										]}/>
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showAge"] && (
							<Col xl={12} sm={12} xs={24} key={"showAge"}>
								<Form.Item name={REPORT_FILTER.AGE} label={"Age"}>
								<Input autoComplete="off" />
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showNationality"] && (
							<Col xl={12} sm={12} xs={24} key={"showNationality"}>
								<Form.Item name={REPORT_FILTER.NATIONALITY} label={"Nationality"}>
								<Input autoComplete="off" />
								</Form.Item>
							</Col>
						)}

						{data && filters["showPT"] && (
							<Col xl={12} sm={12} xs={24} key={"showPT"}>
								<Form.Item 
									name={REPORT_FILTER.PT} 
									label={"PT"}
									rules={[{ required: data.required }]}>
								<Select allowClear={true}  inputReadOnly={true} options={ptOptions} loading={isLoading} disabled={isLoading} />
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showMonth"] && (
							<Col xl={12} sm={12} xs={24} key={"showMonth"}>
								<Form.Item 
									name={REPORT_FILTER.MONTH} 
									label={"Month"}
									rules={[{ required: data.required }]}>
									<DatePicker picker={"month"} style={{ width: '100%' }} inputReadOnly={true}/>
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showPaymentStatus"] && (
							<Col xl={12} sm={12} xs={24} key={"showPaymentStatus"}>
								<Form.Item name={"status"} label={"Payment Status"}>
									<Select
										inputReadOnly={true}
										options={[
											{key: "active", value: "active", label: "Active"},
											{key: "cancel", value: "cancel", label: "Cancel"}
										]}/>
								</Form.Item>
							</Col>
						)}

						{data && filters["showRewardType"] && (
							<Col xl={12} sm={12} xs={24} key={"showRewardType"}>
								<Form.Item name={REPORT_FILTER.REWARD_TYPE} label={"Reward Type"}>
									<Select 
										inputReadOnly={true}  
										options={[
											{key: "manual", value: "manual", label: "MANUAL"},
											{key: "booking", value: "booking", label: "BOOKING"},
											{key: "purchase", value: "purchase", label: "PURCHASE"},
											{key: "referral", value: "referral", label: "REFERRAL"},
										]}/>
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showAbsent"] && (
							<Col xl={12} sm={12} xs={24} key={"showAbsent"}>
								<Form.Item name={REPORT_FILTER.ABSENT_DAY} label={"Absent days"} initialValue={"14"}>
									<Input type={"number"} autoComplete="off"/>
								</Form.Item>
							</Col>
						)}
						
						{data && filters["showExpriring"] && (
							<Col xl={12} sm={12} xs={24} key={"showExpriring"}>
								<Form.Item name={REPORT_FILTER.EXPRIRING_DAY} label={"Expriring days"} initialValue={"7"}>
									<Input type={"number"} autoComplete="off"/>
								</Form.Item>
							</Col>
						)}
						
					</Row>
				</Form>
		</Modal>
	)
}

ReportModal.defaultProps = {
	namePrefix: null,
  open: false,
	data: null,
  onClose: () => {},
	onStartLoading: (type) => {},
	onEndLoading: () => {},
}

ReportModal.propTypes = {
	namePrefix: propTypes.string,
  open: propTypes.bool,
	data: propTypes.object,
  onClose: propTypes.func,
	onStartLoading: propTypes.bool,
	onEndLoading: propTypes.bool,
}