import React from 'react'
import Cookies from 'js-cookie';
import { useLiff } from 'react-liff';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Image, Spin, Alert, Typography, Result, theme } from 'antd';
// import Vconsole from 'vconsole';

import './../../styles.css'
import logo from '../../assets/ahc_logo.png';
import { POST } from '../../frameworks/HttpClient';
import { PATH } from '../../routes/CustomRoutes';
import { URL_AUTH } from '../../constants/urls';
import { COOKIE_AUTH } from '../../constants/cookies';
import Segment from '../../components/common/Segment';
import useUserData from '../../hooks/useUserData';
import * as _ from 'lodash';


export default function LiffLoginScreen() {
  const REDIRECT_PATH = `${PATH.MEMBER}/profile`;

  const navigate = useNavigate();
  const user = useUserData();
  const { token: { colorSuccess } } = theme.useToken();
  const { liff, isLoggedIn, isReady, error } = useLiff();
  const liffLoading = (!error && !isReady);

  // general state
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  // line user
  const DEFAULT_LINE_USER_DETAIL = {
    pictureUrl: null,
    displayName: null,
  }
  const [lineUserDetail, setLineUserDetail] = React.useState(DEFAULT_LINE_USER_DETAIL);

  // auth user
  const DEFAULT_AUTH_RESPONSE = {
    token: null,
    user: null,
  }
  const [checkingUserError, setCheckingUserError] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState(DEFAULT_AUTH_RESPONSE)
  const checkUserExists = _.get(userDetail, 'token', false);

  const checkLiffAuth = async () => {
    setLoading(true);
    setErrorMessages(null);
    setCheckingUserError(false);
    try {
      const accessToken = liff.getAccessToken();
      const response = await POST(URL_AUTH.CHECK_LIFF_AUTH, { liff_access_token: accessToken });
      setUserDetail(prev => ({...prev, ...response.data}))
    } catch (error) {
      setCheckingUserError(true);
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handleReLogin = async () => {
    await Cookies.set(COOKIE_AUTH.TOKEN, _.get(userDetail, 'token'), { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
    await user.setUserData(_.get(userDetail, 'user'));
    navigate(REDIRECT_PATH)
  }

  const handleLiffAuthenticate = async () => {
    setErrorMessages(null)
    setLoading(true);
    try {
      // scan QRCODE with member id from reception
      const qrCode = await liff.scanCodeV2();
      // send access token with member id to register LINE with back-end user
      const response = await POST(URL_AUTH.API_LIFF_TOKEN_AUTH, {
        liff_access_token: liff.getAccessToken(),
        member_id: qrCode.value
      })
      Cookies.set(COOKIE_AUTH.TOKEN, response.data.token, { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
      user.setUserData(response.data.user);
      navigate(REDIRECT_PATH);
    } catch (error) {
      setErrorMessages(
        _.get(error, 'errorMessages', false) ? 
        error.errorMessages : 
        String(error)
      );
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    // if liff is ready then start flow check
    if (isReady) {
      // if already have cookie auth then bypass and redirect to menu
      if (Cookies.get(COOKIE_AUTH.TOKEN)) {
        navigate(REDIRECT_PATH)
      } else if (isLoggedIn) {
        // check auth data from back-end
        checkLiffAuth();
  
        // feth LINE user profile data
        // if loggedIn then it should able to fetch
        const fetchLineUserProfile = async () => {
          const profile = await liff.getProfile();
          setLineUserDetail(prev => ({...prev, ...profile}))
        }
        fetchLineUserProfile();
      }
    }
  }, [isLoggedIn, isReady])

  React.useEffect(() => {
    if (error != null) {
      if (error.message !== errorMessages) {
        console.log(error)  // print once
      }
      setErrorMessages(error.message)
    }
  }, [error])

  // for testing
  // React.useEffect(() => {  // Enable debug on login screen only
  //   const vc = new Vconsole()
  //   return () => {vc.destroy()}
  // }, [])

  return (
    <div className='center-screen'>
      <div style={{width: '90%', maxWidth: 420}}>
        <Image src={logo} preview={false} style={{textAlign: 'center', width: '100%', maxWidth: 120}}/>
        <Segment style={{ marginTop: 24, padding: 24 }} detail={
          <Spin spinning={loading || liffLoading}>
            { !checkUserExists && <Typography.Title style={{ marginTop: 0 }} level={2}>Line Login</Typography.Title> }
            { errorMessages && <Alert style={{ marginBottom: 12 }} showIcon type='error' message={errorMessages}/> }
            { (errorMessages && errorMessages == 'Failed to fetch') && (
              <Alert
                style={{ marginBottom: 12 }} showIcon type='error'
                message='Home > Settings > Chats > Delete Data > Cache (Clear)'/>
            ) }

            { (isLoggedIn && isReady) ? (
              <div>
                { !checkUserExists && (
                  <div>
                    { lineUserDetail.pictureUrl && <Image preview={false} src={`${lineUserDetail.pictureUrl}/small`}/> }
                    <Typography.Title style={{ marginTop: 0 }} level={5}>{_.get(lineUserDetail, 'displayName', '-')}</Typography.Title>
                  </div>
                ) }
                
                { checkingUserError ? (
                  <div>
                    <Typography.Paragraph>
                      <div>Something wrong with login page.</div>
                      <div>Please try reload page or try again later.</div>
                    </Typography.Paragraph>
                    <Button type='primary' onClick={() => window.location.reload(true) }>Reload</Button>
                  </div>                
                ) : (
                  <div>
                    { checkUserExists ? (
                      <div>
                        <Result
                          style={{ padding: 0 }}
                          status='success'
                          title='Welcome back!'
                          subTitle={`Proceed as ${_.get(userDetail, 'user.full_name', '-')}.`}
                          extra={
                            <Button
                              style={{ backgroundColor: colorSuccess }}
                              type='primary'
                              override={colorSuccess}
                              icon={<UserOutlined/>}
                              onClick={handleReLogin}>
                              Go to Profile
                            </Button>
                          }/>
                      </div>
                    ) : (
                      <div>
                        <Typography.Paragraph>
                          Start using Alpha health club through LINE.
                        </Typography.Paragraph>
                        <Button
                          type='primary'
                          icon={<LoginOutlined />}
                          onClick={handleLiffAuthenticate}>
                          Start Authenticate
                        </Button>
                      </div>
                    ) }
                  </div>
                ) }
              </div>
            ) : (
              <div>
                { liffLoading ? (
                  <div>Logging in...</div>
                ) : (
                  <div>
                    <Typography.Paragraph>
                      <div>Something wrong with LINE.</div>
                      <div>Please try reload page or try again later.</div>
                    </Typography.Paragraph>
                    <Button type='primary' onClick={() => window.location.reload(true) }>Reload</Button>
                    {/* for development outside LINE */}
                    {/* <Button type='primary' onClick={() => liff.login() }>Login</Button> */}
                  </div>
                )}
              </div>
            ) }
          </Spin>
        }/>
      </div>
    </div>
  )
}
