import React from "react";
import propTypes from "prop-types";
import { Card, Popconfirm, message } from "antd";
import "./../../../styles.css"
import { DELETE } from "../../../frameworks/HttpClient";
import { URL_TRAINER } from "../../../constants/urls";
import Meta from "antd/es/card/Meta";
import DetailClassBookingModal from "./DetailClassBooking";
import { toHumanDate } from "../../../frameworks/Util";

export default function CardPTClassBooking(props) {
  const [data, setData] = React.useState(null);
  const [time, setTime] = React.useState("Unknown");
  const [openMore, setOpenMore] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleCancelBooking = async () => {
    if(!data) {
      messageApi.open({
        type: 'error',
        content: "Error unknown booking data"
      })
    }

    if(props.data && props.data.type === "pt_class") {
    try {
      await DELETE(`${URL_TRAINER.MEMBER_BOOKING}${data.id}/`);
      messageApi.open({
        type:'success',
        content: "Cancel Booking Success" 
      })
      props.onCancel()
    } catch (error) {
      messageApi.open({
        type:'error',
        content: error.errorMessages
      })
    } 
    } else {
      try {
        await DELETE(`${URL_TRAINER.SESSION_APPOINTMENT}${data.id}/`);
        messageApi.open({
          type:'success',
          content: "Cancel Booking Success" 
        })
        props.onCancel()
      } catch (error) {
        messageApi.open({
          type:'error',
          content: error.errorMessages
        })
      } 
      }
    }


  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
      if (props.data.start_time && props.data.end_time) {
        setTime(`${props.data.start_time.substring(0, 5)} - ${props.data.end_time.substring(0, 5)}`);
      }
    }
  }, [props.data])

  return (
    <div>
      {contextHolder}
      <Card actions={(data && data.is_checked) ? 
        [<p style={{fontSize: 12, margin: 0}} onClick={() => setOpenMore(true)}>{"More"}</p>,] :
        [<p style={{fontSize: 12, margin: 0}} onClick={() => setOpenMore(true)}>{"More"}</p>,
         <Popconfirm
          title="Cancel Booking"
          description="Are you sure to cancel this booking?"
          onConfirm={handleCancelBooking}
          okText="Yes"
          cancelText="No"
        >
          <p style={{fontSize: 12, margin: 0}}>{"Cancel"}</p>
        </Popconfirm>
      ]}>
        <Meta
          style={{marginTop: 0, textAlign: 'left'}}
          title={data ? data.class_type_name : "Unknown"}/>
        <p style={{marginBottom: 0}}>{data ? data.studio_name : "-"}</p>
        <p style={{margin: 0}}>{data ? data.trainer_name : "-"}</p>
        <Meta 
          style={{ marginTop: 24 }}
          description={`Date: ${(data && data.date) ? toHumanDate(data.date) : "-"}`} />
        <Meta description={`Time: ${time}`} />
      </Card>

      <DetailClassBookingModal 
        class={props.data}
        open={openMore}
        onClose={() => setOpenMore(false)}/>
    </div>
  )
}

CardPTClassBooking.defaultProps = {
  data: null,
  onCancel: () => null,
}

CardPTClassBooking.propTypes = {
  data: propTypes.object,
  onCancel: propTypes.func,
}