import React from "react";
import propTypes from "prop-types";
import { 
  MinusOutlined, 
  PlusOutlined, 
} from "@ant-design/icons";
import { Alert, Button, Col, Collapse, List, message, Popconfirm, Row, theme, Spin } from "antd";
import { DATE_FORMAT } from "../../constants/string";
import SegmentHeader from "../../components/common/SegmentHeader";
import { URL_TRAINER } from "../../constants/urls";
import { DELETE, GET, POST } from "../../frameworks/HttpClient";
import { toHumanDate } from "../../frameworks/Util";
import dayjs from 'dayjs';
import moment from "moment";

// Class
const RenderClassList = ({ data, booked, refresh, member }) => {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const {
    token: { colorSuccess, colorPrimary },
  } = theme.useToken();

  // Booking
  const bookingClass = async (classId) => {
    if (!classId) {
      setErrorMessages("Class not found!");
      return;
    }

    setErrorMessages(null);
    try {
      await POST(`${URL_TRAINER.MEMBER_BOOKING}`, {trainer_class: classId, member_id: member.id});
      message.success("Add Class success");
      refresh();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  return (
    <div>
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon/>
      }
      <List 
        itemLayout="vertical"
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.id} style={{ textAlign: "left" ,padding: 0 }}>
            <Row align={"middle"}>
              <Col span={18}>
                <List.Item.Meta 
                  title={(item && item.class_type_name) ? item.class_type_name : "Unknown"}
                  description={
                    <div style={{fontSize: 12}}>
                      {`Studio: ${item && item.studio_name ? item.studio_name : '-'}`} <br />
                      {`Trainer: ${item && item.trainer_name ? item.trainer_name : '-'}`}<br />
                      {`Date: ${item && item.date ? toHumanDate(item.date) : '-'}`}<br />
                      {`Time: ${(item && item.start_time && item.end_time) ? (`${item.start_time.substring(0, 5)} - ${item.end_time.substring(0, 5)}`) : '-'}`}
                    </div>
                  }/>
              </Col>
              
              <Col span={6} style={{textAlign: "center"}}>
                <Button 
                  disabled 
                  style={{
                    color: colorPrimary, 
                    borderColor: colorPrimary, 
                    background: "transparent"}}>
                    {`${item ? (`${item.booked_count} / ${item.members_limit}`) : "0 / 0"}`}
                </Button>
                <br />
                {item && !booked.some(b => b.trainer_class_detail.id === item.id) && (
                  <Button 
                    style={{background: colorSuccess, marginTop: 8}}
                    type={"primary"} 
                    shape={"circle"}
                    size={"large"}
                    icon={<PlusOutlined />}
                    onClick={() => bookingClass(item.id)}/>
                )}
              </Col>
            </Row>
          </List.Item>
        )}/>
    </div>
  )
}

export default function MyBookingsView(props) {
  const { Panel } = Collapse;
  const [my, setMy] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [bookedErrorMessages, setBookedErrorMessages] = React.useState(null);
  const [booked, setBooked] = React.useState([]);
  const [classList, setClassList] = React.useState([]);
  const [dateList, setDateList] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState([]);
  const [loadingBooked, setLoadingBooked] = React.useState(false);
  const [loadingClasses, setLoadingClasses] = React.useState(false);

  const {
    token: { colorPrimary }
  } = theme.useToken();

  // Fetch Class Booked
  const fetchBooked = async () => {
    setErrorMessages(null);
    try {
      setLoadingBooked(true)
      const response = await GET(`${URL_TRAINER.MEMBER_BOOKING_HISTORY}`, {'member_id': props.member.id});
      const bookedData = response.data.booked_classes.map((item) => {
        return {
          ...item,
          id: item.id,
          class_type: item.trainer_class_detail.class_type_name,
          start_time: item.trainer_class_detail.start_time,
          end_time: item.trainer_class_detail.end_time,
          date: item.trainer_class_detail.date,
          trainer: item.trainer_class_detail.trainer_name,
          studio: item.trainer_class_detail.studio_name,
        }
      })
      
      setBooked(bookedData);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoadingBooked(false)
    }
  }

  // Fetch PT Class
  const fetchClassList = async () => {
    setErrorMessages(null);
    setLoadingClasses(true)
    const params = {
      from_date: dayjs().add(0, 'day').format(DATE_FORMAT),
      to_date: dayjs().add(6, 'day').format(DATE_FORMAT),
      page_size: 999,
      rent: false,
      is_available: true,
    }
    try {
      const response = await GET(URL_TRAINER.TRAINER_CLASS, params);
      setClassList(response.data.results);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoadingClasses(false)
    }
  }

  // Cancel class booking
  const handleCancel = async (bookId) => {
    if (!bookId) {
      setBookedErrorMessages("Class not found!");
      return;
    }

    setBookedErrorMessages(null);
    try {
      await DELETE(`${URL_TRAINER.MEMBER_BOOKING}${bookId}/`);
      message.success("Delete success");
      fetchBooked();
      fetchClassList();
    } catch (error) {
      setBookedErrorMessages(error.errorMessages);
    }
  }

  // Display PT Class
  const prepareDateList = (list) => {
    let dates = [];
    let key = [];
    for (let i = 0; i < 7; i++) {
      const date = dayjs().add(i, 'day').format(DATE_FORMAT);
      const classes = list.filter(item => item.date === date);
      dates.push({
        date: date,
        classes: classes ? classes : [],
      });
      
      if (classes && (classes.length > 0))
        key.push(date);
    }

    setDateList(dates);
    setActiveKey(key);
  }

  React.useEffect(() => {
    if (classList && (classList.length > 0)) {
      prepareDateList(classList)
    }
  }, [classList]);
  
  React.useEffect(() => {
    if (my) {
      fetchBooked();
      fetchClassList();
    }

  }, [my]);

  React.useEffect(() => {
    if (props.member) 
      setMy(props.member.id);

  }, [props.member]);


  return (
    <div >
      
      {errorMessages &&
        <Alert
          style={{ marginTop: "12px", textAlign: "left" }}
          message={errorMessages}
          type="error"
          showIcon />
      }

      <SegmentHeader
        style={{ marginTop: 16 }}
        headStyle={{ textAlign: "center" }}
        header={"คลาสที่จองแล้ว ( Booked)"}
        content={
          <div>
            {bookedErrorMessages &&
              <Alert
                message={errorMessages}
                type="error"
                showIcon />
            }
            <Spin tip="Loading" spinning={loadingBooked}>
            <List
              itemLayout="horizontal"
              dataSource={booked}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  style={{ padding: 0 }}
                  actions={[
                    <Popconfirm
                      title="Do you to cancel this class?"
                      placement={"topLeft"}
                      onConfirm={() => handleCancel(item.id)}
                      onCancel={null}
                      okText="Yes"
                      cancelText="No">
                      <Button
                        style={{ background: colorPrimary }}
                        type={"primary"}
                        shape={"circle"}
                        size={"large"}
                        icon={<MinusOutlined />} />
                    </Popconfirm>
                  ]}>
                  <List.Item.Meta
                    style={{textAlign : "left"}}
                    title={(item && item.class_type) ? item.class_type : "Unknown"}
                    description={
                      <div style={{ fontSize: 12 }}>
                        {`${item && item.trainer ? item.trainer : '-'} [ ${item && item.studio ? item.studio : '-'} ]`} <br />
                        {`Date: ${item && item.date ? toHumanDate(item.date) : '-'}`}<br />
                        {`Time: ${(item && item.start_time && item.end_time) ? (`${item.start_time.substring(0, 5)} - ${item.end_time.substring(0, 5)}`) : '-'}`}
                      </div>
                    } />
                </List.Item>
              )} />
            </Spin>
          </div>
        }/>

      <p style={{ marginTop: 24, textAlign: 'center', fontWeight: 'bold' }}>รายการคลาส ( Class List )</p>
      <Spin tip="Loading" spinning={loadingClasses}>
        <Collapse
          // activeKey={activeKey}
          className='custom-collapse-header'
          style={{ background: colorPrimary }}>
          {dateList.map(data => (
            <Panel
              key={data.date}
              header={`${toHumanDate(data.date)} (${moment(data.date).format('dddd')})`}
              className="custom-content-box">
                <RenderClassList member={props.member} data={data.classes} booked={booked}
                  refresh={() => { fetchBooked(); fetchClassList(); }} />
            </Panel>
          ))}
        </Collapse>
      </Spin>
    </div>
  )
}

MyBookingsView.defaultProps = {
  member: null,
}

MyBookingsView.propTypes = {
  member: propTypes.object,
}