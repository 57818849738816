import React from "react"
import propTypes from "prop-types";
import { Alert, Card, Col, Form, Input, InputNumber, Radio, Row, Select, Spin, theme } from "antd"
import SegmentHeader from "../common/SegmentHeader";
import "./../../styles.css"
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_SALE } from "../../constants/urls";
import { formatComma } from "../../frameworks/Util";
import * as _ from 'lodash';
import { DISCOUNT_TYPE_OPTIONS } from "../../constants/string";


const ManualDiscount = ({form, price, doAddDiscount}) => {
	const TYPE = {
		BATH: 'bath',
		PERCENTAGE: 'percentage',
	}
	const [type, setType] = React.useState(TYPE.BATH);

	const handleSubmit = async () => {
		await form.validateFields();
		doAddDiscount()
	}

	const convertPercentage = (value) => {
		form.setFieldValue('amount', Number(value) / Number(100) * Number(price))
	}

	return (
		<>
			<Form.Item label='Unit'>
				<Radio.Group value={type} onChange={(e) => { setType(_.get(e, 'target.value', TYPE.BATH)) }}>
					<Radio value={TYPE.BATH}>Bath</Radio>
					<Radio value={TYPE.PERCENTAGE}>%</Radio>
				</Radio.Group>
			</Form.Item>
			{ type == TYPE.BATH ? (
				<Form.Item
					style={{marginBottom: 0}}
					rules={[{ required: true }]}
					name='amount'
					label='Amount'>
					<Input autoComplete='off' type='number' onPressEnter={handleSubmit}/>
				</Form.Item>
			) : (
				<>
					<Form.Item
						style={{marginBottom: 0}}
						label='Percent'>
						<InputNumber
							style={{ width: '100%' }} 
							type='number' min={0} max={100}
							onChange={convertPercentage}
							onPressEnter={handleSubmit}/>
					</Form.Item>
					<Form.Item
						style={{marginBottom: 0}}
						rules={[{ required: true }]}
						name='amount'
						label='Amount'>
						<Input autoComplete='off' disabled={true} type='number' addonAfter='Bath'/>
					</Form.Item>
				</>
			) }
			
		</>
	)
}


const FreeVoucherDiscount = ({form, doAddDiscount}) => {
	const numberInputRef = React.useRef();

	return (
		<>
			<Form.Item
				style={{marginBottom: 0}}
				rules={[{ required: true }]}
				name='note'
				label='Voucher'>
				<Input autoComplete='off' onPressEnter={() => {
					if (numberInputRef.current) {
						numberInputRef.current.focus();
					}
				}}/>
			</Form.Item>
			<Form.Item
				style={{marginBottom: 0}}
				rules={[{ required: true }]}
				name='amount'
				label='Amount'>
				<Input ref={numberInputRef} autoComplete="off" type='number' onPressEnter={doAddDiscount}/>
			</Form.Item>
		</>
	)
}


const DiscountDetailCard = ({data, onDelete}) => {
	const {
		token: { colorBorderSecondary }
	} = theme.useToken();

	return (
		<Card
			className="custom-card-actions-red custom-card-actions-text"
			size="small"
			style={{textAlign: "center", background: colorBorderSecondary}}
			actions={[<p style={{margin: 0, fontSize: 12}} onClick={onDelete}>{"Delete"}</p>]}>
			<p style={{fontWeight: "bold", marginTop: 8, marginBottom: 8}}>{data.title}</p>
			<p style={{margin: 0, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{data.label}</p>
		</Card>
	)
}

const typeOptions = [
	{key: "POINT", value: "POINT", label: "Reward Point"},
	{key: "COUPON", value: "COUPON", label: "Coupon"},
	{key: "PROMOTION", value: "PROMOTION", label: "Promotion"},
	{key: "MANUAL", value: "MANUAL", label: "Cash"},
	{key: "FREE_VOUCHER", value: "FREE_VOUCHER", label: "Free Voucher"},
];

export default function DiscountSegment(props) {
	const [form] = Form.useForm();
	const [typeSelected, setTypeSelected] = React.useState(null);
	const [discounts, setDiscounts] = React.useState([]);
	const [trigger, setTrigger] = React.useState(false);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [promotionOptions, setPromotionOptions] = React.useState([]);	

	const initialRewardPoint = _.get(props, 'member.reward_point', 0)
	const [rewardBalance, setRewardBalance] = React.useState(initialRewardPoint)

	const handleTypeChange = async (value, item) => setTypeSelected(item);

	const checkDiscount = async (item) => {
		if (!props.cart) {
			setErrorMessages("Cart not found!");
			return null;
		}
		if (item.type == "POINT" && item.amount > rewardBalance) {
			setErrorMessages(`Insufficient Balance Reward Point (${formatComma(rewardBalance)})`)
			return null;
		}

		let data = { ...item, cart: props.cart.id }
		try {
			const response = await POST(URL_SALE.DISCOUNT_VALIDATE, data);
			return response.data;
		} catch (error) {
			setErrorMessages(error.errorMessages);
			return null;
		}
	}

	// Add Discount
	const handleAdd = async () => {
		let data = await form.validateFields();
		// TODO: check discount from server

		const result = await checkDiscount(data);
		if (!result) return
		setErrorMessages(null);
		setLoading(true);

		// // Add discount
		setDiscounts([
			...discounts,
			{
				...result,
				title: result.type ? result.type : "Unknown",
				label: result.detail,
			}
		]);
		setLoading(false);
		// Clear form
		form.resetFields();
		setTypeSelected(null);
	}

	// Delete Discount
	const handleDelete = async (index,item) => {
		let temp = discounts
		temp.splice(index, 1);
		setDiscounts(temp);
		setTrigger(!trigger);
	}

	const recalculateDiscount = () => {
		let rewardDiscount = discounts.reduce(
			(prev, curr) => {
				return prev + Number(_.get(curr, 'type', null) == "POINT" ? _.get(curr, 'amount', 0) : 0)
		}, Number(0))
		setRewardBalance(initialRewardPoint - rewardDiscount)
		props.onDiscountChange(discounts);
	}

	// Fetch promotion options
	const fetchPromotions = async () => {
		try {
			const response = await GET(URL_SALE.PROMOTION, { page_size: 999 });
			setPromotionOptions(response.data.results.map(item => {
				return {
					...item,
					key: item.id,
					value: item.id,
					label: item.name,
				}
			}));
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open)
			recalculateDiscount();

	}, [discounts, trigger]);

	React.useEffect(() => {
		if (!props.open) {
			setErrorMessages(null);
			setTypeSelected(null);
			setDiscounts([]);
			form.resetFields();
		} else {
			fetchPromotions();
		}
	}, [props.open]);

	return (
		<SegmentHeader 
				style={props.style}
				header={"ส่วนลด / Discount"}
				content={
					<div>
						{errorMessages && 
							<Alert
								message={errorMessages}
								type="error"
								showIcon
								style={{marginBottom: "8px", textAlign: "left"}}/>
						}
						<Row gutter={[16, 16]}>
							<Col md={8} sm={24} xs={24}>
								<Spin spinning={loading}>
								<Card
									size="small"
									className="custom-card-actions-blue custom-card-actions-text"
									actions={[<p style={{margin: 0, fontSize: 12}} onClick={handleAdd}>{"Add"}</p>]}>
									<Form 
										form={form}
										labelCol={{span: 8}}
										wrapperCol={{span: 16}}>
										<Form.Item 
											style={{marginBottom: 16}}
											rules={[{ required: true }]}
											name={"type"} 
											label={"Type"}>
											<Select options={DISCOUNT_TYPE_OPTIONS} onChange={handleTypeChange}/>
										</Form.Item>

										{typeSelected && (typeSelected.value === "MANUAL") && (
											<ManualDiscount form={form} price={_.get(props, 'cart.price', 0)} doAddDiscount={handleAdd}/>
										)}
										{typeSelected && (typeSelected.value === "POINT") && (
											<Form.Item 
												style={{marginBottom: 0}}
												rules={[{ required: true }]}
												name={"amount"} 
												label={"Amount"}>
												<Input autoComplete="off" type={"number"} onPressEnter={handleAdd}/>
											</Form.Item>
										)}
										{typeSelected && (typeSelected.value === "POINT") && (
											<Form style={{textAlign:'center'}}>{`Remain Point : ${formatComma(rewardBalance)}`}</Form>
										)}

										{typeSelected && typeSelected.value === "COUPON" && (
											<Form.Item 
												style={{marginBottom: 0}}
												rules={[{ required: true }]}
												name={"coupon"} 
												label={"Coupon"}>
												<Input autoComplete="off" onPressEnter={handleAdd}/>
											</Form.Item>
										)}
										{typeSelected && typeSelected.value === "PROMOTION" && (
											<Form.Item 
												style={{marginBottom: 0}}
												rules={[{ required: true }]}
												name={"promotion"} 
												label={"Promotion"}>
												<Select options={promotionOptions} onSelect={handleAdd}/>
											</Form.Item>
										)}
										{typeSelected && typeSelected.value === "FREE_VOUCHER" && (
											<FreeVoucherDiscount form={form} doAddDiscount={handleAdd}/>
										)}
									</Form>
								</Card>
								</Spin>
							</Col>

							{discounts.map((item, index) => {
								return (
									<Col key={index} md={4} sm={24} xs={24}>
										<DiscountDetailCard 
											data={item}
											onDelete={() => handleDelete(index,item)}/>
									</Col>
								)
							})}
						</Row>
					</div>
				}/>
	)
}

DiscountSegment.defaultProps = {
	style: {},
	cart: null,
	open: false,
	onDiscountChange: (list) => {},
}

DiscountSegment.propTypes = {
	style: propTypes.object,
	cart: propTypes.object,
	open: propTypes.bool,
	onDiscountChange: propTypes.func,
}