import { Alert, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, theme, TimePicker } from 'antd'
import React from 'react'
import propTypes from "prop-types";
import Header from '../common/Header'
import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/string';
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_TRAINER } from '../../constants/urls';

export default function PTCourseModal(props) {
  const [form] = Form.useForm();
  const [classOptions, setClassOptions] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const {
    token: { colorSuccess },
  } = theme.useToken();
  
  // Handle date picker
  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };
  
  // Handle create PT Class with course method
  const handleCreate = async () => {
    // Validate data
    let data = await form.validateFields();
    setErrorMessages(null);
    setLoading(true);
    // Prepare data
    data["start_date"] = data.date[0].format(DATE_FORMAT);
    data["end_date"] = data.date[1].format(DATE_FORMAT);
    data["start_time"] = data.time[0].format(TIME_FORMAT);
    data["end_time"] = data.time[1].format(TIME_FORMAT);
    delete data["time"];
    let response = null;
    try {
      response = await POST(URL_TRAINER.TRAINER_COURSE, data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
    finally { setLoading(false) }

    if (response)
      props.onCreated();
  }

  // Fetch class options
  const fetchClass = async () => {
    try {
      const response = await GET(URL_TRAINER.CLASS_TYPE);
      setClassOptions(response.data.results.map(item => (
        { ...item, value: item.id, label: item.name }
      )));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  };

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
      fetchClass();
    } else {
      setErrorMessages(null);
    }
  }, [props.open]);

  return (
    <Modal
      okText={"Create"}
      okButtonProps={{ 
        style: {background: colorSuccess},
        loading: loading,
      }}
      open={props.open}
      onOk={handleCreate}
      onCancel={props.onClose}>

      <Header title={"Course"} />
      {errorMessages && (
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{
            marginBottom: "12px",
            marginTop: "12px",
            textAlign: "left"
          }} />
      )}

      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
        <Form.Item 
          name={"studio"} 
          label={"Studio"} 
          rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            options={props.studioOptions}
            // showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} />
        </Form.Item>

        <Form.Item 
          name={"class_type"} 
          label={"Class"} 
          rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            options={classOptions}
            // showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} />
        </Form.Item>

        <Form.Item 
          name={"trainer"} 
          label={"PT"} 
          rules={[{ required: true }]}>
          <Select
            placeholder={'Select...'}
            options={props.trainerOptions}
            // showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} />
        </Form.Item>

        <Form.Item 
          name={"members_limit"} 
          label={"Limit"} 
          rules={[{ required: true }]}>
          <Input autoComplete="off" placeholder={"0"} type={"number"} />
        </Form.Item>

        <Form.Item 
          name={"weekdays"} 
          label={"Day"} 
          rules={[{ required: true }]}>
          <Checkbox.Group>
            <Row>
              <Col span={6}>
                <Checkbox value={0}>{"Mon"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={1}>{"Tue"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={2}>{"Wed"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={3}>{"Thu"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={4}>{"Fri"}</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={5}>{"Sat"}</Checkbox>
              </Col> 
              <Col span={6}>
                <Checkbox value={6}>{"Sun"}</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item 
          name={"time"} 
          label={"Class Time"} 
          rules={[{ required: true }]}>
          <TimePicker.RangePicker 
            style={{width: '100%'}} 
            format={TIME_FORMAT}
            inputReadOnly={true}
            minuteStep={15}
            disabledTime={() => ({ disabledHours: () => [0, 1, 2, 3, 4] })}/>
        </Form.Item>

        <Form.Item 
          name={"date"} 
          label={"Date"} 
          rules={[{ required: true }]}>
          <DatePicker.RangePicker 
            style={{width: '100%'}}  
            disabledDate={disabledDate} 
            inputReadOnly={true}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

PTCourseModal.defaultProps = {
  open: false,
  studioOptions: [],
  trainerOptions: [],
  onCreated: () => { },
  onClose: () => { },
}

PTCourseModal.propTypes = {
  open: propTypes.bool,
  studioOptions: propTypes.array,
  trainerOptions: propTypes.array,
  onCreated: propTypes.func,
  onClose: propTypes.func,
}