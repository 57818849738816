import { Button, Col, DatePicker, Row, theme } from "antd";
import React from "react";
import LogoutModal from "../../components/user/LogoutModal";
import TSCalendar from "../../components/common/TSCalendar";
import EventModal from "./EventModal";
import { GET } from "../../frameworks/HttpClient";
import { URL_TRAINER } from "../../constants/urls";
import dayjs from 'dayjs';
import { MONTH_FORMAT } from "../../constants/string";
import { LeftOutlined, RightOutlined, PoweroffOutlined } from "@ant-design/icons";


export default function MyCalendarView(props) {
  const [month, setMonth] = React.useState(dayjs());
  const [activatePackage, setActivatePackage] = React.useState(null);
  const [showLogoutConfirm, setShowLogoutConfirm] = React.useState(false)
  const [selected, setSelected] = React.useState(false);
  const [events, setEvents] = React.useState();
  const btnForwardRef = React.useRef(null);
  const btnBackwardRef = React.useRef(null);

  const {
    token: { colorError ,colorSuccess, colorInfoTextHover },
  } = theme.useToken();

  const handleMonthChange = (date) => setMonth(date);

  const fetchEvents = async () => {
    // Prepare Data
    let params = {
      month: month ? month.format(MONTH_FORMAT) : null,
      page_size: 999
    }

    try {
      const response = await GET(URL_TRAINER.MEMBER_CALENDAR, params);
      setActivatePackage(response.data.active_package)
      const booked_classes = response.data.events.map(item => ({
        ...item,
        label: (item && item.type === "pt_class")
          ? `${item.class_type_name} ${item.is_member_checked === true ? "(Joined)" : item.is_member_checked === false && (new Date() >= new Date(`${item.date} , ${item.end_time}`)) ? "(Missed)" : "(Waiting)"}`
          : `${item.trainer_name && item.trainer_name} ${item.is_checked === true ? "(Joined)" : item.is_checked === false ? "(Missed)" : "(Waiting)"}`,
          color:
          (item && item.type === "pt_session" && item.is_checked === true ) ? colorSuccess : 
					(item.type === "pt_session" && item.is_checked === false ) ? "#E87F1C" : 
          (item.type === "pt_session" && !item.is_checked) ? colorInfoTextHover :
          (item.type === "pt_class" && item.is_member_checked === true) ? colorSuccess : 
          (item.type === "pt_class" && item.is_member_checked === false && (new Date() >= new Date(`${item.date} , ${item.end_time}`))) ? "#E87F1C" : colorInfoTextHover
      }))
      const drops = response.data.drops.map(drop => ({
        ...drop,
        label: ('Package Drop'),
        color: colorError
      }))
      setEvents([...booked_classes, ...drops]);
      
    } catch (error) {
      console.log(error.errorMessages);
    }
  }

  const CustomCalendarHeader = ({ value, onChange }) => {
    return (
      <Row
        gutter={2}
        style={{ marginTop: 16, marginBottom: 16 }}>
        <Col span={4}>
          <Button ref={btnBackwardRef} onClick={() => { onChange(month.subtract(1, 'month')) }}><LeftOutlined/></Button>
        </Col>
        <Col xl={6} sm={9} xs={16}>
          <DatePicker
            style={{ width: "100%" }}
            allowClear={false}
            picker={"month"}
            value={value}
            onChange={onChange} />
        </Col>
        <Col span={4}>
          <Button ref={btnForwardRef} onClick={() => { onChange(month.add(1, 'month')) }}><RightOutlined/></Button>
        </Col>
      </Row>
    )
  }

  React.useEffect(() => {
    if (month) {
      fetchEvents();
    }

  }, [month]);

  return (
    <div>
      <TSCalendar
        data={events}
        headerRender={CustomCalendarHeader}
        onChange={handleMonthChange}
        onSwipeLeft={() => btnForwardRef.current.click()}
        onSwipeRight={() => btnBackwardRef.current.click()}
        onDateSelected={(date, events) => {
          if (events && (events.length > 0)) {
            setSelected({
              date: date.format('DD MMMM YYYY'),
              events: events,
            });
          } 
        }}
        activeStart={activatePackage ? activatePackage.start_date : null}
        activeEnd={activatePackage ? activatePackage.end_date : null}
      />

      <Button size='small' icon={<PoweroffOutlined/>} onClick={() => setShowLogoutConfirm(true)}></Button>


      <LogoutModal
        isLiff={(props.liff && props.liff.isLoggedIn()) ? true : false}
        open={showLogoutConfirm}
        onClose={() => setShowLogoutConfirm(false)} />


      <EventModal
        open={(selected ? true : false)}
        onClose={() =>  setSelected(null)}
        data={selected}
      />
    </div>
  )
}

MyCalendarView.defaultProps = {}

MyCalendarView.propTypes = {}