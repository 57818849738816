import React from 'react'
import propTypes from "prop-types";
import { Modal } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { COOKIE_AUTH } from '../../constants/cookies';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import { URL_AUTH } from '../../constants/urls';
import { POST } from '../../frameworks/HttpClient';
import useUserData from '../../hooks/useUserData';

export default function LogoutModal(props) {
	const navigate = useNavigate();
	const user = useUserData();

	const handleLogout = () => {
		try {
			POST(URL_AUTH.SIGN_OUT, { is_liff : props.isLiff ? true : false})
		} catch (error) {}

		finally {
			Cookies.remove(COOKIE_AUTH.TOKEN);
			user.removeUserData();
			let page = PATH.LOGIN;
			if (props.isLiff) 
				page = PATH.LIFF_LANDING;
			
			navigate(page);
			props.onClose();
		}
	}

	return (
		<Modal 
			title={"Logout"}
			okText="Logout"
			centered={true}
			icon={<LogoutOutlined />}
			open={props.open}
			onOk={handleLogout}
			onCancel={props.onClose}>
				
				<p>{"Are you sure you want to logout?"}</p>
		</Modal>
	)
}

LogoutModal.defaultProps = {
	open: false,
	onClose: () => {},
	isLiff: false,
}

LogoutModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	isLiff: propTypes.bool,
}