import React from 'react';
import { Alert, Badge, Button, DatePicker, Form, Popconfirm, Tabs, Tooltip, theme } from 'antd';
import Search from 'antd/es/input/Search';
import Header from '../components/common/Header';
import TSTable from '../components/common/TSTable';
import { URL_REPORT, URL_SALE } from '../constants/urls';
import { DATE_FORMAT } from '../constants/string';
import { currencyFormat } from '../frameworks/Util';
import { GET, POST } from '../frameworks/HttpClient';
import dayjs from 'dayjs';
import { 
  CheckCircleOutlined, 
  DeleteOutlined, 
  MinusCircleOutlined, 
  PrinterOutlined, 
  QuestionCircleOutlined ,
  EditOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import "../css/App.css";
import ReceiptActionModal from '../components/payment/ReceiptActionModal';
import EditCartDetail from '../components/payment/EditCartDetail';
import useUserData from '../hooks/useUserData';
import { USER_GROUP } from '../constants/string';
import { PATH } from '../routes/CustomRoutes';
import { useMenuBadgeContext } from '../hooks/MenuBadgeProvider';
import _ from 'lodash';


const ACTIONS = {
  VERIFY: 'verify',
  REJECT: 'reject',
  UPDATE: 'update_payment',
  CANCEL: 'cancel',
}


export default function PaymentListView() {
  const PAYMENT_STATUS = {
    ALL: 'ALL',
    REJECTED: 'REJECTED'
  }
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [printTarget, setPrintTarget] = React.useState(null);
  const [cancelTarget, setCancelTarget] = React.useState(null);
  const [rejectTarget, setRejectTarget] = React.useState(null);
  const [editTarget, setEditTarget] = React.useState(null);
  const [dateFilter, setDateFilter] = React.useState('');
  const [tabStatus, setTabStatus] = React.useState(PAYMENT_STATUS.ALL);
  const tableRef = React.useRef(null);
  const user = useUserData();
  const { menuBadges, fetchMenuBadges } = useMenuBadgeContext();
  const [columnRender, setColumnRender] = React.useState([])
  const tabOptions = [
    { label: <span><FileTextOutlined/> All Payment</span>, key: PAYMENT_STATUS.ALL },
    { label: 
        <Badge offset={[10, -2]} color='#faad14' count={menuBadges.rejected_receipt_badge_count}>
          <span><ExclamationCircleOutlined/> Rejected Payment</span>
        </Badge>,
        key: PAYMENT_STATUS.REJECTED 
    },
    // { label: <span><CloseCircleOutlined/> Canceled Payment</span>, key: 'CANCELED' },
  ]

  const isAccountant = user.user.groups.includes(USER_GROUP.ACCOUNTANT)

  const {
    token: { colorPrimary, colorError, colorSuccess, colorFill, colorInfoTextHover}
  } = theme.useToken();

  const columns = [
    {
      title: 'Date',
      dataIndex: 'paid_datetime',
      sorter: true,
      render: (value) => (<div>{value ? dayjs(value).format(DATE_FORMAT) : "-"}</div>)
    },
    {
      title: 'Bill No.',
      dataIndex: 'receipt_no',
      sorter: true,
    },
    {
      title: 'Member ID',
      dataIndex: 'member_code',
    },
    {
      title: 'Name',
      dataIndex: 'member_full_name',
      render: (value, original) => 
        (<a href={`${PATH.MEMBER_DETAIL}${PATH.MEMBER_INFO}/${original.member}`} target="_blank">{value}</a>)
    },
    {
      title: 'Tax Name',
      dataIndex: 'invoice',
      render: (value) => (<div>{value ? value.invoice_name : "-"}</div>)
    },
    {
      title: 'Discount',
      dataIndex: 'total_discount',
      render: (value) => (<div style={{textAlign: "right"}}>{value ? currencyFormat(value) : "0.00"}</div>),
      permission: tabStatus != PAYMENT_STATUS.REJECTED
    },
    {
      title: 'Total Price',
      dataIndex: 'price',
      render: (value) => (<div style={{textAlign: "right"}}>{value ? currencyFormat(value) : "0.00"}</div>),
      permission: tabStatus != PAYMENT_STATUS.REJECTED
    },
    {
      title: 'Net Price',
      dataIndex: 'net_price',
      render: (value) => (<div style={{textAlign: "right"}}>{value ? currencyFormat(value) : "0.00"}</div>)
    },
    {
      title: 'Sale',
      dataIndex: 'approver_full_name',
    },
    {
      title: 'Action',
      dataIndex: 'is_verified',
      render: (value, original) => (  
        <div style={{textAlign: "center"}}>
          {/* Cancel receipt */}
          {!original.is_canceled && 
          ((original.is_verified === null) 
          || (original.is_verified === false && isOwner(original)) 
          || isAccountant) && (
            <Button 
              type={"primary"} 
              style={{marginLeft: 4, marginRight: 4, background: colorError}} 
              icon={<DeleteOutlined />}
              onClick={() => setCancelTarget(original)} />
          )}

          {/* Print receipt */}
          <Button 
            type={"primary"} 
            loading={(printTarget && (printTarget.id === original.id)) ? true : false}
            style={{marginLeft: 4, marginRight: 4, background: colorInfoTextHover}} 
            icon={<PrinterOutlined />} 
            onClick={() => setPrintTarget(original)} />

          {/* Accountant can change status of receipt */}
          {!original.is_canceled && original.is_verified !== false && isAccountant && (
            <Popconfirm
              title={"To change status of receipt."}
              placement={"topRight"}
              okText={"Approve"}
              okButtonProps={{
                type: "primary",
                style: {background: colorSuccess},
                icon: <CheckCircleOutlined />
              }}
              cancelText={"Reject"}
              cancelButtonProps={{
                type: "primary",
                style: {background: colorError},
                icon: <MinusCircleOutlined />
              }}
              onConfirm={() => handleAction(original, ACTIONS.VERIFY)}
              onCancel={() => setRejectTarget(original)}>
              <Button 
                type={"primary"} 
                style={{marginLeft: 4, marginRight: 4, background: (value === null) ? colorFill : (value) ? colorSuccess : colorError}} 
                icon={(value === null) 
                ? <QuestionCircleOutlined /> 
                : (value) 
                ? <CheckCircleOutlined /> 
                : <MinusCircleOutlined />} 
              />
            </Popconfirm>
          )}

          {/** Accountant shows rejected button after reject bill */}
          {!original.is_canceled && original.is_verified === false && isAccountant &&
            <Tooltip title="Rejected">
              <Button 
                type={"primary"} 
                style={{marginLeft: 4, marginRight: 4, background: colorError}} 
                icon={<MinusCircleOutlined />} 
              />
            </Tooltip>
          }

          {/** Reception & Sale Edit the rejected bill */}
          { !original.is_canceled && original.is_verified === false && !isAccountant && isOwner(original) && (
            <Popconfirm
              title={"Edit or Submit Form."}
              placement={"topRight"}
              okText={"Finish"}
              okButtonProps={{
                type: "primary",
                style: {background: colorPrimary},
                icon: <CheckCircleOutlined />
              }}
              cancelText={"Edit"}
              cancelButtonProps={{
                type: "primary",
                style: {background: colorSuccess },
                icon: <EditOutlined />
              }}
              onConfirm={() => handleAction(original, ACTIONS.UPDATE)}
              onCancel={() => setEditTarget(original)}>
              <Button 
                type={"primary"} 
                style={{marginLeft: 4, marginRight: 4, background: colorFill}} 
                icon={<QuestionCircleOutlined />} 
              />
            </Popconfirm>
          )}

          {/** owner of bill can edit when on pending approval  */}
          {!original.is_canceled && original.is_verified == null && !isAccountant && (
            <Tooltip title="Edit">
              <Button
                type={"primary"}
                style={{ marginLeft: 4, marginRight: 4, background: colorSuccess }}
                icon={<EditOutlined />}
                onClick={() => setEditTarget(original)}
              />
            </Tooltip>
          )}
        </div>
      )
    }
  ]

  const handleSearchChange = (e) => setSearchText(e.target.value);

  // Actions
  const handleAction = async (item, action) => {
    if (!item) {
			setErrorMessages("Cart not found!");
			return;
		}
    
		setErrorMessages(null);
		try {
      await POST(`${URL_SALE.CART}${item.id}/${action}/`);
      refreshDataWithoutResetPage();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  }
  
  const isOwner = (original) => {
    return _.get(user, 'user.id', '') === _.get(original, 'approved_by', '-')
  }

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const refreshDataWithoutResetPage = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
      fetchMenuBadges();
    }
  }

  // Print receipt
  const printReceipt = async (item) => {
    if (!item) {
      setErrorMessages("Receipt not found!");
      return;
    }
    
    // Prepare data
    setErrorMessages(null);
    try {
      const params = {cart: item.id};
      // Goto export
      const response = await GET(`${URL_REPORT.RECEIPT_REPORT}pdf/`, params, false, true) // Download as blob
      window.open(URL.createObjectURL(response.data));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
    finally { setPrintTarget(null) }
  }

  const updateColumns = () => {
    setColumnRender(columns.filter(c => c.permission != false))
  }

  React.useEffect(() => {
    if (printTarget)
      printReceipt(printTarget);

  }, [printTarget]);

  React.useEffect(() => {
    fetchMenuBadges();
  }, [])

  React.useEffect(() => {
    refreshData();
  }, [dateFilter, tabStatus])

  React.useEffect(() => {
    updateColumns();
  }, [tabStatus])

  return (
    <div>
      <Header title={"Payments"} />
      <Tabs
        items={tabOptions}
        onChange={(key) => { setTabStatus(key) }}/>

      <Form style={{ marginTop: "1rem", textAlign: "left" }} layout="inline">
        <Form.Item 
          label="Member ID"
          name="member_id">
          <Search
            style={{ width: '300px' }}
            placeholder="Search member ID..." 
            onSearch={refreshData} 
            onChange={handleSearchChange}
            onPressEnter={refreshData}/>
        </Form.Item>
        <Form.Item
          label="Date"
          name="date">
          <DatePicker
            style={{ width: '150px', textAlign: 'center' }}
            format='YYYY-MM-DD'
            onChange={(_, data) => setDateFilter(data)} 
            inputReadOnly={true}
            allowClear={true}
            value={dateFilter}/>
        </Form.Item>
      </Form>

      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }

      <TSTable 
        ref={tableRef}
        columns={columnRender}
        url={`${URL_SALE.CART}?is_paid=true&member_code=${searchText}&paid_date=${dateFilter}&status=${tabStatus}`}
        rowKey={"id"}
        size={"small"}
        pageSize={10}
        onRow={(record, rowIndex) => ({
          // onClick: _ => navigate(`${PATH.MEMBER_DETAIL}${PATH.MEMBER_INFO}/${record.id}`)
        })}
        rowClassName={(record, index) => {
          if (record.is_canceled) {
            return "table-row-red"
          }
          else if (record.is_verified === false) {
            return "table-row-yellow"
          }
        }}
        />

        {/* Cancel Receipt */}
        <ReceiptActionModal 
          title={"Cancel Receipt!"}
          open={cancelTarget ? true : false}
          target={cancelTarget}
          action={'cancel'}
          requireNote={true}
          url={`${URL_SALE.CART}${cancelTarget ? cancelTarget.id : ""}/${ACTIONS.CANCEL}/`}
          onClose={() => setCancelTarget(null)}
          onSuccess={() => {
            setCancelTarget(null);
            refreshDataWithoutResetPage();
          }}/>
        
        {/* Reject Receipt */}
        <ReceiptActionModal 
          title={"Reject Receipt!"}
          open={rejectTarget ? true : false}
          requireNote={true}
          target={rejectTarget}
          action={'reject'}
          url={`${URL_SALE.CART}${rejectTarget ? rejectTarget.id : ""}/${ACTIONS.REJECT}/`}
          onClose={() => setRejectTarget(null)}
          onSuccess={() => {
            setRejectTarget(null);
            refreshDataWithoutResetPage();
          }}/>

        {/* Edit Tax Invoice */}
        <EditCartDetail
          cart={editTarget}
          open={editTarget ? true : false}
          onClose={() => setEditTarget(null)}
          onSuccess={() => {
            setEditTarget(null);
            refreshDataWithoutResetPage();
          }}
        />
    </div>
	)
}