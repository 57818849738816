import React from "react";
import {
  CalendarFilled,
  CalendarOutlined,
  ProfileFilled,
  ProfileOutlined,
  EditFilled,
  EditOutlined,
  GiftFilled,
  GiftOutlined,
} from "@ant-design/icons";
import { Badge, TabBar } from 'antd-mobile'
import { Alert, Layout, Menu, theme } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLiff } from 'react-liff';
import { PATH } from "../../routes/CustomRoutes";
import MyProfileView from "../../views/member/MyProfileView";
import MyPackagesView from "../../views/member/MyPackagesView";
import MyBookingsView from "../../views/member/MyBookingsView";
import MyCalendarView from "../../views/member/MyCalendarView";
import { GET } from "../../frameworks/HttpClient";
import { URL_MEMBER, URL_TRAINER } from "../../constants/urls";
import useUserData from "../../hooks/useUserData";

export default function MemberMenuScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { error, isLoggedIn, isReady, liff } = useLiff();
  const { user } = useUserData();
  const [my, setMy] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [current, setCurrent] = React.useState('profile');
  const [member, setMember] = React.useState(null);
  // const [booking, setBooking] = React.useState(null);

  const {
    token: { colorPrimary }
  } = theme.useToken();

  const onMenuSelected = (key) => {
    setCurrent(key)
    navigate(`${PATH.MEMBER}/${key}`)
    console.log(key)
  };

  const tabs = [
    {
      key: 'profile',
      icon: active => active ? <EditFilled/> : <EditOutlined/>,
      title: 'Profile',
    },
    {
      key: 'member-packages',
      icon: active => active ? <GiftFilled/> : <GiftOutlined />,
      title: 'Packages',
    },
    {
      key: 'member-booking',
      icon: active => active ? <ProfileFilled/> : <ProfileOutlined />,
      title: 'Class Booking',
    },
    {
      key: 'member-calendar',
      icon: active => active ? <CalendarFilled/> : <CalendarOutlined />,
      title: 'Calendar',
    },
  ]

  const renderContent = () => {
    const menu = `/${params.menu}`
    switch (menu) {
      case PATH.PROFILE: return (<MyProfileView member={member} liff={liff} />)
      case PATH.MEMBER_PACKAGES: return (<MyPackagesView member={member} />)
      case PATH.MEMBER_BOOKING: return (<MyBookingsView member={member} />)
      case PATH.MEMBER_CALENDAR: return (<MyCalendarView liff={liff} />)
      default: return null;
    }
  }

  const fetchMember = async (id) => {
    if (!id) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.MEMBER}${id}`);
      setMember(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // Block update every time
  React.useEffect(() => {
    if (user) {
      setMy(user);
    }
  }, [user.member]);

  React.useEffect(() => {
    if (my) {
      fetchMember(my.member);
    }
  }, [my]);

  React.useEffect(() => {
    const menu = params.menu
    if (menu) {
      setCurrent(menu);
    }
    else {
      // Set default page
      setCurrent("profile")
      navigate(`${PATH.MEMBER}${PATH.PROFILE}`)
    }
  }, [params])

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout>
        <Content style={{ margin: 24}}>
          {errorMessages &&
            <Alert
              message={errorMessages}
              type="error"
              showIcon
              style={{ marginBottom: "12px", textAlign: "left" }}
            />
          }
          {renderContent()}
        </Content>
      </Layout>
      <Layout style={{flex: 0}}>
        <TabBar 
          style={{marginBottom: 8}} 
          activeKey={current} 
          onChange={onMenuSelected}>
          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </Layout>
    </Layout>
  )
}