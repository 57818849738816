import React from 'react'
import propTypes from 'prop-types';
import { Row, Form, Input, Checkbox, Card, Col, Modal, Alert, Spin, Typography, Select, InputNumber, Divider } from 'antd';
import { GET, PATCH, POST } from '../../frameworks/HttpClient';
import TSTable from '../common/TSTable';
import Header from '../../components/common/Header';
import { URL_SALE } from '../../constants/urls';
import { currencyFormat } from '../../frameworks/Util';
import * as _ from 'lodash';
import { DISCOUNT_TYPE_OPTIONS, PAYMENT_TYPE, getPaymentOptions } from '../../constants/string';


const TaxInvoiceForm = ({ form, style }) => {
  const { TextArea } = Input;

  return (
    <Form
      style={style}
      form={form}
      layout='vertical'
      autoComplete='off'>
      <Form.Item
        hidden={true}
        name='id'
        rules={[{ required: true }]}>
        <Input autoComplete='off' />
      </Form.Item>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            name='is_head_office'
            valuePropName='checked'>
            <Checkbox>Head Office</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6} style={{ align: 'right' }}>
          <Form.Item
            name='is_branch_office'
            valuePropName='checked'>
            <Checkbox>Branch</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='branch_name'>
            <Input autoComplete='off' placeholder='Branch Name...' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='invoice_name'
            label='Name'
            rules={[{ required: true }]}>
            <Input autoComplete='off' placeholder='Name...' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='invoice_id'
            label='Tax ID'>
            <Input autoComplete='off' placeholder='Tax ID...' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name='invoice_address'
            label='Address'>
            <TextArea placeholder='Address....' />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}


const PaymentMethodForm = ({ form, style }) => {
  return (
    <Form
      style={style}
      form={form}
      layout='horizontal'
      autoComplete='off'>
      { _.get(form.getFieldValue(), 'payments', []).map((payment, index) => (
        <Row gutter={16}>
          <Form.Item
            hidden={true}
            name={['payments', index, 'id']}
            rules={[{ required: true }]}>
            <Input autoComplete='off' />
          </Form.Item>
          <Col span={12}>
            <Form.Item
              name={['payments', index, 'type']}
              label='Type'>
              <Select
                disabled={_.get(payment, 'type', null) == PAYMENT_TYPE.REWARD_POINT || _.get(payment, 'type', null) == PAYMENT_TYPE.AR}
                options={getPaymentOptions()}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['payments', index, 'amount']}
              label='Amount'>
              <InputNumber disabled decimalSeparator=',' addonAfter='฿'/>
            </Form.Item>
          </Col>
        </Row>
      ))}
    </Form>
  )
}

const DiscountForm = ({ discounts }) => {
  return discounts.length > 0 ? (
    <Card style={{ marginTop: '1rem' }}>
      <Typography.Title style={{ marginTop: 0 }} level={5} type='secondary'>Discount</Typography.Title>
      { discounts.map(discount => (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label='Type'>
            <Select
              disabled
              options={DISCOUNT_TYPE_OPTIONS}
              value={_.get(discount, 'type', '')}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Amount'>
              <InputNumber disabled decimalSeparator=',' addonAfter='฿' value={_.get(discount, 'amount', '')}/>
            </Form.Item>
          </Col>
        </Row>
      )) }
    </Card>
  ) : null
}


export default function EditCartDetail(props) {
  const [taxInvoiceForm] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const [logMessages, setLogMessages] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [cart, setCart] = React.useState(null);

  const handleEdit = async () => {
    const taxInVoicedata = await taxInvoiceForm.validateFields();
    const paymentData = await paymentForm.validateFields();
    try {
      setLoading(true)
      await PATCH(`${URL_SALE.CART_TAX_INVOICE}${taxInVoicedata.id}/`, taxInVoicedata)
      await POST(URL_SALE.MULTIPLE_PAYMENT_UPDATE, paymentData)
      props.onSuccess()
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  const fetchUpdateLog = async () => {
    try {
      const response = await GET(`${URL_SALE.CART_UPDATE_LOG_LIST}${props.cart.id}/`);
      const logList = response.data.results
      if(logList.length > 0) {
        setLogMessages(logList[0].note)  
      } else {
        setLogMessages(null);
      }
    } catch(error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (props.open && props.cart) {
      setErrorMessages(null)
      setCart(props.cart)
      taxInvoiceForm.setFieldsValue(_.get(props, 'cart.invoice', {}))
      paymentForm.setFieldsValue({ payments: _.get(props, 'cart.payments', []) })
      fetchUpdateLog()
    }
  }, [props.open, props.cart])

  return (
    <Modal
      width={800}
      okText='Edit'
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{loading: loading}}
      open={props.open}
      onOk={handleEdit}
      onCancel={props.onClose}>
      <Header title='Edit Tax Invoice' />
      <Spin spinning={loading}>
        {errorMessages &&
          <Alert
            message={errorMessages}
            type='error'
            showIcon
            style={{ marginTop: 8, textAlign: 'left' }}
          />
        }
        {logMessages &&
          <Alert
            message={logMessages}
            type='info'
            showIcon
            style={{ marginTop: 8, textAlign: 'left' }}
          />
        }
        {cart && 
          <Alert 
            style={{ marginTop: 16, textAlign: 'left' }}
            message={`Cashier: ${cart.approver_full_name}`} 
            type='info' />
        }
        <Card style={{ marginTop: '1rem' }}>
          <TaxInvoiceForm form={taxInvoiceForm} />
        </Card>

        <TSTable
          style={{ marginTop: '1rem' }}
          columns={[
            { title: 'Name', dataIndex: 'item_detail' },
            { title: 'Price', dataIndex: 'price',
              render: (value) => (<div style={{textAlign: 'right'}}>{value ? currencyFormat(value) : '0.00'}</div>)
            },
          ]}
          data={props.cart ? props.cart.items : []}
          hidePagination={true}/>

        <Card style={{ marginTop: '1rem' }}>
          <Typography.Title style={{ marginTop: 0 }} level={5} type='secondary'>Payment</Typography.Title>
          <PaymentMethodForm form={paymentForm} />
        </Card>
        
        <DiscountForm discounts={_.get(props, 'cart.discounts', [])}/>
      </Spin>
    </Modal>
  )
}


EditCartDetail.defaultProps = {
  cart: null,
  open: false,
  onClose: () => null,
  onSuccess: () => null,
}

EditCartDetail.propTypes = {
  cart: propTypes.object, // cart object 
  open: propTypes.bool,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
}
