import React from 'react'
import propTypes from "prop-types";
import { Button, Image, message, List, theme, Modal, Alert, Typography, Tag } from 'antd';
import logo from "../../assets/ahc_logo.png";
import {
	CheckCircleOutlined, 
	ExclamationCircleFilled, 
	CameraFilled, 
	GiftOutlined, 
	EditOutlined, 
	PrinterOutlined, 
	QrcodeOutlined,
	PlusSquareOutlined,
	IdcardOutlined,
	CloseCircleOutlined,
} from '@ant-design/icons';
import UpdateUserGroupModal from './relation/UpdateUserGroupModal';
import UpdateReferralModal from './relation/UpdateReferralModal';
import PrintModal from './PrintModal';
import QRCodeModal from './QRCodeModal';
import CorporateModal from './CorporateModal';
import Segment from '../common/Segment';
import { URL_USERS,URL_MEMBER } from '../../constants/urls';
import { POST } from '../../frameworks/HttpClient';
import AvatarEditorModal from '../common/AvatarEditorModal';
import ConsentOldMemberModal from './ConsentOldMemberModal';
import { DATE_FORMAT, USER_GROUP } from '../../constants/string';
import useUserData from '../../hooks/useUserData';
import GateTagModal from './GateTagModal';
import * as _ from 'lodash';
import moment from 'moment';
import { formatComma } from '../../frameworks/Util';
import BlacklistModal from './menu/BlacklistModal';


export default function InformationCardView(props) {
	// Handle consent modal
	const uploadRef = React.useRef(null);
	const [uploadFile, setUploadFile] = React.useState(null);
	const [errorMessage, setErrorMessages] =React.useState(null);
	const [openConsentModal, setOpenConsentModal] = React.useState(false);
	const [openEditSaleModal, setOpenEditSaleModal] = React.useState(false);
	const [openEditPTModal, setOpenEditPTModal] = React.useState(false);
	const [openReferralModal, setOpenReferralModal] = React.useState(false);
	const [openPrintModal, setOpenPrintModal] = React.useState(false);
	const [openQRCodeModal, setOpenQRCodeModal] = React.useState(false);
	const [openCorporateModal, setOpenCorporateModal] = React.useState(false);
	const [openGateTagModal, setOpenGateTagModal] = React.useState(false);
	const [targetCorporate, setTargetCorporate] = React.useState(null);
	const [openBlacklistModal, setOpenBlacklistModal] = React.useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const user = useUserData();
	const isSaleManager = user.user.groups.includes(USER_GROUP.SALE_MANAGER)
	const { confirm } = Modal;

	const {
		token: { colorPrimary, colorWarning, colorInfoTextHover, colorSuccess},
	} = theme.useToken();
	
	const MessageSuccess = () => {
		const key = 'updatable';
		messageApi.open({
		  key,
		  type: 'loading',
		  content: 'Loading...',
		});
		setTimeout(() => {
		  messageApi.open({
			key,
			type: 'success',
			content: 'Success!',
			duration: 2,
		  });
		}, 500);
	  };

	const handleCorporate = (item) => setTargetCorporate(item);

	const handleResent = async () => {
		setErrorMessages(null);
		try {
			await POST(`${URL_MEMBER.MEMBER}${props.member.id}/resent-consent/`)
			MessageSuccess()
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

	// Show confirm modal
	const showConfirm = () => {
		confirm({
			title: 'Resend Email',
			icon: <ExclamationCircleFilled />,
			content: 'Are you sure you want to resend email?',
			okButtonProps: { style: {background: colorPrimary} },
			okText: "Yes",
			cancelButtonProps: { style: {background: null} },
			cancelText: "No",
			onOk() {
				handleResent();
			},
			onCancel() {},
		});
	};

	React.useEffect(() => {
		setOpenCorporateModal(targetCorporate ? true : false);
	}, [targetCorporate]);

	return (
		<div style={{textAlign: 'center', width: '100%'}}>
			{contextHolder}

			{ _.get(props, 'member.is_blacklisted', false) && 
				<Tag
					bordered={false}
					style={{ 
						padding: '1rem 2rem 1rem 2rem', 
						fontSize: '2rem',
						borderRadius: '1rem',
						width: '100%',
						textAlign: 'center',
						marginBottom: '1rem',
					}}
					color='#f50'
				>
					Blacklisted
				</Tag>
			}

			{(props.member && !props.member.is_consent_agreed) ? (
				<Button
					onClick={() => setOpenConsentModal(true)}
					color='black'
					style={{ width: '100%', marginBottom: 16}}>
						<ExclamationCircleFilled style={{color: colorWarning}}/>
						CONSENT
				</Button>
			) : <Button
			onClick={showConfirm}
			color='black'
			style={{ width: '100%', marginBottom: 16}}>
						<CheckCircleOutlined style={{color: colorSuccess}}/>
						CONSENT
				</Button>}
			{/* Image */}
			<Segment 
				style={{margin: 0}}
				detail={
					<div >
						<Image
							style={{borderRadius: 8, height: 180, width: "auto"}}
							src={(props.member && props.member.profile_image) ? props.member.profile_image : logo}/>

						<div align='center'>
							<input id="imgSelect"
								type="file"
								accept="image/*"
								ref={uploadRef}
								style={{ display: 'none' }}
								onChange={async (evt) => {
									if (evt.target.files.length > 0) {
										setUploadFile(URL.createObjectURL(evt.target.files[0]))
										evt.target.value = null  // clear reference to enable select same file
									}
								}}
							/>

							<AvatarEditorModal
								open={uploadFile ? true : false}
								image={uploadFile}
								member={props.member ? props.member.id : null}
								onUpdated={() => {
									setUploadFile(null)
									props.onUpdated();
								}}
								onClose={() => setUploadFile(null)}/>

							<Button 
								style={{
									color: 'rgba(0, 0, 0, 0.25)',
									marginTop: 8,
									fontWeight: 'bold'}}
								onClick={() => { uploadRef.current.click() }}>
										<CameraFilled />Edit
							</Button>
						</div>
					</div>
				}/>

			<p 
				style={{color: colorPrimary, fontSize: 18, fontWeight: 'bold'}}>
					{props.member ? props.member.full_name : "Unknown"}
			</p>
			<p 
				style={{color: colorPrimary, fontWeight: 'bold'}}>
					<div>
						<GiftOutlined style={{ paddingRight: 8, }} />
						{`Points: ${props.member ? formatComma(props.member.reward_point) : 0}`}
					</div>
					<div>
						{_.get(props, 'member.expiring_point', 0) > 0 ? (
							<span>(Expiring: {formatComma(_.get(props, 'member.expiring_point', 0))} on {moment(_.get(props, 'member.expiring_point_date', '0000-00-00'), DATE_FORMAT).format('DD/MM/YYYY')})</span>
						) : (
							_.get(props, 'member.reward_point', 0) > 0 && <span>(Expiring on {_.get(props, 'member.next_expiring_point_date', '-')})</span>
						)}
					</div>
			</p>

			<Button
				style={{
					backgroundColor: 'rgba(0, 0, 0, 0.06)',
					color: colorPrimary,
					fontWeight: 'bold',
					width: '100%'
				}}
				disabled={!isSaleManager}
				onClick={() => {setOpenEditSaleModal(true)}}>
					<EditOutlined />
					{`Sale: ${props.member ? props.member.sale_full_name : "Unknown"}`}
			</Button>
				
			<Button
				style={{
					marginTop: 8,
					backgroundColor: 'rgba(0, 0, 0, 0.06)',
					color: colorPrimary,
					fontWeight: 'bold',
					width: '100%'
				}}
				disabled={!isSaleManager}
				onClick={() => {setOpenEditPTModal(true)}}>
					<EditOutlined />
					{`PT: ${props.member ? props.member.trainer_name : "Unknown"}`}
			</Button>

			<Button
				style={{
					marginTop: 8,
					backgroundColor: 'rgba(0, 0, 0, 0.06)',
					color: colorPrimary,
					fontWeight: 'bold',
					width: '100%'
				}}
				onClick={() => {setOpenReferralModal(true)}}>
					<EditOutlined />
					{`Referral: ${(props.member && props.member.referral_full_name) ? props.member.referral_full_name : "-"}`}
			</Button>

			<Button
				type={"primary"}
				style={{
					marginTop: 8,
					fontWeight: 'bold',
					width: '100%'
				}}
				onClick={() => {setOpenPrintModal(true)}}>
					<PrinterOutlined />PRINT
			</Button>

			<Button
				type={"primary"}
				style={{
					marginTop: 8,
					fontWeight: 'bold',
					width: '100%'
				}}
				onClick={() => {setOpenGateTagModal(true)}}>
					<IdcardOutlined />ADD GATE TAGS
			</Button>

			<Button
				type={"primary"}
				style={{
					marginTop: 8,
					fontWeight: 'bold',
					width: '100%'
				}}
				onClick={() => { setOpenQRCodeModal(true) }}>
				<QrcodeOutlined />LINE QR Code
			</Button>

			<Button
				type='primary'
				style={{
					marginTop: 8,
					fontWeight: 'bold',
					width: '100%'
				}}
				onClick={() => { setOpenBlacklistModal(true) }}>
				<CloseCircleOutlined /> Blacklist
			</Button>

			<Button
				type={"primary"}
				style={{
						backgroundColor: colorInfoTextHover,
						marginTop: 8,
						fontWeight: 'bold',
						width: '100%',
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
				}}
				onClick={() => {setOpenCorporateModal(true)}}> 
					<PlusSquareOutlined />
					Corperate
			</Button>

			<Segment 
				style={{
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				}}
				detail={
					<List 
						size={"small"}
						dataSource={props.member ? props.member.corporates : []}
						renderItem={(item) => <List.Item onClick={() => handleCorporate(item)}>{item.card_type_name}</List.Item>}/>
				} />

			<ConsentOldMemberModal
				open={openConsentModal}
				member={props.member}
				onUpdated={() => {
					setOpenConsentModal(false);
					props.onUpdated();
				}}
				onClose={() => setOpenConsentModal(false)}/>

			{/* Update Sale */}
			<UpdateUserGroupModal
				open={openEditSaleModal}
				title={"Sale"}
				label={"Full Name (Sale)"}
				userType={"sale"}
				fetchUrl={URL_USERS.SALES}
				member={props.member ? props.member.id : null}
				data={props.member ? props.member.sale : null}
				onUpdated={() => {
					setOpenEditSaleModal(false);
					props.onUpdated();
				}}
				onClose={() => setOpenEditSaleModal(false)}/>

			{/* Update PT */}
			<UpdateUserGroupModal
				open={openEditPTModal}
				title={"PT"}
				label={"Full Name (PT)"}
				userType={"pt"}
				fetchUrl={URL_USERS.TRAINERS}
				member={props.member ? props.member.id : null}
				data={props.member ? props.member.pt : null}
				onUpdated={() => {
					setOpenEditPTModal(false);
					props.onUpdated();
				}}
				onClose={() => setOpenEditPTModal(false)}/>

			{/* Update Referral */}
			<UpdateReferralModal 
				open={openReferralModal}
				fetchUrl={""}
				member={props.member ? props.member.id : null}
				data={(props.member && props.member.referral) 
					? {
						key: props.member.referral,
						value: props.member.referral,
						label: props.member.referral_full_name,
					} 
					: null}
				onUpdated={() => {
					setOpenReferralModal(false);
					props.onUpdated();
				}}
				onClose={() => setOpenReferralModal(false)}/>

			<PrintModal
				open={openPrintModal}
				memberCode={props.member ? props.member.code : null}
				onClose={() => setOpenPrintModal(false)}/>

			<QRCodeModal 
				open={openQRCodeModal}
				memberId={props.member ? props.member.id : null}
				onClose={() => setOpenQRCodeModal(false)} />

			<CorporateModal
				open={openCorporateModal}
				member={props.member ? props.member.id : null}
				target={targetCorporate}
				onUpdated={() => {
					setOpenCorporateModal(false);
					props.onUpdated();
				}}
				onClose={() => {
					if (targetCorporate) {
						setTargetCorporate(null) 
					} else {
						setOpenCorporateModal(false)
					}
					props.onUpdated();
				}}/>

			<GateTagModal
				open={openGateTagModal}
				member={props.member ? props.member.id : null}
				defaultValue={props.member ? props.member.rfid_tag : null}
				onClose={() => {
					setOpenGateTagModal(false)
					props.onUpdated();
				}}
			/>

			<BlacklistModal
				open={openBlacklistModal}
				member={props.member ? props.member.id : null}
				onClose={() => {
					setOpenBlacklistModal(false);
					props.onUpdated();
				}}/>
		</div>
	)
}

InformationCardView.defaultProps = {
	member: null,
	onUpdated: () => {}
}
InformationCardView.propTypes = {
	member: propTypes.object,
	onUpdated: propTypes.func
}