import React from 'react';
import { Card, Button, Typography, List, Row , Col } from 'antd';
import * as _ from 'lodash';
import { formatComma } from '../../../frameworks/Util';
import { t } from 'i18next';
import { PATH } from "../../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";
import { URL_PACKAGE, URL_SALE } from '../../../constants/urls';
import { GET, POST } from '../../../frameworks/HttpClient';
import useUserData from '../../../hooks/useUserData';
import "./PackagesVariantsListKiosk.css"

export default function PackagesVariantsListKiosk(props) {
  const { packageData } = props;
  const [ errorMessages, setErrorMessages] = React.useState(null)
  const { user } = useUserData();
  const { Text } = Typography;
  const navigate = useNavigate();

  const buttonStyle = {
    width: 160,
    height: 50,
    top: 12,
    fontSize: 20,
    fontWeight : 600,
  }
  const buttonStyleNew = {
    width: 240,
    height: 80,
    top: 0,
    fontSize: 24,
    fontWeight : 600,
  }

  const cardRedLineStyle = {
    borderRadius: '10px',
    textAlign: 'center',
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column', 
    textAlign: 'center',
    width: '100%'
  }

  // const VARIANT_TO_TEXT = {
  //   GYM: t("varaints.gym"),
  //   POOL: t("varaints.pool"),
  //   ALL: t("varaints.all"),
  // }
  
  
    const VARIANT_TO_TEXT = {
    GYM: "Gym only",
    POOL: "Pool only",
    ALL: "Gym + Pool",
  }
  
  const addToCart = async (data, pkg) => {
		if (!user.member || !data) {
			setErrorMessages("Data not found!");
			return;
		}
		let myData = {
			member: user.member,
			item: pkg.package_variant ? `${URL_PACKAGE.ALL_CLUB_PACKAGE}${pkg.id}/` : `${URL_PACKAGE.PACKAGE_VARIANT}${data.id}/`,
      clear_on_add: true
		}

    let type = pkg.package_variant ? 'all-club' : 'main';
		
		setErrorMessages(null);
		try {
			await POST(URL_SALE.CART_ITEM, myData);
			navigate(`${PATH.KIOSK_CONTRACT_SIGN}/${pkg.id}/${user.member}/${type}`)
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
	}

  return(
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
    {packageData.variants ?
      <List
        dataSource={(_.get(packageData, 'variants', []).filter(e => e.show_kiosk !== false))}
        itemLayout={'vertical'}
        grid={{ gutter: 0 }}
        renderItem={(item, idx) => 
          <List.Item style={{ width: '100%', padding: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Card className='cardselectyour' style={{ ...cardRedLineStyle, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ width: 195, height: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 30, fontWeight: 700 }}>
                  {_.get(VARIANT_TO_TEXT, _.get(item, 'type', ''), '')}
                </Text>
              </div>
              <Button className='buttoncalsspackages' style={buttonStyle} onClick={() => addToCart(item, packageData)}>
                {formatComma(_.get(item, 'price', ' - '))} {t("baht")}
              </Button>
            </Card>
          </List.Item>
        }
      />
      : 
      <Card className='cardselectyourallFighter' style={{...cardRedLineStyle}}>
            <Row>
            <Col span={16} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Text className='textpackagesmodel' style={{ fontSize: '33px', color:'#BF1E2D' ,fontWeight: 600 }}>
                Alpha Packages
              </Text>                
              <Text style={{fontSize:'24px'}}>
                {_.get(packageData, 'name', 'Unknow')}
              </Text>   
            </Col>
            <Col span={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button className='buttoncalsspackages' style={buttonStyleNew} onClick={() => addToCart(_, packageData)}>
                {formatComma(_.get(packageData, 'price', ' - '))} {t("baht")}
              </Button>
            </Col>
          </Row>
      </Card>
    }
  </div>  
  
  );
}

// button-kiosk