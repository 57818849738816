import React from 'react';


export default function Container({children, ...props}) {
  return (
    <div className='ahc-custom-container' style={props.style}>
      <style>{`
        .ahc-custom-container {
          max-width: 100%;
          margin: 0 auto;
          padding: 0 1rem;
        
          @media (min-width: 576px) {
            max-width: 576px;
          }
        
          @media (min-width: 768px) {
            max-width: 768px;
          }
        
          @media (min-width: 992px) {
            max-width: 992px;
          }
        }
      `}</style>
      {children}
    </div>
  )
}