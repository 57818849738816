import React from "react"
import propTypes from "prop-types";
import { Alert, Button, Form, Modal } from "antd";
import Header from "../common/Header";
import { POST } from "../../frameworks/HttpClient";
import TextArea from "antd/es/input/TextArea";
import SignaturePad from 'react-signature-canvas';
import useUserData from "../../hooks/useUserData";

export default function ReceiptActionModal(props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
	const [width, setWidth] = React.useState(0);
	const [target,setTarget] = React.useState(null)
	const padRef = React.useRef(null);
	const signatureRef = React.useRef(null);
	const user = useUserData();

	const handleAction = async () => {
		if (!props.url) {
			setErrorMessages("URL not found!");
			return;
		}
		// Validate data
		let data = await form.validateFields();
		data = {...data, ...props.data}
		// Handle signature require
		if (props.requireSignature) {
			let signatureError = "Signature is empty!"
			if (signatureRef && signatureRef.current) {
				const ref = signatureRef.current;
				if (!ref.isEmpty()) {
					data["signature"] = ref.getTrimmedCanvas().toDataURL("image/png");
					signatureError = null;
				}
			} 

			if (signatureError) {
				setErrorMessages(signatureError);
				return;
			}
		}
		
		setLoading(true);
		setErrorMessages(null);
		try {
			await POST(props.url, data);
			props.onSuccess();
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}
	}

	const renderFooter = () => {
		let footer = [<Button onClick={props.onClose}>Cancel</Button>];
		if (props.requireSignature)
			footer.push(<Button type={"primary"} ghost onClick={handleSignatureClear}>Clear</Button>);
		
		footer.push(<Button type={"primary"} loading={loading} onClick={handleAction}>Confirm</Button>);
		return footer;
	}

	const handleSignatureClear = () => {
		if (signatureRef && signatureRef.current) {
			signatureRef.current.clear();
		}
	}

	React.useEffect(() => {
		// Initial width for signature pad
		if (props.open && props.requireSignature 
				&& padRef && padRef.current && (width === 0)) {
			setWidth(padRef.current.offsetWidth);
		}

	}, [props.open, props.requireSignature, padRef]);

	React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			form.resetFields();
		} else {
			handleSignatureClear();
		}

	}, [props.open]);

	React.useEffect(() =>{
		const x = props.target
		setTarget(x);
	},[props.target])

	return (
		<Modal
      open={props.open}
      onOk={handleAction}
      onCancel={props.onClose}
			footer={renderFooter()}>
      <Header title={props.title} />
			{errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginTop: "12px", textAlign: "left"}}
				/>
			}
			<br/>
			<Form 
				form={form}
				style={{ marginTop: 16 }}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}>
				<Form.Item
					name="note"
					label="Note"
					rules={[{ required: props.requireNote }]}>
						<TextArea rows={4}/>
				</Form.Item>
				{props.requireSignature && (
					<Form.Item label="Signature">
						<div className="section-wrapper" ref={padRef}>
							<SignaturePad
								penColor={"blue"} 
								ref={signatureRef} 
								canvasProps={{width: (width ? (width - 32) : 0), height: 120}}
								/>
						</div>
					</Form.Item>
				)}
				
			</Form>
			{/* )} */}
		</Modal>
	)
}

ReceiptActionModal.defaultProps = {
	url: null,
	data: {},
	title: "Title",
	requireNote: false,
	requireSignature: false,
	open: false,
	onClose: () => {},
	onSuccess: () => {},
}

ReceiptActionModal.propTypes = {
	url: propTypes.string,
	data: propTypes.object,
	title: propTypes.string,
	requireNote: propTypes.bool,
	open: propTypes.bool,
	onClose: propTypes.func,
	onSuccess: propTypes.func,
}