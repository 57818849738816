import React from 'react'
import '../css/App.css'
import { useParams } from "react-router-dom";
import { QRCode, Card, Image, Avatar, Button } from "antd";
import { 
  ExclamationCircleOutlined,
  CloseOutlined
} from "@ant-design/icons"
import { formatComma, isNumeric, closeTab } from '../frameworks/Util';
import iconQR from '../assets/thai_qr_icon.png';
import banner from '../assets/thai_qr_banner.png';

const generatePayload = require('promptpay-qr');
const { Meta } = Card;

export default function PromptpayQRScreen() {
  const ACCOUNT = "0833559000672";
  const [content, setContent] = React.useState(null);
  const {amount} = useParams();

  React.useEffect(() => {
    if (amount != null && isNumeric(amount)) {
      setContent(generatePayload(ACCOUNT, { amount: parseFloat(amount) }));
    }
  }, [amount])
  

  return(
    <div className='center-screen'>
      <Card
        style={{
          width: 256,
        }}
        actions={[
          <Button onClick={closeTab}>Close</Button>
        ]}
      >
        {content && 
          <>
            <Image src={banner} preview={false}/>
            <QRCode value={content} icon={iconQR} size={205} />
            <br/>
            <Meta 
              title={`ยอดชำระ ${formatComma(amount)} บาท`} 
              description={`หจก.อัลฟ่าเฮลธ์คลับ ${ACCOUNT}`}
            />
          </>
        }
        {!content &&
          <Meta
            avatar={<Avatar icon={<ExclamationCircleOutlined/>} />}
            title="Wrong Parameter !!"
            description="Please check the url or contact administrator."
          />
        }
      </Card>
    </div>
  )
}
