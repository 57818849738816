import React, { useCallback, useEffect } from 'react';
import * as _ from 'lodash';
import { Layout, Space, Col, Row, Typography, Button, Form, Spin } from 'antd';
import PropTypes from 'prop-types';
import './CssScreen/KioskPaymentMethod.css';
import PromoCodeSearch from './kiosk-components/PromoCodeSearch';
import UsePointForDiscount from './kiosk-components/UsePointForDiscount';
import {DeleteOutlined, LeftCircleOutlined } from '@ant-design/icons';
import ChoosePaymentOptionFooter from './kiosk-components/ChoosePaymentOptionFooter';
import { PATH } from '../../routes/CustomRoutes';
import { useNavigate, useLocation, useParams  } from "react-router-dom";
import { URL_PACKAGE , URL_MEMBER, URL_SALE } from '../../constants/urls';
import { GET, POST } from '../../frameworks/HttpClient';
import useUserData from '../../hooks/useUserData';
import { useTranslation } from 'react-i18next';
import LogoutModalKiosk from "./kiosk-components/LogoutModalKiosk";
import { formatComma } from '../../frameworks/Util';

const { Content, Footer } = Layout;
const { Text } = Typography;


const layoutpayment = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const contentpaymenall = {
  width: '913px',
  height: 'auto',
  paddingLeft: '120px',
};

const VARIANT_TO_TEXT = {
  GYM: "Gym Only",
  POOL: "Pool Only",
  ALL: "Gym + Pool",
};



function PaymentMethodkiosk(props) {
  const [loading, setLoading] = React.useState(true);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [packageData, setPackagesData] = React.useState(null);
  const [member, setMember] = React.useState(null)
  const { user } = useUserData();
  const [ cartItem, setCartItem ] = React.useState(null);
  const [ cart, setCart ] = React.useState(null);
  const [ totalPrice, setTotalPrice ] = React.useState(0);;
  const [ netPrice, setNetPrice ] = React.useState(0);
  const [ discountList, setDiscountList] = React.useState([]);
  const [ voucherDiscountList, setVoucherDiscountList] = React.useState([]);
  const [ pointsDiscountList, setPointsDiscountList] = React.useState([]);
  const [ pointsDiscount, setPointDiscount] = React.useState(0);
  const [ voucherDiscount, setVoucherDiscount ] = React.useState(0);
  const promoRef = React.useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { packageId } = useParams();
  const { t , i18n } = useTranslation();
  

  const getCurrentLanguageDetail = (language) => {
    const languageMapping = {
        'en': 'detail',
        'ru': 'detail_ru',
        'th': 'detail_th',
    };
    return languageMapping[language] || '-';
};

  const fetchMember = async (id) => {
    if (!user.member) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.MEMBER}${id}`);
      setMember(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  const fetchCart = async (id) => {
    if (!user.member) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    try {
			const response = await GET(`${URL_SALE.CART_MEMBER}${user.member}/`);
			setCart(response.data);
			if (response.data && response.data.items) {
        setCartItem(response.data.items[0]);
			}
		} catch (error) {
			setErrorMessages(error.errorMessages);
		}
  }

	const updatePaymentDetail = async (discountList) => {
		if (!cart) {
			setErrorMessages("Cart not found!");
			return;
		}
    setLoading(true);
		setErrorMessages(null);
    let voucherAmount = 0
    let pointAmount = 0

	try {
  const response = await POST(
    `${URL_SALE.CART}${cart.id}/payment-detail/`, 
    { discounts: discountList }
  )

    setNetPrice(response.data.net_price < 0 ? 0 : response.data.net_price)

    if (discountList) {
      discountList.filter((e) => e.type === "KIOSK_VOUCHER").map((e) => voucherAmount = (parseInt(e.amount) + voucherAmount));
      discountList.filter((e) => e.type === "POINT").map((e) => pointAmount = (parseInt(e.amount) + pointAmount));
      setVoucherDiscount(voucherAmount);
      setPointDiscount(pointAmount);
    }

    setDiscountList(discountList);

    const totalPrice = response.data.total_price < 0 ? 0 : response.data.total_price;
    setTotalPrice(totalPrice);
  } catch (error) {
    setErrorMessages(error.errorMessages);
  } finally {
    setLoading(false);
  }
}

const fetchPackages = async (id) => {
  if (!id) {
    setErrorMessages(' error ')
    return;
  }

  setErrorMessages(null)
  try{
    const response = await GET(`${URL_PACKAGE.PACKAGES}${id}/`);
    setPackagesData(response.data)
  } catch (error) {
    setErrorMessages(error.errorMessages);
  }
}

const fetchPackagesAllClub = async (id) => {
  if (!id) {
    setErrorMessages(' error ')
    return;
  }

  setErrorMessages(null)
  try{
    const response = await GET(`${URL_PACKAGE.ALL_CLUB_PACKAGE}${id}/`);
    setPackagesData(response.data)
  } catch (error) {
    setErrorMessages(error.errorMessages);
  }
}

const fetchData = async() => {
  setLoading(true)
  try {
    if(user && user.member && packageId){
      await fetchMember(user.member);
      await fetchCart(user.member)
      if(params.type === 'main'){ 
        await fetchPackages(packageId)
      } else if (params.type === 'all-club'){
        await fetchPackagesAllClub(packageId)
      }
    } else {
      setErrorMessages('Data not found!')
    } 
  } catch (error) {
    setErrorMessages(error && error.errorMessages)
  } finally {
    setLoading(false)
  }
  
}

  React.useEffect(() => {
    fetchData();
  } ,[user])

  React.useEffect(() => {
    const discount = [...voucherDiscountList, ...pointsDiscountList]
    updatePaymentDetail(discount)
  }, [voucherDiscountList, pointsDiscountList])

  React.useEffect(() => {
    if(cart){
      updatePaymentDetail([])
    }
  },[cart])

  const backToMenu = async() => {
    await POST(`${URL_SALE.CART}${cart.id}/clear/`)
    navigate(PATH.KIOSK_MENU_PACKAGES);
  };

  const handleClearForPointInParent = () => {
    setPointsDiscountList([]); 
    setPointDiscount(0);
  };
  const handleClearForVoucherInParent = () => {
    if(promoRef.current){
      promoRef.current.cancelCode();
      setVoucherDiscountList([]); 
      setVoucherDiscount(0);
    }
  };

  return (
    <Layout style={layoutpayment} className="layoutpayment">
      <Spin spinning={loading} size={'large'}>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Layout className="contentpayment">
          <Col span={4}>
            <LeftCircleOutlined onClick={backToMenu} style={{ fontSize: '50px', color: '#3E3935', padding: '50px' }} />
          </Col>
          <Content style={contentpaymenall}>
            <Row gutter={[16, 50]}>
              <Col span={24} style={{paddingRight:'120px'}}>
                <Text className="fontSizepayment" style={{ alignItems: 'center' }}>Payment Method</Text>
              </Col>
              <Col>
                <Text style={{ fontSize: '48px', color: '#3E3935', textAlign: 'left' , paddingRight:'120px' }} className="name_pack">
                  {_.get(packageData, 'name', '-')}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text style={{ fontSize: '24px', color: '#3E3935', textAlign: 'left' , paddingRight:'120px'}} >
                {_.get(packageData, getCurrentLanguageDetail(i18n.language), '-')}
                </Text>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Text style={{ fontSize: '24px', color: '#3E3935', textAlign: 'right', fontWeight: '800' , paddingRight:'120px' }} className="condition_pack">
                  {cartItem ? VARIANT_TO_TEXT[cartItem.item_detail.split('- ', 2)[1]] : 'Unknown'} {`${cartItem ? formatComma(cartItem.price) : 0}`} {t('baht')}
                </Text>
              </Col>
            </Row>

            <Row gutter={[0, 16]} className="promocodesearch">
              <Col span={24} style={{paddingRight:'120px'}}>
                <PromoCodeSearch ref={promoRef} cart={cart && cart.id} addDiscountList={(discount) => setVoucherDiscountList(discount)} onClear={handleClearForVoucherInParent}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} className="usepoint-fordiscountcss">
              <Col span={24} style={{paddingRight:'120px'}}>
                <UsePointForDiscount cart={cart} member={member} addDiscountList={(discount) => setPointsDiscountList(discount)} onClear={handleClearForPointInParent}/>
              </Col>
            </Row>

            <Space>
              <Row gutter={[0, 20]}>
                <Col span={12} style={{ textAlign: 'left' }}>
                  <Text style={{ fontSize: '24px' }}>{t("order")}:</Text>
                </Col>
                <Col span={12} style={{ textAlign: 'right' , paddingRight:'120px' }}>
                  <Text style={{ fontSize: '24px', fontWeight: '600' }}>
                    {`${totalPrice ? formatComma(totalPrice) : 0}`}
                  </Text>
                </Col>

                <Col span={12} style={{ textAlign: 'left' }}>
                  <Text style={{ fontSize: '24px' }}>{t("platform_voucher_discount")}:</Text>
                </Col>
                <Col span={12} style={{ alignContent: 'right' , justifyContent:'end' , display:'flex' }}>
                  <Row style={{ paddingRight: '90px' }}>
                    <Text style={{ fontSize: '24px', color: '#BF1E2D', fontWeight: '500', marginRight : '30px' }}>
                      {`${voucherDiscount ? formatComma(voucherDiscount) : 0}`}
                    </Text>
                      {/* <Row style={{ paddingRight: '30px', width: '30px' }}>
                      {voucherDiscount > 0 && (
                        <DeleteOutlined 
                          style={{ 
                            color:'#B61F2D', 
                            fontSize:'24px', 
                            marginLeft: '10px', 
                            textAlign: 'center', 
                            justifyContent: 'center', 
                            height: '32px', 
                            display:'flex',
                            textShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.5)' 
                          }}                        
                          onClick={handleClearForVoucherInParent}
                        />
                      )}
                      </Row> */}
                  </Row>
                </Col>
                

                <Col span={12} style={{ textAlign: 'left' }}>
                  <Text style={{ fontSize: '24px' }}>{t("discount_from_points")}:</Text>
                </Col>
                <Col span={12} style={{ alignContent: 'right' , justifyContent:'end' , display:'flex' }}>
                  <Row style={{ paddingRight: '90px' }}>
                    <Text style={{ fontSize: '24px', color: '#BF1E2D', fontWeight: '500', marginRight : '30px' }}>
                      {`${pointsDiscount ? formatComma(pointsDiscount) : 0}`}
                    </Text>
                      {/* <Row style={{ paddingRight: '30px', width: '30px' }}>
                      {pointsDiscount > 0 && (
                            <DeleteOutlined 
                            style={{ 
                              color:'#B61F2D', 
                              fontSize:'24px', 
                              marginLeft: '10px', 
                              textAlign: 'center', 
                              justifyContent: 'center', 
                              height: '32px', 
                              display:'flex',
                              textShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.5)' 
                            }}
                            onClick={handleClearForPointInParent}
                          />
                      )}
                      </Row> */}
                  </Row>
                </Col>

              
                
                <Col span={12} style={{ textAlign: 'left'  }}>
                  <Text style={{ fontSize: '24px' }}>{t("summary")}:</Text>
                </Col>
                <Col span={12} style={{ textAlign: 'right', paddingBottom: '40px' , paddingRight:'120px' }}>
                  <Text style={{ fontSize: '30px', fontWeight: '800' }}>
                    {`${netPrice ?  formatComma(netPrice) : 0}`}
                  </Text>
                </Col>
              </Row>
            </Space>
          </Content>
          <Footer style={{ paddingTop: '0' }}>
            <ChoosePaymentOptionFooter member={member} discount={discountList} amount={netPrice}/>
          </Footer>
        </Layout>
      </Content>
      </Spin>
      <LogoutModalKiosk
        isLiff={false}
        idleTime={3}
  		/>

    </Layout>
    
  );
}

export default PaymentMethodkiosk;
