import { Avatar, Col, Row, Tag, Typography, notification } from 'antd';
import React from 'react';
import * as _ from 'lodash';
import { URL_COMMON } from '../../constants/urls';
import Cookies from 'js-cookie';
import { GET } from '../../frameworks/HttpClient';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { ExclamationCircleOutlined, LoginOutlined, LogoutOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ellipsisString } from '../../frameworks/Util';
import { PATH } from '../../routes/CustomRoutes';


const { Title, Text } = Typography;

const GATE_STATUS = {
	VALID: 'valid',
	INVALID: 'invalid',
	NOT_FOUND: 'not_found',
}

const WEBSOCKET_URL = {
  'prod': `wss://${window.location.hostname}/ws/gate/?token=${Cookies.get('ahc-token')}`,
  'dev': `ws://localhost:8000/ws/gate/?token=${Cookies.get('ahc-token')}`
}

const NotificationToast = ({ data }) => {
  const STATUS_LABEL = {
    [GATE_STATUS.VALID]: _.get(data, 'checked_in', false) ? <><LoginOutlined/> Checked In</> :  <><LogoutOutlined /> Checked Out</>,
    [GATE_STATUS.INVALID]: <><ExclamationCircleOutlined /> Invalid</>,
    [GATE_STATUS.NOT_FOUND]: <><QuestionCircleOutlined /> Not Found</>,
  }
  const STATUS_COLOR = {
    [GATE_STATUS.VALID]: _.get(data, 'checked_in', false) ? '#87d068' : '#108ee9',
    [GATE_STATUS.INVALID]: '#ff4d4f',
    [GATE_STATUS.NOT_FOUND]: '#f50',
  }

  const detail = _.get(data, 'detail', {})

  const gotoMemberDetail = () => {
    if (_.get(detail, 'id', false)) 
      window.open(`${PATH.MEMBER_DETAIL}${PATH.MEMBER_INFO}/${_.get(detail, 'id')}`, "_blank")
  }
  

  return (
    <Row wrap={false} gutter={[48, 0]} onClick={gotoMemberDetail}>
      <Col flex='none' style={{ textAlign:'center' }}>
        <div>
          <Avatar size={350} shape='circle' src={_.get(detail, 'image', '')} icon={<UserOutlined/>} />
        </div>
      </Col>

      <Col flex='auto'>
        <div>
          <Text style={{ fontSize: 20 }}  type='secondary'>{moment(_.get(data, 'timestamp', '')).format('h:mm a, Do MMMM YYYY')}</Text>
        </div>
        { _.get(detail, 'code', false) &&
          <Title style={{ margin: 0, fontSize: 48 }}>Code: {_.get(detail, 'code', '')}</Title>
        }
        <Title style={{ margin: 0, fontSize: 64 }}>{ellipsisString(_.get(detail, 'name', ''), 25)}</Title>
        { _.get(detail, 'package_name', false) &&
          <div><Text style={{ fontSize: 36 }} type='secondary'>Package: {_.get(detail, 'package_name')}</Text></div>
        }
        { _.get(detail, 'reward_point', null) != null &&
          <div><Text style={{ fontSize: 24 }} type='secondary'>Reward Point: {_.get(detail, 'reward_point')}</Text></div>
        }
        <Tag
          bordered={false}
          style={{ marginTop: '1rem', padding: '1rem 2rem 1rem 2rem', fontSize: '2rem', borderRadius: '1rem' }}
          color={_.get(STATUS_COLOR, _.get(data, 'status', ''))}
        >
          {_.get(STATUS_LABEL, _.get(data, 'status', ''))}
        </Tag>
        { _.get(data, 'note', false) &&
          <div>
            <Text style={{ fontSize: 24 }} type='danger'>{_.get(data, 'note')}</Text>
          </div>
        }
      </Col>
    </Row>
  )
}

export default function GateCheckInToast({ onMemberPass }) {
  const { lastMessage, readyState } = useWebSocket(WEBSOCKET_URL['prod'], {
    shouldReconnect: (evt) => true
  });
  const [api, contextHolder] = notification.useNotification();
  const [gateAlarmAudio, setGateAlarmAudio] = React.useState(null);
  const [gateCheckInAudio, setGateCheckInAudio] = React.useState(null);
  const [gateCheckOutAudio, setGateCheckOutAudio] = React.useState(null);

	const fetchReceptionAsset = async () => {
		try {
			const response = await GET(URL_COMMON.RECEPTION_ASSET)
      const alarmAudio = new Audio(_.get(response, 'data.gate_alarm', ''))
      alarmAudio.volume = 0.5
      setGateAlarmAudio(alarmAudio)
      setGateCheckInAudio(new Audio(_.get(response, 'data.gate_check_in', '')))
      setGateCheckOutAudio(new Audio(_.get(response, 'data.gate_check_out', '')))
		} catch (error) {
			console.log(error.errorMessages)
		}
	}

  const handleCheckInMessage = (data) => {
    if (_.get(data, 'status', GATE_STATUS.NOT_FOUND) === GATE_STATUS.INVALID && gateAlarmAudio) {
      gateAlarmAudio.play()
    } 
    else if (_.get(data, 'status', GATE_STATUS.NOT_FOUND) === GATE_STATUS.VALID) {
      onMemberPass();
      if(_.get(data, 'checked_in', false) && gateCheckInAudio) {
        gateCheckInAudio.play()
      }
      else {
        gateCheckOutAudio.play()
      }
    }
    api.open({
      description: <NotificationToast data={data}/>,
      style: { width: '1200px' },
      placement: 'bottomRight'
    })
  }

  React.useEffect(() => {
    fetchReceptionAsset();
  }, [])

  React.useEffect(() => {
		if (lastMessage) {
			handleCheckInMessage(JSON.parse(lastMessage.data))
		}
	}, [lastMessage])

  return (
    <>
      {contextHolder}
    </>
  )
}