import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal, Select, Spin, theme } from "antd";
import Header from '../common/Header';
import _ from "lodash";
import { URL_MEMBER, URL_PACKAGE } from '../../constants/urls';
import { GET, POST } from '../../frameworks/HttpClient';

export default function TransferPackageModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [debouncedMember, setDebouncedMember] = React.useState("");
  const [fetching, setFetching] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const [form] = Form.useForm();

  const handleConfirm = async () => {
    if (!props.package) {
      setErrorMessages("Package not found!");
      return;
    }
    // Handle transfer package
    let data = await form.validateFields();
    setErrorMessages(null);
    setLoading(true);
    let response = null;
    const targetMember = data.target_member.value
    data = { 
      ...data, 
      target_member: targetMember,
      member_package: props.package.id 
    }
    try {
      response = await POST(URL_PACKAGE.PACKAGE_TRANSFER, data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }

    if (response)
      props.onSuccess(targetMember);
  };

  // Delay search member
  const debounceMember = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedMember(_searchVal) }, 800),
    []
  );

  // Search member
  const fetchMembers = async () => {
    setFetching(true);
    setErrorMessages(null);
		try {
			const response = await GET(`${URL_MEMBER.MEMBER}?search=${debouncedMember}`)
			setMembers(response.data.results.map((item) => {
				return {
					...item,
					label: `[${item.code}] ${item.full_name}`,
					value: item.id
				}
			}))
		} catch (error) {
			setErrorMessages(error.errorMessages)
		}

		finally {
			setFetching(false);
		}
  }

  React.useEffect(() => {
		if (debouncedMember)
			fetchMembers();
	}, [debouncedMember])

  React.useEffect(() => {
    if (props.open && props.package) {
      const balance = (props.package.duration_remain / 30);
      console.log(props.package.duration_remain);
      console.log(parseInt(balance));
      form.setFieldsValue({balance: parseInt(balance)})
    } else {
      if (!props.open) {
        form.resetFields();
        setErrorMessages(null);
        setMembers([]);
      }
    }
  }, [props.open, props.package]);

  return (
    <Modal
      okText={"Confirm"}
      okButtonProps={{loading: loading}}
      open={props.open}
      onOk={handleConfirm}
      onCancel={props.onClose}>

      <Header title={"Transfer Package"} />
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginTop: "12px", textAlign: "left" }}
        />
      }

      <Form
        form={form}
        style={{ marginTop: 16 }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}>
    
        <Form.Item label={"Package"}>
          <Input autoComplete="off" value={props.package ? props.package.package_name : "Unknown"} disabled/>
        </Form.Item>

        <Form.Item name={"balance"} label={"Balance"}>
          <Input autoComplete="off" disabled/>
        </Form.Item>

        <Form.Item name={"target_member"} label={"Transfer to"} rules={[{ required: true }]}>
          <Select 
            placeholder="Name" 
            showSearch
            labelInValue
            filterOption={false}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onSearch={debounceMember}
            options={members} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

TransferPackageModal.defaultProps = {
  package: null,
  open: false,
  onClose: () => {},
  onSuccess: (another) => {},
}

TransferPackageModal.propTypes = {
  package: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
  onSuccess: propTypes.func,
}