import React from 'react'
import { BroadcastChannel } from 'broadcast-channel';
import { CHANNEL } from '../constants/string';
import { Button } from 'antd';
import VideoFullScreenView from '../views/VideoFullScreenView'
import MemberCheckInView from '../views/secondaryScreen/MemberCheckInView';


export default function SecondaryScreen() {
  const channel = new BroadcastChannel(CHANNEL.NAME);
	const [member, setMember] = React.useState(null);
	const [memberPackage, setMemberPackage] = React.useState(null);
	const [checkedIn, setCheckedIn] = React.useState(null);
	const checkInRef = React.useRef(null);

  const initialChannel = () => {
    if(channel != null) {
      channel.addEventListener('message', handleMessage)
    }
  }

  const destroyChannel = () => {
    if (channel != null) {
      channel.postMessage({ key: CHANNEL.CANCEL });
      channel.removeEventListener('message', handleMessage)
    }
  }

  const handleMessage = msg => {
    switch (msg != null && msg.key) {
      case CHANNEL.REQUEST_CHECK_IN:
				setMember(msg.member);
				setMemberPackage(msg.package);
				setCheckedIn(msg.checkedIn);
        break
      case CHANNEL.REQUEST_MANUAL_INPUT:
        if (checkInRef && checkInRef.current) {
					checkInRef.current.setLoading();
				}
        break
      case CHANNEL.CANCEL:
				setMember(null);
        break
      default:
        break
    }
  }

	const handleMemberCheckIn = (data) => {
		channel.postMessage({ 
			key: CHANNEL.RESPONSE_CHECK_IN,
			data: data
		});
	}

  React.useEffect(() => {
    initialChannel();
    return destroyChannel;
  }, []);

  return(
    <>
      {!member &&
        <VideoFullScreenView url="https://www.youtube.com/watch?v=rlJRlfdvkl4" />
      }

			{member && (
				<MemberCheckInView 
					ref={checkInRef}
					member={member}
					package={memberPackage}
					isCheckedIn={member ? member.is_working : false}
					checkedIn={checkedIn}
					onMemberCheckIn={handleMemberCheckIn}/>
			)}
			
    </>
    
  )
}
