import React from "react";
import { Button, Modal, theme, Form, Alert, Row, Col, Input, Card, Image, List } from "antd";
import Header from "../common/Header";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { URL_PRODUCT, URL_SALE } from "../../constants/urls";
import { GET, POST } from "../../frameworks/HttpClient";
import logo from "../../assets/ahc_logo.png";
import { formatComma } from "../../frameworks/Util";

export default function ProductModal(props) {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [productDetail, setProductDetail] = React.useState(null);

  const {
    token: { colorSuccess, colorTextSecondary },
  } = theme.useToken();

  const addToCart = async (data) => {
    if (!props.memberID || !data) {
        setErrorMessages("Data not found!");
        return;
    }
    let myData = {
        member: props.memberID,
        item: `${URL_PRODUCT.PRODUCTS}${data.id}/`
    }
    
    setErrorMessages(null);
    try {
        await POST(URL_SALE.CART_ITEM, myData);
        props.onAdded();
    } catch (error) {
        setErrorMessages(error.errorMessages);
    }
}

  const fetchData = async () => {
    let data = await form.validateFields();
    setErrorMessages(null);
    try {
        const response = await GET(`${URL_PRODUCT.PRODUCTS}?page_size=100`, data);
        setProductDetail(response.data.results);
    } catch (error) {
      console.log(error)
      setErrorMessages(error);
    }
}

  React.useEffect(() => {
    if (props.open) {
        setErrorMessages(null);
    } else {
      setErrorMessages(null);
    }
  }, [props.open]);

  React.useEffect(() => {
    fetchData().catch((err) => {})
  },[props.open])

  return (
    <Modal
      width={"760px"}
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}
      footer={[
        <Button type={"primary"} onClick={props.onClose}>
          {"Close"}
        </Button>,
      ]}
    >
      <Header title={"Other Product"}></Header>

      {errorMessages && (
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{
            marginBottom: "12px",
            marginTop: "12px",
            textAlign: "left",
          }}
        />
      )}

      <Row justify={"end"} style={{ marginTop: 16 }}>
        <Col md={12} sm={24} xs={24}>
          <Form form={form} layout={"horizontal"}>
            <Form.Item name={"search"} label={"Search"}>
              <Input.Search
                autoComplete="off"
                placeholder={"Search ..."}
                onSearch={fetchData}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
            <List
                grid={{gutter: 8, column: 1}}
                dataSource={productDetail}
                renderItem={(item) => (
            <List.Item key={item.id} style={{padding:0}}>
                <Card>
                  <Row>
                    <Col md={6} sm={24} xs={24} style={{ textAlign: "center" }}>
                      <Image
                        style={{ height: 86, width: "auto" }}
                        src={item.image ? item.image : logo}
                      />
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <p style={{ fontWeight: "bold", marginTop: 0 }}>
                        {item.name}
                      </p>
                      <div
                        style={{
                          lineHeight: "1.5em",
                          whiteSpace: "pre-wrap",
                          color: colorTextSecondary,
                        }}
                      >
                        {item.detail ? item.detail : ""}
                      </div>
                    </Col>
                    <Col md={6} sm={24} xs={24} style={{ textAlign: "right" }}>
                    <div >
						<Button
							type={"primary"}
							icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
							style={{
							width: "100%",
							background: colorSuccess,
							fontWeight: "bold", 
							margin: 0 }}
							onClick={() => addToCart(item)}>
						    {`${item.price ? formatComma(item.price) : "0"} Bath`}
						</Button>
					</div>
                    </Col>
                  </Row>
                </Card>
            </List.Item>
            )}
          />   
    </Modal>
  );
}
