import { Content } from "antd/es/layout/layout";
import Header from "../common/Header";
import { Table } from "antd";

export default function PDPAModalTHView(props) {
    
    const columns = [
		{
		  title: 'Personal Data',
		  dataIndex: 'personal',
		  key: 'personal',
		},
		{
		  title: 'Purpose',
		  dataIndex: 'objective',
		  key: 'objective',
		},
		{
		  title: 'Retention period',
		  dataIndex: 'phase',
		  key: 'phase',
		},
	  ];

	  const dataSource = [
		{
		  personal: <>- Photos of members <br/>
		  - Name, address, telephone number, e-mail or other communication channels <br/>
		  - Religious information</>,
		  objective:<>To be used for promotion of services, advertising and public relations, direct marketing, or entering into contracts.</>,
		  phase: <>Alpha Health Club will retain the personal information and documents of its members for 
		  the duration of their membership. Once membership has expired, Alpha Health Club 
		  will retain such personal information for 10 years from the date of termination of the contract.</>,
		}
	  ];

    return (
        <div>
            <Header
            fontSize={25}
                title={"Letter of Consent for the Collection, Use, and/or Disclosure of Sensitive Information"}
            />
			<Content style={{ margin: 62, textAlign: 'left', marginTop: 0,fontSize: 15 }}>
				<p style={{ fontWeight: 'bold' }}>I consent to Alpha Health Club Limited Partnership (hereinafter referred to as “Alpha Health Club”) to collect, use and/or disclose my information, including</p>
            <Table align={"center"} columns={columns} dataSource={dataSource} pagination={false}/>	
				<p>
				<b>Remarks :</b> In the event that there is information that requires explicit consent, 
				such as religion or blood group appearing in other documents or information that I have provided to Alpha Health Club 
				because the information provided has been given as a data set, I expressly consent that Alpha Health Club can destroy, 
				damage or opt out of recording such information.
				</p>
                <br/>
				<p>
				&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;I acknowledge that Alpha Health Club is the controller of this personal information. 
				Alpha Health Club will provide a security and safety system for personal information to prevent improper or harmful use 
				or disclosure or change without rights or law. This consent shall be effective from the date of signing until I have a written 
				notice of cancellation to Alpha Health Club, No. 9/56, Village No. 5, Wichit Sub-district, Mueang District, Phuket Province 83000, 
				Telephone number 076-612656, Email info@alphahealthclub.com
				</p>
			</Content>
        </div>
    )
}