import React, { useCallback } from 'react';
import propTypes from "prop-types";
import { Row, Form, Input, Select, Checkbox, Card, Col, Alert, Spin, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons'
import Title from 'antd/es/typography/Title';
import { CHOICES_CARD_TYPE, CHOICES_NAME_PREFIX } from '../../constants/string';
import _ from "lodash";
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_COMMON, URL_MEMBER } from '../../constants/urls';
import { SelectDatepicker } from 'react-select-datepicker';

const RegisterMemberView = React.forwardRef((props, ref) => {
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [subdistricts, setSubdistricts] = React.useState([]);
	const [debouncedCode, setDebouncedCode] = React.useState("");
	const [fetching, setFetching] = React.useState(false);
	const [useCurrentAddress, setUseCurrentAddress] = React.useState(true);
	const [warningPhoneMessages, setWarningPhoneMessages] = React.useState(null);
	const [warningEmailMessages, setWarningEMailMessages] = React.useState(null);
	const { TextArea } = Input;
	// Handle Nationality
	const [nationFetching, setNationFetching] = React.useState(false);
	const [nationOptions, setNationOptions] = React.useState([]);
	const [searchNation, setSearchNation] = React.useState("");

	const handleCheckbox = (e) => {
		setUseCurrentAddress(e.target.checked);
	}

	const debounceNation = React.useCallback(
		_.debounce((_searchVal) => { setSearchNation(_searchVal) }, 800), 
		[]
	)

	// Search nationality
	const fetchNationality = async () => {
		setNationFetching(true);
		try {
			const response = await GET(`${URL_COMMON.NATIONALITY}?search=${searchNation}`);
			setNationOptions(response.data.results.map((item) => {
				return {
					...item,
					label: item.name,
					value: item.name
				}
			}))
		} catch (error) {
			console.log(error.errorMessages)
		} finally {
			setNationFetching(false);
		}
	}

	const debounceSubdistrict = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

  const [value, setValue] = React.useState(null);

  const onDateChange = useCallback((date) => {

	if(date){
	const d = new Date(date)
	const day = d.getDate()
	const month = d.getMonth() +1
	const year = d.getFullYear()
	const formatDate = `${year}-${month}-${day}`
	form.setFieldsValue({birth_date : formatDate })
	}else{
		form.setFieldsValue({birth_date : date })
	}

  });

	// Search address
	const fetchSubdistrict = async () => {
		setFetching(true);
		try {
			const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}/`)
			setSubdistricts(response.data.results.map((item) => {
				return {
					...item,
					label: item.display_name,
					value: item.subdistrict_id
				}
			}))
		} catch (error) {
			console.log(error.errorMessages)
		}

		finally {
			setFetching(false);
		}
	}

	// Register
	const handleRegister = async () => {
		setErrorMessages(null);
		try {
			let data = await form.validateFields();
			// Handle nationality
			if (data["nationality"])
				data["nationality"] = data.nationality.value

			const subdistrict = data.sub_district;
			if (subdistrict) 
				data['sub_district'] = subdistrict.value;
			// Handle tax invoice address
			if (useCurrentAddress) {
				data['invoice'] = {
					invoice_name: `${data.first_name} ${data.last_name}`,
					invoice_id: data.id_card_code,
					invoice_address: `${data.address ? data.address : ""} ${subdistrict ? subdistrict.label : ""}`,
				}
			} else {
				data['invoice'] = {
					invoice_name: data.invoice_name,
					invoice_id: data.invoice_id,
					invoice_address: data.invoice_address,
					is_head_office: data.is_head_office,
					is_branch_office: data.is_branch_office
				}
				if (data['branch_name'])
					data['invoice']['branch_name'] = data.branch_name;
			}
			const response = await POST(URL_MEMBER.MEMBER, data);
			props.onSuccess(response.data);
		} catch (error) {
			window.scrollTo(0, 0)
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
		}
	}

	const handleNameChanged = async () => {
		const first_name = form.getFieldValue('first_name')
		const last_name = form.getFieldValue('last_name')
		if (first_name == null || last_name == null) {
			return
		}

		// validate first_name and last_name
		try {
			setErrorMessages(null)
			props.onLoading(true)
			await GET(URL_MEMBER.VALIDATE_NAME, {
				first_name: first_name,
				last_name: last_name
			});
			props.onValidation(false)
		} catch (error) {
			props.onValidation(true)
			setErrorMessages(error.errorMessages)
		} finally {
			props.onLoading(false)
		}
	}

	const handleMobilePhoneChanged = async () => {
		const mobile_phone = form.getFieldValue('mobile_phone')
		if (mobile_phone == null) {
			return;
		}

		// Validate mobile phone
		try {
			setWarningPhoneMessages(null)
			props.onLoading(true)
			await GET(URL_MEMBER.VALIDATE_PHONE, { mobile_phone: mobile_phone })
		} catch (error) {
			modal.warning({
				title: 'Phone number already been used by',
				content: error.errorMessages,
			});
			setWarningPhoneMessages(error.errorMessages)
		} finally {
			props.onLoading(false)
		}
	}

	const handleEmailChanged = async () => {
		const email = form.getFieldValue('email')
		if (email == null) {
			return;
		}

		// Validate Email
		try {
			setWarningEMailMessages(null)
			props.onLoading(true)
			await GET(URL_MEMBER.VALIDATE_EMAIL, { email: email })
		} catch (error) {
			modal.warning({
				title: 'Email already been used by',
				content: error.errorMessages,
			});
			setWarningEMailMessages(error.errorMessages)
		} finally {
			props.onLoading(false)
		}
	}

	React.useImperativeHandle(ref, () => ({
		save: async () => await handleRegister()
	}));

	React.useEffect(() => {
		fetchNationality();
	}, [searchNation])

	React.useEffect(() => {
		if (debouncedCode)
			fetchSubdistrict();
	}, [debouncedCode])

	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Card>
			{errorMessages && 
				<Alert
					message={errorMessages}
					type="error"
					showIcon
					style={{marginBottom: "12px", textAlign: "left"}}
				/>
			}
			{contextHolder}
			<Form 
				form={form}
				layout="vertical"
				autoComplete="off" >
				<Row gutter={16} >
					<Col md={4} sm={24} xs={24}>
						<Form.Item
							label="Title"
							name="name_prefix"
							rules={[{ required: true }]}>
							<Select options={CHOICES_NAME_PREFIX}/>
						</Form.Item>
					</Col>

					<Col md={10} sm={24} xs={24}>
						<Form.Item 
							name={"first_name"} 
							label="Firstname" 
							rules={[{ required: true }]}>
							<Input autoComplete="off" placeholder="Firstname..." onBlur={handleNameChanged}/>
						</Form.Item>
					</Col>

					<Col md={10} sm={24} xs={24}>
						<Form.Item
							name={"last_name"}
							label="Surname"
							rules={[{ required: true }]}>
							<Input autoComplete="off" placeholder="Surname..." onBlur={handleNameChanged}/>
						</Form.Item>
					</Col>

					<Col md={12} sm={24} xs={24}>
						<Form.Item 
								name={"birth_date"} 
								label="Date of Birth"
								rules={[{ required: true }]} >
						<SelectDatepicker
						className={'dropdown-date custom-mount'}
						order = "day/month/year"
						selectedDate={value}
						onDateChange={onDateChange}
						hideLabels={true}
						labels = {{
							"dayPlaceholder": "Day",
							"monthPlaceholder": "Month",
							"yearPlaceholder": "Year",
						}}
						/>
						</Form.Item>
					</Col>

					<Col md={12} sm={24} xs={24}>
						<Form.Item 
							name={"nationality"}
							label="Nationality"
							rules={[{ required: true }]} >
							<Select
								placeholder="Name" 
								showSearch
								labelInValue
								filterOption={false}
								notFoundContent={nationFetching ? <Spin size="small" /> : null}
								onSearch={debounceNation}
								options={nationOptions} />
							{/* <Input autoComplete="off"  placeholder={"Nationality..."}/> */}
						</Form.Item>
					</Col>

					<Col md={12} sm={24} xs={24}>
						<Form.Item 
							name={"mobile_phone"}
							label="Mobile Phone"
							rules={[{ required: true }]}>
							<Input
								status={warningPhoneMessages && "warning"}
								prefix={warningPhoneMessages && <WarningOutlined />}
								autoComplete="off"
								type='tel'
								placeholder='Mobile Phone...'
								onBlur={handleMobilePhoneChanged} />
						</Form.Item>
					</Col>

					<Col md={12} sm={24} xs={24}>
						<Form.Item 
							name={"email"}
							label="Email"
							rules={[{ required: true }]}>
							<Input
								status={warningEmailMessages && "warning"}
								prefix={warningEmailMessages && <WarningOutlined />}
								autoComplete="off"
								type='email'
								placeholder='Email...'
								onBlur={handleEmailChanged} />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item 
							name={"address"} 
							label="Address"
							rules={[{ required: true }]} >
							<Input.TextArea autoComplete="off" placeholder='Address....' />
						</Form.Item>

						<Form.Item 
							name={"sub_district"} 
							label="Subdistrict / District / Province - Zip Code">
							<Select 
								defaultValue={"-"}
								autoComplete="off" 
								showSearch
								labelInValue
								filterOption={false}
								notFoundContent={fetching ? <Spin size="small" /> : "-"}
								onSearch={debounceSubdistrict}
								options={subdistricts}
								allowClear={"-"}/>
						</Form.Item>
					</Col>

					<Col md={8} sm={24} xs={24}>
						<Form.Item 
							name={"id_card_type"}
							label="Card Type">
							<Select options={CHOICES_CARD_TYPE}/>
						</Form.Item>
					</Col>

					<Col md={8} sm={24} xs={24}>
						<Form.Item 
							name={"id_card_code"}
							label="Number" >
							<Input autoComplete="off"  placeholder="Card ID /Passport ID...." />
						</Form.Item>
					</Col>

					<Col md={8} sm={24} xs={24}>
						<Form.Item 
							name={"line_id"}
							label="Line ID">
							<Input autoComplete="off" placeholder="Line ID..." />
						</Form.Item>
					</Col>
				</Row>

				{/* Tax Invoice */}
				<Col span={24}>
					<Title level={5}>{"Tax Invoice Address"}</Title>
					<Checkbox 
						style={{marginBottom: 8}}
						 onChange={handleCheckbox}
						 checked={useCurrentAddress}>
							Current Address of Tax Invoice
					</Checkbox>
				</Col>
				{!useCurrentAddress && (
					<Card>
						<Row gutter={16}>
							<Col md={6} sm={24} xs={24}>
								<Form.Item 
									name="is_head_office" 
									valuePropName="checked">
									<Checkbox>Head Office</Checkbox>
								</Form.Item>
							</Col>

							<Col md={4} sm={24} xs={24}>
								<Form.Item 
									name="is_branch_office" 
									valuePropName="checked">
									<Checkbox>Branch</Checkbox>
								</Form.Item>
							</Col>

							<Col md={6} sm={24} xs={24} style={{marginTop: "auto", marginBottom: "auto"}}>
								<Form.Item 
									name="branch_name">
									<Input autoComplete="off" placeholder='Branch Name...'/>
								</Form.Item>
							</Col>

							<Col md={16} sm={24} xs={24}>
								<Form.Item
									name={"invoice_name"}
									label="Name" 
									rules={[{ required: true }]}>
									<Input autoComplete="off" placeholder={"Name..."}/>
								</Form.Item>
							</Col>

							<Col md={8} sm={24} xs={24}>
								<Form.Item 
									name={"invoice_id"}
									label="Tax ID"
									rules={[{ required: true }]}>
									<Input autoComplete="off" />
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item 
									name={"invoice_address"}
									label="Address"
									rules={[{ required: true }]}>
									<TextArea placeholder='Address....' />
								</Form.Item>
							</Col>
						</Row>
					</Card>
				)}
				
			</Form>
		</Card>
	)
})

RegisterMemberView.defaultProps = {
	onSuccess: (data) => null,
	onLoading: (isLoading) => null,
	onValidation: (isError) => null,
}

RegisterMemberView.propTypes = {
	onSuccess: propTypes.func,
	onLoading: propTypes.func,
	onValidation: propTypes.func,
}

export default React.memo(RegisterMemberView);