import React from 'react'
import propTypes from "prop-types";
import { Button, Descriptions, Modal } from "antd";
import Header from '../../common/Header';
import { toHumanDate } from '../../../frameworks/Util';

export default function DetailClassBookingModal(props) {

  return (
    <Modal
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}
      footer={[
        <Button type={"primary"} onClick={props.onClose}>{"OK"}</Button>
      ]}>
        
      <Header title={(props.class && props.class.class_type_name === "Appointment") ? "Session Detail" : "Class Detail"} />
      <Descriptions column={1} style={{ marginTop: 16 }}>
        {(props.class && props.class.class_type_name) && (
          <Descriptions.Item
            label={"Class"}
            style={{ fontWeight: "bold" }}>
            {props.class.class_type_name}
          </Descriptions.Item>
        )}
      </Descriptions>
      
      <Descriptions column={2}>
        {(props.class && props.class.studio_name) && (
          <Descriptions.Item
            label={"Studio"}
            style={{ fontWeight: "bold" }}>
            {props.class.studio_name}
          </Descriptions.Item>
        )}

        {(props.class && props.class.trainer_name) && (
          <Descriptions.Item
            label={"Trainer"}
            style={{ fontWeight: "bold" }}>
            {props.class.trainer_name}
          </Descriptions.Item>
        )}
      </Descriptions>
            
      <Descriptions column={2}>
        {(props.class && props.class.date) && (
          <Descriptions.Item
            label={"Date"}
            style={{ fontWeight: "bold" }}>
            {toHumanDate(props.class.date)}
          </Descriptions.Item>
        )}

        {(props.class && props.class.start_time && props.class.end_time) && (
          <Descriptions.Item
            label={"Time"}
            style={{ fontWeight: "bold" }}>
            {`${props.class.start_time.substring(0, 5)} - ${props.class.end_time.substring(0, 5)}`}
          </Descriptions.Item>
        )}

        {(props.class && props.class.members_limit) && (
          <Descriptions.Item
            label={"Limit"}
            style={{ fontWeight: "bold" }}>
            {props.class.members_limit}
          </Descriptions.Item>
        )}

        {(props.class && props.class.booked_count) && (
          <Descriptions.Item
            label={"Booked"}
            style={{ fontWeight: "bold" }}>
            {props.class.booked_count}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Modal>
  )
};

DetailClassBookingModal.defaultProps = {
  class: null,
  open: false,
  onClose: () => { },
}

DetailClassBookingModal.propTypes = {
  class: propTypes.object,
  open: propTypes.bool,
  onClose: propTypes.func,
}