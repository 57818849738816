import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Card, Col, Empty, Row, theme, Menu } from 'antd';
import "./../../../styles.css"
import InformationCardView from '../InformationCardView';
import { ShoppingCartOutlined, HistoryOutlined, GiftOutlined, FileSearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import MembershipPackageModal from '../../package/MembershipPackageModal';
import AdditionalPackageModal from '../../package/AdditionalPackageModal';
import ProductModal from '../../package/ProductModal';
import CardMemberPackage from '../../package/CardMemberPackage';
import CardAdditionalPackage from '../../package/CardAdditionalPackage';
import CardOtherPackage from '../../package/CardOtherPackage';
import { GET } from '../../../frameworks/HttpClient';
import { URL_PACKAGE } from '../../../constants/urls';
import AllClubPackageModal from '../../package/AllClubPackageModal';
import CouponModal from '../../package/CouponModal';
import { useNavigate } from 'react-router-dom';
import { PATH } from "../../../routes/CustomRoutes";
import PackageHistory from "../../../components/member/PackageHistory";
import UsageHistory from '../UsageHistory';
import PackagePenaltyList from '../PackagePenaltyList';
import * as _ from 'lodash';


export default function PackagesView(props) {
  const navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = React.useState('active')
  const [errorMessages, setErrorMessages] = React.useState(null);
  // Handle Package modal
  const [openMembershipModal, setOpenMembershipModal] = React.useState(false);
  const [openAdditionalModal, setOpenAdditionalModal] = React.useState(false);
  const [openAllClubModal, setOpenAllClubModal] = React.useState(false);
  const [openCouponModal, setOpenCouponModal] = React.useState(false);
  const [openProductModal, setOpenProductModal] = React.useState(false);
  // Main package => package for handle check-in/check-out
  const [mainPackages, setMainPackages] = React.useState([]);
  // Additional package => [pt session]
  const [additionalPackage, setAdditionalPackage] = React.useState([]);
  // Other package => [dynamic club, coupon]
  const [otherPackage, setOtherPackage] = React.useState([]);

  const isBlacklisted = _.get(props, 'member.is_blacklisted', false);

  const {
    token: { colorSuccess }
  } = theme.useToken();

  const gotoAnotherCart = (another) => navigate(`${PATH.MEMBER_DETAIL}${PATH.CART}/${another}/`)

  const menuItems = [
    {
      label: 'Active Packages',
      key: 'active',
      icon: <GiftOutlined />,
    },
    {
      label: 'Package History',
      key: 'package_history',
      icon: <FileSearchOutlined />,
    },
    {
      label: 'Usage',
      key: 'usage',
      icon: <HistoryOutlined />,
    },
    {
      label: 'Day Penalty',
      key: 'penalty',
      icon: <ExclamationCircleOutlined />
    }
  ]

  const fetchData = async () => {
    if (!props.member) {
      setErrorMessages("Member not found!");
      return;
    }

    setErrorMessages(null);
    const id = props.member.id
    try {
      const response = await GET(`${URL_PACKAGE.PACKAGES_MEMBER}${id}/`);
      setMainPackages(response.data.membership_packages);
      setAdditionalPackage(response.data.additional_packages.map(
        item => ({...item , isPartner: (id !== item.member)})));
      // Coupon
      let temp = [];
      const dynamics = response.data.other_packages ? response.data.other_packages.dynamic_packages : [];
      for (let dynamic of dynamics) {
        temp.push({
          ...dynamic,
          package_name: dynamic.name,
          title: `${dynamic.name}`,
          label: ``,
          detail: ``
        })
      }
      const coupons = response.data.other_packages ? response.data.other_packages.coupons : [];
      for (let coupon of coupons) {
        temp.push({
          ...coupon,
          package_name: coupon.name,
          title: `[Coupon] ${coupon.name}`,
          label: `Code: ${coupon.code}`,
          detail: `[CODE] ${coupon.code} \n${coupon.detail}`,
          end_date: coupon.expired
        })
      }
      setOtherPackage(temp);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }
  
  React.useEffect(() => {
    if (props.member) {
      fetchData();
    }
  }, [props.member]);

  return (
    <div>
      
        <Row gutter={[16, 16]}>
          <Col md={18} sm={24} xs={24} >
            {errorMessages && 
              <Alert
                message={errorMessages}
                type="error"
                showIcon
                style={{marginBottom: "12px", textAlign: "left"}}
              />
            }
            <Menu onClick={(e) => setCurrentMenu(e.key)} selectedKeys={[currentMenu]} mode="horizontal" items={menuItems} />
            
            {currentMenu === "active" &&
              <Card style={{ textAlign: 'center', width: '100%' }} >
                {/* Main Package*/}
                <Row >
                  <Col md={8} sm={24} xs={24}  >
                    <p style={{ fontWeight: "bold", textAlign: 'left', }}>Membership Package</p>
                  </Col>
                  <Col md={16} sm={24} xs={24} style={{ textAlign: 'right', }}>
                    { !isBlacklisted && (
                      <span>
                        <Button
                          onClick={() => setOpenAllClubModal(true)}
                          icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
                          type={"primary"}
                          style={{ background: colorSuccess, marginRight: 8 }}>
                            {"All Club"}
                        </Button>
                        <Button
                          onClick={() => setOpenMembershipModal(true)}
                          icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
                          type={"primary"}
                          style={{ background: colorSuccess }}>
                            {"Alpha"}
                        </Button>
                      </span>
                    ) }
                  </Col>
                </Row>

                <Card style={{width: '100%', textAlign: 'left'}}>
                  <Row justify={"left"} gutter={[16, 16]} >
                    {mainPackages.map(item => {
                      return (
                        <Col key={item.id} md={8} sm={24} xs={24}  >
                          <CardMemberPackage 
                            data={item} 
                            onRefresh={fetchData}
                            onCartUpdate={props.onRefreshMenu}/>
                        </Col>
                      )
                    })}
                  </Row>
                  {mainPackages && (mainPackages.length === 0) && (<Empty />)}
                </Card>
                <br />

                {/* Additional Package*/}
                <Row >
                  <Col md={8} sm={24} xs={24}>
                    <p style={{ fontWeight: "bold", textAlign: 'left', }}>Additional Package</p>
                  </Col>
                  <Col md={16} sm={24} xs={24} style={{ textAlign: 'right', }}>
                    { !isBlacklisted && 
                      <Button
                        onClick={() => setOpenAdditionalModal(true)}
                        icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
                        type={"primary"}
                        style={{ background: colorSuccess }}>
                          {"Purchase"}
                      </Button>
                    }
                  </Col>
                </Row>
                <Card style={{width: '100%', textAlign: 'left'}}>
                  <Row justify={"left"} gutter={[16, 16]}>
                    {additionalPackage.map(item => {
                      return (
                        <Col key={item.id} md={8} sm={24} xs={24}>
                          <CardAdditionalPackage 
                            data={item} 
                            refresh={fetchData}/>
                        </Col>
                      )
                    })}
                  </Row>
                  {additionalPackage && (additionalPackage.length === 0) && (<Empty />)}
                </Card>
                <br />

                {/* Other*/}
                <Row >
                  <Col md={8} sm={24} xs={24}  >
                    <p style={{ fontWeight: "bold", textAlign: 'left', }}>Other</p>
                  </Col>
                  <Col md={16} sm={24} xs={24} style={{ textAlign: 'right', }}>
                    { !isBlacklisted && (
                      <span>
                        <Button
                          onClick={() => setOpenCouponModal(true)}
                          icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
                          type={"primary"}
                          style={{ background: colorSuccess, marginRight: 8}}>
                          {"Coupon"}
                        </Button>
                        <Button
                          onClick={() => setOpenProductModal(true)}
                          icon={<ShoppingCartOutlined style={{ fontSize: 18 }} />}
                          type={"primary"}
                          style={{ background: colorSuccess }}>
                          {"Other"}
                        </Button>
                      </span>
                    )}
                  </Col>
                </Row>
                <Card style={{width: '100%', textAlign: 'left'}}>
                  <Row justify={"left"} gutter={[16, 16]}>
                    {otherPackage.map(item => {
                      return (
                        <Col key={item.id} md={8} sm={24} xs={24}  >
                          <CardOtherPackage data={item} />
                        </Col>
                      )
                    })}
                  </Row>
                  {otherPackage && (otherPackage.length === 0) && (<Empty />)}
                </Card>

              </Card>
            }

            { currentMenu === "package_history" &&
              <PackageHistory member={props.member ? props.member.id : null}/>
            }

            {currentMenu === "usage" &&
              <UsageHistory id={props.member ? props.member.id : "unknown"} isMember={false}/>
            }

            { currentMenu === "penalty" && 
              <PackagePenaltyList member={_.get(props, 'member.id', null)}/>
            }
          </Col>

          <Col
            span={6}
            style={{ textAlign: 'center' }}>
            <InformationCardView member={props.member} onUpdated={props.onUpdateSuccess} />
          </Col>
        </Row>

      <MembershipPackageModal
        open={openMembershipModal}
        memberID={props.member ? props.member.id : null}
        onAdded={() => {
          setOpenMembershipModal(false);
          props.onRefreshMenu();
        }}
        onClose={() => setOpenMembershipModal(false)}
      />

      <AdditionalPackageModal
        open={openAdditionalModal}
        memberID={props.member ? props.member.id : null}
        onAdded={() => {
          setOpenAdditionalModal(false);
          props.onRefreshMenu();
        }}
        onClose={() => setOpenAdditionalModal(false)}
      />

      <AllClubPackageModal 
        open={openAllClubModal}
        memberID={props.member ? props.member.id : null}
        onAdded={() => {
          setOpenAllClubModal(false);
          props.onRefreshMenu();
        }}
        onClose={() => setOpenAllClubModal(false)}
      />

      <CouponModal 
        open={openCouponModal}
        memberID={props.member ? props.member.id : null}
        onAdded={() => {
          setOpenCouponModal(false);
          props.onRefreshMenu();
        }}
        onClose={() => setOpenCouponModal(false)}
      />

      <ProductModal
        open={openProductModal}
        memberID={props.member ? props.member.id : null }
        onAdded={() => {
          setOpenProductModal(false);
          props.onRefreshMenu();
        }}
        onClose={() => setOpenProductModal(false)}
        />
    </div>
  )
}

PackagesView.defaultProps = {
  member: null,
  onUpdated: () => {},
  onRefreshMenu: () => {},
}

PackagesView.propTypes = {
  member: propTypes.object,
  onUpdated: propTypes.func,
  onRefreshMenu: propTypes.func,
}